export const CREATE_TABLE_REQUEST = 'CREATE_TABLE_REQUEST'
export const CREATE_TABLE_SUCCESS = 'CREATE_TABLE_SUCCESS'
export const CREATE_TABLE_FAILED = 'CREATE_TABLE_FAILED'

export const GET_TABLE_REQUEST = 'GET_TABLE_REQUEST'
export const GET_TABLE_SUCCESS = 'GET_TABLE_SUCCESS'
export const GET_TABLE_FAILED = 'GET_TABLE_FAILED'

export const GET_TABLES_REQUEST = 'GET_TABLES_REQUEST'
export const GET_TABLES_SUCCESS = 'GET_TABLES_SUCCESS'
export const GET_TABLES_FAILED = 'GET_TABLES_FAILED'

export const SAVE_STATE_TABLE = 'SAVE_STATE_TABLE'

export const SAVE_STATE_TOURNAMENT_TABLE = 'SAVE_STATE_TOURNAMENT_TABLE'

export const SAVE_STATE_TABLE_ASSETS = 'SAVE_STATE_TABLE_ASSETS'