import React from "react";
import "./chatBox.css";

const ChatBox = ({chat}) => {
  return (
    <div className="chatbox">
      <img className="chatbox-img" src={chat?.image} alt="" />
      <div className="chatbox-text">
        <div className="chatbox-text-name flex items-center gap-[6px]">
          {chat?.username || chat?.walletAddress?.substring(0, 10) + '...' + chat?.walletAddress?.substring(chat?.walletAddress?.length - 8, chat?.walletAddress?.length)}

          {chat?.isSpectator && <div className="spectator-box-chat">spectator</div>}
        </div>
        {chat?.gif ?
          <img src={chat?.gif} alt="" style={{ height: '60px', width: '60px' }} />
        :
          <pre className="chatbox-text-content whitespace-pre-line break-all">
            {chat?.text}
          </pre>
        }
      </div>
    </div>
  );
};

export default ChatBox;
