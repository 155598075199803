import axios from "axios"
import { store } from "../../store"
import { CREATE_COMMUNITY_FAILED, CREATE_COMMUNITY_REQUEST, CREATE_COMMUNITY_SUCCESS, EXCHANGE_FAILED, EXCHANGE_REQUEST, EXCHANGE_SUCCESS, GET_COINS_FROM_KURAMA_FAILED, GET_COINS_FROM_KURAMA_REQUEST, GET_COINS_FROM_KURAMA_SUCCESS, GET_COMMUNITY_BY_ID_FAILED, GET_COMMUNITY_BY_ID_REQUEST, GET_COMMUNITY_BY_ID_SUCCESS, GET_COMMUNITY_FAILED, GET_COMMUNITY_REQUEST, GET_COMMUNITY_SUCCESS, GET_ROUND_FAILED, GET_ROUND_REQUEST, GET_ROUND_SUCCESS } from "../constants/CommunityConstant"
import { BACKEND_URL, ErrorMessage } from "../../constants"
import { ALL_CHAT_FAILED, ALL_CHAT_REQUEST, ALL_CHAT_SUCCESS } from "../constants/userConstant"

export const uploadToCloudinary500 = async (asset) => {
    try {
        const preset = 'FoxLedgerStudio-BLVCK'
        const url = 'https://api.cloudinary.com/v1_1/foxledgerstudio/image/upload/'

        const formData = new FormData()
        formData.append('file', asset)
        formData.append('upload_preset', preset)
        const { data } = await axios.post(
            url,
            formData
        )

        if (data.url) {
            const oldUrl = data.url.split('/upload')
            const removeHttp = oldUrl[0].split('//')
            const newUrl = 'https://' + removeHttp[1] + '/upload/g_xy_center,q_auto:best' + oldUrl[1]
            return newUrl
        }
    } catch (error) {
        console.log(error)
    }
}

export const uploadToCloudinary1000 = async (asset) => {
    try {
        const preset = 'FoxLedgerStudio-BLVCK'
        const url = 'https://api.cloudinary.com/v1_1/foxledgerstudio/image/upload/'

        const formData = new FormData()
        formData.append('file', asset)
        formData.append('upload_preset', preset)
        const { data } = await axios.post(
            url,
            formData
        )

        if (data.url) {
            const oldUrl = data.url.split('/upload')
            const removeHttp = oldUrl[0].split('//')
            const newUrl = 'https://' + removeHttp[1] + '/upload/g_xy_center,q_auto:best' + oldUrl[1]
            return newUrl
        }
    } catch (error) {
        console.log(error)
    }
}

export const uploadToCloudinary1500 = async (asset) => {
    try {
        const preset = 'FoxLedgerStudio-BLVCK'
        const url = 'https://api.cloudinary.com/v1_1/foxledgerstudio/image/upload/'

        const formData = new FormData()
        formData.append('file', asset)
        formData.append('upload_preset', preset)
        const { data } = await axios.post(
            url,
            formData
        )

        if (data.url) {
            const oldUrl = data.url.split('/upload')
            const removeHttp = oldUrl[0].split('//')
            const newUrl = 'https://' + removeHttp[1] + '/upload/g_xy_center,q_auto:best' + oldUrl[1]
            return newUrl
        }
    } catch (error) {
        console.log(error)
    }
}

export const createCommunityAction =
    (communityInfo, tableTemplate, createGame, coinSetup, setupData, navigate) =>
    async (dispatch) => {
        try {
            dispatch({ type: CREATE_COMMUNITY_REQUEST })
            const userLoginReducer = store.getState().userLoginReducer
            const token = userLoginReducer?.accessToken

            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + token,
                },
            }

            const img1 = await uploadToCloudinary1000(communityInfo.backgroundImage)
            const img2 = await uploadToCloudinary1000(tableTemplate.dealersImage)
            const img3 = await uploadToCloudinary1000(tableTemplate.backgroundSymbol)
            const img4 = await uploadToCloudinary1000(createGame.tableMascott)

            communityInfo = {...communityInfo, backgroundImage: img1}
            tableTemplate = {...tableTemplate, dealersImage: img2, backgroundSymbol: img3}
            createGame = {...createGame, tableMascott: img4}

            const { data } = await axios.post(
                `${BACKEND_URL}/community/createCommunity`,
                {
                    // projectName : communityInfo.projectName,
                    communityName: communityInfo.communityName,
                    backgroundImage: communityInfo.backgroundImage,
                    communitySlug: communityInfo.communitySlug,
                    tableInfo: tableTemplate,
                    gameInfo: createGame,
                    personalCoinSetup: setupData,
                    verificationHash: userLoginReducer?.verificationHash,
                },
                config
            )
            if (data) {
                dispatch({
                    type: CREATE_COMMUNITY_SUCCESS,
                    payload: data,
                })

                navigate('/community')
            } else
                dispatch({
                    type: CREATE_COMMUNITY_FAILED,
                    payload: data.msg,
                })
        } catch (error) {
            dispatch({
                type: CREATE_COMMUNITY_FAILED,
                payload: ErrorMessage(error),
            })
        }
    }

export const updateCommunityAction =
    (communityData, communityId, navigate) =>
    async (dispatch) => {
        try {
            dispatch({ type: CREATE_COMMUNITY_REQUEST })
            const userLoginReducer = store.getState().userLoginReducer
            const token = userLoginReducer?.accessToken

            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + token,
                },
            }

            const bannerImg1 = communityData?.bannerImage1 && typeof communityData?.bannerImage1 !== 'string' ? await uploadToCloudinary1000(communityData?.bannerImage1) : communityData?.bannerImage1
            const bannerImg2 = communityData?.bannerImage2 && typeof communityData?.bannerImage2 !== 'string' ?  await uploadToCloudinary1000(communityData.bannerImage2) : communityData?.bannerImage2
            const bannerImg3 = communityData?.bannerImage3 && typeof communityData?.bannerImage3 !== 'string' ? await uploadToCloudinary1000(communityData.bannerImage3) : communityData?.bannerImage3
            const backgroundImg1 = communityData?.backgroundImage1 && typeof communityData?.backgroundImage1 !== 'string' ? await uploadToCloudinary1500(communityData.backgroundImage1) : communityData?.backgroundImage1
            const backgroundImg2 = communityData?.backgroundImage2 && typeof communityData?.backgroundImage2 !== 'string' ? await uploadToCloudinary1500(communityData.backgroundImage2) : communityData?.backgroundImage2
            const backgroundImg3 = communityData?.backgroundImage3 && typeof communityData?.backgroundImage3 !== 'string' ? await uploadToCloudinary1500(communityData.backgroundImage3) : communityData?.backgroundImage3

            const pageBanners = [bannerImg1, bannerImg2, bannerImg3]
            const backgroundImages = [backgroundImg1, backgroundImg2, backgroundImg3]

            const { data } = await axios.post(
                `${BACKEND_URL}/community/updateCommunity`,
                {
                    communityName: communityData?.communityName,
                    pageBanners,
                    pageBannerId : communityData?.bannerSelected,
                    backgroundImages,
                    communityId,
                    backgroundImageId : communityData?.backgroundSelected,
                    url: communityData?.url,
                    theme : communityData?.themeColour,
                    verificationHash: userLoginReducer?.verificationHash,
                },
                config
            )
            if (data?.status === 200) {
                dispatch({
                    type: CREATE_COMMUNITY_SUCCESS,
                    payload: data,
                })
                dispatch(getCommunity(communityData?.url))
                // if(navigate) navigate('/community')
                // dispatch(getCommunities())
            } else
                dispatch({
                    type: CREATE_COMMUNITY_FAILED,
                    payload: data.msg,
                })
        } catch (error) {
            dispatch({
                type: CREATE_COMMUNITY_FAILED,
                payload: ErrorMessage(error),
            })
        }
    }

export const getCommunities =
    () =>
    async (dispatch) => {
        try {
            dispatch({ type: GET_COMMUNITY_REQUEST })
            const userLoginReducer = store.getState().userLoginReducer
            const token = userLoginReducer?.accessToken

            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + token,
                },
            }

            const { data } = await axios.get(
                `${BACKEND_URL}/community/getCommunities`,
                config
            )

            const { data: newCommunityData } = await axios.get(
                `${BACKEND_URL}/community/newOnboardedCommunities`,
                config
            )

            if (data) {
                let arr = data?.communities
                arr.reverse()
                dispatch({
                    type: GET_COMMUNITY_SUCCESS,
                    payload: arr,
                    newOnboarding: newCommunityData?.communities || []
                })
            } else
                dispatch({
                    type: GET_COMMUNITY_FAILED,
                    payload: data.msg,
                })
        } catch (error) {
            dispatch({
                type: GET_COMMUNITY_FAILED,
                payload: ErrorMessage(error),
            })
        }
    }

export const getCommunity = (id) => async(dispatch) => {
    try {
        dispatch({ type: GET_COMMUNITY_BY_ID_REQUEST })

        const config = {
            headers: {
                'content-type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `${BACKEND_URL}/community/getCommunity/${id}`,
            config
        )
        if (data?.status === 200) {
            dispatch({
                type: GET_COMMUNITY_BY_ID_SUCCESS,
                community: data?.community,
                assets: data?.assets,
            })
        } else
            dispatch({
                type: GET_COMMUNITY_BY_ID_FAILED,
                payload: data.msg,
            })
    } catch (error) {
        dispatch({
            type: GET_COMMUNITY_BY_ID_FAILED,
            payload: ErrorMessage(error),
        })
    }
}

export const getAllChats = (tableId) => async(dispatch) => {
    try{
        dispatch({ type: ALL_CHAT_REQUEST })

        const config = {
            headers: {
                'content-type': 'application/json',
            },
        }

        await axios
            .get(
                `${BACKEND_URL}/community/getChats/${tableId}`,
                config
            )
            .then((res) => {
                if(res.data.status === 200) {
                    dispatch({
                        type: ALL_CHAT_SUCCESS,
                        payload: res.data.chats || [],
                        size: res.data.chats.length || 0
                    })
                }else{
                    dispatch({
                        type: ALL_CHAT_FAILED,
                        payload: res.data.msg,
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: ALL_CHAT_FAILED,
                    payload: ErrorMessage(error),
                })
            })

    }catch(err){
        dispatch({
            type: ALL_CHAT_FAILED,
            payload: ErrorMessage(err),
        })
    }
}

export const getUserCoinsForArcadeVerse = () => async(dispatch) =>{
    try{
        const {accessToken, verificationHash} = store.getState().userLoginReducer
        const {allChips} = store.getState().getUserCoinsForArcadeVerseReducer

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + accessToken,
            },
        }

        dispatch({ type: GET_COINS_FROM_KURAMA_REQUEST, allChips : allChips?.length > 0 ? allChips : []})
        await axios
            .post(
                `${BACKEND_URL}/community/getExchangeData`,
                {
                    verificationHash: verificationHash
                },
                config
            )
            .then((res) => {
                if(res.data.status === 200) {
                    dispatch({
                        type: GET_COINS_FROM_KURAMA_SUCCESS,
                        allChips: res.data?.allChips || [],
                        allKuramaCoins: res.data?.allKuramaCoins || [],
                    })
                }else{
                    dispatch({
                        type: GET_COINS_FROM_KURAMA_FAILED,
                        payload: res.data.msg,
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_COINS_FROM_KURAMA_FAILED,
                    payload: ErrorMessage(error),
                })
            })

    }catch(err){
        dispatch({
            type: GET_COINS_FROM_KURAMA_FAILED,
            payload: ErrorMessage(err),
        })
    }
}

export const exchangeChipsForCoinsAction = (coinValue, coinData, setOpenModal) => async(dispatch) =>{
    try{
        dispatch({ type: EXCHANGE_REQUEST })

        const {accessToken, verificationHash} = store.getState().userLoginReducer

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + accessToken,
            },
        }

        await axios
            .post(
                `${BACKEND_URL}/community/exchangeChipsForCoins`,
                {
                    coinValue,
                    coinData,
                    verificationHash: verificationHash
                },
                config
            )
            .then((res) => {
                if(res.data.status === 200) {
                    dispatch({
                        type: EXCHANGE_SUCCESS,
                        payload: res.data.msg
                    })

                    dispatch(getUserCoinsForArcadeVerse())
                    // setOpenModal(false)
                }else{
                    dispatch({
                        type: EXCHANGE_FAILED,
                        payload: res.data.msg,
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: EXCHANGE_FAILED,
                    payload: ErrorMessage(error),
                })
            })

    }catch(err){
        dispatch({
            type: EXCHANGE_FAILED,
            payload: ErrorMessage(err),
        })
    }
}

export const exchangeCoinForChipsAction = (coinValue, coinData, setOpenModal) => async(dispatch) =>{
    try{
        dispatch({ type: EXCHANGE_REQUEST })

        const {accessToken, verificationHash} = store.getState().userLoginReducer

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + accessToken,
            },
        }

        await axios
            .post(
                `${BACKEND_URL}/community/exchangeCoinForChips`,
                {
                    coinValue,
                    coinData,
                    verificationHash: verificationHash
                },
                config
            )
            .then((res) => {
                if(res.data.status === 200) {
                    dispatch({
                        type: EXCHANGE_SUCCESS,
                        payload: res.data.msg
                    })

                    dispatch(getUserCoinsForArcadeVerse())
                    // setOpenModal(false)
                }else{
                    dispatch({
                        type: EXCHANGE_FAILED,
                        payload: res.data.msg,
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: EXCHANGE_FAILED,
                    payload: ErrorMessage(error),
                })
            })

    }catch(err){
        dispatch({
            type: EXCHANGE_FAILED,
            payload: ErrorMessage(err),
        })
    }
}

export const emptyRound = () => async(dispatch) =>{
    try{
        dispatch({
            type: GET_ROUND_SUCCESS,
            payload: {}
        })
    }catch(err){
        dispatch({
            type: GET_ROUND_FAILED,
            payload: ErrorMessage(err),
        })
    }
}

export const getRound = (tableId, skipRound) => async(dispatch) =>{
    try{
        dispatch({ type: GET_ROUND_REQUEST })

        const {accessToken, verificationHash} = store.getState().userLoginReducer

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + accessToken,
            },
        }

        await axios
            .post(
                `${BACKEND_URL}/community/getRound`,
                {
                    tableId,
                    skipRound,
                    verificationHash: verificationHash
                },
                config
            )
            .then((res) => {
                if(res.data.status === 200) {
                    dispatch({
                        type: GET_ROUND_SUCCESS,
                        payload: res.data.round
                    })
                }else{
                    dispatch({
                        type: GET_ROUND_FAILED,
                        payload: res.data.msg,
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: GET_ROUND_FAILED,
                    payload: ErrorMessage(error),
                })
            })

    }catch(err){
        dispatch({
            type: GET_ROUND_FAILED,
            payload: ErrorMessage(err),
        })
    }
}