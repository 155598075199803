import React from "react";
import "./user.css";
import { useEffect } from "react";
import $ from "jquery";
import { useState } from "react";
import { gsap } from "gsap";

const User = ({
  socketGameTimer,
  winners,
  hasTurn,
  turnChanged,
  removeAnimationCircle,
  setremoveAnimationCircle,
  playerHandRank,
  hasFolded,
  hand,
  chipImg,
  Player,
  tableChipImage,
  winnerAnnounced,
  updateState,
  cardImg
}) => {

  const [animatedCircleOffset, setanimatedCircleOffset] = useState(70)
  const [initialOffset, setinitialOffset] = useState(0)
  const [hasWon, sethasWon] = useState(false)

  useEffect(() => {
    // if (hasTurn) {
      // var time = 0;

      // var interval = setInterval(function () {
        // if (time == 450) {
        //   clearInterval(interval);
        //   return;
        // }
        // if (i === 10) {
        //   $(".circle_animation").css("stroke", "#D6CD00");
        // }
        // if (i === 5) {
        //   $(".circle_animation").css("stroke", "#FF0000");
        // }
        // $(".circle_animation").css("stroke-dashoffset", parseInt(initialOffset) - (i - 1) * (parseInt(initialOffset) / time));
        // setInitialOffset(initialOffset - (i - 1) * (initialOffset / time))
        // i--;
      //   time = time + 15
      // }, 500);

    // }

    var interval
    var dashoffset = 70
    setanimatedCircleOffset(dashoffset)
    setremoveAnimationCircle(false)
    
    if (hasTurn && !winnerAnnounced) {
      localStorage.setItem('clearInterval', false)
      interval = setInterval(() => {
        if (dashoffset >= 500) {
          clearInterval(interval);
          dashoffset = 70
          setanimatedCircleOffset(dashoffset)
          return;
        }
        if (dashoffset <= 0) {
          clearInterval(interval);
          dashoffset = 70
          setanimatedCircleOffset(dashoffset)
          return;
        }
        if (localStorage.getItem('clearInterval') === 'true') {
          clearInterval(interval);
          dashoffset = 70
          setanimatedCircleOffset(dashoffset)
          return;
        }

        const timer = (socketGameTimer - new Date().getTime())/1000
        dashoffset = dashoffset + ((500 - dashoffset)/(timer - 1))
        setanimatedCircleOffset(dashoffset)
        
        if ((dashoffset > 360) && (localStorage.getItem('fourBeepsPlayed') !== 'true')) {
          document.getElementById("fourBeeps-audio").play();
          localStorage.setItem('fourBeepsPlayed', true)
        }

        $(".circle_animation-user").css("stroke-dashoffset", dashoffset)
      }, 1000)
    }

    if (!hasTurn) {
      clearInterval(interval);
      dashoffset = 70
      setanimatedCircleOffset(dashoffset)
      localStorage.setItem('clearInterval', true)
      localStorage.setItem('fourBeepsPlayed', false)
      return;
    }

    if (winnerAnnounced) {
      clearInterval(interval);
      dashoffset = 70
      setanimatedCircleOffset(dashoffset)
      localStorage.setItem('clearInterval', true)
      localStorage.setItem('fourBeepsPlayed', false)
      return;
    }

    return () => {
      window.clearInterval(interval);
    };
  }, [ hasTurn, turnChanged, winnerAnnounced ])

  useEffect(() => {
    setinitialOffset(0)
    // Only for Chip animation when round starts
  }, updateState)

  useEffect(() => {
    if (winners?.length > 0) {
      for (let i = 0; i < winners.length; i++) {
        if (winners[i]?.playerData?.walletAddress == Player?.walletAddress) {
            sethasWon(true)
            document.getElementById("win-audio").play();
        }
      }
    } else {
      sethasWon(false)
    }
  }, [ winners ])

  useEffect(() => {
    if (hasWon && winnerAnnounced) {
      const ctx = gsap.context(() => {
        gsap
        .timeline({
          onUpdate: function() {
              if (this.progress() > 0.99) {
                  this.play(0)
              }
          }
        })
        .to(".starImage", { opacity: 0, ease: "linear" }, 0)
        .to(".starBackground", { opacity: 1, x: -261, y: 10, ease: "linear" }, 0)
        .to(".starImage", { opacity: 1, duration: 0.75, ease: "linear" }, 0)
        .to(".starBackground", { opacity: 1, duration: 0.75, x: 18, y: 10, ease: "linear" }, 0)
        .to(".starImage", { opacity: 0, duration: 0.75, ease: "linear" }, 1.5)
        .to(".starBackground", { opacity: 1, duration: 0.75, x: 297, y: 10, ease: "linear" }, 1.5)
        .to(".starImage", { opacity: 0, duration: 0.75, ease: "linear" }, 2.25)
        .to(".starBackground", { opacity: 1, duration: 0.75, x: 297, y: 10, ease: "linear" }, 2.25)
      })

      return () => ctx.revert();
    }
  }, [winnerAnnounced, hasWon])

  return (
    <div className="user-card">
      {(
      // {!hasFolded ? (
        <>
          <div style={winnerAnnounced ? { opacity: 0 } : { opacity: 0 }} className="user-card-1 flip-card">
            <div className="flip-card-front">
              {(hand.length > 0) && <img
                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 8px -1px' }}
                src={
                  `https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/${hand[0][1]}-${hand[0][0]}.svg`
                }
                alt=""
              />}
            </div>
            <div className="flip-card-back">
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 8px -1px' }}
                src={cardImg}
                alt=""
              />
            </div>
          </div>
          <div style={winnerAnnounced ? { opacity: 0 } : { opacity: 0 }} className="user-card-2 flip-card">
            <div className="flip-card-front">
              {(hand.length > 0) && <img
                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 8px -1px' }}
                src={
                  `https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/${hand[1][1]}-${hand[1][0]}.svg`
                }
                alt=""
              />}
            </div>
            <div className="flip-card-back">
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 8px -1px' }}
                src={cardImg}
                alt=""
              />
            </div>
          </div>
        </>
      )}
      {Player?.turnAmount > 0 && !winnerAnnounced && ((localStorage.getItem('roundStartedChips1') == 'false') || (localStorage.getItem('roundStartedChips1') && (Player?.turnAmount !== Number(localStorage.getItem('minBid'))))) && ((localStorage.getItem('roundStartedChips2') == 'false') || (localStorage.getItem('roundStartedChips2') && (Player?.turnAmount !== Number(localStorage.getItem('minBid'))*2))) && <div className="user-chip" style={window.innerHeight > 750 ? { position: 'absolute', top: '-105px', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', width: 'max-content' } : { position: 'absolute', top: '-90px', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', width: 'max-content' }}>
        <p style={{ fontFamily: "Poppins", background: '#0000004d', fontSize: '14px', color: 'white', padding: '4px 12px 4px 12px', borderRadius: '4px' }}>{Player?.turnAmount}</p>
        {Player?.turnAmount < 101 && <div style={{ display: 'flex', flexDirection: 'column', padding: '5px 10px 4.5px 0px' }}>
          <img style={{ width: '23px', marginBottom: '3px', marginLeft: '10px' }} src={tableChipImage} />
        </div>}
        {(Player?.turnAmount > 100 && Player?.turnAmount < 501) && <div style={{ display: 'flex', flexDirection: 'column', padding: '5px 10px 8px 0px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
        </div>}
        {(Player?.turnAmount > 500 && Player?.turnAmount < 1001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '6px 10px 10px 0px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
        </div>}
        {(Player?.turnAmount > 1000 && Player?.turnAmount < 3001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 10px 11.5px 0px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
        </div>}
        {(Player?.turnAmount > 3000 && Player?.turnAmount < 5001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 0 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginLeft: '-10px' }} src={tableChipImage} />
        </div>}
        {(Player?.turnAmount > 5000 && Player?.turnAmount < 10001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 2px 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginLeft: '-10px', marginTop: '2px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} />
        </div>}
        {(Player?.turnAmount > 10000) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 5px 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginLeft: '-10px', marginTop: '2px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} />
        </div>}
      </div>}
      {playerHandRank && <p className="mobile-set-this-extra" style={{
          position: 'absolute',
          background: 'red',
          top: '89px',
          left: '162px',
          width: 'max-content',
          padding: '3px 20px 1px',
          background: 'linear-gradient(271.35deg, #666666 0%, #717171 12.28%, #8F8F8F 35.08%, #BFBFBF 64.9%, #FFFFFF 98.23%, #999999 175.41%)',
          fontFamily: "Electrolize",
          fontSize: '14px'
      }}>
        {playerHandRank}
      </p>}
      {hasFolded ? <span className="fold-text">Fold</span> : ""}
      {Player.status == "AllIn" ? <span className="fold-text">All In</span> : ""}
      <div
        className={"user-outer-circle"}
      >
        <div className="user-inner-circle">
          <div className="user-inner-image" id="playerModel">
            {hasWon && winnerAnnounced && <div className="winnerStar">
              <div style={{ width: '280px', transform: 'translate(-261px, 10px) skew(60deg)' }} className="starBackground"></div>
              <img className="starImage" src="https://res.cloudinary.com/kuramaverse/image/upload/v1692387874/ArcadeVerse%20Tables/Property_1_Frame_1814105272_hfqs5w.svg" />
            </div>}
            {hasFolded && <div className="trans-label-abs" style={{ cursor: 'default', zIndex: 1, width: '100%', height: '100%', position: 'absolute', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '28px', background: '#00000099', opacity: 1 }}><div style={{ background: "rgba(0, 0, 0, 0.32)", borderRaius: '4px', padding: '6px 8px 4px', borderRadius: '4px' }}>Fold</div></div>}
            {Player.status == "AllIn" && <div className="trans-label-abs" style={{ cursor: 'default', zIndex: 1, width: '100%', height: '100%', position: 'absolute', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '28px', background: '#00000099', opacity: 1 }}><div style={{ background: "rgba(0, 0, 0, 0.32)", borderRaius: '4px', padding: '6px 8px 4px', borderRadius: '4px' }}>All In</div></div>}
            <img style={{ position: 'absolute', borderRadius: '100%' }} src={Player?.image} />
            {hasTurn && !hasFolded && !hasWon && !winnerAnnounced && !removeAnimationCircle ? (
              <div className="progress-bar-user">
                <svg
                  style={{
                    width: '120%',
                    height: '120%',
                    position: 'absolute',
                    top: '-5px',
                    left: '-4.5px',
                  }}
                  width="160"
                  height="160"
                  xmlns="http://www.w3.org/2000/svg"
                  className="progress-svg"
                >
                  <circle
                    id="circle"
                    className="circle_animation-user"
                    r="68"
                    cy="74"
                    cx="74"
                    strokeWidth="3"
                    stroke={animatedCircleOffset < 214 ? "#01D219" : (animatedCircleOffset < 358 ? "#e8df0c" : "#d20606")}
                    fill="none"
                  />
                </svg>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div
        className={
          hasWon && winnerAnnounced ? "user-cash-flex-won" : "user-cash-flex"
        }
      >
        <span className="user-cash">{Player?.chips}</span>
        <img style={{ marginTop: '1px' }} src={chipImg} alt="" className="player-cash-coin" />
      </div>
    </div>
  );
};

export default User;
