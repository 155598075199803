import React, { useState } from 'react'
import styles from './Tables.module.css'
import TableTopCard from './TableTopCard/TableTopCard'
import DeleteTable from '../../shared/modals/DeleteTable/DeleteTable'
import { useDispatch } from 'react-redux'
import { editTableData } from '../../../redux/actions/otherAction'
import { saveTableDataState, saveTableState } from '../../../redux/actions/TableAction'

const TableCard = ({data, setOpenModal, id}) => {
    const dispatch = useDispatch()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [hover, setHover] = useState(false)

    return <div className={styles.table_card_container_outer}>
        <div className={styles.table_card_container}>
            {/* Top Card */}
            <div className={styles.table_top_card}>
                <TableTopCard id={id} table={data} img={data?.cardPreview} />
            </div>

            <div className={styles.table_card_cntnt}>
                <div className={styles.vertical_align_st}>
                    <h1 className={styles.table_card_minor_heading}>CHIPS</h1>
                    <div className='flex items-center w-full gap-[6px] flex-wrap'>
                        <div className={styles.coin_design_btn}>
                            <img src={data?.chipImg} style={{ width: '15px', marginBottom: '1px', marginLeft: '2px' }} />
                            <span>{data?.chipName}</span>
                        </div>
                    </div>
                </div>

                <div className={styles.horizontal_align}>
                    <div className={styles.vertical_align_st}>
                        <h1 className={styles.table_card_minor_heading}>MAX PLAYERS</h1>
                        <h2 className={styles.table_card_minor_value}>{data?.maxPlayers}</h2>
                    </div>
                    <div className={styles.vertical_align_end}>
                        <h1 className={styles.table_card_minor_heading}>MAX CHIPS</h1>
                        <h2 className={styles.table_card_minor_value}>{data?.maxChips}</h2>
                    </div>
                </div>

                <div className={styles.horizontal_align}>
                    <div className={styles.vertical_align_st}>
                        <h1 className={styles.table_card_minor_heading}>MIN BIDS</h1>
                        <h2 className={styles.table_card_minor_value}>{data?.minBid}</h2>
                    </div>
                    <div className={styles.vertical_align_end}>
                        <h1 className={styles.table_card_minor_heading}>MAX BIDS</h1>
                        <h2 className={styles.table_card_minor_value}>{data?.maxBid}</h2>
                    </div>
                </div>

                <div className={styles.table_card_divider}></div>

                <div className={styles.horizontal_align}>
                    <div className={styles.vertical_align_st}>
                        <h1 className={styles.table_card_minor_heading}>HOLDERS ONLY</h1>
                        <h2 className={`${styles.table_card_minor_value} ${!data?.nftsRequired ? styles.null : ''}`}>{data?.nftsRequired || 'None'}</h2>
                    </div>
                    <div className={styles.vertical_align_end}>
                        <h1 className={styles.table_card_minor_heading}>DISCORD ROLE</h1>
                        <h2 className={`${styles.table_card_minor_value} ${!data?.roleRequired ? styles.null : ''}`}>{data?.roleRequired?.name || 'None'}</h2>
                    </div>
                </div>

                <div className='flex items-center gap-2 w-full'>
                    <button className={styles.remove_btn} onClick={() => setOpenDeleteModal(true)}><Dustbin /></button>
                    <button
                        className={`secondary-custom-button w-full`}
                        onClick={() => {
                            dispatch(editTableData(data))
                            dispatch(saveTableDataState(null));
                            dispatch(saveTableState(null));
                            setOpenModal(true)
                        }}
                        onMouseEnter={() => {setHover(true)}}
                        onMouseLeave={() => {setHover(false)}}
                        >
                        <div className={`${styles.edit_table_btn} secondary-custom-button-inner`}>
                            Edit table{'  '}<Add fill={hover ? "#D93D48" : "white"} />
                        </div>
                    </button>
                </div>


                <DeleteTable openModal={openDeleteModal} tableId={data?._id} communityId={data?.slug} id={id} data={data} setOpenModal={setOpenDeleteModal} />
            </div>
        </div>
    </div>
}

export default React.memo(TableCard)

const Add = ({fill}) => {
    return(<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
    <path d="M8 15.1992L13 10.1992L8 5.19922" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>
    )
}

const Dustbin = () => {
    return(<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3.19922H15M3 6.19922H21M19 6.19922L18.2987 16.7185C18.1935 18.2968 18.1409 19.0859 17.8 19.6842C17.4999 20.211 17.0472 20.6345 16.5017 20.8989C15.882 21.1992 15.0911 21.1992 13.5093 21.1992H10.4907C8.90891 21.1992 8.11803 21.1992 7.49834 20.8989C6.95276 20.6345 6.50009 20.211 6.19998 19.6842C5.85911 19.0859 5.8065 18.2968 5.70129 16.7185L5 6.19922M10 10.6992V15.6992M14 10.6992V15.6992" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}