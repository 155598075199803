import React, { useEffect, useState } from "react";
import "./onTableContent.css";

const OnTableContent = ({ TableCards, tableChipImage, Pot, RaisedBy, cardImg, initialTableCardsLength, activePlayers, startingNewRound, setstartingNewRound, isTournament, startingNewRoundType }) => {
  const [showNumber, setshowNumber] = useState('-')
  const [totalPot, settotalPot] = useState(Pot.length ? 0 : Pot)
  const [showPot, setshowPot] = useState(Pot.length ? 0 : Pot)

  useEffect(() => {
    if (Pot.length) {
      var totalpot = 0, showpot = ''
      for (let i = 0; i < Pot.length; i++) {
        // console.log(i, Pot[i].potAmount, Pot[i])
        totalpot = totalpot + Pot[i].potAmount
        if (Pot[i].potAmount > 0) {
          if (i == 0) {
            showpot = Pot[i].potAmount
          } else {
            showpot = showpot + ` + ${Pot[i].potAmount}`
          }
        }
      }

      settotalPot(totalpot)
      setshowPot(showpot)
    } else {
      settotalPot(Pot)
      setshowPot(Pot)
    }
  }, [ Pot ])

  useEffect(() => {
    if ((startingNewRound > 0) && (Math.ceil((startingNewRound - new Date().getTime())/1000) > 0)) {
      const interval = setInterval(() => {
        if (Math.ceil((startingNewRound - new Date().getTime())/1000) > 0){
          if (Math.ceil((startingNewRound - new Date().getTime())/1000) == 0) {
            document.getElementById("countdownEnd-audio").play();
          } else {
            document.getElementById("countdownStart-audio").play();
          }
          setshowNumber(Math.ceil((startingNewRound - new Date().getTime())/1000))
        } else {
          setstartingNewRound(0)
          clearInterval(interval)
          return
        }
      }, 1000)

      return () => {
        window.clearInterval(interval);
      };
    }
  }, [ startingNewRound ])

  return (
    <div style={{ marginTop: '-65px' }} className="onTable-content z-[1]">
      <div style={totalPot > 0 ? { fontSize: '18px', display: 'flex', alignItems: 'center', padding: '5px 15px 3px 15px' } : { fontSize: '18px', display: 'flex', alignItems: 'center', padding: '8px 20px' }} className="trans-label">Pot : <span style={{ marginLeft: '10px' }}>{showPot || 0}</span>
      {(totalPot > 0) && <div style={totalPot > 15000 ? { width: 'max-content', marginTop: '12px', marginLeft: '3px' } : { width: 'max-content', marginTop: '10px', marginLeft: '3px' }}>
        {totalPot < 101 && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 6.5px 0' }}>
          <img style={{ width: '23px', marginBottom: '3px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(totalPot > 100 && totalPot < 501) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 11px 0' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(totalPot > 500 && totalPot < 1001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 11px 0' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(totalPot > 1000 && totalPot < 3001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 10px 0' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(totalPot > 3000 && totalPot < 5001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 0 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(totalPot > 5000 && totalPot < 10001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 2px 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '-10px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(totalPot > 10000 && totalPot < 15001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 6px 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '-10px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(totalPot > 15000 && totalPot < 20001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 8px 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '-10px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(totalPot > 20000 && totalPot < 25001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 0 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '-10px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '8px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(totalPot > 25000 && totalPot < 30001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 3px 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '-10px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '8px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '8px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(totalPot > 30000) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 5px 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '-10px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '8px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '8px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '8px' }} src={tableChipImage} alt = ''  />
        </div>}
      </div>}
      </div>
      <div style={{ position: "relative", width: "352px", height: '80px' }} className="card-flex">
        {TableCards.length !== 0 ? (
          <>
            {TableCards.map((card, id) => {
              return (
                <div style={(id > (initialTableCardsLength - 1)) ? { position: 'absolute', left: `${id == 0 ? 10 : (id == 1 ? 79 : (id == 2 ? 148 : (id == 3 ? 217 : 286)))}px`, opacity: 0 } : { position: 'absolute', left: `${id == 0 ? 10 : (id == 1 ? 79 : (id == 2 ? 148 : (id == 3 ? 217 : 286)))}px` }} className={`card-filled flip-card card-filled-${id}`} key={id}>
                  <div className="flip-card-table-front">
                    <img
                      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 8px -1px' }}
                      src={`https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/${card[1]}-${card[0]}.svg`}
                      alt="Card"
                      className="card"
                    />
                  </div>
                  <div className="flip-card-table-back">
                    <img
                      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 8px -1px' }}
                      src={cardImg}
                      alt=""
                    />
                  </div>
                </div>
              );
            })}
            <div className="card-empty"></div>
            <div style={{ marginLeft: '4px' }} className="card-empty"></div>
            <div style={{ marginLeft: '4px' }} className="card-empty"></div>
            <div style={{ marginLeft: '4px' }} className="card-empty"></div>
            <div style={{ marginLeft: '4px' }} className="card-empty"></div>
          </>
        ) : (
          <>
            <div className="card-empty"></div>
            <div style={{ marginLeft: '4px' }} className="card-empty"></div>
            <div style={{ marginLeft: '4px' }} className="card-empty"></div>
            <div style={{ marginLeft: '4px' }} className="card-empty"></div>
            <div style={{ marginLeft: '4px' }} className="card-empty"></div>
          </>
        )}
      </div>
      {activePlayers?.length > 1 && <div style={RaisedBy > 0 ? { display: 'flex', alignItems: 'center', padding: '5px 15px 3px 15px' } : { display: 'flex', alignItems: 'center', padding: '8px 20px' }} className="trans-label">current bet :
      <span style={{ marginLeft: '10px' }}>{RaisedBy}</span>
          {(RaisedBy > 0) && <div style={RaisedBy > 15000 ? { width: 'max-content', marginTop: '12px', marginLeft: '3px' } : { width: 'max-content', marginTop: '10px', marginLeft: '3px' }}>
        {RaisedBy < 101 && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 6.5px 0' }}>
          <img style={{ width: '23px', marginBottom: '3px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(RaisedBy > 100 && RaisedBy < 501) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 11px 0' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(RaisedBy > 500 && RaisedBy < 1001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 11px 0' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(RaisedBy > 1000 && RaisedBy < 3001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 10px 0' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(RaisedBy > 3000 && RaisedBy < 5001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0 0 0 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(RaisedBy > 5000 && RaisedBy < 10001) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 2px 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '-10px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
        </div>}
        {(RaisedBy > 10000) && <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 0px 6px 20px' }}>
          <img style={{ width: '23px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginLeft: '-10px', marginTop: '2px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
          <img style={{ width: '23px', marginTop: '-18px', marginLeft: '-10px' }} src={tableChipImage} alt = ''  />
        </div>}
      </div>}
      </div>}

      {(activePlayers?.length > 0 && activePlayers?.length < 2 && Pot == 0) && ((startingNewRound <= 0) || (startingNewRoundType == 'Tournament Wait')) && <div style={{ position: 'relative', width: '600px', height: '27px' }} className="flex items-center flex-col gap-2 z-[4]">
        <div style={{ position: 'absolute', width: '100%', top: '20px', left: '3%', display: 'flex', justifyContent: 'center', marginLeft: '-18px' }} className="flex items-center gap-3">
          <span className="w-[20px] h-[20px] border-[3px] border-r-[0px] border-b-[0px] border-[#fff] animate-spin rounded-full"></span>
          <h1 className="text-[#FFF] font-[Orbitron] text-[18px] font-[600]">{isTournament ? `Waiting for players. Redirecting in ${showNumber == '-' ? 60 : showNumber}s if no one joins` : 'Waiting for more players to join'}</h1>
        </div>
      </div>}
      {(((startingNewRound > 0) && (activePlayers?.length > 1)) || ((startingNewRound > 0) && (isTournament) && (startingNewRoundType !== 'Tournament Wait'))) && <div style={{ position: 'relative', width: '100%' }} className="flex items-center flex-col gap-2 z-[4]">
        <h1 style={{ position: 'absolute' }} className="text-[#FFF] font-[Orbitron] text-[18px] font-[600]">{startingNewRoundType == 'Tournament' ? 'Tournament starts in' : 'Game Will Start in'} {(showNumber == 0) ? (Math.ceil((startingNewRound - new Date().getTime())/1000) > 0 ? 7 : 0) : showNumber}</h1>
      </div>}
    </div>
  );
};

export default OnTableContent;
