export const TableCardColorShades = [
    {
        id: 0,
        backgroundColor: '#56939B'
    },
    {
        id: 1,
        backgroundColor: '#006B80'
    },
    {
        id: 2,
        backgroundColor: '#1E4184'
    },
    {
        id: 3,
        backgroundColor: '#ABB350'
    },
    {
        id: 4,
        backgroundColor: '#6C9B08'
    },
    {
        id: 5,
        backgroundColor: '#00842D'
    },
    {
        id: 6,
        backgroundColor: '#005E42'
    },
    {
        id: 7,
        backgroundColor: '#890084'
    },
    {
        id: 8,
        backgroundColor: '#830B44'
    },
    {
        id: 9,
        backgroundColor: '#71089B'
    },
    {
        id: 10,
        backgroundColor: '#5B0081'
    },
    {
        id: 11,
        backgroundColor: '#E95621'
    },
    {
        id: 12,
        backgroundColor: '#835A0B'
    },
    {
        id: 13,
        backgroundColor: '#484848'
    },
    {
        id: 14,
        backgroundColor: '#455687'
    },
    {
        id: 15,
        backgroundColor: '#293654'
    },
    {
        id: 16,
        backgroundColor: '#ff587a'
    }
]

export const CommunityCardColorShades = [
    {
        id: 0,
        backgroundColor: '#56939B'
    },
    {
        id: 1,
        backgroundColor: '#006B80'
    },
    {
        id: 2,
        backgroundColor: '#1E4184'
    },
    {
        id: 3,
        backgroundColor: '#ABB350'
    },
    {
        id: 4,
        backgroundColor: '#6C9B08'
    },
    {
        id: 5,
        backgroundColor: '#00842D'
    },
    {
        id: 6,
        backgroundColor: '#005E42'
    },
    {
        id: 7,
        backgroundColor: '#890084'
    },
    {
        id: 8,
        backgroundColor: '#830B44'
    },
    {
        id: 9,
        backgroundColor: '#71089B'
    },
    {
        id: 10,
        backgroundColor: '#5B0081'
    },
    {
        id: 11,
        backgroundColor: '#E95621'
    },
    {
        id: 12,
        backgroundColor: '#835A0B'
    },
    {
        id: 13,
        backgroundColor: '#484848'
    },
    {
        id: 14,
        backgroundColor: '#455687'
    },
    {
        id: 15,
        backgroundColor: '#293654'
    },
    {
        id: 16,
        backgroundColor: '#ff587a'
    }
]