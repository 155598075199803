import React, { useState } from 'react'
import styles from './LoginModal.module.css'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useRef } from 'react'
import close from '../../../../Assets/svgs/close.svg'
import metamask from '../../../../Assets/Images/metamask.png'
import coinbase from '../../../../Assets/Images/coinbase.png'
import wallet_connect from '../../../../Assets/Images/wallet_connect.png'
import user_filled from '../../../../Assets/Images/user_filled.svg'
import discord from '../../../../Assets/Images/discord.svg'
import twitter from '../../../../Assets/Images/twitter.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { connectTwitter, loginWithCoinbase, loginWithMetamask, loginWithWalletConnect } from '../../../../redux/actions/userAction'
import { REACT_APP_DISCORD_URL } from '../../../../constants'
import ConnectBox from './ConnectBox'
import { addCoinbaseToProfile, addMetamaskToProfile, addWalletConnectToProfile } from '../../../../redux/actions/authAction'
import { SwipeableDrawer } from '@mui/material'

const LoginModal = ({openModal, setOpenGuestModal, setOpenModal, checkOnlyWallet, checkOnlyDiscordConnect, backUrl, setOpenProfileModal}) => {
    const cancelButtonRef = useRef(null)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {loading} = useSelector((state) => state.userLoginReducer)

    const [walletConnectLoading, setWalletConnectLoading] = useState(false)
    const [discordHover, setdiscordHover] = useState(false)
    const [twitterHover, settwitterHover] = useState(false)

    const MainModal = () => {
    return <>
            <div className={styles.top_align}>
                <h2 className={styles.login_text}>{checkOnlyDiscordConnect || checkOnlyWallet ? 'Connect' : 'Log In'}</h2>

                {!(loading || walletConnectLoading) && <div className={styles.close_btn} onClick={setOpenModal}>
                    <img src={close} alt="" />
                </div>}
            </div>

            <span className={styles.border_line}></span>

            {!checkOnlyDiscordConnect && <ConnectBox loading={loading || walletConnectLoading} name={'MetaMask'} icon={metamask} onClick={() => {
                if(checkOnlyWallet) dispatch(addMetamaskToProfile(setWalletConnectLoading))
                else dispatch(loginWithMetamask(navigate,backUrl, setOpenProfileModal, setOpenModal))
            }} />}
            {!checkOnlyDiscordConnect && <ConnectBox loading={loading || walletConnectLoading} name={'Coinbase'} icon={coinbase} onClick={() => {
                if(checkOnlyWallet) dispatch(addCoinbaseToProfile(setWalletConnectLoading))
                else dispatch(loginWithCoinbase(navigate,backUrl, setOpenProfileModal, setOpenModal))
            }} />}
            {!checkOnlyDiscordConnect && <ConnectBox loading={loading || walletConnectLoading} name={'Wallet Connect'} icon={wallet_connect} onClick={() => {
                if(checkOnlyWallet) dispatch(addWalletConnectToProfile(setWalletConnectLoading))
                else dispatch(loginWithWalletConnect(navigate,backUrl, setOpenProfileModal, setOpenModal))
            }} />}

            {!(checkOnlyWallet || checkOnlyDiscordConnect) && <div className={styles.wrap_or_border}>
                <span className={styles.border_light}></span>
                <h2 className={styles.or_text}>Or</h2>
                <span className={styles.border_light}></span>
            </div>}

            {!checkOnlyWallet && <div className={styles.button_wrapper}>
                <button style={discordHover ? { background: 'linear-gradient(0deg, #515FF7, #515FF7), linear-gradient(0deg, rgba(88, 101, 242, 0.8), rgba(88, 101, 242, 0.8))' } : { background: '#5865F2E5' }} onMouseEnter={() => setdiscordHover(true)} onMouseLeave={() => setdiscordHover(false)} className={`${styles.other_btn} ${styles.discord_btn}`}
                    disabled={loading}
                    onClick={() => {
                        if(checkOnlyDiscordConnect) {
                            localStorage.setItem('redirect_url', JSON.stringify(location.pathname))
                            window.location.assign(process.env.REACT_APP_DISCORD_URL)
                        }else{
                            localStorage.setItem('redirect_url', JSON.stringify(location.pathname))
                            localStorage.setItem('LoginDiscord', JSON.stringify(true))
                            window.location.assign(REACT_APP_DISCORD_URL)
                        }
                    }}
                >
                    <span className='flex items-center gap-3'>
                        <img src={discord} alt="" className='w-[30px]'/>
                        Discord
                    </span>

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g opacity={discordHover ? "1" : "0.5"}>
                            <path d="M9 18L15 12L9 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                    </svg>
                </button>

                {!checkOnlyDiscordConnect && <button style={twitterHover ? { background: 'linear-gradient(0deg, #0D93EA, #0D93EA), linear-gradient(0deg, rgba(27, 157, 240, 0.78), rgba(27, 157, 240, 0.78))' } : { background: '#1B9DF0D9' } } onMouseEnter={() => settwitterHover(true)} onMouseLeave={() => settwitterHover(false)} className={`${styles.other_btn} ${styles.twitter_btn}`}
                    disabled={loading}
                    onClick={() => {
                        localStorage.setItem('redirect_url', JSON.stringify(location.pathname))
                        localStorage.setItem('LoginTwitter', JSON.stringify(true))
                        dispatch(connectTwitter())
                    }}
                >
                    <span className='flex items-center gap-3'>
                        <img src={twitter} alt="" className='w-[30px]'/>
                        Twitter
                    </span>

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g opacity={twitterHover ? "1" : "0.5"}>
                            <path d="M9 18L15 12L9 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>
                </button>}
            </div>}

            {!(checkOnlyWallet || checkOnlyDiscordConnect) && <ConnectBox loading={loading} name={'Continue As Guest'} icon={user_filled}
                onClick={() => {
                    setOpenModal(false)
                    setOpenGuestModal(true)
                }}
            />}
        </>
    }

    if(window.innerWidth > 650)
    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-[100000] inset-0 overflow-y-auto'
                initialFocus={cancelButtonRef}
                onClose={setOpenModal}
            >
                <div className='h-screen pt-4 px-4 pb-20 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[#0c0c0d9a] bg-opacity-75 backdrop-blur-[3px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block bg-transparent rounded-lg text-left transform transition-all align-middle max-w-[500px] w-full'>
                            <div className={`${styles.modal_background} flex justify-center items-center w-full rounded-lg text-white p-10 flex-col gap-y-8 border border-[#3D3D3D] relative`}>
                                <MainModal />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
    else return <SwipeableDrawer
        anchor={'bottom'}
        open={openModal}
        onClose={(e) => {setOpenModal(false)}}
        transitionDuration={600}
        onOpen={(e) => setOpenModal(true)}
        className='transition-all ease-in-out z-[100000]'
        PaperProps={{
            sx: {
                zIndex: 100000,
                backdropFilter: 'blur(50px)',
                borderRadius: '0 !important',
                color: 'white',
                // height: '400px',
                width: '100%',
                background: 'transparent',
                // background: 'linear-gradient(167.1deg, #262626 36.57%, #1B1818 94.9%)',
                boxShadow: 'none',
                padding: '0',
                paddingBottom: '0',
            },
        }}
        ModalProps={{
            sx: {
                zIndex: '128000',
            },
        }}
    >
        <div className={styles.modal_background_swipe}>
            <MainModal/>
        </div>
    </SwipeableDrawer>
}

export default LoginModal
