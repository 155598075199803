import React from "react";
const BetButton = ({ raise, user, hasFold, playerTurn, bet, raisedBy, winnerAnnounced, turnAmount, walletAddress }) => {
  return (
    <button
      className="button-bet"
      onClick={() => {
        document.getElementById("chipThrow-audio").play();
        raise();
      }}
      disabled={(playerTurn !== walletAddress) || winnerAnnounced || (((turnAmount + bet) < raisedBy) && (raisedBy < (turnAmount + user?.chips))) || ((raisedBy > (user?.chips + turnAmount)) && (bet < user?.chips)) || (bet == 0) || hasFold}
    >
      <div className="button-bottom-layer-medium">
        <div className="button-border-layer">
          <div style={{ paddingTop: '4px' }} className="button-top-layer-medium">{(bet == user.chips) ? "All In" : (bet == 0 ? 'Bet' : ((turnAmount + bet) == raisedBy ? 'Call' : 'Bet'))}</div>
        </div>
      </div>
    </button>
  );
};

export default BetButton;
