import { EDIT_COMMUNITY_TAB, EDIT_TABLE_INFO, FETCH_MOST_PLAYED_COMMUNITIES_FAILED, FETCH_MOST_PLAYED_COMMUNITIES_REQUEST, FETCH_MOST_PLAYED_COMMUNITIES_SUCCESS, FETCH_MOST_REWARDING_COMMUNITIES_FAILED, FETCH_MOST_REWARDING_COMMUNITIES_REQUEST, FETCH_MOST_REWARDING_COMMUNITIES_SUCCESS, FETCH_TODAY_MOST_PLAYED_COMMUNITIES_FAILED, FETCH_TODAY_MOST_PLAYED_COMMUNITIES_REQUEST, FETCH_TODAY_MOST_PLAYED_COMMUNITIES_SUCCESS, OPEN_TOAST, OPEN_TOAST_RESET, RESET_TABLE_ACTIVATE, SET_CLOSE_MODAL, SET_OPEN_MODAL, SET_TABLE_ACTIVATE } from "../constants/otherConstant"

const ToastState = {
    open: false,
    msg: 'msg',
    heading: 'msg',
    error: false,
}

export const toastHandleReducer = (state = ToastState, action) => {
    switch (action.type) {
        case OPEN_TOAST:
            return { open: action.open, msg: action.msg, heading: action.heading, error: action.error }

        case OPEN_TOAST_RESET:
            return { open: false, msg: '', heading: '', error: false }

        default:
            return state
    }
}

const mostPlayedCommunityState = {
    loading: true,
    communities: [],
    error: null,
    fetched: false
}

export const mostPlayedCommunitiesReducer = (state = mostPlayedCommunityState, action) => {
    switch (action.type) {
        case FETCH_MOST_PLAYED_COMMUNITIES_REQUEST:
            return {
                loading: true,
                communities: [],
                error: null,
                fetched: false
            }
        case FETCH_MOST_PLAYED_COMMUNITIES_SUCCESS:
            return {
                loading: false,
                communities: action.communities,
                error: null,
                fetched: true
            }
        case FETCH_MOST_PLAYED_COMMUNITIES_FAILED:
            return {
                loading: false,
                communities: [],
                error: action.payload,
                fetched: false
            }

        default:
            return state
    }
}

const todayMostPlayedCommunityState = {
    loading: true,
    communities: [],
    error: null,
    fetched: false
}

export const todayMostPlayedCommunitiesReducer = (state = todayMostPlayedCommunityState, action) => {
    switch (action.type) {
        case FETCH_TODAY_MOST_PLAYED_COMMUNITIES_REQUEST:
            return {
                loading: true,
                communities: [],
                error: null,
                fetched: false
            }
        case FETCH_TODAY_MOST_PLAYED_COMMUNITIES_SUCCESS:
            return {
                loading: false,
                communities: action.communities,
                error: null,
                fetched: true
            }
        case FETCH_TODAY_MOST_PLAYED_COMMUNITIES_FAILED:
            return {
                loading: false,
                communities: [],
                error: action.payload,
                fetched: false
            }

        default:
            return state
    }
}

const mostRewardedCommunityState = {
    loading: true,
    topPlayers: [],
    table: null,
    error: null,
    fetched: false
}

export const mostRewardedCommunitiesReducer = (state = mostRewardedCommunityState, action) => {
    switch (action.type) {
        case FETCH_MOST_REWARDING_COMMUNITIES_REQUEST:
            return {
                loading: true,
                topPlayers: [],
                table: null,
                error: null,
                fetched: false
            }
        case FETCH_MOST_REWARDING_COMMUNITIES_SUCCESS:
            return {
                loading: false,
                topPlayers: action.topPlayers,
                table: action.table,
                error: null,
                fetched: true
            }
        case FETCH_MOST_REWARDING_COMMUNITIES_FAILED:
            return {
                loading: false,
                topPlayers: [],
                table: null,
                error: action.payload,
                fetched: false
            }
        default:
            return state
    }
}

export const currentValidTableReducer = (state = {tableId: null}, action) => {
    switch (action.type) {
        case SET_TABLE_ACTIVATE:
            return {tableId: action.payload}
        case RESET_TABLE_ACTIVATE:
            return {tableId: null}
        default:
            return state
    }
}

export const editCommunityTabReducer = (state = {tabID: null}, action) => {
    switch (action.type) {
        case EDIT_COMMUNITY_TAB:
            return {tabId: action.tabID}
        default:
            return state
    }
}

export const editTableInfoReducer = (state = {editTableInfo: null}, action) => {
    switch (action.type) {
        case EDIT_TABLE_INFO:
            return {editTableInfo: action.editTableInfo}
        default:
            return state
    }
}

export const newUserOpenModalReducer = (state = {openNewUserModal: false}, action) => {
    switch (action.type) {
        case SET_OPEN_MODAL:
            return {openNewUserModal: true}
        case SET_CLOSE_MODAL:
            return {openNewUserModal: false}
        default:
            return state
    }
}