import { CREATE_TOURNAMENT_FAILED, CREATE_TOURNAMENT_REQUEST, CREATE_TOURNAMENT_SUCCESS, DELETE_TOURNAMENT_FAILED, DELETE_TOURNAMENT_REQUEST, DELETE_TOURNAMENT_SUCCESS, GET_TOURNAMENTS_FAILED, GET_TOURNAMENTS_REQUEST, GET_TOURNAMENTS_SUCCESS, GET_TOURNAMENT_FAILED, GET_TOURNAMENT_REQUEST, GET_TOURNAMENT_SUCCESS, REGISTER_TOURNAMENT_FAILED, REGISTER_TOURNAMENT_REQUEST, REGISTER_TOURNAMENT_SUCCESS } from "../constants/TournamentConstant"

const createTournamentState = {
    loading: false,
    data:{},
    error: false
}

export const createTournamentReducer = (state=createTournamentState, action) => {
    switch(action.type){
            case CREATE_TOURNAMENT_REQUEST :
                return {
                    loading: true,
                    data: {},
                    error: false
                }
            case CREATE_TOURNAMENT_SUCCESS:
                return {
                    loading: false,
                    data: action.payload,
                    error: false
                }
            case CREATE_TOURNAMENT_FAILED:
                return {
                    loading: false,
                    data: {},
                    error: action.payload
                }

            default:
                return state
    }
}


const registerTournamentState = {
    loading: false,
    data:{},
    error: false
}

export const registerTournamentReducer = (state=registerTournamentState, action) => {
    switch(action.type){
            case REGISTER_TOURNAMENT_REQUEST :
                return {
                    loading: true,
                    data: {},
                    error: false
                }
            case REGISTER_TOURNAMENT_SUCCESS:
                return {
                    loading: false,
                    data: action.payload,
                    error: false
                }
            case REGISTER_TOURNAMENT_FAILED:
                return {
                    loading: false,
                    data: {},
                    error: action.payload
                }

            default:
                return state
    }
}

const deleteTournamentState = {
    loading: false,
    data:{},
    error: false
}

export const deleteTournamentReducer = (state=deleteTournamentState, action) => {
    switch(action.type){
            case DELETE_TOURNAMENT_REQUEST :
                return {
                    loading: true,
                    data: {},
                    error: false
                }
            case DELETE_TOURNAMENT_SUCCESS:
                return {
                    loading: false,
                    data: action.payload,
                    error: false
                }
            case DELETE_TOURNAMENT_FAILED:
                return {
                    loading: false,
                    data: {},
                    error: action.payload
                }

            default:
                return state
    }
}

const getTournamentsState = {
    loading: false,
    tournaments:[],
    error: false
}

export const getTournamentsReducer = (state=getTournamentsState, action) => {
    switch(action.type){
            case GET_TOURNAMENTS_REQUEST :
                return {
                    loading: true,
                    tournaments: [],
                    error: false
                }
            case GET_TOURNAMENTS_SUCCESS:
                return {
                    loading: false,
                    tournaments: action.tournaments,
                    error: false
                }
            case GET_TOURNAMENTS_FAILED:
                return {
                    loading: false,
                    tournaments: [],
                    error: action.payload
                }

            default:
                return state
    }
}

const getTournamentState = {
    loading: false,
    tournament: {},
    registerations: [],
    error: false
}

export const getTournamentReducer = (state=getTournamentState, action) => {
    switch(action.type){
            case GET_TOURNAMENT_REQUEST :
                return {
                    loading: true,
                    tournament: {},
                    registerations: [],
                    error: false
                }
            case GET_TOURNAMENT_SUCCESS:
                return {
                    loading: false,
                    tournament: action.tournament,
                    registerations: action.registerations,
                    error: false
                }
            case GET_TOURNAMENT_FAILED:
                return {
                    loading: false,
                    tournament: {},
                    registerations: [],
                    error: action.payload
                }

            default:
                return state
    }
}