import { combineReducers, configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { createCommunityReducer, exchangeCoinForChipsReducer, getCommunityByIDReducer, getCommunityReducer, getRoundReducer, getUserCoinsForArcadeVerseReducer } from './redux/reducers/CommunityReducer'
import { connectDiscordReducer, getAllChatsReducer, updateProfileReducer, userLoginReducer } from './redux/reducers/userReducer'
import { currentValidTableReducer, editCommunityTabReducer, editTableInfoReducer, mostPlayedCommunitiesReducer, mostRewardedCommunitiesReducer, newUserOpenModalReducer, toastHandleReducer, todayMostPlayedCommunitiesReducer } from './redux/reducers/otherReducer';
import { createTableReducer, getTableReducer, getTablesReducer, saveTableReducer } from './redux/reducers/TableReducer';
import { createAdminReducer, deleteAdminReducer, getAllAdminReducer } from './redux/reducers/AdminReducer';
import { NavbarReduer, dailyTasksReducer, getFreeTableReducer, getLeaderboardReducer, getPublicTablesReducer } from './redux/reducers/GlobalReducer';
import { createTournamentReducer, deleteTournamentReducer, getTournamentReducer, getTournamentsReducer, registerTournamentReducer } from './redux/reducers/TournamentReducer';

const initialState = {}

const reducer = combineReducers({
    updateProfileReducer: updateProfileReducer,
    userLoginReducer: userLoginReducer,
    getCommunityReducer: getCommunityReducer,
    createCommunityReducer: createCommunityReducer,
    connectDiscordReducer: connectDiscordReducer,
    toastHandleReducer: toastHandleReducer,
    createTableReducer: createTableReducer,
    getTableReducer: getTableReducer,
    getTablesReducer: getTablesReducer,
    getCommunityByIDReducer: getCommunityByIDReducer,
    getAllAdminReducer: getAllAdminReducer,
    deleteAdminReducer: deleteAdminReducer,
    createAdminReducer: createAdminReducer,
    getAllChatsReducer: getAllChatsReducer,
    NavbarReduer: NavbarReduer,
    currentValidTableReducer: currentValidTableReducer,
    editCommunityTabReducer: editCommunityTabReducer,
    editTableInfoReducer: editTableInfoReducer,
    newUserOpenModalReducer: newUserOpenModalReducer,
    getUserCoinsForArcadeVerseReducer: getUserCoinsForArcadeVerseReducer,
    exchangeCoinForChipsReducer: exchangeCoinForChipsReducer,
    saveTableReducer: saveTableReducer,
    getRoundReducer: getRoundReducer,
    getPublicTablesReducer: getPublicTablesReducer,
    dailyTasksReducer: dailyTasksReducer,
    getLeaderboardReducer: getLeaderboardReducer,
    getFreeTableReducer: getFreeTableReducer,
    mostPlayedCommunitiesReducer: mostPlayedCommunitiesReducer,
    mostRewardedCommunitiesReducer: mostRewardedCommunitiesReducer,
    todayMostPlayedCommunitiesReducer: todayMostPlayedCommunitiesReducer,
    createTournamentReducer: createTournamentReducer,
    registerTournamentReducer: registerTournamentReducer,
    getTournamentsReducer: getTournamentsReducer,
    getTournamentReducer: getTournamentReducer,
    deleteTournamentReducer: deleteTournamentReducer,
})

export const store = configureStore({
    reducer,
    devTools: process.env.REACT_APP_PRODUCTION === "DEVELOPMENT",
    initialState,
    middleware: [thunk],
})