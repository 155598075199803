import { Popover } from '@mui/material'
import styles from './DeleteProfilePopover.module.css'
import React from 'react'
import deletesvg from '../../../../Assets/svgs/delete.svg'
import { Logout } from '@mui/icons-material'

const DeleteProfilePopover = ({ handleClose, anchorEl, setOpenModal, handelLogout}) => {
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const handleClick = () =>{
        handleClose()
        setOpenModal(true)
    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                top: '8px',
                '& .MuiPopover-paper': {
                    background: 'transparent',
                },
            }}
        >
            <button className={`${styles.popover_wrapper}`} onClick={handleClick}>
                <img src={deletesvg} alt="" />
                Delete Profile
            </button>
            <button className={`${styles.popover_wrapper}`} onClick={handelLogout}>
                <Logout />
                Logout
            </button>
        </Popover>
    )
}

export default DeleteProfilePopover
