import styles from './RegisterModal.module.css'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef, useState } from 'react'
import holder from '../../../../Assets/svgs/Holders.svg'
import { registerTournament } from '../../../../redux/actions/TournamentAction'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { SwipeableDrawer } from '@mui/material'

const RegisterModal = ({openModal, setOpenModal, tournament}) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const cancelButtonRef = useRef(null)

    const {loading} = useSelector((state) => state.registerTournamentReducer)
    const {userInfo} = useSelector((state) => state.userLoginReducer)

    const [error, setError] = useState('')

    const MainModal = () => {
        return(
            <>
                <div className={styles.top_header}>
                <h1>Register</h1>

                <button onClick={() => setOpenModal(false)}>
                    <Close />
                </button>
            </div>

            <div className={styles.divider}></div>

            <div className={styles.modal_main_cntnt}>
                {/* < */}
                <div className={styles.banner_holder}
                    style={{
                        background: `linear-gradient(283deg, #00000066 0%, #0000001a 100%), linear-gradient(0deg, ${tournament?.tableBackground?.backgroundColor} 0%, ${tournament?.tableBackground?.backgroundColor} 100%)`
                    }}
                >
                    {/* <TableTopCard id={id} table={data} img={data?.cardPreview} /> */}
                    <img src={tournament?.cardPreview} alt=""  className={styles.banner_img} />

                    <div className={styles.heading_txts}>
                        <figure className={styles.fig_wrap}>
                            <img src={tournament?.logoImage} alt="" />
                        </figure>
                        <h1>{tournament?.tournamentName}</h1>
                    </div>
                </div>

                <div className={styles.tasks_wrapper}>
                    {tournament?.nftsRequired && <div className={styles.task}>
                        <div className={styles.left}>
                            <figure>
                                <img src={holder} alt="" />
                            </figure>
                            <h1>Holder</h1>
                        </div>
                        <div className={styles.right}>
                            <h1>3/3</h1>
                        </div>
                    </div>}

                    {false && <div className={styles.errorWrapper}>
                        <Alert />
                        <span>{'You don’t have enough Earpitz NFTs to participate.'}</span>
                    </div>}

                    {<div className={styles.task}>
                        <div className={styles.left}>
                            <figure>
                                {/* <img src={discord} alt="" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                                    <g clipPath="url(#clip0_4960_7177)">
                                        <path d="M20.2155 2.09113C18.6858 1.3664 17.0454 0.832444 15.3303 0.526628C15.2991 0.520726 15.2679 0.535475 15.2518 0.564976C15.0408 0.952407 14.8072 1.45784 14.6435 1.85511C12.7988 1.56995 10.9636 1.56995 9.15676 1.85511C8.99308 1.44901 8.75092 0.952407 8.53901 0.564976C8.52292 0.53646 8.49172 0.52171 8.46049 0.526628C6.74635 0.831467 5.106 1.36542 3.57537 2.09113C3.56212 2.09703 3.55076 2.10687 3.54323 2.11965C0.431829 6.91935 -0.420513 11.6011 -0.00238238 16.2248C-0.000490434 16.2474 0.0118074 16.269 0.0288355 16.2828C2.08165 17.8394 4.07014 18.7844 6.02172 19.4108C6.05295 19.4206 6.08604 19.4088 6.10592 19.3823C6.56756 18.7313 6.97908 18.045 7.33191 17.3232C7.35274 17.2809 7.33286 17.2307 7.2903 17.214C6.63757 16.9583 6.01604 16.6466 5.41817 16.2926C5.37088 16.2641 5.36709 16.1943 5.4106 16.1608C5.53641 16.0635 5.66226 15.9622 5.78239 15.8599C5.80413 15.8413 5.83441 15.8373 5.85997 15.8491C9.78772 17.7008 14.04 17.7008 17.9214 15.8491C17.9469 15.8363 17.9772 15.8403 17.9999 15.859C18.1201 15.9612 18.2459 16.0635 18.3727 16.1608C18.4162 16.1943 18.4133 16.2641 18.366 16.2926C17.7682 16.6535 17.1466 16.9583 16.4929 17.213C16.4504 17.2297 16.4315 17.2809 16.4523 17.3232C16.8127 18.0439 17.2242 18.7303 17.6773 19.3813C17.6963 19.4088 17.7303 19.4206 17.7615 19.4108C19.7226 18.7844 21.7111 17.8394 23.7639 16.2828C23.7819 16.269 23.7932 16.2484 23.7951 16.2258C24.2955 10.8803 22.9569 6.23692 20.2467 2.12062C20.24 2.10687 20.2287 2.09703 20.2155 2.09113ZM7.91846 13.4094C6.73594 13.4094 5.76157 12.2884 5.76157 10.9117C5.76157 9.53505 6.71704 8.41406 7.91846 8.41406C9.12931 8.41406 10.0942 9.54489 10.0753 10.9117C10.0753 12.2884 9.11985 13.4094 7.91846 13.4094ZM15.8932 13.4094C14.7107 13.4094 13.7363 12.2884 13.7363 10.9117C13.7363 9.53505 14.6918 8.41406 15.8932 8.41406C17.1041 8.41406 18.069 9.54489 18.0501 10.9117C18.0501 12.2884 17.1041 13.4094 15.8932 13.4094Z" fill="#5562EA"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4960_7177">
                                        <rect width="24" height="19.1969" fill="white" transform="translate(0 0.401566)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </figure>
                            <h1>Discord</h1>
                        </div>
                        <div className={styles.right}>
                            {userInfo?.isDiscordConnected
                                ? <h1>@{userInfo?.isDiscordConnected ? userInfo?.discordInfo?.username + '#' + userInfo?.discordInfo?.discriminator : ''}</h1>
                                : <button className='primary-custom-button' onClick={() => {
                                    localStorage.setItem('redirect_url', JSON.stringify(location.pathname))
                                    window.location.assign(process.env.REACT_APP_DISCORD_URL)
                                }}>
                                    <button className={`${styles.connect_btn} primary-custom-button-inner`}>Connect</button>
                                </button>
                            }
                        </div>
                    </div>}

                    {error && <div className={styles.errorWrapper}>
                        <Alert />
                        <span>{error}</span>
                    </div>}
                </div>

                {/* main-part */}
                <div className={styles.btn_holder}>
                    <button className={`primary-custom-button w-full`}
                        disabled={!userInfo?.isDiscordConnected || loading}
                        onClick={() => {
                            dispatch(registerTournament(tournament?._id, setOpenModal, setError))
                        }}
                    >
                        {userInfo?.isDiscordConnected ? <div className={`primary-custom-button-inner ${styles.success_btn}`}>{loading ? 'Registering...' : <>Register for {tournament?.entryFees || 'FREE'}{tournament?.entryFees > 0 && <img src={tournament?.chipImg} alt='' className='w-5' />}  </>}</div> : <div className={`primary-custom-button-inner ${styles.success_btn}`}>Connect discord to Register</div>}
                    </button>
                </div>
            </div>
            </>
        )
    }

    if(window.innerWidth > 650)
        return (
            <Transition.Root show={openModal} as={Fragment}>
                <Dialog
                    as='div'
                    className='fixed z-10 inset-0 overflow-y-auto'
                    initialFocus={cancelButtonRef}
                    onClose={setOpenModal}
                >
                    <div className='h-screen px-4 text-center block p-0'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <Dialog.Overlay className='fixed inset-0 bg-[#0c0c0d9a] bg-opacity-75 backdrop-blur-[3px] transition-opacity' />
                        </Transition.Child>
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className='inline-block align-middle h-screen'
                            aria-hidden='true'
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            enterTo='opacity-100 translate-y-0 sm:scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        >
                            <div className='inline-block bg-transparent rounded-lg text-left transform transition-all my-8 align-middle max-w-[540px] w-full'>
                                <div
                                    className={`${styles.modal_background} flex justify-center w-full items-center rounded-lg text-white h-full p-10 flex-col gap-y-8 border border-[#3D3D3D]`}
                                >
                                    <MainModal />
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    else return <SwipeableDrawer
        anchor={'bottom'}
        open={openModal}
        onClose={(e) => {setOpenModal(false)}}
        transitionDuration={600}
        onOpen={(e) => setOpenModal(true)}
        className='transition-all ease-in-out'
        PaperProps={{
            sx: {
                backdropFilter: 'blur(50px)',
                borderRadius: '0 !important',
                color: 'white',
                // height: '400px',
                width: '100%',
                background: 'transparent',
                // background: 'linear-gradient(167.1deg, #262626 36.57%, #1B1818 94.9%)',
                boxShadow: 'none',
                padding: '0',
                paddingBottom: '0',
            },
        }}
        ModalProps={{
            sx: {
                zIndex: '1280',
            },
        }}
    >
        <div className={styles.modal_background_swipe}>
            <MainModal/>
        </div>
    </SwipeableDrawer>
}

export default React.memo(RegisterModal)

const Close = () =>{
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}
const Alert = () => {
    return(<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99951 6.00003V8.6667M7.99951 11.3334H8.00618M7.07639 2.59451L1.59313 12.0656C1.289 12.5909 1.13693 12.8536 1.1594 13.0692C1.17901 13.2572 1.27752 13.4281 1.43043 13.5392C1.60573 13.6667 1.90924 13.6667 2.51625 13.6667H13.4828C14.0898 13.6667 14.3933 13.6667 14.5686 13.5392C14.7215 13.4281 14.82 13.2572 14.8396 13.0692C14.8621 12.8536 14.71 12.5909 14.4059 12.0656L8.92263 2.59451C8.61959 2.07107 8.46806 1.80935 8.27038 1.72145C8.09794 1.64477 7.90108 1.64477 7.72864 1.72145C7.53096 1.80935 7.37944 2.07107 7.07639 2.59451Z" stroke="#F70000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
    </svg>
    )
}