import React, { useCallback, useEffect, useState } from "react";
import "./dashboardNav.css";
import coins from "../../Assets/Images/coins.svg";
import dashNavVector from "../../Assets/Images/dashNavVector.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginModal from "../shared/modals/LoginModal/LoginModal";
import gameCoin from "../../Assets/Images/gameCoin.svg";
import { useDispatch, useSelector } from "react-redux";
import ExchangeModal from "../shared/modals/ExchangeModal/ExchangeModal";
import CreateProfileModal from "../shared/modals/CreateProfile/CreateProfile";
import { ArrowBack } from "@mui/icons-material";
import PlayCoins from "../shared/modals/PlayCoins/PlayCoins";
import { setCurrentPos } from "../../redux/actions/GlobalAction";
import CreateGuestModal from "../shared/modals/CreateGuestModal/CreateGuestModal";

const DashboardNav = ({backUrl: propsBackUrl, title: propsTitle, disabled}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { isLogin, userInfo } = useSelector((state) => state.userLoginReducer)
  const { currId: active, title, backUrl } = useSelector((state) => state.NavbarReduer)

  const [openModal, setOpenModal] = useState(false);
  const [dashBlink, setDashBlink] = useState(true)
  const [exchangeModal, setExchangeModal] = useState(false);
  const [playCoinsModal, setPlayCoinsModal] = useState(false);
  const [checkOnlyWallet, setCheckOnlyWallet] = useState(false);
  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const {allChips} = useSelector((state) => state.getUserCoinsForArcadeVerseReducer)

  const setActive = useCallback((id) => {
    dispatch(setCurrentPos(id))
  },[dispatch])

  const getCurrentChip = () => {
    const arr = allChips.filter((chip) => chip?.slug === 'kuramaverse')
    return arr?.length > 0 ? arr[0] : {}
  }

  useEffect(() => {
    setTimeout(() => {
      setDashBlink(false)
    }, 6000)
  },[])

  useEffect(() => {
    if (active === "dashboard") {
      if(document.getElementById("glow")) document.getElementById("glow").style.transform = isLogin ? "translateX(0)" : "translateX(34px)";
    } else if (active === "community") {
      if(document.getElementById("glow")) document.getElementById("glow").style.transform = isLogin ? "translateX(160px)" : "translateX(194px)";
    } else if (active === "leaderboard") {
      if(document.getElementById("glow")) document.getElementById("glow").style.transform = isLogin ? "translateX(320px)" : "translateX(354px)";
    } else if (active === "profile") {
      if(document.getElementById("glow")) document.getElementById("glow").style.transform = isLogin ? "translateX(480px)": "translateX(504px)";
    }
  }, [active, isLogin]);

  useEffect(() => {
    const switchNavigationPage = () => {
        const current = location.pathname.split('/')[1]

        switch (current) {
            case '':
              setActive("")
                return
            case 'dashboard':
              setActive("dashboard")
                return
            case 'community':
              setActive("community")
                return
            case 'leaderboard':
              setActive("leaderboard")
                return
            case 'profile':
              setActive("profile")
                return
            default:
                return
        }
    }

    return switchNavigationPage()
  }, [location, setActive])

  if((location.pathname.split('/')[2] || (active !== "dashboard" && active !== 'community' && active !== 'leaderboard' && active !== 'profile')) && !disabled){
    return
  }

  return (
    <div className={!true ? "dash-nav-fixed" : "dash-nav"} style={{position: disabled ? 'unset' : '',}}>
      <Link to={disabled ? '#' : '/'} className="dash-logo-equalizer" style={{width: disabled ? 'auto' : '', paddingTop: title || propsTitle ? '12px' : '', cursor: disabled ? 'default' : ''}}>
        <img src={"https://res.cloudinary.com/kuramaverse/image/upload/v1695756520/ArcadeVerse_Logo_vujobz.svg"} alt="poker" className="dash-logo" />
      </Link>

      {!(title || propsTitle) && <div className="dash-nav-options" id="nav">
          <div className="dash-nav-glow" id="glow" style={{visibility: title ? "hidden" : "", display: title ? 'none' : ''}}/>
          <img src={dashNavVector} alt="" className="dash-nav-vector-left z-[1]" />

          <HyperLink
            link={'/dashboard'}
            heading={'Dashboard'}
            activeKey={'dashboard'}
            active={active}
            disabled={disabled}
            title={title}
            setActive={setActive}
          />

          <HyperLink
            link={'/community'}
            heading={'Community'}
            activeKey={'community'}
            active={active}
            disabled={disabled}
            title={title}
            setActive={setActive}
          />

          <HyperLink
            link={'/leaderboard'}
            heading={'Leaderboard'}
            activeKey={'leaderboard'}
            active={active}
            disabled={disabled}
            title={title}
            setActive={setActive}
          />

          {isLogin && <HyperLink
            link={'/profile'}
            heading={'Profile'}
            activeKey={'profile'}
            active={active}
            disabled={disabled}
            title={title}
            setActive={setActive}
          />}

          <img src={dashNavVector} alt="" className="dash-nav-vector-right" />
        </div>
        }

      {(title || propsTitle) && <div className="" style={{padding: '2px 100px 0px 100px', position: 'absolute', width: '705px', top: '-4px', left: 'calc(50% - 300px)', height: '61px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: `url(${'https://res.cloudinary.com/kuramaverse/image/upload/v1694892443/Group_1000002711_mkwqte.svg'}) no-repeat`, backgroundSize: 'contain', paddingBottom: '4px'}}>
          <button onClick={()=>{if(!disabled) navigate(backUrl || propsBackUrl || -1)}} style={{color: "#D93D48", zIndex: 2, cursor: disabled ? 'default' : ''}}>
            <ArrowBack />
          </button>
          <h2 className="title_community_name">{title || propsTitle}</h2>
        </div>}

      {isLogin && <div className="dash-utils">
        <div className="dash-utils-flex-2 cursor-pointer" onClick={() => {if(!disabled) setExchangeModal(true)}} style={{cursor: disabled ? 'default' : ''}}>
          <img src={gameCoin} alt="coins" className="dash-utils-img" />
          <div className="dash-utils-text">{(getCurrentChip()?.value > 1000000 ? (Math.floor(getCurrentChip()?.value / 1000)) + 'K' : getCurrentChip()?.value) || 0}</div>
        </div>
        <div className={Date.now() - userInfo?.lastClaimed > 14400000 && dashBlink ? `dash-utils-flex-1-lighter` : ''}>
          <div className="dash-utils-flex-1 cursor-pointer" onClick={() => {if(!disabled) setPlayCoinsModal(true)}} style={{cursor: disabled ? 'default' : '', border: Date.now() - userInfo?.lastClaimed > 14400000 && dashBlink ? '1.5px solid #F2BD01' : '', overflow: Date.now() - userInfo?.lastClaimed > 14400000 ? '' : 'hidden'}}>
            <img src={coins} alt="coins" className="dash-utils-img" />
            <div className="dash-utils-text">{userInfo?.playChips > 1000000 ? (Math.floor(userInfo?.playChips / 1000)) + 'K' : userInfo?.playChips}</div>
            {Date.now() - userInfo?.lastClaimed < 14400000 && <div className="dash-utils-flex-1-bottom-line" style={{width: `${((Date.now() - userInfo?.lastClaimed) / 14400000) * 100}%`}}></div>}
          </div>
        </div>
      </div>}

      {!isLogin && <button className='login-button' onClick={() => {if(!disabled) setOpenModal(true)}}>
        <div className="login-button-inner">
          Connect
        </div>
      </button>}

      {playCoinsModal && !disabled && <PlayCoins setCheckOnlyWallet={setCheckOnlyWallet} setOpenLoginModal={setOpenModal} openModal={playCoinsModal} setOpenModal={() => {setPlayCoinsModal(false)}} />}
      {exchangeModal && !disabled && <ExchangeModal openModal={exchangeModal} setOpenModal={() => {setExchangeModal(false)}} />}
      {openModal && !disabled && <LoginModal checkOnlyWallet={checkOnlyWallet} setOpenGuestModal={setOpenGuestModal} setOpenProfileModal={setOpenCreateModal} openModal={openModal} setOpenModal={() => {setOpenModal(false)}} />}
      {openGuestModal && <CreateGuestModal setOpenConnectModal={setOpenModal} openModal={openGuestModal} setOpenModal={setOpenGuestModal} setOpenCreateModal={setOpenCreateModal} />}
      {openCreateModal && <CreateProfileModal openModal={openCreateModal} setOpenModal={setOpenCreateModal} />}
    </div>
  );
};

export default DashboardNav;

const HyperLink = ({active, disabled, setActive, title, link, heading, activeKey}) => {
  return(<Link
    to={link}
    className="dash-nav-option-wrapper"
    style={{
      background: `linear-gradient(0deg, rgba(250, 50, 50, 0.45) 0%, rgba(255, 0, 0, 0) 81.56%)`,
      borderRadius: '0px 0px 2px 2px',
      padding: '1px',
      visibility: title ? "hidden" : ""
    }}
    onClick={() => {if(!disabled) setActive(activeKey)}}
  >
    <div className={
        active === activeKey
          ? "dash-nav-option dash-active"
          : "dash-nav-option"
      }>
        {heading}
      </div>
  </Link>)
}