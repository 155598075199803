import styles from './InsufficeintFundModal.module.css'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

const InsufficeintFundModal = ({openModal, buyInChips, setOpenModal, table, navigationOff, validate, connectBtn}) => {
    const chipType = table?.chipName === 'Play Chips' ? 1  : 2
    const cancelButtonRef = useRef(null)
    const [value, setValue] = useState(table?.minBuyIn || 0)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const {userInfo, accessToken, verificationHash, isLogin} = useSelector((state) => state.userLoginReducer)
    const {allChips} = useSelector((state) => state.getUserCoinsForArcadeVerseReducer)

    useEffect(() => {
        const check = () => {
            try{
                const chips = (chipType !== 1 ? getCurrentChip()?.value : userInfo?.playChips)
                const range = document.getElementById('range')
                range.style.backgroundSize = (value - range.min) * 100 / (range.max - range.min) + '% 100%'

                if (Number(value) > Number(range.max)) {
                    range.style.backgroundSize = 100 + '% 100%'
                }

                if (Number(range.max) === 0) {
                    range.style.backgroundSize = 0 + '% 100%'
                }
                if(isLogin && (chips !== undefined) && (chips < value)){
                    setError('Not enough chips. You can get more chips from exchange')
                    return
                } else {
                    setError(null)
                }
            } catch(err) {
                console.log(err)
            }
        }
        setTimeout(() => {
            check()
        },100)
    },[userInfo, isLogin])


    const getCurrentChip = () => {
        const arr = allChips.filter((chip) => chip?.slug === table?.slug)
        return arr?.length > 0 ? arr[0] : {}
    }

    function handleInputChange(e) {
        let target = e.target
        if (e.target.type !== 'range') {
            target = document.getElementById('range')
        }
        const min = target.min
        const max = target.max
        const val = target.value

        if (val > ((chipType !== 1 ? (getCurrentChip()?.value > 100000 ? 100000 : getCurrentChip()?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0)) {
            setValue((chipType !== 1 ? (getCurrentChip()?.value > 100000 ? 100000 : getCurrentChip()?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0)
            target.style.backgroundSize = (((chipType !== 1 ? (getCurrentChip()?.value > 100000 ? 100000 : getCurrentChip()?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0) - min) * 100 / (max - min) + '% 100%'
        } else {
            setValue(val);
            target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
        }

        if (Number(val) > Number(max)) {
            target.style.backgroundSize = 100 + '% 100%'
        }

        setError(null)
    }

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-20 inset-0 overflow-y-auto'
                initialFocus={cancelButtonRef}
                onClose={() => 0}
            >
                <div className='h-screen px-4 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 transition-opacity' style={{
                            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%)`
                        }} />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block bg-transparent rounded-lg text-left transform transition-all align-middle max-w-[520px] w-full'>
                            <div
                                className={`${styles.modal_background} text-white h-full`}
                            >
                                <div className={styles.background_black}></div>
                                <div className={styles.profile_holder}>
                                    <img src={table?.cardPreview} alt="" className='w-[inherit] h-[inherit] object-contain' />
                                </div>
                                <div className={styles.main_holder_content}>
                                    <div className={styles.modal_bkg_svg}>
                                        <img src={'https://res.cloudinary.com/kuramaverse/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1694629721/playmodalimage_yty5tv.webp'} alt="" />
                                    </div>

                                    <div className='w-full flex flex-col items-center gap-5'>
                                        <div className={styles.top_header}>
                                            <button onClick={() => {
                                                if(!navigationOff || (isLogin && validate))
                                                    setOpenModal(false)
                                            }}>
                                                {/* <Close /> */}
                                            </button>
                                        </div>

                                        <div className='flex flex-col gap-1 items-center w-full'>
                                            <h1 className={styles.heading}>{(userInfo?.playChips < table?.minBuyIn) ? 'Insufficeint Chips' : 'Buy In Chips'}</h1>
                                        </div>
                                    </div>

                                    <div className={styles.bottom_info_section} style={{justifyContent: error ? 'space-between' : ''}}>
                                        <div className={styles.divider}></div>
                                        <p className={styles.in__text}>{`You need at least ${table?.minBuyIn} Play Chips to play the next round. You can buy in more chips or spectate.`}</p>
                                        <div className={styles.divider}></div>

                                        <div className={styles.align_in_line}>
                                            <div className={styles.section_cntnt}>
                                                <h1>Current Balance</h1>
                                                <div className='flex items-center gap-3'>
                                                    <h3>{JSON.parse(localStorage.getItem('playingPlayerInfo')).chips}</h3>
                                                    <div className={styles.chipImgHolderOuter}>
                                                        <div className={styles.chipImgHolder}>
                                                            <img src={table?.chipImg} alt='' className={styles.chipImgMain} />
                                                            <h2>{table?.chipName}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.align_in_line}>
                                            <div className={styles.section_cntnt} style={{width: '100%'}}>
                                                <h1>Buy In amount</h1>
                                                {/* <h2>7</h2> */}
                                                <div className={styles.slider_container}>
                                                    <input type="text" className={styles.buyInput} value={value} min={table?.minBuyIn} max={(chipType !== 1 ? (getCurrentChip()?.value > 100000 ? 100000 : getCurrentChip()?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0} onChange={(e) => {
                                                        if (e.target.value > ((chipType !== 1 ? (getCurrentChip()?.value > 100000 ? 100000 : getCurrentChip()?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0)) {
                                                            setValue((chipType !== 1 ? (getCurrentChip()?.value > 100000 ? 100000 : getCurrentChip()?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0)
                                                            setError(null)

                                                            let target = document.getElementById('range')
                                                            const min = target.min
                                                            const max = target.max

                                                            target.style.backgroundSize = (((chipType !== 1 ? (getCurrentChip()?.value > 100000 ? 100000 : getCurrentChip()?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0) - min) * 100 / (max - min) + '% 100%'
                                                        } else {
                                                            setValue(!e.target.value || parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value));
                                                            setError(null)

                                                            let target = document.getElementById('range')
                                                            const min = target.min
                                                            const max = target.max
                                                            const val = e.target.value

                                                            target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
                                                        }
                                                    }} />
                                                    <div className='flex items-center w-full gap-0'>
                                                        <input type="range" value={value} min={0} max={(chipType !== 1 ? getCurrentChip()?.value : userInfo?.playChips) || 0} id="range" className={styles.amnt_slider} onInput={handleInputChange} />
                                                        <span className={styles.slider_value}>{(chipType !== 1 ? getCurrentChip()?.value : userInfo?.playChips) || ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {error && <div className={styles.errorWrapper}>
                                            <Alert />
                                            <span>{error}</span>
                                        </div>}

                                        {navigationOff && !isLogin ?
                                            <div className={styles.button_holder}>
                                                <button className={`primary-custom-button w-full`} disabled={loading} onClick={connectBtn}>
                                                    <div className={`primary-custom-button-inner ${styles.play_btn}`}>Connect</div>
                                                </button>
                                            </div>
                                        :
                                            <div className={styles.button_holder}>
                                                <button onClick={() => setOpenModal()} className={`secondary-custom-button`}>
                                                    <div className={`secondary-custom-button-inner ${styles.spectate_btn}`}>Spectate</div>
                                                </button>
                                                <button className={`primary-custom-button w-full`} disabled={loading || (parseInt(value) < table?.minBuyIn) || error} onClick={() => {
                                                    buyInChips(value)
                                                    setOpenModal(false)
                                                }}>
                                                    <div className={`primary-custom-button-inner ${styles.play_btn}`}>Buy in</div>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default React.memo(InsufficeintFundModal)

// const Close = () =>{
//     return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//     </svg>
//     )
// }

const HoldersSvg = () => {
    return(<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="7.76621" height="9.66772" rx="0.691744" transform="matrix(0.979925 0.199367 -0.199369 0.979925 3.38867 0)" fill="#FF5050"/>
    <rect width="6.82451" height="6.86618" rx="0.172936" transform="matrix(0.979925 0.199367 -0.199369 0.979925 3.75195 0.583923)" fill="url(#paint0_linear_2943_49338)"/>
    <rect x="1.30469" y="1.35461" width="7.76621" height="9.66772" rx="0.691744" fill="#FF5050"/>
    <rect x="1.77734" y="1.85516" width="6.82451" height="6.86618" rx="0.172936" fill="url(#paint1_linear_2943_49338)"/>
    <rect x="1.77734" y="1.85516" width="6.82451" height="6.86618" rx="0.172936" fill="black" fillOpacity="0.2"/>
    <defs>
    <linearGradient id="paint0_linear_2943_49338" x1="6.82444" y1="9.07688e-08" x2="3.41228" y2="6.8662" gradientUnits="userSpaceOnUse">
    <stop/>
    <stop offset="1" stopColor="#394740"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2943_49338" x1="8.60179" y1="1.85516" x2="5.18963" y2="8.72136" gradientUnits="userSpaceOnUse">
    <stop stopColor="#0000FF"/>
    <stop offset="1" stopColor="#394740"/>
    </linearGradient>
    </defs>
    </svg>
    )
}

const Alert = () => {
    return(<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99951 6.00003V8.6667M7.99951 11.3334H8.00618M7.07639 2.59451L1.59313 12.0656C1.289 12.5909 1.13693 12.8536 1.1594 13.0692C1.17901 13.2572 1.27752 13.4281 1.43043 13.5392C1.60573 13.6667 1.90924 13.6667 2.51625 13.6667H13.4828C14.0898 13.6667 14.3933 13.6667 14.5686 13.5392C14.7215 13.4281 14.82 13.2572 14.8396 13.0692C14.8621 12.8536 14.71 12.5909 14.4059 12.0656L8.92263 2.59451C8.61959 2.07107 8.46806 1.80935 8.27038 1.72145C8.09794 1.64477 7.90108 1.64477 7.72864 1.72145C7.53096 1.80935 7.37944 2.07107 7.07639 2.59451Z" stroke="#F70000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
    </svg>
    )
}