import React from 'react'

const LeaderboardSvg = ({active}) => {
    return (
        <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter={active ? '' : 'url(#filter0_d_5356_2950)'}>
                <mask
                    id='mask0_5356_2960'
                    style={{ maskType: 'alpha' }}
                    maskUnits='userSpaceOnUse'
                    x='0'
                    y='0'
                    width='32'
                    height='32'
                >
                    <rect width='32' height='32' fill='#D9D9D9' />
                </mask>
                <g mask='url(#mask0_5356_2960)'>
                    <path
                        d='M5.46787 25.2003H11.25V14.8028H5.46787V25.2003ZM13.1153 25.2003H18.8846V6.80283H13.1153V25.2003ZM20.7499 25.2003H26.532V17.4695H20.7499V25.2003ZM3.60254 27.0656V12.9375H11.25V4.9375H20.7499V15.6042H28.3973V27.0656H3.60254Z'
                        fill={active ? '#A01F1F' : '#7D7D7D'}
                    />
                </g>
            </g>
            {active && <defs>
                <filter
                    id='filter0_d_5356_2950'
                    x='0.908813'
                    y='4.91144'
                    width='32.4062'
                    height='30.1819'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='4' />
                    <feGaussianBlur stdDeviation='2' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_5356_2950'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_5356_2950'
                        result='shape'
                    />
                </filter>
            </defs>}
        </svg>
    )
}

export default LeaderboardSvg
