import React from 'react'

const DealerPositionPath = ({ activePlayers }) => {
    return (
        <svg style={((localStorage.getItem('startPath') !== '0') && (activePlayers.length > 2)) ? { zIndex: 10, left: '50%', position: 'absolute', transform: 'translate(-50%, -7px)', opacity: 1 } : { zIndex: 10, left: '50%', position: 'absolute', transform: 'translate(-50%, -7px)', opacity: 0 }} width="910" height="274" viewBox="0 0 910 274" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="runningPath" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M697.45 29.9922H212.55C106.696 29.9922 30 83.1757 30 155.651C30 218.742 105.646 243.769 198.178 243.769H711.822C804.354 243.769 880 218.742 880 155.651C880 83.1757 803.304 29.9922 697.45 29.9922ZM695.536 31.959H214.556C109.557 31.959 33.4805 83.9922 33.4805 154.9C33.4805 216.626 108.515 241.112 200.3 241.112H709.792C801.577 241.112 876.611 216.626 876.611 154.9C876.611 83.9922 800.535 31.959 695.536 31.959Z" />
            <g id="runningSVG" clipPath="url(#clip0_106_632)">
                <g filter="url(#filter0_ii_106_632)">
                    <path d="M232 247C232 238.716 225.284 232 217 232C208.716 232 202 238.716 202 247C202 255.284 208.716 262 217 262C225.284 262 232 255.284 232 247Z" fill="#A90000" />
                    <path d="M217 238.123C215.678 241.496 209.041 242.733 209.041 247.918C209.045 248.779 209.308 249.618 209.795 250.327C210.282 251.036 210.972 251.582 211.774 251.893C212.575 252.205 213.453 252.268 214.291 252.075C215.129 251.881 215.89 251.44 216.474 250.808C216.366 252.47 215.689 254.044 214.557 255.265V255.878H219.455V255.265C218.32 254.048 217.64 252.476 217.533 250.814C218.118 251.444 218.878 251.884 219.716 252.076C220.554 252.268 221.43 252.204 222.231 251.891C223.031 251.579 223.72 251.033 224.206 250.325C224.693 249.616 224.955 248.778 224.959 247.918C224.959 242.733 218.329 241.496 217 238.123Z" fill="#0A0A0A" />
                    <path d="M231.688 247C231.688 238.888 225.112 232.312 217 232.312C208.888 232.312 202.312 238.888 202.312 247C202.312 255.112 208.888 261.688 217 261.688C225.112 261.688 231.688 255.112 231.688 247Z" stroke="black" strokeWidth="0.5" />
                </g>
                <defs>
                    <filter id="filter0_ii_106_632" x="200" y="230" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="2" dy="2" />
                        <feGaussianBlur stdDeviation="1" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_106_632" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="-2" dy="-2" />
                        <feGaussianBlur stdDeviation="1" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0" />
                        <feBlend mode="normal" in2="effect1_innerShadow_106_632" result="effect2_innerShadow_106_632" />
                    </filter>
                    <clipPath id="clip0_106_632">
                        <rect width="30" height="30" fill="white" transform="translate(202 232)" />
                    </clipPath>
                </defs>
            </g>
        </svg>
    )
}

export default DealerPositionPath
