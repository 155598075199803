import React from 'react'
import styles from './InputDrawer.module.css'

const InputDrawer = ({label='Project Name', value='Blvck', extra, onChange}) => {
    return (
        <div className={styles.inp_holder}>
        <label htmlFor=''>{label}{extra && <span className={styles.extra}>{extra}</span>}</label>
        <input type='text' value={value} onChange={(e) => onChange(e.target.value)}/>
    </div>
    )
}

export default InputDrawer
