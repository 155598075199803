export const OPEN_TOAST = 'OPEN_TOAST'
export const OPEN_TOAST_RESET = 'OPEN_TOAST_RESET'
export const SET_TABLE_ACTIVATE = 'SET_TABLE_ACTIVATE'
export const RESET_TABLE_ACTIVATE = 'RESET_TABLE_ACTIVATE'
export const EDIT_COMMUNITY_TAB = 'EDIT_COMMUNITY_TAB'
export const EDIT_TABLE_INFO = 'EDIT_TABLE_INFO'
export const SET_OPEN_MODAL = 'SET_OPEN_MODAL'
export const SET_CLOSE_MODAL = 'SET_CLOSE_MODAL'

export const FETCH_MOST_REWARDING_COMMUNITIES_REQUEST = 'FETCH_MOST_REWARDING_COMMUNITIES_REQUEST'
export const FETCH_MOST_REWARDING_COMMUNITIES_SUCCESS = 'FETCH_MOST_REWARDING_COMMUNITIES_SUCCESS'
export const FETCH_MOST_REWARDING_COMMUNITIES_FAILED = 'FETCH_MOST_REWARDING_COMMUNITIES_FAILED'

export const FETCH_MOST_PLAYED_COMMUNITIES_REQUEST = 'FETCH_MOST_PLAYED_COMMUNITIES_REQUEST'
export const FETCH_MOST_PLAYED_COMMUNITIES_SUCCESS = 'FETCH_MOST_PLAYED_COMMUNITIES_SUCCESS'
export const FETCH_MOST_PLAYED_COMMUNITIES_FAILED = 'FETCH_MOST_PLAYED_COMMUNITIES_FAILED'

export const FETCH_TODAY_MOST_PLAYED_COMMUNITIES_REQUEST = 'FETCH_TODAY_MOST_PLAYED_COMMUNITIES_REQUEST'
export const FETCH_TODAY_MOST_PLAYED_COMMUNITIES_SUCCESS = 'FETCH_TODAY_MOST_PLAYED_COMMUNITIES_SUCCESS'
export const FETCH_TODAY_MOST_PLAYED_COMMUNITIES_FAILED = 'FETCH_TODAY_MOST_PLAYED_COMMUNITIES_FAILED'