import { Dialog, Transition } from '@headlessui/react'
import styles from './AddAdmin.module.css'
import React, { Fragment, useRef } from 'react'
import Input from '../../Input/Input'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAdmin } from '../../../../redux/actions/AdminAction'

const AddAdmin = ({openModal, setOpenModal, communitySlug, communityId}) => {
    const dispatch = useDispatch()
    const cancelButtonRef = useRef(null)

    const [walletAddress, setWalletAddress] = useState('')
    const {loading} = useSelector((state) => state.createAdminReducer)

    const create = () => {
        if(walletAddress)
            dispatch(createAdmin(walletAddress, communitySlug, communityId))
        setOpenModal(false)
    }

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0 overflow-y-auto'
                initialFocus={cancelButtonRef}
                onClose={setOpenModal}
            >
                <div className='h-screen px-4 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[#0c0c0d9a] bg-opacity-75 backdrop-blur-[3px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block bg-transparent rounded-lg text-left transform transition-all my-8 align-middle max-w-[540px] w-full'>
                            <div
                                className={`${styles.modal_background} flex justify-center w-full items-center rounded-lg text-white h-full p-10 flex-col gap-y-8 border border-[#3D3D3D]`}
                            >
                                <div className={styles.top_header}>
                                    <h1>Add Admin</h1>

                                    <button onClick={() => setOpenModal(false)}>
                                        <Close />
                                    </button>
                                </div>

                                <div className={styles.divider}></div>

                                <div className={styles.modal_main_cntnt}>
                                    <h1 className={styles.heading}>Enter the wallet address linked to an existing poker account.</h1>

                                    <Input label={'wallet address'} value={walletAddress} onChange={(e) => setWalletAddress(e)} />

                                    <button className={`${styles.create_btn} primary-custom-button`} onClick={create}>
                                        <button className='primary-custom-button-inner'>
                                            {loading ? 'Creating' : 'Create'}
                                        </button>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default React.memo(AddAdmin)

const Close = () =>{
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}