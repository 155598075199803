import styles from './MaxWinning.module.css'
import React from 'react'
import community_coin from '../../../Assets/Images/community_coin.svg'

const MaxWinning = ({disabled, communityInfo}) => {
    return <div className={styles.container} style={{minWidth: disabled ? '200px' : '', borderRadius: disabled ? '12px' : '', padding : disabled ? '4px 10px' : ''}}>
        <h2 className={styles.max_winning_head} style={{fontSize: disabled ? '16px' : ''}}>Max Winnings</h2>

        <span className={styles.border_line}></span>

        <div className={styles.winning_amt_wrapper}>
            <h1 className={styles.winning_amt} style={{fontSize: disabled ? '30px' : ''}}>{communityInfo?.maxWinning || '20K'}</h1>

            <div className={styles.coin_wrapper_outer}>
            {/* <div className={styles.coin_wrapper_outer} style={{background : communityInfo?.theme?.backgroundColor ? communityInfo?.theme?.backgroundColor : ''}}> */}
                <div className={styles.coin_wrapper}>
                    <img src={communityInfo?.chipImg || "https://res.cloudinary.com/kuramaverse/image/upload/v1692310719/ArcadeVerse%20Tables/Play%20Chip%202.svg"} alt="" style={{width: disabled ? '30px' : '36px', height: disabled ? '30px' : '36px'}}/>

                    <h1>{communityInfo?.chipName || "Play Chips"}</h1>
                </div>
            </div>
        </div>
    </div>
}

export default MaxWinning
