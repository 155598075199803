import React, { useEffect } from "react";
import "./CreateEventCard.css";

const CreateEventCard = ({ img, title, subtitle, id, color, onClick }) => {
  return (
    <div className="tablecard-btm-layer-custom group items-center justify-center flex relative" id={`tablecard-btm-layer-${id}`} onClick={onClick}>
      {/* <img src={create} alt='' /> */}
      <div className="table--create-svg-event-back transition-all group-hover:transition-all">
        {/* <CreateTableSvg /> */}
        <img src={"https://res.cloudinary.com/kuramaverse/image/upload/v1695765493/Frame_427320632_qxqoab.svg"} alt="" />
      </div>
    </div>
  );
};

export default CreateEventCard;
