import gsap from "gsap";
import { useEffect } from "react";
import styles from './LoaderBig.module.css'
import plus from '../../Assets/svgs/Plus.svg'
import block1 from '../../Assets/svgs/block1.svg'
import block2 from '../../Assets/svgs/block2.svg'
import block3 from '../../Assets/svgs/block3.svg'
import block4 from '../../Assets/svgs/block4.svg'
import block5 from '../../Assets/svgs/block5.svg'
import block6 from '../../Assets/svgs/block6.svg'
import block7 from '../../Assets/svgs/block7.svg'
import block8 from '../../Assets/svgs/block8.svg'
import block9 from '../../Assets/svgs/block9.svg'

const LoaderBig = ({loading}) => {
    useEffect(() => {
        const ctx = gsap.context(() => {
            gsap.timeline({repeat: -1})
            .to("#block1", { opacity: 0 }, 0)
            .to("#block2", { opacity: 0 }, 0)
            .to("#block3", { opacity: 0 }, 0)
            .to("#block4", { opacity: 0 }, 0)
            .to("#block5", { opacity: 0 }, 0)
            .to("#block6", { opacity: 0 }, 0)
            .to("#block7", { opacity: 0 }, 0)
            .to("#block8", { opacity: 0 }, 0)
            .to("#block9", { opacity: 0 }, 0)
            .to("#block1", { opacity: 1, right:'0px', top: '20px', duration: 0.5 }, 0)
            .to("#block2", { opacity: 1, left:'0px', top: '40px', duration: 0.5 }, 1)
            .to("#block3", { opacity: 1, left:'0px', top: '30px', duration: 0.5 }, 2)
            .to("#block4", { opacity: 1, left:'20px', top: '30px', duration: 0.5 }, 3)
            .to("#block5", { opacity: 1, left:'0px', top: '20px', duration: 0.5 }, 4)
            .to("#block7", { opacity: 1, left:'40px', top: '20px', duration: 0.5 }, 5)
            .to("#block6", { opacity: 1, left:'0px', top: '40px', duration: 0.5 }, 6)
            .to("#block1", { opacity: 0, right:'0px', top: '20px', duration: 0.5 }, 6)
            .to("#block2", { opacity: 0, left:'0px', top: '40px', duration: 0.5 }, 6)
            .to("#block3", { opacity: 0, left:'0px', top: '30px', duration: 0.5 }, 6)
            .to("#block4", { opacity: 0, left:'20px', top: '30px', duration: 0.5 }, 6)
            .to("#block5", { opacity: 0, left:'0px', top: '20px', duration: 0.5 }, 6)
            .to("#block7", { opacity: 0, left:'40px', top: '20px', duration: 0.5 }, 6)
            .to("#block8", { opacity: 1, left:'20px', top: '40px', duration: 0.5 }, 7)
            .to("#block9", { opacity: 1, left:'30px', top: '40px', duration: 0.5 }, 8)
            .to("#block6", { opacity: 0, left:'0px', top: '40px', duration: 0.5 }, 9)
            .to("#block8", { opacity: 0, left:'20px', top: '40px', duration: 0.5 }, 9)
            .to("#block9", { opacity: 0, left:'30px', top: '40px', duration: 0.5 }, 9)
        })

        return () => ctx.revert();
    }, [])

    useEffect(() => {
        const ctx = gsap.context(() => {
            gsap.timeline({repeat: -1})
            .to("#dots1", { opacity: 1 }, 0.5)
            .to("#dots2", { opacity: 1 }, 1)
            .to("#dots3", { opacity: 1 }, 1.5)
            .to("#dots3", { opacity: 1 }, 2)
        })

        return () => ctx.revert();
    }, [])

    return(
        <div className={styles.loader_bg}>
        <div className={styles.main_wrapper}>
            <div className={styles.centre_src}>
                <img src={'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1692477456/Group_1000002683_xx3deg.webp'} alt="" className={styles.outer_circle}/>
                <div className={styles.center_game}>
                    <img src={block1} id="block1" alt="" className={styles.block1} />
                    <img src={block2} id="block2" alt="" className={styles.block2} />
                    <img src={block3} id="block3" alt="" className={styles.block3} />
                    <img src={block4} id="block4" alt="" className={styles.block4} />
                    <img src={block5} id="block5" alt="" className={styles.block5} />
                    <img src={block6} id="block6" alt="" className={styles.block6} />
                    <img src={block7} id="block7" alt="" className={styles.block7} />
                    <img src={block8} alt="" id="block8" className={styles.block8} />
                    <img src={block9} alt="" id="block9" className={styles.block9} />
                </div>

                <img src={plus} alt="" className={`${styles.plus}`} />
                <img src={'https://res.cloudinary.com/kuramaverse/image/upload/v1692477941/Component_6_xgqeql.svg'} alt="" className={`${styles.dot1}`} />
                <img src={'https://res.cloudinary.com/kuramaverse/image/upload/v1692477941/Component_6_xgqeql.svg'} alt="" className={`${styles.dot2}`} />
            </div>

            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }} className={styles.text}>{loading ? 'Loading' : 'Loading'} <div id="dots1" className={styles.dots}></div> <div id="dots2" className={styles.dots}></div> <div id="dots3" className={styles.dots}></div></div>
        </div>
    </div>
    )
}

export default LoaderBig
