import React from 'react'
import styles from './SetupCoinDrawer.module.css'
import { ArrowBack } from '@mui/icons-material'
import InputDrawer from '../InputDrawer/InputDrawer'


const SetupCoinDrawer = ({ toggleDrawer, data, setData }) => {
    const submit = (e) => {
        toggleDrawer(false)
    }

    return (
        <div className={styles.drawerWrapper}>
            {/* TOP Heading */}
            <div className={styles.top}>
                <button
                    onClick={(e) => {
                        toggleDrawer(false)
                    }}
                    style={{color: '#D53100'}}
                >
                    <ArrowBack />
                </button>
                <h1>Setup Chips</h1>
            </div>

            <div className={styles.formFields}>
                <InputDrawer
                    label={'Collection Name'}
                    value={data.collectionName}
                    onChange={(e) => {
                        setData((prev) => ({ ...prev, collectionName: e }))
                    }}
                />
                <InputDrawer
                    label={'Describe your expectation in short'}
                    extra={'(260)'}
                    value={data.description}
                    onChange={(e) => {
                        setData((prev) => ({ ...prev, description: e }))
                    }}
                />
                <InputDrawer
                    label={'Website Link'}
                    value={data.websiteLink}
                    onChange={(e) => {
                        setData((prev) => ({ ...prev, websiteLink: e }))
                    }}
                />
                <InputDrawer
                    label={'Twitter Handle'}
                    value={data.twitterLink}
                    onChange={(e) => {
                        setData((prev) => ({ ...prev, twitterLink: e }))
                    }}
                />
                <InputDrawer
                    label={'Email'}
                    value={data.email}
                    onChange={(e) => {
                        setData((prev) => ({ ...prev, email: e }))
                    }}
                />
                <InputDrawer
                    label={'DISCORD HANDLE'}
                    value={data.discordLink}
                    onChange={(e) => {
                        setData((prev) => ({ ...prev, discordLink: e }))
                    }}
                />

                <button
                    className={styles.saveChanges}
                    // disabled={!data.username || loading || !data.profileImg}
                    onClick={(e) => submit(e)}
                >
                    <span>Send Query</span>
                </button>
            </div>
        </div>
    )
}

export default SetupCoinDrawer