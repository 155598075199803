import styles from './EditCommunity.module.css'
import React, { useEffect, useState } from 'react'
import Admins from './../../Components/EditCommunity/Admins/Admins';
import Tables from './../../Components/EditCommunity/Tables/Tables';
import PageProperties from '../../Components/EditCommunity/PageProperties/PageProperties';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCommunity } from '../../redux/actions/CommunityAction';
import { editCommunityTabAction } from '../../redux/actions/otherAction';
import LoaderBig from '../LoaderBig/LoaderBig';

const EditCommunity = ({data, setData, openModal, setOpenModal, tableData, setTableData}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams()
    const communitySlug = params?.communitySlug
    const isTableSaved = location.state

    // const {userInfo} = useSelector(state => state.userLoginReducer)
    const {data:community, loading, error} = useSelector((state) => state.getCommunityByIDReducer)
    const {tabId} = useSelector((state) => state.editCommunityTabReducer)
    const [active, setActive] = useState(0)

    useEffect(() => {
        if(Object.entries(community)?.length === 0)
            dispatch(getCommunity(communitySlug))
    },[dispatch, communitySlug])

    useEffect(() => {
        if(tabId){
            setActive(tabId)
            dispatch(editCommunityTabAction(null))
        }
    },[tabId, dispatch])

    useEffect(() => {
        if(community?.defaultTable && !isTableSaved)
            setTableData(community?.defaultTable)
    },[community, isTableSaved])


    useEffect(() => {
        return() => {
            const doc = document.getElementById('main_body_edit_community')
            doc?.scroll(0, 0);
        }
    },[active])

    if(loading)
        return <LoaderBig />
    if(error)
        return <pre>{error}</pre>


    return <div className={styles.page_container}>
        {/* Header */}
        <div className={styles.header}>
            <div className={styles.header_info}>
                <button onClick={() => navigate(`/${community?.slug}` || -1)}>
                    <ArrowLeft />
                </button>
                <h1>Edit Community Page</h1>
            </div>

            <div className={styles.header_links_holder}>
                <TopHeaderButton text={'Page Properties'} selected={active === 0} setActive={() => setActive(0)} />
                <TopHeaderButton text={'Tables'} selected={active === 1} setActive={() => setActive(1)} />
                <TopHeaderButton text={'Admins'} selected={active === 2} setActive={() => setActive(2)} />
            </div>
        </div>

        {/* Body */}
        <div className={styles.main_body} id='main_body_edit_community'>
            {active === 0 && <PageProperties />}
            {active === 1 && <Tables id={communitySlug} slug={community?.slug} communintyId={community?._id} tableData={tableData} setOpenModal={setOpenModal} setTableData={setTableData} data={data} setData={setData} openModal={openModal} />}
            {active === 2 && <Admins communityId={community?._id} communitySlug={community?.slug}/>}
        </div>
    </div>
}

export default EditCommunity

const TopHeaderButton = ({text, selected, setActive}) =>{
    return(
        <div className={`${styles.header_link_button} ${selected ? styles.active_state : styles.disabled_state}`}
            onClick={setActive}
        >
            {text}
        </div>
    )
}

const ArrowLeft = () => {
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 12H5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 19L5 12L12 5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}