import React, { useState } from "react";
import "./freePlayCard.css";
import coins from "../../../Assets/Images/coins.svg";
import game from "../../../Assets/Images/game.svg";
import PlayGameModal from "../../shared/modals/PlayGameModal/PlayGameModal";
import LoginModal from "../../shared/modals/LoginModal/LoginModal";
import CreateProfileModal from "../../shared/modals/CreateProfile/CreateProfile";
import { useSelector } from "react-redux";
import CreateGuestModal from "../../shared/modals/CreateGuestModal/CreateGuestModal";

const FreePlayCard = ({ socketObj, table, id }) => {
  const {isLogin, accessToken, verificationHash} = useSelector((state) => state.userLoginReducer)
  const socket = socketObj

  const [openModal, setOpenModal] = useState(false);
  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openTableModal, setOpenTableModal] = useState(false);

  const handlePlayGame = () => {
    if(isLogin)
      setOpenTableModal(true)
    else
      setOpenModal(true)
  }

  const joinTable = (chipValue, tableId) => {
    socket.emit("joinTable", {
      tableId: tableId || table._id,
      verificationHash,
      accessToken,
      socketId: socket.id,
      chipValue
    });
  }

  const joinSpectatorTable = () => {
    socket.emit("joinTable", {
      tableId: table._id,
      verificationHash,
      accessToken,
      socketId: socket.id,
      isSpectator: true
    });
  }


  return (
    <div className="fpcard-btm-layer">
      <div className="fpcard-middle-layer">
        <div className="fpcard-top-layer">
          {/* <img src={fpBg} alt="" className="fpcard-top-layer-illustration" /> */}
          <div className="fpcard-top-layer-content-flex">
            <img src={game} alt="" className="fpcard-top-layer-content-img" />
            <div className="fpcard-top-layer-content">
              <div className="fpcard-top-layer-content-top">
                <div className="fpcard-top-layer-content-top-title">
                  free play
                </div>
                <div className="fpcard-top-layer-content-top-subtitle">
                  By {table?.communityName}
                </div>
              </div>
              <div className="fpcard-top-layer-content-btm">
                <div className="fpcard-top-layer-content-btm-child">
                  <img
                    src={table?.chipImg || coins}
                    alt=""
                    className="fpcard-top-layer-sub-img"
                  />
                  <div className="fpcard-top-layer-content-btm-text">
                    {`${table?.minBid}/${table?.minBid*2}`}
                  </div>
                </div>
                <div className="fpcard-top-layer-content-btm-child">
                  <div className="fpcard-top-layer-sub-circle" />
                  <div className="fpcard-top-layer-content-btm-text">
                    {`${!table?.activePlayers || (table?.activePlayers.length < 0) ? '0 Online' : `${table?.activePlayers} Online`}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fpcard-btn" onClick={handlePlayGame}>
            PLAY
          </div>
        </div>
      </div>

      <LoginModal setOpenGuestModal={setOpenGuestModal} openModal={openModal} setOpenModal={() => {setOpenModal(false)}} setOpenProfileModal={setOpenCreateModal} backUrl={`/pokertable/${table?._id}`}/>
      {openGuestModal && <CreateGuestModal setOpenConnectModal={setOpenModal} backUrl={`/pokertable/${table?._id}`}  openModal={openGuestModal} setOpenModal={setOpenGuestModal} setOpenCreateModal={setOpenCreateModal} />}
      {openTableModal && <PlayGameModal key={id} openModal={openTableModal} setOpenModal={() => setOpenTableModal(false)} table={table} joinTable={joinTable} joinSpectatorTable={joinSpectatorTable} />}
      {openCreateModal && <CreateProfileModal openModal={openCreateModal} setOpenModal={setOpenCreateModal} />}
    </div>
  );
};

export default FreePlayCard;
