import React, { useEffect, useState } from 'react'
import styles from './EditTable.module.css'
import card from '../../Assets/Images/other/card_.png'
import logo from '../../Assets/Images/other/logo_.png'
import dealer from '../../Assets/Images/other/person_.png'
import cardArr from '../../Assets/Images/other/cards_arr.png'
import dealerArr from '../../Assets/Images/other/dealer_arr.png'
import tableArr from '../../Assets/Images/other/tablemat_arr.png'
import backdropArr from '../../Assets/Images/other/backdrop_arr.png'
import backgroundArr from '../../Assets/Images/other/back_arr.png'
import logoArr from '../../Assets/Images/other/logo_arr.png'
import carpetArr from '../../Assets/Images/other/carpet_arr.png'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCommunity, uploadToCloudinary1000 } from '../../redux/actions/CommunityAction'
import axios from 'axios'
import { BACKEND_URL } from '../../constants'
import { GET_COMMUNITY_BY_ID_SUCCESS } from '../../redux/constants/CommunityConstant'
import { editCommunityTabAction } from '../../redux/actions/otherAction'
import LoaderBig from '../LoaderBig/LoaderBig'
import DeleteAsset from '../../Components/shared/modals/DeleteAsset/DeleteAsset'
import { saveTableDataState } from '../../redux/actions/TableAction'

const EditTable = ({tableData, setTableData, setOpenModal, type}) => {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch= useDispatch()
    const communitySlug = params?.communitySlug.toLowerCase()

    const {data:communityInfo, assets, loading} = useSelector((state) => state.getCommunityByIDReducer)
    const {userInfo, verificationHash, accessToken} = useSelector((state) => state.userLoginReducer)

    const [loadingMe, setLoading] = useState(false)
    const [imageUploading, setImageUploading] = useState(false)
    const [saving, setSave] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selected, setSelected] = useState('')
    const [heading, setHeading] = useState('')
    const [index, setIndex] = useState(0)
    const [deleteNumber, setDeleteNumber] = useState('')
    const [data, setData] = useState([])
    const [lazyLoadingBackground, setlazyLoadingBackground] = useState({})
    const [lazyLoadingCardBG, setlazyLoadingCardBG] = useState({})
    const [lazyLoadingLogo, setlazyLoadingLogo] = useState({})
    const [lazyLoadingBackdrop, setlazyLoadingBackdrop] = useState({})
    const [lazyLoadingTableMaterial, setlazyLoadingTableMaterial] = useState({})
    const [lazyLoadingCarpet, setlazyLoadingCarpet] = useState({})
    const [lazyLoadingDealer, setlazyLoadingDealer] = useState({})
    const [thisAssets, setAssets] = useState({
        background: assets?.background || [],
        cardBG: assets?.cardBG ||[],
        logo: assets?.logo ||[],
        backdrop: assets?.backdrop ||[],
        dealer: assets?.dealer ||[],
        carpet: assets?.carpet ||[],
        tableMaterial: assets?.tableMaterial ||[]
    })

    useEffect(() => {
        setAssets({
            background: assets?.background || [],
            cardBG: assets?.cardBG ||[],
            logo: assets?.logo ||[],
            backdrop: assets?.backdrop ||[],
            dealer: assets?.dealer ||[],
            carpet: assets?.carpet ||[],
            tableMaterial: assets?.tableMaterial ||[]
        })
    },[assets])

    useEffect(() => {
        if (!tableData.background && communityInfo?.defaultTable?.background) {
            setTableData({
                background: communityInfo?.defaultTable?.background,
                card: communityInfo?.defaultTable?.card,
                logo: communityInfo?.defaultTable?.logo,
                backdrop: communityInfo?.defaultTable?.backdrop,
                dealer: communityInfo?.defaultTable?.dealer,
                carpet: communityInfo?.defaultTable?.carpet,
                material: communityInfo?.defaultTable?.material
            })
        }
    }, [communityInfo])

    const save = async() => {
        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + accessToken,
            },
        }
        setSave(true)
        dispatch(editCommunityTabAction(1))

        await axios.post(`${BACKEND_URL}/community/updateAsset`,{
            verificationHash,
            communityId: communityInfo?._id,
            data: thisAssets
        }, config).then((res) => {
            dispatch(saveTableDataState(tableData))
            // if(res.data?.assets)
            if(res.data.status === 200)
                dispatch({
                    type: GET_COMMUNITY_BY_ID_SUCCESS,
                    community: communityInfo,
                    assets: res?.data?.assets,
                })
            navigate(type === 'update' ? `/${communityInfo?.slug}/editCommunity` : `/${communityInfo?.slug}`, {state: true})
            setOpenModal(true)
            setSave(false)
        }).catch((err) => {
            navigate(type === 'update' ? `/${communityInfo?.slug}/editCommunity` : `/${communityInfo?.slug}`)
            setOpenModal(true)
            console.log(err)
            setSave(false)
        })
    }

    useEffect(() => {
        const container = document.getElementById('editTable__Page')

        const action = (e) => {
            console.log('click outside', e.target.id)
            if(e.target?.id === 'upload_asset_now'){}
            else if(e?.target?.id !== 'edit_page_button'){
                setSelected('')
            }
        }
        if(container) container.addEventListener('click', action)

        return () => {
            if(container) container.removeEventListener('click', action)
        }
    },[userInfo, loading])

    const editFun = (index) => {
        if(selected === 'background'){
            // setIndex(tableData?.background)
            // setHeading('Background')
            // setData(Backgrounds)
            setTableData((prev) => ({...prev, background: index}))
        }else if(selected === 'carpet'){
            // setIndex(tableData?.carpet)
            // setHeading('Carpet')
            // setData(Carpets)
            setTableData((prev) => ({...prev, carpet: index}))
        }else if(selected === 'tableMaterial'){
            // setIndex(tableData?.material)
            // setHeading('Material')
            // setData(Materials)
            setTableData((prev) => ({...prev, material: index}))
        }else if(selected === 'backdrop'){
            // setIndex(tableData?.backdrop)
            // setHeading('Backdrop')
            // setData(Backdrops)
            setTableData((prev) => ({...prev, backdrop: index}))
        }else if(selected === 'logo'){
            // setIndex(tableData?.logo)
            // setHeading('Logo')
            // setData(Logos)
            setTableData((prev) => ({...prev, logo: index}))
        }else if(selected === 'dealer'){
            // setIndex(tableData?.dealer)
            // setHeading('Dealer')
            // setData(Dealers)
            setTableData((prev) => ({...prev, dealer: index}))
        }else if(selected === 'cardBG'){
            // setIndex(tableData?.card)
            // setHeading('Card')
            // setData(Cards)
            setTableData((prev) => ({...prev, card: index}))
        }
    }

    const resetTable = () => {
        setTableData(communityInfo?.defaultTable)
    }

    useEffect(() => {
            if(selected === 'background'){
                setLoading(true)
                setIndex(tableData?.background)
                setHeading('Background')
                setData(thisAssets.background)
                setLoading(false)
                // setTableData((prev) => ({...prev, background: index}))
            }else if(selected === 'carpet'){
                setLoading(true)
                setIndex(tableData?.carpet)
                setHeading('Carpet')
                setData(thisAssets.carpet)
                setLoading(false)
                // setTableData((prev) => ({...prev, carpet: index}))
            }else if(selected === 'tableMaterial'){
                setLoading(true)
                setIndex(tableData?.material)
                setHeading('Material')
                setData(thisAssets.tableMaterial)
                setLoading(false)
                // setTableData((prev) => ({...prev, material: index}))
            }else if(selected === 'backdrop'){
                setLoading(true)
                setIndex(tableData?.backdrop)
                setHeading('Backdrop')
                setData(thisAssets.backdrop)
                setLoading(false)
                // setTableData((prev) => ({...prev, backdrop: index}))
            }else if(selected === 'logo'){
                setLoading(true)
                setIndex(tableData?.logo)
                setHeading('Logo')
                setData(thisAssets.logo)
                setLoading(false)
                // setTableData((prev) => ({...prev, logo: index}))
            }else if(selected === 'dealer'){
                setLoading(true)
                setIndex(tableData?.dealer)
                setHeading('Dealer')
                setData(thisAssets.dealer)
                setLoading(false)
                // setTableData((prev) => ({...prev, dealer: index}))
            }else if(selected === 'cardBG'){
                setLoading(true)
                setIndex(tableData?.card)
                setHeading('Card')
                setData(thisAssets.cardBG)
                setLoading(false)
                // setTableData((prev) => ({...prev, card: index}))
            }
        // }
    }, [selected, index, thisAssets])

    useEffect(() => {
        if(assets && Object.keys(assets).length === 0){
            dispatch(getCommunity(communitySlug))
        }
    },[])

    const handleNewAssetUpload = async(asset) => {
        if(selected === '' || !asset) return
        setImageUploading(true)
        const image = await uploadToCloudinary1000(asset)
        setAssets((prev) => {
            let data = {...prev}
            data[selected].push(image)
            return data
        })
        setIndex(image)
        setImageUploading(false)
    }

    const deleteAsset = () => {
        setAssets((prev) => {
            let data = {...prev}
            let arr = data[selected]
            const index = arr.indexOf(deleteNumber);
            arr.splice(index, 1);
            data[selected] = [...arr]
            return data
        })
        setIndex(0)
        setOpenDeleteModal(false)
    }

    if(loading)
        <LoaderBig />

    if(userInfo?.isAdmin && userInfo?.communityId?.length > 0 && userInfo?.communityId?.find((ele) => ele === communityInfo?._id)){
        return <div id = 'editTable__Page' style={{height: '100vh', background: '#000', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
            <div className={styles.backdrop_wrapper}>
                {!tableData?.backdrop ? <div style={{ width: '100%', position: 'absolute', height: '15vh' }} /> : typeof tableData?.backdrop === 'string'
                ? <img alt='' style={{ width: '100%', position: 'absolute', height: '15vh' }} src={tableData?.backdrop} />
                :<img alt='' style={{ width: '100%', position: 'absolute', height: '15vh' }} src={URL.createObjectURL(tableData?.backdrop)} />
                }
            </div>
            <div className={styles.background_screen}
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    background: typeof tableData?.background === 'string' ? `radial-gradient(
                        44% 58% at 50% 50%,
                        rgba(0, 0, 0, 0.2) 0%,
                        rgba(0, 0, 0, 0.15) 24.84%,
                        rgba(0, 0, 0, 0.98) 100%
                    ), url(${tableData?.background || '../../Assets/Images/other/background_.png'}) no-repeat top center / cover`
                    : `radial-gradient(
                        44% 58% at 50% 50%,
                        rgba(0, 0, 0, 0.2) 0%,
                        rgba(0, 0, 0, 0.15) 24.84%,
                        rgba(0, 0, 0, 0.98) 100%
                    ), url(${URL.createObjectURL(tableData?.background) || '../../Assets/Images/other/background_.png'}) no-repeat top center / cover`
                    ,
                }}
            >
            {/* Top Backdrop */}
            {/* <img src={typeof tableData?.backdrop === 'string' ? tableData?.backdrop : URL.createObjectURL(tableData?.backdrop) || backdrop} alt="" className={styles.backdrop}/> */}

            {/* Logo */}
            <div className={styles.logo_dealer_wrapper}>
                <img src={typeof tableData?.logo === 'string' ? tableData?.logo : URL.createObjectURL(tableData?.logo) || logo} alt="" className={styles.logo} />
                <div style={{ width: '180px', height: '175px' }}>
                    <img style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'top' }} src={typeof tableData?.dealer === 'string' ? tableData?.dealer : URL.createObjectURL(tableData?.dealer) || dealer} alt="" className={styles.dealer} />
                </div>
            </div>

            {/* Center Table */}
            <div className={styles.table_container}>
                <div className={styles.table}>
                    <img src={typeof tableData?.material === 'string' ? tableData?.material : URL.createObjectURL(tableData?.material)} alt="" className={styles.table}/>
                    <img src={typeof tableData?.carpet === 'string' ? tableData?.carpet : URL.createObjectURL(tableData?.carpet)} alt="" className={styles.table_carpet}/>

                    <div className={styles.card_wrapper}>
                        <img src={typeof tableData?.card === 'string' ? tableData?.card : URL.createObjectURL(tableData?.card) || card} alt="" className={styles.card} />
                        <img src={typeof tableData?.card === 'string' ? tableData?.card : URL.createObjectURL(tableData?.card) || card} alt="" className={styles.card} />
                        <img src={typeof tableData?.card === 'string' ? tableData?.card : URL.createObjectURL(tableData?.card) || card} alt="" className={styles.card} />
                        <img src={typeof tableData?.card === 'string' ? tableData?.card : URL.createObjectURL(tableData?.card) || card} alt="" className={styles.card} />
                        <img src={typeof tableData?.card === 'string' ? tableData?.card : URL.createObjectURL(tableData?.card) || card} alt="" className={styles.card} />
                    </div>
                </div>

                <div className={styles.dealer_btn} id='edit_page_button' style={{opacity : selected !== 'dealer' ? '0.4' : '' }} onClick={() => {if(selected === 'dealer')setSelected(''); else setSelected('dealer')}}>
                    <Button title={'dealer'} />
                    <img src={dealerArr} alt="" className={styles.left_arr} />
                </div>
                <div className={styles.logo_btn} id='edit_page_button' style={{opacity : selected !== 'logo' ? '0.4' : '' }} onClick={() => {if(selected === 'logo')setSelected(''); else setSelected('logo')}}>
                    <Button title={'logo'} />
                    <img src={logoArr} alt="" className={styles.logo_arr} />
                </div>


                <div className={styles.carpet_btn} id='edit_page_button' style={{opacity : selected !== 'carpet' ? '0.4' : '' }} onClick={() => {if(selected === 'carpet')setSelected(''); else setSelected('carpet')}}>
                    <Button title={'carpet'} />
                    <img src={carpetArr} alt="" className={styles.carpetArr} id='edit_page_button'/>
                </div>

                <div className={styles.cardBg_btn} id='edit_page_button' style={{opacity : selected !== 'cardBG' ? '0.4' : '' }} onClick={() => {if(selected === 'cardBG')setSelected(''); else setSelected('cardBG')}}>
                    <Button title={'Card bG'} />
                    <img src={cardArr} alt="" className={styles.cardArr} id='edit_page_button'/>
                </div>

                <div className={styles.tableMaterial_btn} id='edit_page_button' style={{opacity : selected !== 'tableMaterial' ? '0.4' : '' }} onClick={() => {if(selected === 'tableMaterial')setSelected(''); else setSelected('tableMaterial')}}>
                    <Button title={'table material'} />
                    <img src={tableArr} alt="" className={styles.tableArr} id='edit_page_button'/>
                </div>

                <div className={styles.background_btn} id='edit_page_button' style={{opacity : selected !== 'background' ? '0.4' : '' }} onClick={() => {if(selected === 'background')setSelected(''); else setSelected('background')}}>
                    <Button title={'background'} />
                    <img src={backgroundArr} alt="" className={styles.backgroundArr} id='edit_page_button'/>
                </div>
            </div>

            <div className={styles.backdrop_btn} id='edit_page_button' style={{opacity : selected !== 'backdrop' ? '0.4' : '' }} onClick={() => {if(selected === 'backdrop')setSelected(''); else setSelected('backdrop')}}>
                <Button title={'Backdrop'} id='edit_page_button' />
                <img src={backdropArr} alt="" className={styles.backdropArr} />
            </div>

            {selected !== '' ? <div className={styles.choose_outer_box} id='edit_page_button'>
                <h1 id='edit_page_button'>{heading}</h1>
                <div className={styles.divider} id='edit_page_button'></div>

                <div className={styles.images_slider} id='edit_page_button' >
                    {heading === 'Backdrop' && <div className={styles.none_box} onClick={() => {
                        editFun("")
                        setIndex("")
                    }} id='edit_page_button'>
                        NONE
                    </div>}
                    {loadingMe ?
                        Array(3).fill(0, 0).map((i, k) => (
                            <div id='edit_page_button' className={`animate-pulse ${styles.selected_image_wrapper_skelton}`} key={k}>
                            </div>
                        ))
                    : data?.map((card, key) =>
                        {
                            return (<div id='edit_page_button' className={index === (typeof card === 'string' ? card : URL?.createObjectURL(card)) ? styles.selected_image_wrapper : styles.image_wrapper} onClick={async() => {
                                editFun(card)
                                setIndex(card)
                            }
                                } key={key}>
                                {!(heading == 'Background' ? lazyLoadingBackground[`${key}`] : (heading == 'Dealer' ? lazyLoadingDealer[`${key}`] : (heading == 'Logo' ? lazyLoadingLogo[`${key}`] : (heading == 'Carpet') ? lazyLoadingCarpet[`${key}`] : (heading == 'Material') ? lazyLoadingTableMaterial[`${key}`] : (heading == 'Backdrop' ? lazyLoadingBackdrop[`${key}`] : (heading == 'Card' && lazyLoadingCardBG[`${key}`]))))) ? <div id='edit_page_button' className='animate-pulse' style={{
                                        background: 'linear-gradient(270deg, rgba(61, 61, 61, 0.05) 0%, #3D3D3D 0%)',
                                        minWidth: heading === 'Carpet' ? '200px' : heading === 'Card' ? '40px' : heading === 'Background' ? '135px' : heading === 'Material' ? '137px' : heading === 'Backdrop' ? '250px' : '70px',
                                }}>
                                <img onLoad={() => {
                                    if (heading == 'Background') {
                                        setlazyLoadingBackground((prev) => ({...prev, [`${key}`]: true}))
                                    } else if (heading == 'Backdrop') {
                                        setlazyLoadingBackdrop((prev) => ({...prev, [`${key}`]: true}))
                                    } else if (heading == 'Card') {
                                        setlazyLoadingCardBG((prev) => ({...prev, [`${key}`]: true}))
                                    } else if (heading == 'Logo') {
                                        setlazyLoadingLogo((prev) => ({...prev, [`${key}`]: true}))
                                    } else if (heading == 'Dealer') {
                                        setlazyLoadingDealer((prev) => ({...prev, [`${key}`]: true}))
                                    } else if (heading == 'Material') {
                                        setlazyLoadingTableMaterial((prev) => ({...prev, [`${key}`]: true}))
                                    } else if (heading == 'Carpet') {
                                        setlazyLoadingCarpet((prev) => ({...prev, [`${key}`]: true}))
                                    }
                                }} src={typeof card === 'string' ? card : URL?.createObjectURL(card)} alt=""
                                    style={{
                                        display: "none"
                                    }}
                                />
                                </div> : <img src={typeof card === 'string' ? card : URL?.createObjectURL(card)} alt="" id='edit_page_button'
                                    style={{
                                        // objectFit: 'cover',
                                        minWidth: heading === 'Carpet' ? '200px' : heading === 'Card' ? '40px' : heading === 'Background' ? '135px' : heading === 'Material' ? '137px' : heading === 'Backdrop' ? '250px' : '70px',
                                    }}
                                />}

                                <button className={styles.remove_asset} onClick={(e) => {
                                    e?.preventDefault()
                                    e?.stopPropagation()
                                    setOpenDeleteModal(() => true)
                                    setDeleteNumber(card)
                                }} id='edit_page_button'>
                                    <Dustbin id='edit_page_button'/>
                                </button>
                            </div>
                        )}
                    )}
                    <div id='edit_page_button' className={styles.image_wrapper}>
                        <label htmlFor='upload_asset_now' id='edit_page_button' className={styles.upload_image_wrapper}>
                            {!imageUploading && <Upload />}
                            <h1 id='edit_page_button'>{imageUploading ? 'Uploading...' : 'Upload Image'}</h1>
                        </label>
                        <input type="file" name="" id="upload_asset_now" hidden disabled={imageUploading} onChange={(e) => handleNewAssetUpload(e.target.files[0])} />
                    </div>
                </div>
            </div>
            :
            <div className={styles.button_holder}>
                <button className='secondary-custom-button' id='edit_page_button' onClick={resetTable}>
                    <div className={`secondary-custom-button-inner ${styles.full_preview}`} id='edit_page_button'>Reset Table</div>
                </button>

                <button className={`primary-custom-button`} onClick={save} disabled={saving} id='edit_page_button'>
                    <div className={`primary-custom-button-inner ${styles.save}`} id='edit_page_button'>{saving ? 'Saving...' : 'Save'}</div>
                </button>
            </div>
        }
        </div>
        <DeleteAsset openModal={openDeleteModal} setOpenModal={setOpenDeleteModal} deleteAsset={deleteAsset} id='edit_page_button' />
    </div>
    }else{
        return <pre>404, Error</pre>
    }
}

export default React.memo(EditTable)

const Button = ({title}) => {
    return(
        <div className={styles.bttn_outer} id='edit_page_button'>
            <div className={styles.bttn_inner} id='edit_page_button'>
                {title}

                <Forward id='edit_page_button'/>
            </div>

            <div className={styles.tl} id='edit_page_button'></div>
            <div className={styles.tr} id='edit_page_button'></div>
            <div className={styles.bl} id='edit_page_button'></div>
            <div className={styles.br} id='edit_page_button'></div>
        </div>
    )
}

const Forward = ()  =>{
    return(
        <svg id='edit_page_button' width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.92383 14.7611L8.59049 8.0944L1.92383 1.42773M11.2572 14.7611L17.9238 8.0944L11.2572 1.42773" stroke="white" strokeWidth="2" strokeLinecap="square"/>
    </svg>
    )
}

const Upload = () => {
    return(<svg id='edit_page_button' width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7402 12.5V13.5C17.7402 14.9001 17.7402 15.6002 17.4678 16.135C17.2281 16.6054 16.8456 16.9878 16.3752 17.2275C15.8404 17.5 15.1404 17.5 13.7402 17.5H6.74023C5.3401 17.5 4.64004 17.5 4.10526 17.2275C3.63485 16.9878 3.2524 16.6054 3.01272 16.135C2.74023 15.6002 2.74023 14.9001 2.74023 13.5V12.5M14.4069 6.66667L10.2402 2.5M10.2402 2.5L6.07357 6.66667M10.2402 2.5V12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}

const Dustbin = () => {
    return(<svg id='edit_page_button' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id='edit_page_button' d="M9 3.19922H15M3 6.19922H21M19 6.19922L18.2987 16.7185C18.1935 18.2968 18.1409 19.0859 17.8 19.6842C17.4999 20.211 17.0472 20.6345 16.5017 20.8989C15.882 21.1992 15.0911 21.1992 13.5093 21.1992H10.4907C8.90891 21.1992 8.11803 21.1992 7.49834 20.8989C6.95276 20.6345 6.50009 20.211 6.19998 19.6842C5.85911 19.0859 5.8065 18.2968 5.70129 16.7185L5 6.19922M10 10.6992V15.6992M14 10.6992V15.6992" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}