export const GET_ALL_ADMINS_REQUEST = 'GET_ALL_ADMINS_REQUEST';
export const GET_ALL_ADMINS_SUCCESS = 'GET_ALL_ADMINS_SUCCESS';
export const GET_ALL_ADMINS_FAIL = 'GET_ALL_ADMINS_FAIL';

export const DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST';
export const DELETE_ADMIN_SUCCESS = 'DELETE _ADMIN_SUCCESS';
export const DELETE_ADMIN_FAIL = 'DELETE_ADMIN_FAIL';

export const CREATE_ADMIN_REQUEST = 'CREATE_ADMIN_REQUEST';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAIL = 'CREATE_ADMIN_FAIL';