import React from "react";
import "./controls.css";
import FoldButton from "../Button/FoldButton";
import CheckButton from "../Button/CheckButton";
import BettingButton from "../Button/BettingButton";
import BetButton from "../Button/BetButton";

const Controls = ({
  minBid,
  Bet,
  SetBet,
  playerTurn,
  user,
  winnerAnnounced,
  socketId,
  walletAddress,
  fold,
  check,
  raisedBy,
  raise,
}) => {

  return (
    <div className="controls-bg-outer-layer">
      <div className="controls-bg-middle-layer">
        <div className="controls-bg-inner-layer">
          <div className="button-flex">
            <div className="button-flex-inner">
              <FoldButton
                text="Fold"
                fold={fold}
                playerTurn={playerTurn}
                winnerAnnounced={winnerAnnounced}
                socketId={socketId}
                walletAddress={walletAddress}
                hasFold={user?.status == "Fold"}
              />
              <CheckButton
                text="Check"
                check={check}
                user={user}
                raisedBy={raisedBy}
                playerTurn={playerTurn}
                winnerAnnounced={winnerAnnounced}
                socketId={socketId}
                walletAddress={walletAddress}
                hasFold={user?.status == "Fold"}
              />
            </div>
          </div>
          <div className="button-flex">
            <BettingButton
              bet={Bet}
              minBid={minBid}
              setBet={SetBet}
              playerTurn={playerTurn}
              winnerAnnounced={winnerAnnounced}
              socketId={socketId}
              user={user}
              walletAddress={walletAddress}
              turnAmount={user?.turnAmount}
              raisedBy={raisedBy}
              hasFold={user?.status == "Fold"}
            />
            <BetButton
              bet={Bet}
              turnAmount={user?.turnAmount}
              raise={raise}
              raisedBy={raisedBy}
              playerTurn={playerTurn}
              winnerAnnounced={winnerAnnounced}
              socketId={socketId}
              walletAddress={walletAddress}
              hasFold={user?.status == "Fold"}
              user={user}
            />
          </div>
          <div className="button-flex">
            <div className="button-flex-inner">
              <BetButton
                bet={Bet}
                turnAmount={user?.turnAmount}
                raise={raise}
                raisedBy={raisedBy}
                playerTurn={playerTurn}
                winnerAnnounced={winnerAnnounced}
                socketId={socketId}
                walletAddress={walletAddress}
                hasFold={user?.status == "Fold"}
                user={user}
              />
              <BettingButton
                bet={Bet}
                minBid={minBid}
                setBet={SetBet}
                playerTurn={playerTurn}
                winnerAnnounced={winnerAnnounced}
                socketId={socketId}
                user={user}
                walletAddress={walletAddress}
                turnAmount={user?.turnAmount}
                raisedBy={raisedBy}
                hasFold={user?.status == "Fold"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Controls;
