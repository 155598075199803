import React from "react";
import "./FreePlayCardSkelton.css";
const FreePlayCardSkelton = () => {
  return (
    <div className="fpcard-btm-layer-skelton animate-pulse">
      <div className="fpcard-middle-layer-skelton animate-pulse">
        <div className="fpcard-top-layer-skelton">
        </div>
      </div>
    </div>
  );
};

export default FreePlayCardSkelton;
