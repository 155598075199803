import React from 'react'
import styles from './CommunityBar.module.css'
import progress_ind from '../../../Assets/Images/progress_ind.png'

const CommunityBar = ({tabNumber, setTabNumber}) => {
    return <div className={styles.wrapper}>
        <Info selected={tabNumber === 1} completed={tabNumber > 1} head={'Community Info'} para={'Fill in the applicant information'} setTabNumber={setTabNumber} ind={1}/>
        <Info selected={tabNumber === 2} completed={tabNumber > 2} head={'Table Template'} para={'Fill in the applicant information'} setTabNumber={setTabNumber} ind={2}/>
        <Info selected={tabNumber === 3} completed={tabNumber > 3} head={'Setup Coins'} para={'Fill in the applicant information'} setTabNumber={setTabNumber} ind={3}/>
        <Info selected={tabNumber === 4} completed={tabNumber > 4} head={'Create Game'} para={'Fill in the applicant information'} setTabNumber={setTabNumber} ind={4}/>
    </div>
}

export default CommunityBar

const Info =  ({head, para, selected, completed, setTabNumber, ind}) => {
    // setTabNumber(ind)
    return <div className={styles.info_option} onClick={() => {}}>
        <h1>{head}</h1>
        <p>{para}</p>

        {completed ?<div className={styles.progress_ind}>
                <img src={progress_ind} alt="" />
            </div>
            : selected
            ? <div className={styles.default_circle}>
                <div className={styles.inner_circle}></div>
            </div>
            : <div className={styles.normal_circle}>
            </div>
        }
    </div>
}
