import { CREATE_COMMUNITY_FAILED, CREATE_COMMUNITY_REQUEST, CREATE_COMMUNITY_SUCCESS, EXCHANGE_FAILED, EXCHANGE_REQUEST, EXCHANGE_SUCCESS, GET_COINS_FROM_KURAMA_FAILED, GET_COINS_FROM_KURAMA_REQUEST, GET_COINS_FROM_KURAMA_SUCCESS, GET_COMMUNITY_BY_ID_FAILED, GET_COMMUNITY_BY_ID_REQUEST, GET_COMMUNITY_BY_ID_SUCCESS, GET_COMMUNITY_FAILED, GET_COMMUNITY_REQUEST, GET_COMMUNITY_SUCCESS, GET_ROUND_FAILED, GET_ROUND_REQUEST, GET_ROUND_SUCCESS } from "../constants/CommunityConstant"

const createCommunityState = {
    loading: false,
    data:{},
    error: false
}

export const createCommunityReducer = (state=createCommunityState, action) => {
    switch(action.type){
            case CREATE_COMMUNITY_REQUEST :
                return {
                    loading: true,
                    data: {},
                    error: false
                }
            case CREATE_COMMUNITY_SUCCESS:
                return {
                    loading: false,
                    data: action.payload,
                    error: false
                }
            case CREATE_COMMUNITY_FAILED:
                return {
                    loading: false,
                    data: {},
                    error: action.payload
                }

            default:
                return state
    }
}

const getCommunityState = {
    loading: false,
    data:[],
    newOnboarding:[],
    error: false
}

export const getCommunityReducer = (state=getCommunityState, action) => {
    switch(action.type){
            case GET_COMMUNITY_REQUEST :
                return {
                    loading: true,
                    data: [],
                    newOnboarding: [],
                    error: false
                }
            case GET_COMMUNITY_SUCCESS:
                return {
                    loading: false,
                    data: action.payload,
                    newOnboarding: action.newOnboarding,
                    error: false
                }
            case GET_COMMUNITY_FAILED:
                return {
                    loading: false,
                    data: [],
                    newOnboarding: [],
                    error: action.payload
                }

            default:
                return state
    }
}

const getCommunityByIDState = {
    loading: true,
    data:{},
    assets: {},
    error: null
}

export const getCommunityByIDReducer = (state=getCommunityByIDState, action) => {
    switch(action.type){
            case GET_COMMUNITY_BY_ID_REQUEST :
                return {
                    loading: true,
                    data: {},
                    assets: {},
                    error: false
                }
            case GET_COMMUNITY_BY_ID_SUCCESS:
                return {
                    loading: false,
                    data: action.community,
                    assets: action.assets,
                    error: false
                }
            case GET_COMMUNITY_BY_ID_FAILED:
                return {
                    loading: false,
                    data: {},
                    assets: {},
                    error: action.payload
                }

            default:
                return state
    }
}

const getUserCoinsForArcadeVerseState = {
    loading: true,
    allChips:[],
    allKuramaCoins:[],
    error: null
}

export const getUserCoinsForArcadeVerseReducer = (state=getUserCoinsForArcadeVerseState, action) => {
    switch(action.type){
            case GET_COINS_FROM_KURAMA_REQUEST :
                return {
                    loading: true,
                    allChips: action.allChips,
                    allKuramaCoins: [],
                    error: false
                }
            case GET_COINS_FROM_KURAMA_SUCCESS :
                return {
                    loading: false,
                    allChips: action.allChips,
                    allKuramaCoins: action.allKuramaCoins,
                    error: false
                }
            case GET_COINS_FROM_KURAMA_FAILED:
                return {
                    loading: false,
                    allChips: [],
                    allKuramaCoins: [],
                    error: action.payload
                }

            default:
                return state
    }
}

const getRoundState = {
    loading: false,
    round: {},
    error: null
}

export const getRoundReducer = (state=getRoundState, action) => {
    switch(action.type){
            case GET_ROUND_REQUEST :
                return {
                    loading: true,
                    round: {},
                    error: false
                }
            case GET_ROUND_SUCCESS :
                return {
                    loading: false,
                    round: action.payload || {},
                    error: false
                }
            case GET_ROUND_FAILED:
                return {
                    loading: false,
                    round: {},
                    error: action.payload
                }

            default:
                return state
    }
}

const exchangeCoinForChipsState = {
    loading: false,
    data: {},
    error: null
}

export const exchangeCoinForChipsReducer = (state=exchangeCoinForChipsState, action) => {
    switch(action.type){
            case EXCHANGE_REQUEST :
                return {
                    loading: true,
                    data: {},
                    error: false
                }
            case EXCHANGE_SUCCESS :
                return {
                    loading: false,
                    data: action.payload || {},
                    error: false
                }
            case EXCHANGE_FAILED:
                return {
                    loading: false,
                    data: {},
                    error: action.payload
                }

            default:
                return state
    }
}