import { Popover } from '@mui/material'
import styles from './SelectLeaderboardOption.module.css'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import search from '../../../../Assets/svgs/search.svg'
import { useDispatch } from 'react-redux'
import { REQUEST_GET_LEADERBOARD } from '../../../../redux/constants/GlobalConstant'

const SelectLeaderboardOption = ({ handleClose, anchorEl, data, setData, setPage, setActive}) => {
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    const { data:communityData} = useSelector((state) => state.getCommunityReducer)
    const [text, setText] = useState('')

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPopover-paper': {
                    background: 'transparent',
                },
            }}
        >
            <div className={`${styles.popover_wrapper}`}>
                <div className={styles.search_input}>
                    <input type="text" value={text} onChange={(e) => setText(e.target.value)} />

                    <img src={search} alt="" />
                </div>

                {text === '' ? communityData?.map((commuity, key) => (
                    <PopRow slug={commuity?.slug} key={key} data={data} setData={setData} setActive={setActive} setPage={setPage} handleClose={handleClose} />
                ))
                : communityData?.filter((x) => x?.slug?.toLowerCase()?.includes(text?.toLowerCase()))?.map((commuity, key) => (
                    <PopRow slug={commuity?.slug} key={key} data={data} setData={setData} setActive={setActive} setPage={setPage} handleClose={handleClose} />
                ))
                }
            </div>
        </Popover>
    )
}

export default SelectLeaderboardOption

const PopRow = ({slug, handleClose, setData, data, setPage, setActive}) =>{
    const dispatch = useDispatch()

    return(
        <div className={`${styles.button_holder} ${data === slug && styles.select_button}`}
            onClick={(e) => {
                if (slug !== data) {
                    setPage(0)
                    dispatch({
                        type: REQUEST_GET_LEADERBOARD,
                        leaderboardData: [],
                        userScore: '',
                        size: ''
                    })
                    localStorage.setItem('setPage', 0)
                    setActive(1)
                    setData(slug)
                }
                handleClose(e)
            }}
        >
            <div className='flex gap-[8px]'>
                <h2 className={styles.roundText}>{slug}</h2>
            </div>
        </div>
    )
}