import { CREATE_ADMIN_FAIL, CREATE_ADMIN_REQUEST, CREATE_ADMIN_SUCCESS, DELETE_ADMIN_FAIL, DELETE_ADMIN_REQUEST, DELETE_ADMIN_SUCCESS, GET_ALL_ADMINS_FAIL, GET_ALL_ADMINS_REQUEST, GET_ALL_ADMINS_SUCCESS } from "../constants/AdminConstant"

const getAllAdminState = {
    loading: true,
    admins:[],
    error: null
}

export const getAllAdminReducer = (state=getAllAdminState, action) => {
    switch(action.type){
            case GET_ALL_ADMINS_REQUEST :
                return {
                    loading: true,
                    admins: [],
                    error: false
                }
            case GET_ALL_ADMINS_SUCCESS:
                return {
                    loading: false,
                    admins: action.payload,
                    error: false
                }
            case GET_ALL_ADMINS_FAIL:
                return {
                    loading: false,
                    admins: [],
                    error: action.payload
                }

            default:
                return state
    }
}

const createAdminState = {
    loading: false,
    data:null,
    error: null
}

export const createAdminReducer = (state=createAdminState, action) => {
    switch(action.type){
            case CREATE_ADMIN_REQUEST :
                return {
                    loading: true,
                    data: null,
                    error: false
                }
            case CREATE_ADMIN_SUCCESS:
                return {
                    loading: false,
                    data: action.payload,
                    error: false
                }
            case CREATE_ADMIN_FAIL:
                return {
                    loading: false,
                    data: null,
                    error: action.payload
                }

            default:
                return state
    }
}

const deleteAdminState = {
    loading: false,
    data:null,
    error: null
}

export const deleteAdminReducer = (state=deleteAdminState, action) => {
    switch(action.type){
            case DELETE_ADMIN_REQUEST :
                return {
                    loading: true,
                    data: null,
                    error: false
                }
            case DELETE_ADMIN_SUCCESS:
                return {
                    loading: false,
                    data: action.payload,
                    error: false
                }
            case DELETE_ADMIN_FAIL:
                return {
                    loading: false,
                    data: null,
                    error: action.payload
                }

            default:
                return state
    }
}