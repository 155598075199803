import React from 'react'
import styles from './PrizeCard.module.css'

const PrizeCard = ({prize}) => {
    return (
        <div className={styles.outer_card}>
            <div className={styles.inner_card}>
                <figure className={styles.fig_wrap}>
                    <img src={prize?.prizeImg || 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1695829008/Frame_1814105410_pffmvu.webp'} alt="" className='w-full h-full object-cover'/>
                </figure>

                <h1 className={styles.title}>{prize?.prizeName}</h1>

                <div className={styles.more_info}>
                    <h2>Worth:</h2>
                    <h2>{prize?.prizeAmount ? '$' + prize?.prizeAmount : '--'}</h2>
                </div>
            </div>
        </div>
    )
}

export default PrizeCard