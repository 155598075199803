export const CREATE_COMMUNITY_REQUEST = 'CREATE_COMMUNITY_REQUEST'
export const CREATE_COMMUNITY_SUCCESS = 'CREATE_COMMUNITY_SUCCESS'
export const CREATE_COMMUNITY_FAILED = 'CREATE_COMMUNITY_FAILED'

export const GET_COMMUNITY_REQUEST = 'GET_COMMUNITY_REQUEST'
export const GET_COMMUNITY_SUCCESS = 'GET_COMMUNITY_SUCCESS'
export const GET_COMMUNITY_FAILED = 'GET_COMMUNITY_FAILED'

export const GET_COMMUNITY_BY_ID_REQUEST = 'GET_COMMUNITY_BY_ID_REQUEST'
export const GET_COMMUNITY_BY_ID_SUCCESS = 'GET_COMMUNITY_BY_ID_SUCCESS'
export const GET_COMMUNITY_BY_ID_FAILED = 'GET_COMMUNITY_BY_ID_FAILED'

export const GET_COINS_FROM_KURAMA_REQUEST = 'GET_COINS_FROM_KURAMA_REQUEST'
export const GET_COINS_FROM_KURAMA_SUCCESS = 'GET_COINS_FROM_KURAMA_SUCCESS'
export const GET_COINS_FROM_KURAMA_FAILED = 'GET_COINS_FROM_KURAMA_FAILED'

export const EXCHANGE_REQUEST = 'EXCHANGE_REQUEST'
export const EXCHANGE_SUCCESS = 'EXCHANGE_SUCCESS'
export const EXCHANGE_FAILED = 'EXCHANGE_FAILED'

export const GET_ROUND_REQUEST = 'GET_ROUND_REQUEST'
export const GET_ROUND_SUCCESS = 'GET_ROUND_SUCCESS'
export const GET_ROUND_FAILED = 'GET_ROUND_FAILED'