import React, { useRef, useEffect, useState } from "react";
import "./dashboard.css";
import MainCard from "../../Components/DashboardCards/MainCard/MainCard";
import TableCard from "../../Components/DashboardCards/TableCard/TableCard";
import FreePlayCard from "../../Components/DashboardCards/FreePlayCard/FreePlayCard";
import { useSelector, useDispatch } from 'react-redux';
import { getFreeTableAction, getPublicTablesAction } from '../../redux/actions/GlobalAction';
import TableCardSkelton from "../../Components/skeltons/TableCardSkelton/TableCardSkelton";
import Footer from "../../Components/Footer/Footer";
import FreePlayCardSkelton from "../../Components/DashboardCards/FreePlayCardSkelton/FreePlayCardSkelton";
import EventCard from "../../Components/DashboardCards/EventCard/EventCard";
import { getTournaments } from "../../redux/actions/TournamentAction";
import EventCardSkelton from "../../Components/DashboardCards/EventCardSkelton/EventCardSkelton";
import { Tooltip } from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import { getCurrentLevel } from "../../constants";

const Dashboard = ({socketObj}) => {
  const dispatch = useDispatch()
  const slider = useRef(null)
  const slider2 = useRef(null)

  const {loading, fetched, tables} = useSelector((state) => state.getPublicTablesReducer)
  const {fetched: freeFetched, tables: freeTables, loading: freeTableLoading} = useSelector((state) => state.getFreeTableReducer)
  const {fetched: tournamentFetched, tournaments, loading: tournamentsLoading} = useSelector((state) => state.getTournamentsReducer)
  const {isLogin, userInfo, leaderBoardRank} = useSelector((state) => state.userLoginReducer)

  const [activeTab, setActiveTab] = useState(1)
  const [copy, setCopy] = useState(false)

  useEffect(() => {
    if(!fetched){
      dispatch(getPublicTablesAction())
    }
    if(!freeFetched)
      dispatch(getFreeTableAction())
    if(!tournamentFetched)
      dispatch(getTournaments())
  }, [fetched, dispatch, freeFetched, tournamentFetched])

  const slideBack = () => {
    // const slider = document.getElementById('live_table_cards_holder')
    const clientWidth = slider.current.clientWidth
    const scrollByValue = parseInt(clientWidth/2)

    if(slider.current.scrollLeft === 0){
      return
    }else if(slider.current.scrollLeft - scrollByValue < 0){
      slider.current.scroll(-1 * slider.current.scrollLeft, 0)
      return
    }else{
      slider.current.scroll(slider.current.scrollLeft - scrollByValue, 0);
    }
  }

  const slideBack2 = () => {
    const clientWidth = slider2.current.clientWidth
    const scrollByValue = parseInt(clientWidth/2)

    if(slider2.current.scrollLeft === 0){
      return
    }else if(slider2.current.scrollLeft - scrollByValue < 0){
      slider2.current.scroll(-1 * slider2.current.scrollLeft, 0)
      return
    }else{
      slider2.current.scroll(slider2.current.scrollLeft - scrollByValue, 0);
    }
  }

  const slideForward2 = () => {
    const scrollWidth = slider2.current.scrollWidth
    const clientWidth = slider2.current.clientWidth
    const scrollByValue = parseInt(clientWidth/2)
    if(slider2.current.scrollLeft === clientWidth){
      return
    }else if(slider2.current.scrollLeft + scrollByValue > scrollWidth) {
      slider2.current.scroll(-1 * (scrollWidth - slider2.current.scrollLeft), 0)
      return
    }else{
      slider2.current.scroll(slider2.current.scrollLeft + scrollByValue, 0);
    }
  }

  const slideForward = () => {
    const scrollWidth = slider.current.scrollWidth
    const clientWidth = slider.current.clientWidth
    const scrollByValue = parseInt(clientWidth/2)
    if(slider.current.scrollLeft === clientWidth){
      return
    }else if(slider.current.scrollLeft + scrollByValue > scrollWidth) {
      slider.current.scroll(-1 * (scrollWidth - slider.current.scrollLeft), 0)
      return
    }else{
      slider.current.scroll(slider.current.scrollLeft + scrollByValue, 0);
    }
  }

  return (
    <>
    <div className="dashboard" id="scroll">

    <div className="dashboard-top-mobile-user-details">
    {isLogin
        ? <div className={"loginned-user-discription-wrapper"}>
            <div className={"loginned-user-discription-info-flex"}>
              <div className={"loginned-user-discription-info"}>
                  <figure className={"loginned-user-discription-fig-wrpr"}>
                      <img src={userInfo?.image} alt="" />
                  </figure>
                  <div className={"vertical_align_name"}>
                      <h1 className={"user-username"}>{userInfo?.username}</h1>
                      <div className='flex items-center gap-1'>
                          <h1 className={"user-wallet-address"}>{userInfo?.walletAddress?.substring(0, 3) + '.....' + userInfo?.walletAddress?.substring(userInfo?.walletAddress?.length - 3, userInfo?.walletAddress?.length)}</h1>
                          {userInfo?.walletConnected && <Tooltip title={copy ? 'Copied' : 'Copy'}
                              onClick={() => {
                                  navigator.clipboard.writeText(userInfo?.walletAddress)
                                  setCopy(true)
                                  setTimeout(() => {
                                      setCopy(false)
                                  }, 1500)
                              }}
                          >
                              <button>
                                  <CopyAll sx={{color: '#FFF'}} />
                              </button>
                          </Tooltip>}
                      </div>
                  </div>
              </div>

              <div className={"side_wrapper"}>
                  <div className='h-[28px]'>
                      <Crown />
                  </div>
                  <h2 className={"bottom_desc"}>Level {getCurrentLevel(userInfo?.overallXP || 0)}</h2>
              </div>
            </div>
        </div>
        : <div className={`not-loginned-user-discription-wrapper`}>
            <TaskButton heading={'2048'} subHeading={'Connected Players'} />
            <TaskButton heading={'7'} subHeading={'Communities'} />
        </div>}

        <div className={"mobile-button-holders"}>
          <button className={`mobile-button-holders-tab ${activeTab === 1 ? 'mobile-button-holders-tab-active': ''}`} onClick={() => setActiveTab(1)}>Play poker</button>
          <button className={`mobile-button-holders-tab ${activeTab === 2 ? 'mobile-button-holders-tab-active': ''}`} onClick={() => setActiveTab(2)}>Community</button>
        </div>
    </div>


      <div className="body-holder-dashboard-main">
        {(activeTab === 1 || window.innerWidth > 650) && <MainCard socketObj={socketObj} />}

        {(activeTab === 2 || window.innerWidth > 650) && (tournaments?.length > 0 || tournamentsLoading) && <div className="dashboard-item-flex">
          <div style={{ display: 'flex', alignItems: 'center', height: '50px', marginBottom: '10px' }} className="dashboard-item-title">UPCOMING
          {window.innerWidth <= 650 && <span>Events</span>}
          <svg xmlns="http://www.w3.org/2000/svg"
            style={{
              marginBottom: '20px',
              marginLeft: '20px',
            }}>
              <defs><linearGradient id="gradient" y1="0" y2="1"><stop stopColor="#FF0000" offset="0"></stop><stop stopColor="#ff000033" offset="1"></stop></linearGradient></defs><g><text id="text" y="100" fontFamily="intro" fontSize="120" fontWeight="400" strokeWidth="1" stroke="url(#gradient)" fill="rgba(127, 0, 0, 0.70)" style={{
                color: '#FFF',
                fontFamily: 'Clash Display',
                fontSize: '48px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
                letterSpacing: '4.4px',
              }}>EVENTS</text></g></svg>
          </div>

          <div className="dashboard-horizontal-flex pt-[60px] relative" style={{padding: '10px 0'}} id="live_table_cards_holder"  ref={slider2}>
            {tournamentsLoading
            ? Array(3).fill(0,0).map((table, key) => <EventCardSkelton key={key} /> )
            : tournaments?.map((tournament, key) => (
                <EventCard
                  key={key}
                  live={tournament?.time <= Date.now()}
                  tournament={tournament}
                />
            ))}
          </div>
          {tournaments?.length > 3 && <button className="dashboard-slider-btn-left" onClick={slideBack2}>
            <BackBtn />
          </button>}
          {tournaments?.length >3 && <button className="dashboard-slider-btn-right" onClick={slideForward2}>
            <ForwardBtn />
          </button>}
        </div>}

        {(activeTab === 2 || window.innerWidth > 650) && <div className="dashboard-item-flex">
          <div style={{ display: 'flex', alignItems: 'center', height: '50px', marginBottom: '10px' }} className="dashboard-item-title">LIVE
          {window.innerWidth <= 650 && <span>Tables</span>}
          <svg xmlns="http://www.w3.org/2000/svg" style={{
            marginBottom: '20px',
            marginLeft: '20px',
          }}>
            <defs>
              <linearGradient id="gradient" y1="0" y2="1"><stop stopColor="#FF0000" offset="0"></stop><stop stopColor="#ff000033" offset="1"></stop>
              </linearGradient>
            </defs>
            <g>
              <text id="text" y="100" fontFamily="intro" fontSize="120" fontWeight="400" strokeWidth="1" stroke="url(#gradient)" fill="rgba(127, 0, 0, 0.70)" style={{
                color: '#FFF',
                fontFamily: 'Clash Display',
                fontSize: '48px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
                letterSpacing: '4.4px',
              }}>TABLES
              </text>
            </g>
          </svg>
          </div>
          <div className="dashboard-horizontal-flex pt-[60px]" id="live_table_cards_holder" ref={slider}>
          {loading
            ? Array(4).fill(0,0).map((table, key) => <TableCardSkelton key={key} /> )
            :tables?.map((table, key) => (
              <TableCard
                color="#3C4B5A"
                key={key}
                id={key+1}
                img={table?.cardPreview}
                socketObj={socketObj}
                table={table}
              />
            ))}
          </div>
          {tables?.length > 3 && <button className="dashboard-slider-btn-left" onClick={slideBack}>
            <BackBtn />
          </button>}
          {tables?.length > 3 && <button className="dashboard-slider-btn-right" onClick={slideForward}>
            <ForwardBtn />
          </button>}
        </div>}

        {(activeTab === 2 || window.innerWidth > 650) && <div style={{ marginBottom: '30px' }} className="dashboard-item-flex">
          <div style={{ display: 'flex', alignItems: 'center', height: '50px', marginBottom: '10px' }} className="dashboard-item-title">FREE
          {window.innerWidth <= 650 && <span>Play</span>}
          <svg xmlns="http://www.w3.org/2000/svg" style={{
            marginBottom: '20px',
            marginLeft: '20px',
                    }}><defs><linearGradient id="gradient" y1="0" y2="1"><stop stopColor="#FF0000" offset="0"></stop><stop stopColor="#ff000033" offset="1"></stop></linearGradient></defs><g><text id="text" y="100" fontFamily="intro" fontSize="120" fontWeight="400" strokeWidth="1" stroke="url(#gradient)" fill="rgba(127, 0, 0, 0.70)" style={{
            color: '#FFF',
            fontFamily: 'Clash Display',
            fontSize: '48px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            letterSpacing: '4.4px',
                }}>PLAY</text></g></svg>
          </div>
          <div className="dashboard-horizontal-flex-free-play" style={{padding: '10px 0'}}>
            {freeTableLoading ?
              Array(3).fill(0, 0).map((i, k) => (
                  <FreePlayCardSkelton key={k} />
                ))
              : freeTables?.map((table, key) => (
                <FreePlayCard socketObj={socketObj} key={key} table={table} id={key+1} />
              ))
            }
          </div>
        </div>}
      </div>
    </div>
    <Footer />
    </>
  );
};

export default Dashboard;

const BackBtn = () => {
  return(<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.7602 25.0933C13.0135 25.0933 13.2668 25 13.4668 24.8C13.8535 24.4133 13.8535 23.7733 13.4668 23.3867L6.08017 16L13.4668 8.61334C13.8535 8.22667 13.8535 7.58667 13.4668 7.2C13.0802 6.81334 12.4402 6.81334 12.0535 7.2L3.96017 15.2933C3.5735 15.68 3.5735 16.32 3.96017 16.7067L12.0535 24.8C12.2535 25 12.5068 25.0933 12.7602 25.0933Z" fill="#292D32"/>
  <path d="M4.89301 17H27.333C27.8797 17 28.333 16.5467 28.333 16C28.333 15.4533 27.8797 15 27.333 15H4.89301C4.34634 15 3.89301 15.4533 3.89301 16C3.89301 16.5467 4.34634 17 4.89301 17Z" fill="#292D32"/>
  </svg>
  )
}

const ForwardBtn = () => {
  return(<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.2398 25.0933C18.9865 25.0933 18.7332 25 18.5332 24.8C18.1465 24.4133 18.1465 23.7733 18.5332 23.3867L25.9198 16L18.5332 8.61334C18.1465 8.22667 18.1465 7.58667 18.5332 7.2C18.9198 6.81334 19.5598 6.81334 19.9465 7.2L28.0398 15.2933C28.4265 15.68 28.4265 16.32 28.0398 16.7067L19.9465 24.8C19.7465 25 19.4932 25.0933 19.2398 25.0933Z" fill="#292D32"/>
  <path d="M27.107 17H4.66699C4.12033 17 3.66699 16.5467 3.66699 16C3.66699 15.4533 4.12033 15 4.66699 15H27.107C27.6537 15 28.107 15.4533 28.107 16C28.107 16.5467 27.6537 17 27.107 17Z" fill="#292D32"/>
  </svg>
  )
}

const Crown = () => {
  return (<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.0244 13.2806L5.25012 5.90643L2.35522 13.2806H11.0244Z" fill="#F4900C"/>
  <path d="M13.9517 4.30426L8.84717 13.9402H19.0555L13.9517 4.30426Z" fill="#F4900C"/>
  <path d="M16.9758 13.2806L22.7501 5.90643L25.645 13.2806H16.9758Z" fill="#F4900C"/>
  <path d="M22.2982 11.8145L18.5789 2.7401L14.0055 11.8152L14.0001 11.8176L13.9946 11.8145L9.4213 2.73932L5.70195 11.8145C3.26439 8.42567 0.995605 6.25489 0.995605 6.25489C0.995605 6.25489 2.33339 16.1389 2.33339 25.6667H25.6668C25.6668 16.1389 27.0046 6.25489 27.0046 6.25489C27.0046 6.25489 24.7358 8.42567 22.2982 11.8145Z" fill="#FFCC4D"/>
  <path d="M13.9666 19.9796C15.5508 19.9796 16.8351 18.6954 16.8351 17.1112C16.8351 15.527 15.5508 14.2427 13.9666 14.2427C12.3824 14.2427 11.0981 15.527 11.0981 17.1112C11.0981 18.6954 12.3824 19.9796 13.9666 19.9796Z" fill="#5C913B"/>
  <path d="M20.5823 18.9871C21.6184 18.9871 22.4583 18.1472 22.4583 17.1111C22.4583 16.075 21.6184 15.2351 20.5823 15.2351C19.5462 15.2351 18.7063 16.075 18.7063 17.1111C18.7063 18.1472 19.5462 18.9871 20.5823 18.9871Z" fill="#981CEB"/>
  <path d="M25.5518 18.6559C26.4049 18.6559 27.0964 17.9643 27.0964 17.1112C27.0964 16.2581 26.4049 15.5665 25.5518 15.5665C24.6987 15.5665 24.0071 16.2581 24.0071 17.1112C24.0071 17.9643 24.6987 18.6559 25.5518 18.6559Z" fill="#DD2E44"/>
  <path d="M7.34988 18.9871C8.38597 18.9871 9.22589 18.1472 9.22589 17.1111C9.22589 16.075 8.38597 15.2351 7.34988 15.2351C6.31379 15.2351 5.47388 16.075 5.47388 17.1111C5.47388 18.1472 6.31379 18.9871 7.34988 18.9871Z" fill="#981CEB"/>
  <path d="M2.38085 18.6559C3.23395 18.6559 3.92553 17.9643 3.92553 17.1112C3.92553 16.2581 3.23395 15.5665 2.38085 15.5665C1.52775 15.5665 0.836182 16.2581 0.836182 17.1112C0.836182 17.9643 1.52775 18.6559 2.38085 18.6559Z" fill="#DD2E44"/>
  <path d="M25.6669 26.4445H2.33344C2.12716 26.4445 1.92933 26.3626 1.78347 26.2167C1.63761 26.0708 1.55566 25.873 1.55566 25.6667C1.55566 25.4605 1.63761 25.2626 1.78347 25.1168C1.92933 24.9709 2.12716 24.889 2.33344 24.889H25.6669C25.8731 24.889 26.071 24.9709 26.2168 25.1168C26.3627 25.2626 26.4446 25.4605 26.4446 25.6667C26.4446 25.873 26.3627 26.0708 26.2168 26.2167C26.071 26.3626 25.8731 26.4445 25.6669 26.4445ZM25.6669 23.7332H2.33344C2.12716 23.7332 1.92933 23.6512 1.78347 23.5054C1.63761 23.3595 1.55566 23.1617 1.55566 22.9554C1.55566 22.7491 1.63761 22.5513 1.78347 22.4054C1.92933 22.2596 2.12716 22.1776 2.33344 22.1776H25.6669C25.8731 22.1776 26.071 22.2596 26.2168 22.4054C26.3627 22.5513 26.4446 22.7491 26.4446 22.9554C26.4446 23.1617 26.3627 23.3595 26.2168 23.5054C26.071 23.6512 25.8731 23.7332 25.6669 23.7332Z" fill="#FFAC33"/>
  <path d="M1.12534 7.3492C1.72973 7.3492 2.21968 6.85924 2.21968 6.25486C2.21968 5.65047 1.72973 5.16052 1.12534 5.16052C0.520957 5.16052 0.0310059 5.65047 0.0310059 6.25486C0.0310059 6.85924 0.520957 7.3492 1.12534 7.3492Z" fill="#FFCC4D"/>
  <path d="M5.25011 6.83354C5.76214 6.83354 6.17723 6.41845 6.17723 5.90642C6.17723 5.39439 5.76214 4.97931 5.25011 4.97931C4.73808 4.97931 4.323 5.39439 4.323 5.90642C4.323 6.41845 4.73808 6.83354 5.25011 6.83354Z" fill="#F4900C"/>
  <path d="M9.42125 4.12766C10.1876 4.12766 10.8088 3.50643 10.8088 2.7401C10.8088 1.97377 10.1876 1.35254 9.42125 1.35254C8.65492 1.35254 8.03369 1.97377 8.03369 2.7401C8.03369 3.50643 8.65492 4.12766 9.42125 4.12766Z" fill="#FFCC4D"/>
  <path d="M26.8746 7.3492C27.479 7.3492 27.9689 6.85924 27.9689 6.25486C27.9689 5.65047 27.479 5.16052 26.8746 5.16052C26.2702 5.16052 25.7803 5.65047 25.7803 6.25486C25.7803 6.85924 26.2702 7.3492 26.8746 7.3492Z" fill="#FFCC4D"/>
  <path d="M22.7501 6.83354C23.2621 6.83354 23.6772 6.41845 23.6772 5.90642C23.6772 5.39439 23.2621 4.97931 22.7501 4.97931C22.2381 4.97931 21.823 5.39439 21.823 5.90642C21.823 6.41845 22.2381 6.83354 22.7501 6.83354Z" fill="#F4900C"/>
  <path d="M18.5787 4.12766C19.345 4.12766 19.9663 3.50643 19.9663 2.7401C19.9663 1.97377 19.345 1.35254 18.5787 1.35254C17.8124 1.35254 17.1912 1.97377 17.1912 2.7401C17.1912 3.50643 17.8124 4.12766 18.5787 4.12766Z" fill="#FFCC4D"/>
  <path d="M13.9518 5.69181C14.7181 5.69181 15.3393 5.07058 15.3393 4.30425C15.3393 3.53792 14.7181 2.91669 13.9518 2.91669C13.1854 2.91669 12.5642 3.53792 12.5642 4.30425C12.5642 5.07058 13.1854 5.69181 13.9518 5.69181Z" fill="#F4900C"/>
  </svg>
  )
}

const TaskButton = ({heading, subHeading}) => {
  return(
      <button className={"task_btn_holder"}>
          <h1>{heading}</h1>
          <h2>{subHeading}</h2>
      </button>
  )
}