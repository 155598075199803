import "./CreateTableCard.css";
import CreateAboveCircleSvg from "../../../Assets/svgs/CreateAboveCircleSvg";

const CreateTableCard = ({ title, playersOnline, smallBlind, color, id, img, onClick }) => {
  return (
    <div className="tablecard-btm-layer-custom group items-center justify-center flex relative" id={`tablecard-btm-layer-${id}`} onClick={onClick}>
      <div className="table--create-svg-back-add absolute -top-[54px] z-[2]">
        <CreateAboveCircleSvg />
      </div>
      {/* <img src={create} alt='' /> */}
      <div className="table--create-svg-back  transition-all group-hover:transition-all">
        {/* <CreateTableSvg /> */}
        <img src={"https://res.cloudinary.com/kuramaverse/image/upload/v1695760558/Group_1000002716_g4hipr.svg"} alt="" />
      </div>
    </div>
  );
};

export default CreateTableCard;

