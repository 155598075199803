import styles from './History.module.css'
import React, { useEffect, useState } from 'react'
import dummy from '../../../Assets/svgs/dummy.svg'
import axios from 'axios'
import { BACKEND_URL } from '../../../constants'
import { useSelector } from 'react-redux'

const History = () => {
    const {userInfo, accessToken} = useSelector((state) => state.userLoginReducer)
    const [history, setHistory] = useState([])
    const [fetched, setFetched] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + accessToken,
            },
        }
        const getHistory = async () => {
            setLoading(true)
            await axios.post(`${BACKEND_URL}/user/getUserHistory`,{
                walletAddress: userInfo?.walletAddress
            }, config)
            .then((res) => {
                setHistory(res.data.history)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
        }

        if(!fetched){
            getHistory()
            setFetched(true)
        }
    },[accessToken, userInfo?.walletAddress, fetched])

    return <div className={styles.container} style={{}}>
    <div className={styles.row_align}>
        <h2 className={styles.max_winning_head} style={{}}>History</h2>

        {/* <div className={styles.week_tab}>
            <button onClick={() => setActive(1)} className={active === 1 ? styles.active_tab : styles.normal_tab} style={{padding: disabled ? '0px' : '', width: disabled ? '50px' : '', fontSize: disabled ? '10px' : '', lineHeight: disabled ? 'unset' : ''}}>Weekly</button>
            <button onClick={() => setActive(2)} className={active === 2 ? styles.active_tab : styles.normal_tab} style={{padding: disabled ? '0px' : '', width: disabled ? '50px' : '', fontSize: disabled ? '10px' : '', lineHeight: disabled ? 'unset' : ''}}>Daily</button>
        </div> */}
    </div>

    <span className={styles.border_line}></span>

    <div className={styles.bottom_container} style={{height: '100%'}}>
        <div className={styles.user_title_field}>
            <TitleField />
        </div>
        <div className={styles.user_value_field} style={{
            height: '320px',
            overflow:'scroll',
        }}>
            <div className={styles.user_value_field}>
                {history?.map((value, key) => {
                    return <ValueField value={value} rank={key+1} key={key} />
                })}
                {loading &&
                    Array(2).fill(0, 0).map((i, k) => <div key={k} className={`animate-pulse ${styles.field_load}`} />)
                }
                {/* <ValueField /> */}
            </div>
        </div>
    </div>
</div>
}

export default History

const TitleField = () => {
    return(
        <div className={styles.title_wrapper_field}>
            <h2 className={styles.top_f_t}>Table</h2>
            <h2 className={styles.top_f_t}>Community</h2>
            <h2 className={styles.top_f_t}>Rounds won</h2>
        </div>
    )
}

const ValueField = ({value}) => {
    return(
        <div className={styles.wrapper_field}>
            <div className={styles.brief_info}>
                {/* <div className={styles.sr_no}></div> */}

                <div className={styles.per_user_brief}>
                    <div className={styles.image_wrapper}>
                        <img src={dummy} alt="" className='w-full h-full object-cover' />
                    </div>
                    <h2 className={styles.heading}>{value?.tableName || 'Table'}</h2>
                </div>
            </div>

            <div className={styles.won_wrapp}>
                <h2>{value?.totalChipsWon}</h2>
            </div>

            <div className={styles.won_wrapp}>
                <h2>{value?.roundsWon} out of {value?.roundsPlayed}</h2>
            </div>
        </div>
    )
}