import { useSelector, useDispatch } from 'react-redux'
import styles from './DailyTasks.module.css'
import React, { useState } from 'react'
import LoginModal from '../../shared/modals/LoginModal/LoginModal'
import { claimDailyTaskXP } from '../../../redux/actions/userAction'
import { useCountdown } from '../../../redux/actions/otherAction'
import { getCurrentLevel } from '../../../constants'
import { Tooltip } from '@mui/material'
import { CopyAll } from '@mui/icons-material'
import CreateGuestModal from '../../shared/modals/CreateGuestModal/CreateGuestModal'
// import coin from '../../../Assets/Images/Coin.svg'

const DailyTasks = () => {
    const dispatch = useDispatch()
    const {isLogin, userInfo, leaderBoardRank} = useSelector((state) => state.userLoginReducer)
    const { tasks } = useSelector((state) => state.dailyTasksReducer)
    const {hours, minutes, seconds} = useCountdown(tasks?.taskTimer)

    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [openGuestModal, setOpenGuestModal] = useState(false);
    const [claiming, setClaiming] = useState(false);
    const [copy, setCopy] = useState(false)

    const connectBtn = () =>{
        setOpenLoginModal(true)
    }

    return <div className={styles.wrapper}>
        {isLogin
        ? <div className={styles.wrapper_loginned}>
            <div className={styles.my_info}>
            <div className={styles.my_data}>
                <figure className={styles.my_image}>
                    <img src={userInfo?.image} alt="" />
                </figure>
                <div className={styles.vertical_align_name}>
                    <h1 className={styles.username}>{userInfo?.username}</h1>
                    <div className='flex items-center gap-1'>
                        <h1 className={styles.wallet_address}>{userInfo?.walletAddress?.substring(0, 8) + '...' + userInfo?.walletAddress?.substring(userInfo?.walletAddress?.length - 6, userInfo?.walletAddress?.length)}</h1>
                        {userInfo?.walletConnected && <Tooltip title={copy ? 'Copied' : 'Copy'}
                            onClick={() => {
                                navigator.clipboard.writeText(userInfo?.walletAddress)
                                setCopy(true)
                                setTimeout(() => {
                                    setCopy(false)
                                }, 1500)
                            }}
                        >
                            <button>
                                <CopyAll sx={{color: '#FFF'}} />
                            </button>
                        </Tooltip>}
                    </div>
                </div>
            </div>

            <div className={styles.side_wrapper}>
                {/* <div className={styles.vertical_divider}></div> */}
                <div className={`items-center ${styles.vertical_align}`}>
                    <div className='h-[28px]'>
                        <Crown />
                    </div>
                    <h2 className={styles.bottom_desc}>Level {getCurrentLevel(userInfo?.overallXP || 0)}</h2>
                </div>
                {/* <div className={styles.vertical_divider}></div> */}
                <div className={`items-center ${styles.vertical_align}`}>
                    <h1 className={styles.rank_no}>{userInfo?.overallXP||0}</h1>
                    <h2 className={styles.bottom_desc}>XP</h2>
                </div>
                <div className={`items-center ${styles.vertical_align}`}>
                    <h1 className={styles.rank_no}># {leaderBoardRank}</h1>
                    <h2 className={styles.bottom_desc}>Leaderboard</h2>
                </div>
            </div>
            </div>
        </div>
        : <div className={`w-full flex px-4 pt-4 items-center justify-between ${styles.not_loggined_state}`}>
            <TaskButton heading={'2048'} subHeading={'Connected Players'} />
            <TaskButton heading={'7'} subHeading={'Communities'} />
            <TaskButton heading={'3'} subHeading={'active tables'} />
            <TaskButton heading={'20K'} subHeading={'Max winnings'} />
        </div>}

        {/*  */}

        <div className={styles.daily_tasks_wrapper}>
            <div className={styles.heading}>Daily Tasks</div>

            <div className={styles.main_tasks_wrapper}>
                <DailyTasksSlider
                    opacity={userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber && userInfo?.dailyTaskProgress?.task1Progress >= tasks?.task1?.number && userInfo?.dailyTaskProgress?.task2Progress >= tasks?.task2?.number && userInfo?.dailyTaskProgress?.task3Progress >= tasks?.task3?.number}
                    title={tasks?.task1?.description || ''}
                    Icon={userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber && userInfo?.dailyTaskProgress?.task1Progress >= tasks?.task1?.number ? CompleteTask : Task1}
                    id={1}
                    status={`${Math.min(userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber ? userInfo?.dailyTaskProgress?.task1Progress || 0 : 0, tasks?.task1?.number)}/${tasks?.task1?.number}`}
                    completion={userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber ? (userInfo?.dailyTaskProgress?.task1Progress || 0) <= tasks?.task1?.number ? `${((userInfo?.dailyTaskProgress?.task1Progress || 0)/tasks?.task1?.number) * 100}` : 100 : 0} />
                <DailyTasksSlider
                    opacity={userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber && userInfo?.dailyTaskProgress?.task1Progress >= tasks?.task1?.number && userInfo?.dailyTaskProgress?.task2Progress >= tasks?.task2?.number && userInfo?.dailyTaskProgress?.task3Progress >= tasks?.task3?.number}
                    title={tasks?.task2?.description || ''}
                    Icon={userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber && userInfo?.dailyTaskProgress?.task2Progress >= tasks?.task2?.number ? CompleteTask : Task2}
                    id={2}
                    status={`${Math.min(userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber ? userInfo?.dailyTaskProgress?.task2Progress || 0 : 0, tasks?.task2?.number)}/${tasks?.task2?.number}`}
                    completion={userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber ? (userInfo?.dailyTaskProgress?.task2Progress || 0) <= tasks?.task2?.number ? `${((userInfo?.dailyTaskProgress?.task2Progress || 0)/tasks?.task2?.number) * 100}` : 100 : 0} />
                <DailyTasksSlider
                    opacity={userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber && userInfo?.dailyTaskProgress?.task1Progress >= tasks?.task1?.number && userInfo?.dailyTaskProgress?.task2Progress >= tasks?.task2?.number && userInfo?.dailyTaskProgress?.task3Progress >= tasks?.task3?.number}
                    title={tasks?.task3?.description || ''}
                    Icon={userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber && userInfo?.dailyTaskProgress?.task3Progress >= tasks?.task3?.number ? CompleteTask : Task3}
                    id={3}
                    status={`${Math.min(userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber ? userInfo?.dailyTaskProgress?.task3Progress || 0 : 0, tasks?.task3?.number)}/${tasks?.task3?.number}`}
                    completion={userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber ? (userInfo?.dailyTaskProgress?.task3Progress || 0) <= tasks?.task3?.number ? `${((userInfo?.dailyTaskProgress?.task3Progress || 0)/tasks?.task3?.number) * 100}` : 100 : 0} />
                {userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber && userInfo?.dailyTaskProgress?.task1Progress >= tasks?.task1?.number && userInfo?.dailyTaskProgress?.task2Progress >= tasks?.task2?.number && userInfo?.dailyTaskProgress?.task3Progress >= tasks?.task3?.number && <div className={styles.overlay_collect_reward}>
                    <div className={styles.reward_collect_cntnt}>
                        <CompleteTask />
                        <h1>{userInfo?.lastDailyXPNumber !== tasks?.taskNumber ? 'Claim your Reward' : 'Reward Claimed'}</h1>
                        <p>{userInfo?.lastDailyXPNumber !== tasks?.taskNumber
                            ? <>You have completed all the task for today, Claim your XP before <span className={styles.dark}>{hours}H : {minutes}M : {seconds}S</span></>
                            : <>New Daily Tasks will be live in {tasks?.taskTimer < Date.now() ? 'Few Minutes' : <span className={styles.dark}>{hours}H : {minutes}M : {seconds}S</span>}</>}</p>
                    </div>
                </div>}
            </div>
        </div>

        {/* <div className={styles.reward_section_out}> */}
            <div className={styles.reward_section}>
                <h1>Reward:</h1>

                <div className='flex items-center gap-3'>
                    {userInfo?.dailyTaskProgress?.taskNumber === tasks?.taskNumber && userInfo?.lastDailyXPNumber !== tasks?.taskNumber && userInfo?.dailyTaskProgress?.task1Progress >= tasks?.task1?.number && userInfo?.dailyTaskProgress?.task2Progress >= tasks?.task2?.number && userInfo?.dailyTaskProgress?.task3Progress >= tasks?.task3?.number
                    ? <button className="primary-custom-button" disabled={claiming} onClick={() => dispatch(claimDailyTaskXP(setClaiming))}>
                        <div className='primary-custom-button-inner'>
                            <span className={`${styles.claim_btn}`}>{claiming ? 'Claiming...' : `Claim ${tasks?.task1?.XP + tasks?.task2?.XP + tasks?.task3?.XP} XP`}</span>
                        </div>
                    </button>
                    : <div className={`flex items-center gap-3 ${styles.vertical_align}`}>
                        <h1>{tasks?.task1?.XP + tasks?.task2?.XP + tasks?.task3?.XP} XP</h1>
                    </div>
                    }

                    {!isLogin && window?.innerWidth>650 && <button className="primary-custom-button" onClick={connectBtn}>
                        <div className='primary-custom-button-inner'>
                            <span className={`${styles.claim_btn}`}>Connect to play</span>
                        </div>
                    </button>}
                </div>
            </div>
        {/* </div> */}

        {openGuestModal && <CreateGuestModal setOpenConnectModal={setOpenLoginModal} openModal={openGuestModal} setOpenModal={setOpenGuestModal} />}
        <LoginModal setOpenGuestModal={setOpenGuestModal} openModal={openLoginModal} setOpenModal={() => {setOpenLoginModal(false)}} backUrl={`/dashboard`} />
    </div>
}

export default DailyTasks

const TaskButton = ({heading, subHeading}) => {
    return(
        <button className={styles.task_btn_holder}>
            <h1>{heading}</h1>
            <h2>{subHeading}</h2>
        </button>
    )
}

const DailyTasksSlider = ({title, status, completion, Icon, opacity}) => {
    const {isLogin} = useSelector((state) => state.userLoginReducer)
    const {loading} = useSelector((state) => state.dailyTasksReducer)

    return(
        <div className={styles.task_slider_wrapper} style={{opacity: opacity ? '0.2' : ''}}>
            <div style={{ marginBottom: '10px' }} className={styles.numeric_id}>
                <Icon />
            </div>

            <div className={styles.content_wrapper}>
                {loading ? <div className={`animate-pulse ${styles.heading_skelton}`}></div>
                : <h1>{title}</h1>}

                {isLogin && <div className={styles.main_slider}>
                    <div className={styles.slider_completion_outer}>
                        <div className={styles.slider_completion_inner} style={{width: `${completion}%`}}></div>
                    </div>
                    <div className={styles.bottom_slider_info}>
                        <span className={styles.status}>{status}</span>
                        <span className={styles.completion}>{completion}%</span>
                    </div>
                </div>}
            </div>
        </div>
    )
}

// const CoinWrapper = () =>{
//     return(
//         <div className={styles.coin_wrapper}>
//         <div className={styles.coin_wrapper_inner}>
//             <img src={coin} alt="coin" />
//             kurama coins
//         </div>
//     </div>
//     )
// }

const Task1 = () => {
    return(<svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="36.7695" y="11.3137" width="36" height="36" transform="rotate(45 36.7695 11.3137)" fill="#282828"/>
    <rect x="36.7695" y="0.707107" width="51" height="51" rx="3.5" transform="rotate(45 36.7695 0.707107)" stroke="#FF0000"/>
    <path d="M37.7845 46V32.704L36.1285 34.744H32.3365L37.4005 28.72H40.6645V46H37.7845Z" fill="#FF0000"/>
    </svg>
    )
}

const Task2 = () => {
    return(<svg width="74" height="75" viewBox="0 0 74 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="36.7695" y="11.8528" width="36" height="36" transform="rotate(45 36.7695 11.8528)" fill="#282828"/>
    <rect x="36.7695" y="1.24623" width="51" height="51" rx="3.5" transform="rotate(45 36.7695 1.24623)" stroke="#FF0000"/>
    <path d="M28.4071 46.5391V39.9391C28.4071 39.3471 28.5511 38.8111 28.8391 38.3311C29.1271 37.8351 29.5111 37.4511 29.9911 37.1791C30.4871 36.8911 31.0311 36.7471 31.6231 36.7471H42.3031C42.4151 36.7471 42.5111 36.7071 42.5911 36.6271C42.6871 36.5311 42.7351 36.4191 42.7351 36.2911V32.5711C42.7351 32.4431 42.6871 32.3391 42.5911 32.2591C42.5111 32.1631 42.4151 32.1151 42.3031 32.1151H31.7191C31.5911 32.1151 31.4791 32.1631 31.3831 32.2591C31.3031 32.3391 31.2631 32.4431 31.2631 32.5711V33.6271H28.4071V32.4751C28.4071 31.8671 28.5511 31.3231 28.8391 30.8431C29.1271 30.3631 29.5111 29.9791 29.9911 29.6911C30.4871 29.4031 31.0311 29.2591 31.6231 29.2591H42.3751C42.9671 29.2591 43.5031 29.4031 43.9831 29.6911C44.4791 29.9791 44.8711 30.3631 45.1591 30.8431C45.4471 31.3231 45.5911 31.8671 45.5911 32.4751V36.3871C45.5911 36.9791 45.4471 37.5231 45.1591 38.0191C44.8711 38.4991 44.4791 38.8911 43.9831 39.1951C43.5031 39.4831 42.9671 39.6271 42.3751 39.6271H31.7191C31.5911 39.6271 31.4791 39.6671 31.3831 39.7471C31.3031 39.8271 31.2631 39.9311 31.2631 40.0591V43.2271C31.2631 43.3551 31.3031 43.4671 31.3831 43.5631C31.4791 43.6431 31.5911 43.6831 31.7191 43.6831H45.5911V46.5391H28.4071Z" fill="#FF0000"/>
    </svg>
    )
}

const Task3 = () => {
    return(<svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="36.7695" y="11.3919" width="36" height="36" transform="rotate(45 36.7695 11.3919)" fill="#282828"/>
    <rect x="36.7695" y="0.785293" width="51" height="51" rx="3.5" transform="rotate(45 36.7695 0.785293)" stroke="#FF0000"/>
    <path d="M31.5979 46.0782C30.9899 46.0782 30.4459 45.9342 29.9659 45.6462C29.4859 45.3422 29.0939 44.9502 28.7899 44.4702C28.5019 43.9902 28.3579 43.4542 28.3579 42.8622V41.9742H31.2379V42.7662C31.2379 42.8942 31.2779 43.0062 31.3579 43.1022C31.4539 43.1822 31.5579 43.2222 31.6699 43.2222H42.2539C42.3819 43.2222 42.4859 43.1822 42.5659 43.1022C42.6619 43.0062 42.7099 42.8942 42.7099 42.7662V39.1902C42.7099 39.0622 42.6619 38.9582 42.5659 38.8782C42.4859 38.7982 42.3819 38.7582 42.2539 38.7582H31.4059V35.8782H41.5579C41.6699 35.8782 41.7659 35.8382 41.8459 35.7582C41.9419 35.6622 41.9899 35.5582 41.9899 35.4462V32.1102C41.9899 31.9822 41.9419 31.8782 41.8459 31.7982C41.7659 31.7022 41.6699 31.6542 41.5579 31.6542H31.6699C31.5579 31.6542 31.4539 31.7022 31.3579 31.7982C31.2779 31.8782 31.2379 31.9822 31.2379 32.1102V33.1182H28.3579V32.0142C28.3579 31.4062 28.5019 30.8622 28.7899 30.3822C29.0939 29.9022 29.4859 29.5182 29.9659 29.2302C30.4459 28.9422 30.9899 28.7982 31.5979 28.7982H41.6539C42.2459 28.7982 42.7819 28.9422 43.2619 29.2302C43.7579 29.5182 44.1499 29.9022 44.4379 30.3822C44.7259 30.8622 44.8699 31.4062 44.8699 32.0142V35.5422C44.8699 35.7342 44.8459 35.9342 44.7979 36.1422C44.7659 36.3502 44.7179 36.5422 44.6539 36.7182C44.9579 37.0542 45.1819 37.4302 45.3259 37.8462C45.4859 38.2622 45.5659 38.6782 45.5659 39.0942V42.8622C45.5659 43.4542 45.4219 43.9902 45.1339 44.4702C44.8459 44.9502 44.4539 45.3422 43.9579 45.6462C43.4779 45.9342 42.9419 46.0782 42.3499 46.0782H31.5979Z" fill="#FF0000"/>
    </svg>)
}

const Crown = () => {
    return (<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0244 13.2806L5.25012 5.90643L2.35522 13.2806H11.0244Z" fill="#F4900C"/>
    <path d="M13.9517 4.30426L8.84717 13.9402H19.0555L13.9517 4.30426Z" fill="#F4900C"/>
    <path d="M16.9758 13.2806L22.7501 5.90643L25.645 13.2806H16.9758Z" fill="#F4900C"/>
    <path d="M22.2982 11.8145L18.5789 2.7401L14.0055 11.8152L14.0001 11.8176L13.9946 11.8145L9.4213 2.73932L5.70195 11.8145C3.26439 8.42567 0.995605 6.25489 0.995605 6.25489C0.995605 6.25489 2.33339 16.1389 2.33339 25.6667H25.6668C25.6668 16.1389 27.0046 6.25489 27.0046 6.25489C27.0046 6.25489 24.7358 8.42567 22.2982 11.8145Z" fill="#FFCC4D"/>
    <path d="M13.9666 19.9796C15.5508 19.9796 16.8351 18.6954 16.8351 17.1112C16.8351 15.527 15.5508 14.2427 13.9666 14.2427C12.3824 14.2427 11.0981 15.527 11.0981 17.1112C11.0981 18.6954 12.3824 19.9796 13.9666 19.9796Z" fill="#5C913B"/>
    <path d="M20.5823 18.9871C21.6184 18.9871 22.4583 18.1472 22.4583 17.1111C22.4583 16.075 21.6184 15.2351 20.5823 15.2351C19.5462 15.2351 18.7063 16.075 18.7063 17.1111C18.7063 18.1472 19.5462 18.9871 20.5823 18.9871Z" fill="#981CEB"/>
    <path d="M25.5518 18.6559C26.4049 18.6559 27.0964 17.9643 27.0964 17.1112C27.0964 16.2581 26.4049 15.5665 25.5518 15.5665C24.6987 15.5665 24.0071 16.2581 24.0071 17.1112C24.0071 17.9643 24.6987 18.6559 25.5518 18.6559Z" fill="#DD2E44"/>
    <path d="M7.34988 18.9871C8.38597 18.9871 9.22589 18.1472 9.22589 17.1111C9.22589 16.075 8.38597 15.2351 7.34988 15.2351C6.31379 15.2351 5.47388 16.075 5.47388 17.1111C5.47388 18.1472 6.31379 18.9871 7.34988 18.9871Z" fill="#981CEB"/>
    <path d="M2.38085 18.6559C3.23395 18.6559 3.92553 17.9643 3.92553 17.1112C3.92553 16.2581 3.23395 15.5665 2.38085 15.5665C1.52775 15.5665 0.836182 16.2581 0.836182 17.1112C0.836182 17.9643 1.52775 18.6559 2.38085 18.6559Z" fill="#DD2E44"/>
    <path d="M25.6669 26.4445H2.33344C2.12716 26.4445 1.92933 26.3626 1.78347 26.2167C1.63761 26.0708 1.55566 25.873 1.55566 25.6667C1.55566 25.4605 1.63761 25.2626 1.78347 25.1168C1.92933 24.9709 2.12716 24.889 2.33344 24.889H25.6669C25.8731 24.889 26.071 24.9709 26.2168 25.1168C26.3627 25.2626 26.4446 25.4605 26.4446 25.6667C26.4446 25.873 26.3627 26.0708 26.2168 26.2167C26.071 26.3626 25.8731 26.4445 25.6669 26.4445ZM25.6669 23.7332H2.33344C2.12716 23.7332 1.92933 23.6512 1.78347 23.5054C1.63761 23.3595 1.55566 23.1617 1.55566 22.9554C1.55566 22.7491 1.63761 22.5513 1.78347 22.4054C1.92933 22.2596 2.12716 22.1776 2.33344 22.1776H25.6669C25.8731 22.1776 26.071 22.2596 26.2168 22.4054C26.3627 22.5513 26.4446 22.7491 26.4446 22.9554C26.4446 23.1617 26.3627 23.3595 26.2168 23.5054C26.071 23.6512 25.8731 23.7332 25.6669 23.7332Z" fill="#FFAC33"/>
    <path d="M1.12534 7.3492C1.72973 7.3492 2.21968 6.85924 2.21968 6.25486C2.21968 5.65047 1.72973 5.16052 1.12534 5.16052C0.520957 5.16052 0.0310059 5.65047 0.0310059 6.25486C0.0310059 6.85924 0.520957 7.3492 1.12534 7.3492Z" fill="#FFCC4D"/>
    <path d="M5.25011 6.83354C5.76214 6.83354 6.17723 6.41845 6.17723 5.90642C6.17723 5.39439 5.76214 4.97931 5.25011 4.97931C4.73808 4.97931 4.323 5.39439 4.323 5.90642C4.323 6.41845 4.73808 6.83354 5.25011 6.83354Z" fill="#F4900C"/>
    <path d="M9.42125 4.12766C10.1876 4.12766 10.8088 3.50643 10.8088 2.7401C10.8088 1.97377 10.1876 1.35254 9.42125 1.35254C8.65492 1.35254 8.03369 1.97377 8.03369 2.7401C8.03369 3.50643 8.65492 4.12766 9.42125 4.12766Z" fill="#FFCC4D"/>
    <path d="M26.8746 7.3492C27.479 7.3492 27.9689 6.85924 27.9689 6.25486C27.9689 5.65047 27.479 5.16052 26.8746 5.16052C26.2702 5.16052 25.7803 5.65047 25.7803 6.25486C25.7803 6.85924 26.2702 7.3492 26.8746 7.3492Z" fill="#FFCC4D"/>
    <path d="M22.7501 6.83354C23.2621 6.83354 23.6772 6.41845 23.6772 5.90642C23.6772 5.39439 23.2621 4.97931 22.7501 4.97931C22.2381 4.97931 21.823 5.39439 21.823 5.90642C21.823 6.41845 22.2381 6.83354 22.7501 6.83354Z" fill="#F4900C"/>
    <path d="M18.5787 4.12766C19.345 4.12766 19.9663 3.50643 19.9663 2.7401C19.9663 1.97377 19.345 1.35254 18.5787 1.35254C17.8124 1.35254 17.1912 1.97377 17.1912 2.7401C17.1912 3.50643 17.8124 4.12766 18.5787 4.12766Z" fill="#FFCC4D"/>
    <path d="M13.9518 5.69181C14.7181 5.69181 15.3393 5.07058 15.3393 4.30425C15.3393 3.53792 14.7181 2.91669 13.9518 2.91669C13.1854 2.91669 12.5642 3.53792 12.5642 4.30425C12.5642 5.07058 13.1854 5.69181 13.9518 5.69181Z" fill="#F4900C"/>
    </svg>
    )
}

const CompleteTask = () => {
    return(<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="38" y="12.0833" width="36" height="36" transform="rotate(45 38 12.0833)" fill="#C10000"/>
    <rect x="38" y="1.47664" width="51" height="51" rx="3.5" transform="rotate(45 38 1.47664)" stroke="#FF0000"/>
    <path d="M29 38.4362L34.5385 43.7695L47 31.7695" stroke="black" strokeWidth="4" strokeLinecap="square" strokeLinejoin="bevel"/>
    </svg>
    )
}