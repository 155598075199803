import axios from "axios"
import { CREATE_ADMIN_FAIL, CREATE_ADMIN_REQUEST, CREATE_ADMIN_SUCCESS, DELETE_ADMIN_FAIL, DELETE_ADMIN_REQUEST, DELETE_ADMIN_SUCCESS, GET_ALL_ADMINS_FAIL, GET_ALL_ADMINS_REQUEST, GET_ALL_ADMINS_SUCCESS } from "../constants/AdminConstant"
import { BACKEND_URL, ErrorMessage } from "../../constants"
import { store } from "../../store"

export const getAllAdmins = (communityId) =>
    async (dispatch) => {
        try {
            dispatch({ type: GET_ALL_ADMINS_REQUEST })
            const userLoginReducer = store.getState().userLoginReducer
            const token = userLoginReducer?.accessToken

            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + token,
                },
            }

            const { data } = await axios.post(
                `${BACKEND_URL}/community/getAdmins`,
                {
                    communityId,
                    verificationHash: userLoginReducer?.verificationHash,
                },
                config
            )
            if (data && data?.status === 200) {
                dispatch({
                    type: GET_ALL_ADMINS_SUCCESS,
                    payload: data?.admins,
                })
            } else
                dispatch({
                    type: GET_ALL_ADMINS_FAIL,
                    payload: data.msg,
                })
        } catch (error) {
            dispatch({
                type: GET_ALL_ADMINS_FAIL,
                payload: ErrorMessage(error),
            })
        }
    }

export const createAdmin = (walletAddress, communitySlug, communityId) =>
    async (dispatch) => {
        try {
            dispatch({ type: CREATE_ADMIN_REQUEST })
            const userLoginReducer = store.getState().userLoginReducer
            const token = userLoginReducer?.accessToken

            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + token,
                },
            }

            const { data } = await axios.post(
                `${BACKEND_URL}/community/createAdmin`,
                {
                    walletAddress,
                    communitySlug,
                    communityId,
                    verificationHash: userLoginReducer?.verificationHash,
                },
                config
            )
            if (data && data?.status === 200) {
                dispatch({
                    type: CREATE_ADMIN_SUCCESS,
                    payload: data,
                })
                dispatch(getAllAdmins(communityId))
            } else
                dispatch({
                    type: CREATE_ADMIN_FAIL,
                    payload: data.msg,
                })
        } catch (error) {
            dispatch({
                type: CREATE_ADMIN_FAIL,
                payload: ErrorMessage(error),
            })
        }
    }

export const deleteAdmin = (walletAddress, communitySlug, communityId) =>
    async (dispatch) => {
        try {
            dispatch({ type: DELETE_ADMIN_REQUEST })
            const userLoginReducer = store.getState().userLoginReducer
            const token = userLoginReducer?.accessToken

            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + token,
                },
            }

            const { data } = await axios.post(
                `${BACKEND_URL}/community/deleteAdmin`,
                {
                    walletAddress,
                    communitySlug,
                    communityId,
                    verificationHash: userLoginReducer?.verificationHash,
                },
                config
            )
            if (data && data?.status === 200) {
                dispatch({
                    type: DELETE_ADMIN_SUCCESS,
                    payload: data,
                })
                dispatch(getAllAdmins(communityId))
            } else
                dispatch({
                    type: DELETE_ADMIN_FAIL,
                    payload: data.msg,
                })
        } catch (error) {
            dispatch({
                type: DELETE_ADMIN_FAIL,
                payload: ErrorMessage(error),
            })
        }
    }