import styles from './SelectUploadPopover.module.css'
import Popover from '@mui/material/Popover'
import React from 'react'
import checkCircle from '../../../../Assets/Images/check-circle.png'

const SelectUploadPopover = ({ anchorEl, discord, handleClose, value, onChange, selectData, TextSelect }) => {
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            // transformOrigin={{
            //     vertical: 'top',
            //     horizontal: 'center',
            // }}
            PaperProps={{
                sx:{
                    width: '600px',
                    background: 'linear-gradient(180deg, #1A1919 10.46%, #000000 100%)',
                    color: '#FFF',
                    border: '1px solid #1A1919',
                    borderRadius: '4px'
                }
            }}
        >
            <div className={styles.row_align}>
                {!TextSelect && selectData?.map((data, key) =>(
                    <Row ind={key} selected={value === key} data={data} key={key} setSelect={onChange} handleClose={handleClose} />
                ))}

                {TextSelect && !discord && selectData?.map((data, key) =>(
                    <TextRow ind={key} selected={value === data} data={data} key={key} setSelect={onChange} handleClose={handleClose} />
                ))}

                {TextSelect && discord && selectData?.filter((opt) => opt?.name !== '@everyone')?.map((data, key) =>(
                    <TextRowDiscord ind={key} selected={value === data?.id} data={data?.id} key={key} text={data?.name} setSelect={onChange} handleClose={handleClose} />
                ))}

                {/* <div className={styles.upload_box}>
                    <img src={upload} alt="" />
                    <h1>Upload Background Image</h1>
                </div> */}
            </div>
        </Popover>
    )
}

export default SelectUploadPopover

const TextRowDiscord = ({selected, ind, setSelect, handleClose, data, text}) => {
    return <div className={styles.row}
    style={{
        padding: '10px 12px',
        paddingBottom: '5px',
    }}
    onClick={() => {
        setSelect(data)
        handleClose()
    }}>
        <div className={styles.brief_row}>
            <h1 className={styles.val_title}>{text}</h1>
        </div>

        {selected && <div className={styles.select_row}>
            <img src={checkCircle} alt="" />
            <h2>Selected</h2>
        </div>}
    </div>
}

const TextRow = ({selected, ind, setSelect, handleClose, data}) => {
    return <div className={styles.row}
    style={{
        padding: '10px 12px',
        paddingBottom: '5px',
    }}
    onClick={() => {
        setSelect(data)
        handleClose()
    }}>
        <div className={styles.brief_row}>
            <h1 className={styles.val_title}>{data}</h1>
        </div>

        {selected && <div className={styles.select_row}>
            <img src={checkCircle} alt="" />
            <h2>Selected</h2>
        </div>}
    </div>
}

const Row = ({selected, ind, setSelect, handleClose, data}) => {
    return <div className={styles.row} onClick={() => {
        setSelect(ind)
        handleClose()
    }}>
        <div className={styles.brief_row}>
            <div className={styles.val_image}>
                <img src={data}  alt="" style={{width: 'inherit', height: 'inherit'}}/>
            </div>
            <h1 className={styles.val_title}>Check board background</h1>
        </div>

        {selected && <div className={styles.select_row}>
            <img src={checkCircle} alt="" />
            <h2>Selected</h2>
        </div>}
    </div>
}