import React from 'react'
import styles from './Input.module.css'
import SelectUploadPopover from '../Popovers/SelectUploadPopover/SelectUploadPopover'
import { ArrowDropDownRounded, Close } from '@mui/icons-material';

const Input = ({label='Project Name', value, onChange, upload, select, selectData}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if(upload){
        return (
            <div className={styles.inp_holder}>
            <div>
                <label htmlFor=''>{label}</label>
                    <p>Drag or choose your file to upload</p>
                </div>
            {!value ?
                <>
                    <input type="file" id='upload_items' hidden onChange={(e) => onChange(e.target.files[0])}/>
                    <label for='upload_items' className={styles.upload_here}>
                        <span className={styles.upload_img}>Upload Image</span>
                    </label>
                </>
                :
                <div className={styles.img_holder}>
                    <div className={styles.close_btn} onClick={(e) => onChange('')}>
                        <Close />
                    </div>
                    <img src={URL.createObjectURL(value)} alt="" />
                </div>
            }
        </div>
        )
    }else if(select){
        return (
            <div className={`select_popopver ${styles.inp_holder}`}>
            <label htmlFor=''>{label}</label>

            <div className={styles.select_input} onClick={handleClick}>
                {'SELECT'}

                <ArrowDropDownRounded />
            </div>

            <SelectUploadPopover value={value} selectData={selectData} onChange={onChange} anchorEl={anchorEl} handleClose={handleClose} />
        </div>
        )
    }else{
        return (
            <div className={styles.inp_holder}>
            <label htmlFor=''>{label}</label>
            <input type='text' value={value} onChange={(e) => {onChange(e.target.value)}} />
        </div>
        )
    }
}

export default React.memo(Input)
