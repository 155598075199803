import WalletConnectProvider from '@walletconnect/web3-provider'
import bcrypt from 'bcryptjs'
import Web3 from 'web3'
import axios from "axios"
import { BACKEND_URL, ErrorMessage } from "../../constants"
import { CONNECT_DISCORD_COMPLETED, CONNECT_DISCORD_FAILED, CONNECT_DISCORD_REQUEST, DELETE_DISCORD_COMPLETED, DELETE_DISCORD_FAILED, DELETE_DISCORD_REQUEST, UPDATE_PROFILE_FAIL, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT, WALLET_NOT_FOUND } from "../constants/userConstant"
import { store } from './../../store';
import { openToast } from './otherAction'

const web3 = new Web3(Web3.givenProvider)

export const getNonce = async (address, walletType) => {
    const { data } = await axios.post(`${BACKEND_URL}/auth/getNonce`, {
        walletAddress: address,
        walletType
    })

    return data
}

export const loginWithMetamask = (navigate, backUrl, setOpenProfileModal, setOpenModal) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST })
        const config = {
            headers: {
                'content-type': 'application/json',
            },
        }

        if (window.ethereum) {
            var address
            if (window.ethereum.providers) {
                address = await window.ethereum.providers
                    .find((provider) => provider.isMetaMask)
                    .request({ method: 'eth_requestAccounts' })
            } else {
                address = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                })
            }
            if (address.length > 0) {
                await web3.eth
                    .getAccounts()
                    .then(async (res) => {
                        if (res.length > 0) {
                            if (res[0].length > 1) {
                                const response = await getNonce(res[0], 'Metamask')
                                if (response && response.nonce) {
                                    const _message_ = response.nonce
                                    const address = res[0]

                                    // web3.eth
                                    //     .requestAccounts()
                                    //     .then((accounts) => {
                                    //         web3.eth.personal
                                    //             .sign(_message_, data[0])
                                    //             .then(async (res) => {
                                    //                 const signature = res
                                    const { data } = await axios.post(
                                            `${BACKEND_URL}/auth/login`,
                                            {
                                                nonce: _message_,
                                                walletAddress: address,
                                            },
                                            config
                                        )

                                    if (data.status === 200) {
                                        const salt = await bcrypt.genSalt(10)
                                        const verificationHash = await bcrypt.hashSync(`${process.env.REACT_APP_KURAMA_VERIFICATION_HASH} ${data.accessToken.substring(0, 15)}`, salt)
                                        const verificationHashKURAMA = await bcrypt.hashSync(`Kurama Verification Hash for ArcadeVerse`, salt)
                                        dispatch({
                                            type: USER_LOGIN_SUCCESS,
                                            payload: data,
                                            verificationHash,
                                            verificationHashKURAMA,
                                        })

                                        localStorage.setItem(
                                            'user',
                                            JSON.stringify(data)
                                        )
                                        localStorage.setItem(
                                            'verificationHash',
                                            JSON.stringify(
                                                verificationHash
                                            )
                                        )
                                        localStorage.setItem(
                                            'verificationHashKURAMA',
                                            JSON.stringify(
                                                verificationHashKURAMA
                                            )
                                        )
                                        setOpenModal()
                                        navigate(backUrl ? backUrl : -1)
                                    } else {
                                        dispatch({
                                            type: USER_LOGIN_FAIL,
                                            payload: data.msg,
                                        })
                                    }
                                        //         })
                                        //         .catch((err) => {
                                        //             dispatch({
                                        //                 type: USER_LOGIN_FAIL,
                                        //                 payload:
                                        //                     err.response &&
                                        //                     err.response.data
                                        //                         .msg
                                        //                         ? err.response
                                        //                               .data.msg
                                        //                         : err.message,
                                        //             })
                                        //         })
                                        // })
                                } else {
                                    dispatch({
                                        type: USER_LOGIN_FAIL,
                                        payload: 'Something failed!',
                                    })
                                }
                            } else {
                                dispatch({
                                    type: USER_LOGIN_FAIL,
                                    payload: 'Something failed!',
                                })
                            }
                        } else {
                            dispatch({
                                type: USER_LOGIN_FAIL,
                                payload: 'Something failed!',
                            })
                        }
                    })
                    .catch((err) => {
                        dispatch({
                            type: USER_LOGIN_FAIL,
                            payload: err.toString() || err,
                        })

                        console.log(err, 'Wallet not found!')

                        dispatch({
                            type: WALLET_NOT_FOUND,
                            payload: err.toString() || err,
                        })

                        window.open(
                            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
                            '_blank'
                        )
                    })
            } else {
                dispatch({
                    type: USER_LOGIN_FAIL,
                    payload: 'Something went wrong!',
                })

                dispatch({
                    type: WALLET_NOT_FOUND,
                    payload: 'Wallet not found!',
                })

                window.open(
                    'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
                    '_blank'
                )
            }
        } else {
            dispatch({
                type: USER_LOGIN_FAIL,
                payload: 'Wallet Not Found!',
            })

            dispatch({
                type: WALLET_NOT_FOUND,
                payload: 'Wallet not found!',
            })

            window.open(
                'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
                '_blank'
            )
        }
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: ErrorMessage(error),
        })

        console.log(error, 'Wallet not found!')

        dispatch({
            type: WALLET_NOT_FOUND,
            payload: 'Wallet not found!',
        })
    }
}

export const loginWithWalletConnect = (navigate, backUrl, setOpenProfileModal, setOpenModal) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST })
        const config = {
            headers: {
                'content-type': 'application/json',
            },
        }

        if (window.ethereum) {
            const provider = new WalletConnectProvider({
                rpc: {
                    1: 'https://cloudflare-eth.com',
                    137: 'https://polygon-rpc.com',
                    // 100: 'https://dai.poa.network',
                },
                bridge: 'https://bridge.walletconnect.org',
            })

            await provider.enable()
            const web3 = new Web3(provider)

            await web3.eth
                .getAccounts()
                .then(async (res) => {
                    if (res.length > 0) {
                        if (res[0].length > 1) {
                            const response = await getNonce(res[0], 'WalletConnect')
                            if (response && response.nonce) {
                                const _message_ = response.nonce
                                const address = res[0]
                                // const _message_ = await getNonce(
                                //     data[0],
                                //     'walletconnect'
                                // )
                                // await web3.eth.personal
                                //     .sign(_message_, res[0])
                                //     .then(async (res) => {
                                //         const signature = res
                                        const { data } = await axios.post(
                                            `${BACKEND_URL}/auth/login`,
                                            {
                                                nonce: _message_,
                                                walletAddress: address,
                                            },
                                            config
                                        )

                                        if (data.status === 200) {
                                            const salt = await bcrypt.genSalt(10)
                                            const verificationHash = await bcrypt.hashSync(`${process.env.REACT_APP_KURAMA_VERIFICATION_HASH} ${data.accessToken.substring(0, 15)}`, salt)
                                            const verificationHashKURAMA = await bcrypt.hashSync(`Kurama Verification Hash for ArcadeVerse`, salt)
                                            dispatch({
                                                type: USER_LOGIN_SUCCESS,
                                                payload: data,
                                                verificationHash,
                                                verificationHashKURAMA
                                            })

                                            localStorage.setItem(
                                                'user',
                                                JSON.stringify(data)
                                            )
                                            localStorage.setItem(
                                                'verificationHash',
                                                JSON.stringify(verificationHash)
                                            )
                                            localStorage.setItem(
                                                'verificationHashKURAMA',
                                                JSON.stringify(verificationHashKURAMA)
                                            )
                                            setOpenModal()
                                            navigate(backUrl ? backUrl : -1)
                                        } else {
                                            dispatch({
                                                type: USER_LOGIN_FAIL,
                                                payload: data.msg,
                                            })
                                        }
                                    // })
                                    // .catch((err) => {
                                    //     dispatch({
                                    //         type: USER_LOGIN_FAIL,
                                    //         payload:
                                    //             err.response &&
                                    //             err.response.data.msg
                                    //                 ? err.response.data.msg
                                    //                 : err.message,
                                    //     })
                                    // })
                                // })
                            } else {
                                dispatch({
                                    type: USER_LOGIN_FAIL,
                                    payload: 'Something failed!',
                                })
                            }
                        } else {
                            dispatch({
                                type: USER_LOGIN_FAIL,
                                payload: 'Something failed!',
                            })
                        }
                    } else {
                        dispatch({
                            type: USER_LOGIN_FAIL,
                            payload: 'Something failed!',
                        })
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: USER_LOGIN_FAIL,
                        payload: err.toString() || err,
                    })

                    console.log(err, 'Wallet not found!')

                    dispatch({
                        type: WALLET_NOT_FOUND,
                        payload: err.toString() || err,
                    })
                })
        } else {
            dispatch({
                type: USER_LOGIN_FAIL,
                payload: 'Wallet Not Found!',
            })

            dispatch({
                type: WALLET_NOT_FOUND,
                payload: 'Wallet not found!',
            })
        }
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: ErrorMessage(error),
        })

        console.log(error, 'Wallet not found!')

        dispatch({
            type: WALLET_NOT_FOUND,
            payload: 'Wallet not found!',
        })
    }
}

export const loginWithCoinbase = (navigate, backUrl, setOpenProfileModal, setOpenModal) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST })
        const config = {
            headers: {
                'content-type': 'application/json',
            },
        }

        if (window.ethereum) {
            var address
            if (window.ethereum.providers) {
                address = await window.ethereum.providers
                    .find((provider) => provider.isCoinbaseWallet)
                    .request({ method: 'eth_requestAccounts' })
            } else {
                if (window.ethereum.isCoinbaseWallet) {
                    address = await window.ethereum.request({
                        method: 'eth_requestAccounts',
                    })
                }
            }
            if (address && address.length > 0) {
                await new web3.eth.getAccounts()
                    .then(async (res) => {
                        if (res.length > 0) {
                            if (res[0].length > 1) {
                                const response = await getNonce(res[0], 'CoinBase')
                                if (response && response.nonce) {
                                    const _message_ = response.nonce
                                    const address = res[0]

                                    // await new web3.eth.requestAccounts().then(
                                    //     async (accounts) => {
                                    //         await new web3.eth.personal.sign(
                                    //             _message_,
                                    //             res[0]
                                    //         )
                                                // .then(async (res) => {
                                                    // const signature = res
                                                    const { data } =
                                                        await axios.post(
                                                            `${BACKEND_URL}/auth/login`,
                                                            {
                                                                nonce: _message_,
                                                                walletAddress: address,
                                                            },
                                                            config
                                                        )

                                                    if (data.status === 200) {
                                                        const salt = await bcrypt.genSalt(10)
                                                        const verificationHash = await bcrypt.hashSync(`${process.env.REACT_APP_KURAMA_VERIFICATION_HASH} ${data.accessToken.substring(0, 15)}`, salt)
                                                        const verificationHashKURAMA = await bcrypt.hashSync(`Kurama Verification Hash for ArcadeVerse`, salt)
                                                        dispatch({
                                                            type: USER_LOGIN_SUCCESS,
                                                            payload: data,
                                                            verificationHash,
                                                            verificationHashKURAMA
                                                        })

                                                        localStorage.setItem(
                                                            'user',
                                                            JSON.stringify(data)
                                                        )
                                                        localStorage.setItem(
                                                            'verificationHash',
                                                            JSON.stringify(
                                                                verificationHash
                                                            )
                                                        )
                                                        localStorage.setItem(
                                                            'verificationHashKURAMA',
                                                            JSON.stringify(
                                                                verificationHashKURAMA
                                                            )
                                                        )
                                                        setOpenModal()
                                                        navigate(backUrl ? backUrl : -1)
                                                    } else {
                                                        dispatch({
                                                            type: USER_LOGIN_FAIL,
                                                            payload: data.msg
                                                        })
                                                    }
                                                // })
                                                // .catch((err) => {
                                                //     dispatch({
                                                //         type: USER_LOGIN_FAIL,
                                                //         payload:
                                                //             err.response &&
                                                //             err.response.data
                                                //                 .msg
                                                //                 ? err.response
                                                //                       .data.msg
                                                //                 : err.message,
                                                //     })
                                                // })
                                    //     }
                                    // )
                                }
                            } else {
                                dispatch({
                                    type: USER_LOGIN_FAIL,
                                    payload: 'Something failed!',
                                })
                            }
                        } else {
                            dispatch({
                                type: USER_LOGIN_FAIL,
                                payload: 'Something failed!',
                            })
                        }
                    })
                    .catch((err) => {
                        dispatch({
                            type: USER_LOGIN_FAIL,
                            payload: err.toString() || err,
                        })

                        console.log(err, 'Wallet not found!')

                        dispatch({
                            type: WALLET_NOT_FOUND,
                            payload: err.toString() || err,
                        })

                        window.open(
                            'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad',
                            '_blank'
                        )
                    })
            } else {
                dispatch({
                    type: USER_LOGIN_FAIL,
                    payload: 'Something went wrong!',
                })

                dispatch({
                    type: WALLET_NOT_FOUND,
                    payload: 'Wallet not found!',
                })

                window.open(
                    'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad',
                    '_blank'
                )
            }
        } else {
            dispatch({
                type: USER_LOGIN_FAIL,
                payload: 'Wallet Not Found!',
            })

            dispatch({
                type: WALLET_NOT_FOUND,
                payload: 'Wallet not found!',
            })

            window.open(
                'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad',
                '_blank'
            )
        }
        // setConnectLoading(false)
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: ErrorMessage(error),
        })

        console.log(error, 'Wallet not found!')

        dispatch({
            type: WALLET_NOT_FOUND,
            payload: 'Wallet not found!',
        })
    }
}

export const updateProfile = (image, username, setOpenModal, tokenId) => async (dispatch) => {
    dispatch({
        type: UPDATE_PROFILE_REQUEST
    })

    const userLoginReducer = store.getState().userLoginReducer
    const token = userLoginReducer?.accessToken
    const verificationHash = userLoginReducer?.verificationHash

    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }

    if (token) {
        await axios
            .post(`${BACKEND_URL}/user/updateProfile`,{
                image : image,
                username,
                tokenId,
                verificationHash
            }, config)
            .then(async (res) => {
                if(res.data.status === 200){
                    dispatch({
                        type: UPDATE_PROFILE_SUCCESS,
                        payload: res.data
                    })

                    dispatch(updateLocalUserInfo())
                    setOpenModal(false)
                }else{
                    dispatch({
                        type: UPDATE_PROFILE_FAIL,
                        payload: res.data.msg
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_PROFILE_FAIL,
                    payload: ErrorMessage(error)
                })
            })
    }
}

export const updateLocalUserInfo = () => async (dispatch) => {
    const userLoginReducer = store.getState().userLoginReducer
    const token = userLoginReducer?.accessToken

    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }

    if (token) {
        await axios
            .post(`${BACKEND_URL}/auth/getUser`,{
                connectType: userLoginReducer.userInfo.connectType,
            }, config)
            .then(async (res) => {
                const salt = await bcrypt.genSalt(10)
                const verificationHash = await bcrypt.hashSync(
                    `${
                        process.env.REACT_APP_KURAMA_VERIFICATION_HASH
                    } ${token.substring(0, 15)}`,
                    salt
                )
                const verificationHashKURAMA =
                    await bcrypt.hashSync(
                        `Kurama Verification Hash for ArcadeVerse`,
                        salt
                    )
                if (res?.data?.status === 200) {
                    dispatch({
                        type: USER_LOGIN_SUCCESS,
                        payload: res.data,
                        verificationHash,
                        verificationHashKURAMA
                    })

                    localStorage.setItem('user', JSON.stringify(res?.data))
                    localStorage.setItem(
                        'verificationHash',
                        JSON.stringify(verificationHash)
                    )
                    localStorage.setItem(
                        'verificationHashKURAMA',
                        JSON.stringify(verificationHashKURAMA)
                    )
                } else {
                    localStorage.clear()
                    dispatch({
                        type: USER_LOGIN_FAIL,
                        payload: res?.data?.msg || 'Something Failed...',
                    })
                }
            })
            .catch((error) => {
                if (error?.response?.data?.msg === 'Invalid Token') {
                    localStorage.clear()
                    window.location.reload()
                }
                console.log(error)
            })
    }
}

export const connectToDiscord = (code, navigate) => async (dispatch) => {
    dispatch({
        type: CONNECT_DISCORD_REQUEST,
    })

    const userLoginReducer = store.getState().userLoginReducer
    const token = userLoginReducer?.accessToken

    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }
    const redirect_url = JSON.parse(localStorage.getItem('redirect_url')) || '/'

    try {
        if (token) {
            const { data } = await axios.post(
                `${BACKEND_URL}/auth/connectToDiscord`,
                {
                    code,
                },
                config
            )
            if (data) {
                const userData = data.userData

                const addDiscord = await axios.post(
                    `${BACKEND_URL}/auth/addToDiscord`,
                    {
                        discordData: userData,
                    },
                    config
                )

                if (addDiscord.data.status === 200) {
                    dispatch(updateLocalUserInfo())
                    dispatch({
                        type: CONNECT_DISCORD_COMPLETED,
                        payload: userData,
                    })
                    dispatch(updateLocalUserInfo())
                } else {
                    dispatch(openToast(true, addDiscord.data?.msg, `Can't Connect`, true))
                    dispatch({
                        type: CONNECT_DISCORD_FAILED,
                        payload: addDiscord.data.msg,
                    })
                    dispatch(updateLocalUserInfo())
                    if(navigate) navigate(redirect_url)
                }
            } else {
                dispatch({
                    type: CONNECT_DISCORD_FAILED,
                    payload: 'Connect to Discord Failed!',
                })
                dispatch(updateLocalUserInfo())
            }
        } else {
            dispatch({
                type: CONNECT_DISCORD_FAILED,
                payload: 'User Not LogedIn!',
            })
        }
    } catch (error) {
        console.log(error)
        dispatch({
            type: CONNECT_DISCORD_FAILED,
            payload:
                error.response && error.response.data.msg
                    ? error.response.data.msg
                    : error.message,
        })
    }
}

export const deleteDiscord = (setConnectDiscordLoading) => async (dispatch) => {
    dispatch({
        type: DELETE_DISCORD_REQUEST,
    })

    const userLoginReducer = store.getState().userLoginReducer
    const token = userLoginReducer?.accessToken

    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }

    try {
        if (token) {
            const { data } = await axios.post(`${BACKEND_URL}/auth/deleteDiscord`, {}, config)

            if (data.status === 200) {
                dispatch(updateLocalUserInfo())
                dispatch({
                    type: DELETE_DISCORD_COMPLETED,
                    payload: data,
                })
            } else {
                dispatch(openToast(true, data?.msg, `Discord`, true))
                dispatch({
                    type: DELETE_DISCORD_FAILED,
                    payload: data.msg,
                })
            }
            if(setConnectDiscordLoading) setConnectDiscordLoading(false)
        } else {
            dispatch({
                type: DELETE_DISCORD_FAILED,
                payload: 'User Not LogedIn!',
            })
            if(setConnectDiscordLoading) setConnectDiscordLoading(false)
        }
    } catch (error) {
        console.log(error)
        dispatch({
            type: DELETE_DISCORD_FAILED,
            payload:
                error.response && error.response.data.msg
                    ? error.response.data.msg
                    : error.message,
        })
        if(setConnectDiscordLoading) setConnectDiscordLoading(false)
    }
}

export const connectToTwitter = (oauth_token, oauth_verifier, navigate) => async (dispatch) => {
    const userLoginReducer = store.getState().userLoginReducer
    const token = userLoginReducer?.accessToken

    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }

    await axios
        .post(
            `${BACKEND_URL}/auth/twitter?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`,
            {},
            config
        )
        .then(async (res) => {
            const redirect_url = JSON.parse(localStorage.getItem('redirect_url')) || '/'
            if(res.data.status !== 200){
                if(navigate) navigate(redirect_url)
                dispatch(openToast(true, res.data?.msg, `Can't Connect`, true))
                return
            }
            dispatch(updateLocalUserInfo())
        })
        .catch((err) => {
            console.log(err)
            dispatch(updateLocalUserInfo())
        })
}

export const connectTwitter = () => async (dispatch) => {
    try {
        const userLoginReducer = store.getState().userLoginReducer
        const token = userLoginReducer?.accessToken

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${token}`,
            },
        }
        await axios
            .post(`${BACKEND_URL}/auth/twitter/reverse`, {}, config)
            .then((res) => {
                window.location.assign(
                    `https://api.twitter.com/oauth/authorize?oauth_token=${res.data.oauth_token}`
                )
            })
            .catch((err) => {
                console.log(err)
            })
    } catch (err) {
        console.log(err)
    }
}

export const disconnectTwitter = (setConnectTwitterLoading) => async (dispatch) => {
    const userLoginReducer = store.getState().userLoginReducer
    const token = userLoginReducer?.accessToken

    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }
    await axios
        .post(`${BACKEND_URL}/auth/deleteTwitter`, {}, config)
        .then(async (res) => {
            if(res.data.status !== 200){
                dispatch(openToast(true, res.data?.msg, `Twitter`, true))
                dispatch(updateLocalUserInfo())
                if(setConnectTwitterLoading) setConnectTwitterLoading(false)
                return
            }
            dispatch(updateLocalUserInfo())
            if(setConnectTwitterLoading) setConnectTwitterLoading(false)
        })
        .catch((err) => {
            console.log(err)
            if(setConnectTwitterLoading) setConnectTwitterLoading(false)
            return
        })
}

export const claimDailyTaskXP = (setLoading) => async (dispatch) => {
    const userLoginReducer = store.getState().userLoginReducer
    const token = userLoginReducer?.accessToken
    const verificationHash = userLoginReducer?.verificationHash
    setLoading(true)

    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }
    await axios
        .post(`${BACKEND_URL}/user/claimDailyTaskXP`, {verificationHash}, config)
        .then(async (res) => {
            if(setLoading) setLoading(false)
            dispatch(updateLocalUserInfo())
    })
    .catch((err) => {
        console.log(err)
        if(setLoading) setLoading(false)
        return
    })
}

export const loginByDiscord = (code, navigate) => async (dispatch) => {
    dispatch({
        type: USER_LOGIN_REQUEST,
    })

    localStorage.removeItem('LoginDiscord')
    const redirect_url = JSON.parse(localStorage.getItem('redirect_url')) || '/'

    try {
        const { data } = await axios.post(
            `${BACKEND_URL}/auth/connectToDiscord`,
            {code},
        )
        if (data) {
            const userData = data.userData

            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${userData.accessToken}`,
                },
            }

            const addDiscord = await axios.post(
                `${BACKEND_URL}/auth/loginByDiscord`,
                {
                    discordData: userData,
                    connectType: 'Discord'
                },
                config
            )

            if (addDiscord.data.status === 200) {
                const salt = await bcrypt.genSalt(10)
                const verificationHash = await bcrypt.hashSync(`${process.env.REACT_APP_KURAMA_VERIFICATION_HASH} ${addDiscord.data.accessToken.substring(0, 15)}`, salt)

                dispatch({
                    type: USER_LOGIN_SUCCESS,
                    payload: addDiscord.data,
                    verificationHash,
                })

                localStorage.setItem(
                    'user',
                    JSON.stringify(addDiscord.data)
                )
                localStorage.setItem(
                    'verificationHash',
                    JSON.stringify(
                        verificationHash
                    )
                )

                if(navigate) navigate(redirect_url)
            } else {
                dispatch(openToast(true, addDiscord.data?.msg, `Can't Connect`, true))
                dispatch({
                    type: USER_LOGIN_FAIL,
                    payload: userData
                })
                if(navigate) navigate(redirect_url)
            }
        } else {
            dispatch({
                type: USER_LOGIN_FAIL,
                payload: `Something Failed...`,
            })
        }
    } catch (error) {
        console.log(error)
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:
                error.response && error.response.data.msg
                    ? error.response.data.msg
                    : error.message,
        })
    }
}

export const loginByTwitter = (oauth_token, oauth_verifier, navigate) => async (dispatch) => {
    const userLoginReducer = store.getState().userLoginReducer
    const token = userLoginReducer?.accessToken

    dispatch({ type: USER_LOGIN_REQUEST })
    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }

    localStorage.removeItem('LoginTwitter')
    const redirect_url = JSON.parse(localStorage.getItem('redirect_url')) || '/'

    await axios
        .post(
            `${BACKEND_URL}/auth/loginByTwitter?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`,
            {
                connectType: 'Twitter'
            },
            config
        )
        .then(async (res) => {
            if(res.data.status === 200){
                const salt = await bcrypt.genSalt(10)
                const verificationHash = await bcrypt.hashSync(`${process.env.REACT_APP_KURAMA_VERIFICATION_HASH} ${res.data.accessToken.substring(0, 15)}`, salt)

                dispatch({
                    type: USER_LOGIN_SUCCESS,
                    payload: res.data,
                    verificationHash,
                })

                localStorage.setItem(
                    'user',
                    JSON.stringify(res.data)
                )
                localStorage.setItem(
                    'verificationHash',
                    JSON.stringify(
                        verificationHash
                    )
                )
            }else{
                dispatch({
                    type: USER_LOGIN_FAIL,
                    payload: res.data?.msg,
                })
                dispatch(openToast(true, res.data?.msg, `Can't Connect`, true))
            }
            if(navigate) navigate(redirect_url)
        })
        .catch((error) => {
            console.log(error)
            dispatch({
                type: USER_LOGIN_FAIL,
                payload:
                    error.response && error.response.data.msg
                        ? error.response.data.msg
                        : error.message,
            })
        })
}

export const loginAsGuest = (navigate, redirect_url, setOpenModal) => async (dispatch) => {
    dispatch({ type: USER_LOGIN_REQUEST })
    const config = {
        headers: {
            'content-type': 'application/json',
        },
    }

    await axios
        .post(`${BACKEND_URL}/auth/loginAsGuest`, {}, config)
        .then(async (res) => {
            if(res.data.status === 200){
                const salt = await bcrypt.genSalt(10)
                const verificationHash = await bcrypt.hashSync(`${process.env.REACT_APP_KURAMA_VERIFICATION_HASH} ${res.data.accessToken.substring(0, 15)}`, salt)
                dispatch({
                    type: USER_LOGIN_SUCCESS,
                    payload: res.data,
                    verificationHash,
                })

                localStorage.setItem('user', JSON.stringify(res.data))
                localStorage.setItem('verificationHash', JSON.stringify(verificationHash))
            }else{
                dispatch({
                    type: USER_LOGIN_FAIL,
                    payload: res.data?.msg
                })
                dispatch(openToast(true, res.data?.msg, `Can't Login`, true))
            }
            if(navigate) navigate(redirect_url)
            setOpenModal(false)
        })
    .catch((error) => {
            console.log(error)
            dispatch({
                type: USER_LOGIN_FAIL,
                payload:
                    error.response && error.response.data.msg
                        ? error.response.data.msg
                        : error.message,
            })
            setOpenModal(false)
        })
}

export const deleteUser = (setLoading, setOpenModal) => async (dispatch) => {
    setLoading(true)

    const userLoginReducer = store.getState().userLoginReducer
    const token = userLoginReducer?.accessToken
    const verificationHash = userLoginReducer?.verificationHash

    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }

    await axios
        .post(`${BACKEND_URL}/user/deleteUser`, {
            verificationHash
        }, config)
        .then(async (res) => {
            if(res.data.status === 200){
                localStorage.clear()
                dispatch({
                    type: USER_LOGOUT,
                })
                dispatch(openToast(true, 'User deleted Successfully!', `Successfully Deleted`, false))
                setLoading(false)
            }else{
                console.log(res.data.msg)
                setLoading(false)
            }
            setOpenModal(false)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
            setOpenModal(false)
        })
}

export const logoutUser = () => async(dispatch) => {
    localStorage.clear()
    dispatch({
        type: USER_LOGOUT,
    })
}