import React, { useState } from "react";
import styles from "./TableTopCard.module.css";
import TableTopCardSvg from "../../../../Assets/Images/TableTopCardSvg";

const TableTopCard = ({edit, id, img='', table, setImage, openModal, communityInfo }) => {
  const [hover, setHover] = useState(false)

  return (
    <div className={styles.tablecard_btm_layer} id={`tablecard-btm-layer-${id}`}
    >
      <div
        className={styles.tablecard_middle_layer}
        id={`tablecard-middle-layer-${id}`}
      >
        <TableTopCardSvg id={id} fill={table?.tableBackground?.backgroundColor} backOther={`linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${table?.tableBackground?.backgroundColor}`} />
        <div className={styles.tablecard_top_layer}>
          {(((edit && !img) || hover) && openModal) ?
          <>
            <input type="file" id='edit_cover_table' hidden onChange={(e) => {setImage(e.target.files[0])}} />
            <label onMouseLeave={() => setHover(false)} htmlFor="edit_cover_table" className={`${styles.tablecard_top_layer_img} cursor-pointer flex items-center justify-center bg-[#161616]`}>
              <Thumbnail />
            </label>
          </>
          :
          <img onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} src={typeof img === 'string' ? (communityInfo?.shortImage || img) : URL.createObjectURL(img)} className={styles.tablecard_top_layer_img} alt='' />
          }
          <div className={styles.tablecard_top_layer_title}>{table?.tableName || table?.name}</div>
          <div className={styles.tablecard_top_layer_sub_flex}>
            <div className={styles.tablecard_top_layer_sub}>
              <div className={styles.tablecard_top_layer_sub_circle} />
              <div className={styles.tablecard_top_layer_sub_text}>
                {table?.activePlayers || 0} online
              </div>
            </div>
            <div className={styles.tablecard_top_layer_sub}>
              <img
                src={table?.chipImg}
                alt=""
                className={styles.tablecard_top_layer_sub_img}
              />
              <div className={styles.tablecard_top_layer_sub_num}>{table?.chipName}</div>
            </div>
            <div className={styles.tablecard_play_btn}>
              <div className={styles.shine}/>
              <div className={styles.tablecard_play_btn_top}>PLAY</div>
              <div className={styles.tablecard_play_btn_btm} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TableTopCard);

const Thumbnail = () => {
  return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M3 5C3 2.79086 4.79086 1 7 1H15.3431C16.404 1 17.4214 1.42143 18.1716 2.17157L19.8284 3.82843C20.5786 4.57857 21 5.59599 21 6.65685V19C21 21.2091 19.2091 23 17 23H7C4.79086 23 3 21.2091 3 19V5ZM19 8V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14V5C14 6.65685 15.3431 8 17 8H19ZM18.8891 6C18.7909 5.7176 18.6296 5.45808 18.4142 5.24264L16.7574 3.58579C16.5419 3.37035 16.2824 3.20914 16 3.11094V5C16 5.55228 16.4477 6 17 6H18.8891Z" fill="#CECECE"/>
  <path d="M11.6172 9.07588C11.4993 9.12468 11.3888 9.19702 11.2929 9.29289L8.29289 12.2929C7.90237 12.6834 7.90237 13.3166 8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071L11 12.4142V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V12.4142L14.2929 13.7071C14.6834 14.0976 15.3166 14.0976 15.7071 13.7071C16.0976 13.3166 16.0976 12.6834 15.7071 12.2929L12.7071 9.29289C12.4125 8.99825 11.9797 8.92591 11.6172 9.07588Z" fill="#CECECE"/>
  </svg>
  )
}