import React, { useEffect, useState } from "react";
import "./summary.css";
import UserSummary from "../UserSummary/UserSummary";
import SelectRoundPopover from "../shared/Popovers/SelectRoundPopover/SelectRoundPopover";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import emptySummary from '../../Assets/svgs/empty_summary.svg'
import AllSummary from "./AllSummary/AllSummary";
import { useSelector } from "react-redux";
import UserSummarySkelton from './../skeltons/UserSummarySkelton/UserSummarySkelton';

const Summary = ({ SetSummary, summary, rounds, playerHands, activePlayers, currentRoundActivity, tableCards, table, winners, allPlayerHands, Pot }) => {
  const [select, setSelect] = useState(0)
  const [currentRound, setCurrentRound] = useState(rounds)
  const [anchorEl, setAnchorEl] = useState(null);

  const {userInfo} = useSelector((state) => state.userLoginReducer)
  const {loading, round} = useSelector((state) => state.getRoundReducer)

  const [totalPot, settotalPot] = useState(Pot.length ? 0 : Pot)

  useEffect(() => {
    if (Pot.length) {
      var totalpot = 0
      for (let i = 0; i < Pot.length; i++) {
        // console.log(i, Pot[i].potAmount, Pot[i])
        totalpot = totalpot + Pot[i].potAmount
      }

      settotalPot(totalpot)
    } else {
      settotalPot(Pot)
    }
  }, [ Pot ])

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  useEffect(() => {
    if (summary) {
      console.log('round', !!round, round)
      if (!round._id) {
        setCurrentRound(rounds)
      }
    }
    if ((currentRound == rounds - 1) && !round) {
      setCurrentRound(rounds)
    }
  }, [summary, rounds])

  return (
    <div className="summary-modal">
    <div className="summary-header">
      <div className="summary-header-text">Summary</div>
      <button
        onClick={() => SetSummary(false)}
        >
        <CloseIcon />
      </button>
    </div>
    <div className="select-round-number" onClick={handleClick}>
      <span><span>{`Round ${currentRound ? currentRound : ''} `}</span>{ currentRound && rounds === currentRound && <span style={{ marginLeft: '4px' }} className="option-active-value"> [Current]</span>}</span>
      <span>
        <ArrowDropDownOutlined />
      </span>
    </div>

    <div className="summary-card-flex">
      <div className="summary-card-display-title"><span>Pot:</span><span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>{round?.pot || totalPot || 0} <img style={{ width: '15px', marginBottom: '-1px' }} src={table?.chipImg} /></span></div>
      <div className="summary-card-display">
        {(round?.tableCards && (!!totalPot || round._id)) ?
          round?.tableCards?.map((card, key) => (
              <img src={`https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/${card[1]}-${card[0]}.svg`} alt="Card" className="summary-card" key={key}/>
          ))
          :
          tableCards?.map((card, key) => (
            <img src={`https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/${card[1]}-${card[0]}.svg`} alt="Card" className="summary-card" key={key}/>
          ))
        }
        {
          Array(5 - (round?.tableCards ? round?.tableCards.length : tableCards?.length)).fill(0, 0).map((card, key) => {
            if(loading)
              return <div className="skelton-summary-card animate-pulse" key={key}>
                <div className="skelton-summary-card-inner"></div>
              </div>
            else
              return <img src={table?.cardImage} alt="Card" className="summary-card" key={key}/>
          })
        }
      </div>
    </div>

    <div className="summary-buttons-wrapper">
      <button className={select===0 ? "summary-filled-button" : "summary-outlined-button"} onClick={() => setSelect(0)}>All Activity</button>
      <button className={select===1 ? "summary-filled-button" : "summary-outlined-button"} onClick={() => setSelect(1)}>Player Cards</button>
    </div>

    {select === 0 && <div className="summary-info">
      {loading
        ? Array(3).fill(0).map((_, _k) => (
            <UserSummarySkelton key={_k} />
          ))
        : ((!!totalPot || round._id) && (round?._id ? round?.activity : currentRoundActivity)?.map((round, key) => {
          return <AllSummary
              key={key}
              round={round}
              dealerImage={table?.cardPreview}
            />
      }))}
    </div>}

    {select === 1 && <div className="summary-info">
      {loading
        ? Array(3).fill(0).map((_, _k) => (
            <UserSummarySkelton key={_k} />
          ))
        : ((!!totalPot || round._id) && (round?._id ? round?.players : activePlayers)?.map((thisRound, key) => {
          console.log('thisRound', thisRound)
          // console.log(round?.playerHands,  playerHands)
          return <UserSummary
              allPlayerHands={!thisRound?.playerHands && allPlayerHands}
              walletAddress={thisRound?.walletAddress}
              key={key}
              chipImg={table?.chipImg}
              id={key}
              cardBg={table?.cardImage}
              name={thisRound?.username}
              hidden={(round?._id || (allPlayerHands.length > 0)) ? false : thisRound?.walletAddress !== userInfo?.walletAddress}
              round={thisRound}
              userPlayerHands={thisRound?.playerHands ||  playerHands}
              handRank={round && round?.playerCards && round?.playerCards.find((player) => thisRound?.walletAddress === player.walletAddress)}
              winners={round?.winners || winners}
              userWalletAddress={userInfo?.walletAddress}
            />
      }))}
    </div>}

    {((totalPot == 0) && !round._id) && <div className="load-empty-summary-part">
      <img src={emptySummary} alt="" />
      <h1>Game Summary appears here</h1>
    </div>}

    <SelectRoundPopover table={table} anchorEl={anchorEl} handleClose={handleClose} currentRound={currentRound} setCurrentRound={setCurrentRound} rounds={rounds} />
  </div>
  );
};

export default Summary;

const CloseIcon = () => {
  return(<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_2431_44127" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
  <rect x="0.000488281" y="0.5" width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_2431_44127)">
  <path d="M6.40049 19.5L5.00049 18.1L10.6005 12.5L5.00049 6.9L6.40049 5.5L12.0005 11.1L17.6005 5.5L19.0005 6.9L13.4005 12.5L19.0005 18.1L17.6005 19.5L12.0005 13.9L6.40049 19.5Z" fill="#EEEEEE"/>
  </g>
  </svg>
  )
}