import { CREATE_TABLE_FAILED, CREATE_TABLE_REQUEST, CREATE_TABLE_SUCCESS, GET_TABLES_FAILED, GET_TABLES_REQUEST, GET_TABLES_SUCCESS, GET_TABLE_FAILED, GET_TABLE_REQUEST, GET_TABLE_SUCCESS, SAVE_STATE_TABLE, SAVE_STATE_TABLE_ASSETS, SAVE_STATE_TOURNAMENT_TABLE } from "../constants/TableConstant"

const createTableState = {
    loading: false,
    data:{},
    error: false
}

export const createTableReducer = (state=createTableState, action) => {
    switch(action.type){
            case CREATE_TABLE_REQUEST :
                return {
                    loading: true,
                    data: {},
                    error: false
                }
            case CREATE_TABLE_SUCCESS:
                return {
                    loading: false,
                    data: action.payload,
                    error: false
                }
            case CREATE_TABLE_FAILED:
                return {
                    loading: false,
                    data: {},
                    error: action.payload
                }

            default:
                return state
    }
}

const getTablesState = {
    loading: false,
    data:[],
    tournaments:[],
    error: false
}

export const getTablesReducer = (state=getTablesState, action) => {
    switch(action.type){
            case GET_TABLES_REQUEST :
                return {
                    loading: true,
                    data: [],
                    tournaments: [],
                    error: false
                }
            case GET_TABLES_SUCCESS:
                return {
                    loading: false,
                    data: action.payload,
                    tournaments: action.tournaments,
                    error: false
                }
            case GET_TABLES_FAILED:
                return {
                    loading: false,
                    data: [],
                    tournaments: [],
                    error: action.payload
                }

            default:
                return state
    }
}

const getTableState = {
    loading: false,
    table: {},
    error: false
}

export const getTableReducer = (state=getTableState, action) => {
    switch(action.type){
            case GET_TABLE_REQUEST :
                return {
                    loading: true,
                    table: {},
                    error: false
                }
            case GET_TABLE_SUCCESS:
                return {
                    loading: false,
                    table: action.payload,
                    error: false
                }
            case GET_TABLE_FAILED:
                return {
                    loading: false,
                    table: {},
                    error: action.payload
                }

            default:
                return state
    }
}

const saveTableState = {
    table: null,
    tournament: null,
    tableData: null,
}

export const saveTableReducer = (state=saveTableState, action) => {
    switch(action.type){
            case SAVE_STATE_TABLE :
                return {
                    table: action.table,
                    tournament: action.tournament,
                    tableData: action.tableData,
                }
            case SAVE_STATE_TABLE_ASSETS :
                return {
                    table: action.table,
                    tournament: action.tournament,
                    tableData: action.tableData,
                }
            case SAVE_STATE_TOURNAMENT_TABLE :
                return {
                    table: action.table,
                    tournament: action.tournament,
                    tableData: action.tableData
                }

            default:
                return state
    }
}