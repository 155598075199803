import styles from './AdminCardSkelton.module.css'

const AdminCardSkelton = () => {
    return <div className={styles.card_container_outer_border}>
        <div className={`${styles.card_container} animate-pulse`}>
            <figure className={styles.card_image_wrapper}>
            </figure>

            <h1 className={styles.card_heading}>{}</h1>

            <div className={styles.card_divider}></div>

            <div className={styles.card_wallet_add_wrapper}>
                <h1 className={styles.card_wallet_heading}>{}</h1>
                <h2 className={styles.card_wallet_address}>{}</h2>
            </div>
        </div>
    </div>
}

export default AdminCardSkelton
