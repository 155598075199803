import React from 'react'
import styles from './Input.module.css'
import { ArrowDropDownRounded } from '@mui/icons-material'
import SelectUploadPopover from '../../CreateComunity/Popovers/SelectUploadPopover/SelectUploadPopover'

const Input = ({ label, value, onChange, discord, readonly, type, extra, select, selectData, dark}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if(select){
        return (
            <div className={`select_popopver ${styles.input_wrapper}`}>
            <label className={styles.input_label}>{label}</label>

            <div className={styles.select_input} onClick={handleClick}>
                {discord ? selectData?.find((x) => x?.id ===value)?.name || 'SELECT' : (value || 'SELECT')}

                <ArrowDropDownRounded />
            </div>

            <SelectUploadPopover discord={discord} value={value} TextSelect={true} selectData={selectData} onChange={onChange} anchorEl={anchorEl} handleClose={handleClose} />
        </div>
        )
    }
    if(dark){
        return <div className={styles.input_wrapper}>
            <label className={styles.input_label}>
                {label}{extra && <span className={styles.extra}>{extra}</span>}
            </label>

            <input
                type={type || 'text'}
                className={styles.input_dark}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                readOnly={readonly}
            />
        </div>
    }
    return (
        <div className={styles.input_wrapper}>
            <label className={styles.input_label}>
                {label}{extra && <span className={styles.extra}>{extra}</span>}
            </label>

            <input
                type={type || 'text'}
                className={styles.input}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                readOnly={readonly}
                onFocus={(e) => {
                    if(e.target.type === 'date' || e.target.type === 'time'){
                        e?.target?.showPicker()
                    }
                }}
                min={type === 'date' && new Date().toISOString().split("T")[0]}
                max={type === 'date' && '3000-01-01'}
            />
        </div>
    )
}

export default React.memo(Input)