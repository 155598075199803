export const SELECT_NAVBAR = 'SELECT_NAVBAR';
export const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION';

export const REQUEST_GET_TABLES = 'REQUEST_GET_TABLES';
export const SUCCESS_GET_TABLES = 'SUCCESS_GET_TABLES';
export const FAILED_GET_TABLES = 'FAILED_GET_TABLES';

export const REQUEST_GET_FREE_TABLES = 'REQUEST_GET_FREE_TABLES';
export const SUCCESS_GET_FREE_TABLES = 'SUCCESS_GET_FREE_TABLES';
export const FAILED_GET_FREE_TABLES = 'FAILED_GET_FREE_TABLES';

export const REQUEST_DAILY_TASKS = 'REQUEST_DAILY_TASKS';
export const SUCCESS_DAILY_TASKS = 'SUCCESS_DAILY_TASKS';
export const FAILED_DAILY_TASKS = 'FAILED_DAILY_TASKS';

export const REQUEST_GET_LEADERBOARD = 'REQUEST_GET_LEADERBOARD';
export const SUCCESS_GET_LEADERBOARD = 'SUCCESS_GET_LEADERBOARD';
export const FAILED_GET_LEADERBOARD = 'FAILED_GET_LEADERBOARD';