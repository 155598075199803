import React, { useEffect, useState } from 'react'
import styles from './CommunityGraph.module.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

export const options = {
    // responsive: true,
    // maintainAspectRatio: true,
    tension: 0.4,
    fill: true,
    pointBorderWidth: 1,
    pointHoverRadius: 1,
    hover: {mode: null},
    pointHoverBorderWidth: 1,
    elements:{
        line: {
            tension: 0.4
        },
        point:{
            radius: 0
        }
    },
    tooltips: {
        enabled: false
    },
    scales: {
        y: {
            suggestedMin: 0,
            suggestedMax: 10,
            ticks: {
                beginAtZero: true,
                display: false
            },
            grid: {
                display: false,
            },
            title: {
                display: false,
                text: 'Price Sold',
                font: {
                    size: 16,
                },
            },
        },
        x: {
            ticks: {
                beginAtZero: true,
                display: false
            },
            grid: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        filler: {
            propagate: false,
        },
    },
    interaction: {
        intersect: false,
    },
}


const labels = [
    '1', '2', '3', '4', '5', '6', '7'
]

const CommunityGraph = ({activity}) => {
    const [gradient, setGradient] = useState('#ff363664')
    const [givenData, setGivenData] = useState([])

    useEffect(() => {
        try{
            const cv = document.getElementById('canvass')
            if(cv){
                let ctx = cv.getContext("2d")
                let angle = 90 * Math.PI / 180
                let length = 180
                let x2 = Math.round(length * Math.cos(angle))
                let y2 = Math.round(length * Math.sin(angle))
                let gradient = ctx.createLinearGradient(0, 0, x2, y2)
                gradient.addColorStop(0, 'rgb(255, 54, 54)')
                gradient.addColorStop(1, 'rgba(255, 54, 54, 0.00)')
                setGradient(gradient)
            }
        }catch(e){
            console.log(e)
        }
    },[])

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'PrivateParticipations',
                data: givenData,
                borderColor: '#FF5D5D',
                // backgroundColor: 'linear-gradient(180deg, #FF3636 0%, rgba(255, 54, 54, 0.00) 100%)',
                backgroundColor: gradient,
                fill: 'start'
            },
        ],
    }

    useEffect(() => {
        let arr = []
        for(let i=0; i<activity.length; i++) {
            arr.push(activity[i].activePlayers || 0)
        }
        setGivenData([...arr, ...arr])
    },[activity])

    // if(!loading)
    return <div className={styles.graph_cntnt}>
        <Line id='canvass' options={options} data={data} style={{width: '100%', height: '100%'}} />
    </div>
}

export default CommunityGraph
