import axios from "axios"
import { FAILED_DAILY_TASKS, FAILED_GET_FREE_TABLES, FAILED_GET_LEADERBOARD, FAILED_GET_TABLES, REQUEST_DAILY_TASKS, REQUEST_GET_FREE_TABLES, REQUEST_GET_LEADERBOARD, REQUEST_GET_TABLES, SELECT_NAVBAR, SET_CURRENT_POSITION, SUCCESS_DAILY_TASKS, SUCCESS_GET_FREE_TABLES, SUCCESS_GET_LEADERBOARD, SUCCESS_GET_TABLES } from "../constants/GlobalConstant"
import { BACKEND_URL } from './../../constants';
import { store } from './../../store';

export const ChooseNavLinks = (title, backUrl, disabled) => async(dispatch) => {
    dispatch({
        type: SELECT_NAVBAR,
        title: title,
        backUrl: backUrl,
        disabled:disabled
    })
}

export const setCurrentPos = (id) => async(dispatch) => {
    dispatch({
        type: SET_CURRENT_POSITION,
        payload: id
    })
}

export const getLeaderboardAction = (communityName, skip, setPage) => async(dispatch) => {
    try{
        const {userInfo} = store.getState().userLoginReducer
        const {leaderboardData, count, size} = store.getState().getLeaderboardReducer
        dispatch({
            type: REQUEST_GET_LEADERBOARD,
            leaderboardData: skip === 0 ? [] : leaderboardData,
            userScore: skip === 0 ? '' : count,
            size: skip === 0 ? '' : size
        })

        await axios.post(`${BACKEND_URL}/public/getLeaderboard`,{
            communityName: communityName,
            walletAddress: userInfo?.walletAddress,
            limit: 30,
            skip: skip
        }).then((res) => {
            if(res.data.status === 200) {
                localStorage.setItem('setPage', skip + 1)
                dispatch({
                    type: SUCCESS_GET_LEADERBOARD,
                    leaderboardData: skip > 0 ? [...leaderboardData, ...res.data.LeaderboardData] : res.data.LeaderboardData,
                    userScore: res.data.count,
                    size: res.data.size
                })
                setPage((prev) => prev + 1)
                localStorage.setItem('fetchingPosts', false)
            }else{
                dispatch({
                    type: FAILED_GET_LEADERBOARD,
                    payload: res.data.msg
                })
                localStorage.setItem('fetchingPosts', false)
            }
        }).catch((err) => {
            dispatch({
                type: FAILED_GET_LEADERBOARD,
                payload: err.toString()
            })
            localStorage.setItem('fetchingPosts', false)
        })
    }catch(err){
        dispatch({
            type: FAILED_GET_LEADERBOARD,
            payload: err.toString()
        })
    }
}

export const getFreeTableAction = () => async(dispatch) => {
    try{
        dispatch({
            type: REQUEST_GET_FREE_TABLES
        })
        await axios.get(`${BACKEND_URL}/public/getFreeTables`).then((res) => {
            if(res.data.status === 200) {
                dispatch({
                    type: SUCCESS_GET_FREE_TABLES,
                    payload: res.data.tables
                })
            }else{
                dispatch({
                    type: FAILED_GET_FREE_TABLES,
                    payload: res.data.msg
                })
            }
        }).catch((err) => {
            dispatch({
                type: FAILED_GET_FREE_TABLES,
                payload: err.toString()
            })
        })
    }catch(err){
        dispatch({
            type: FAILED_GET_FREE_TABLES,
            payload: err.toString()
        })
    }
}

export const getPublicTablesAction = () => async(dispatch) => {
    try{
        dispatch({
            type: REQUEST_GET_TABLES
        })
        await axios.get(`${BACKEND_URL}/public/getTables`).then((res) => {
            if(res.data.status === 200) {
                dispatch({
                    type: SUCCESS_GET_TABLES,
                    payload: res.data.tables
                })
            }else{
                dispatch({
                    type: FAILED_GET_TABLES,
                    payload: res.data.msg
                })
            }
        }).catch((err) => {
            dispatch({
                type: FAILED_GET_TABLES,
                payload: err.toString()
            })
        })
    }catch(err){
        dispatch({
            type: FAILED_GET_TABLES,
            payload: err.toString()
        })
    }
}

export const getDailyTasks = () => async(dispatch) => {
    try{
        dispatch({
            type: REQUEST_DAILY_TASKS
        })

        await axios.get(`${BACKEND_URL}/public/getDailyTasks`).then((res) => {
            if(res.data.status === 200) {
                dispatch({
                    type: SUCCESS_DAILY_TASKS,
                    payload: res.data.tasks
                })
            }else{
                dispatch({
                    type: FAILED_DAILY_TASKS,
                    payload: res.data.msg
                })
            }
        }).catch((err) => {
            dispatch({
                type: FAILED_DAILY_TASKS,
                payload: err.toString()
            })
        })
    }catch(err){
        dispatch({
            type: FAILED_DAILY_TASKS,
            payload: err.toString()
        })
    }
}