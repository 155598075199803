import { useDispatch, useSelector } from 'react-redux'
import AddAdmin from '../../shared/modals/AddAdmin/AddAdmin'
import AdminCard from './AdminCard'
import styles from './Admins.module.css'
import React, { useEffect, useState } from 'react'
import { getAllAdmins } from '../../../redux/actions/AdminAction'
import AdminCardSkelton from '../../skeltons/AdminCardSkelton/AdminCardSkelton'

const Admins = ({communitySlug, communityId}) => {
  const dispatch = useDispatch()

  const [openModal, setOpenModal] = useState(false)
  const [hover, setHover] = useState(false)
  const {admins, loading} = useSelector((state) => state.getAllAdminReducer)

  useEffect(() => {
    if(admins?.length === 0) dispatch(getAllAdmins(communityId))
  },[dispatch, communityId]);

  // if(loading){
  //   <div className={styles.container}>
  //     <Loader />
  //   </div>
  // }

  return (
    <div className={styles.container}>
      <div className={styles.page_header}>
        <h1 className={styles.heading}>{`Admins(${admins?.length})`}</h1>

        <button
          className={`${styles.addAdminBtn} primary-custom-button`}
          onClick={() => {setOpenModal(true)}}
          onMouseEnter={() => {setHover(true)}}
          onMouseLeave={() => {setHover(false)}}
        >
          <button className='primary-custom-button-inner flex items-center gap-1'>
            <Add fill={hover ? "#D93D48" : "white"} />{'   '}Add Admin
          </button>
        </button>
      </div>

      <div className={styles.wrap_admins}>
        {loading
        ? Array(3).fill(0, 0).map((admin, key) => <AdminCardSkelton key={key} />)
        : admins.map((admin, key) => (
          <AdminCard communityId={communityId} communitySlug={communitySlug} key={key} data={admin} />
        ))}
      </div>

      <AddAdmin communityId={communityId} communitySlug={communitySlug} openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  )
}

export default Admins

const Add = ({fill}) => {
  return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 5V19M5 12H19" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  )
}