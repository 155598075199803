import { Dialog, Transition } from '@headlessui/react'
import styles from './NavigateTableModal.module.css'
import React, { Fragment, useRef, useState } from 'react'
import Opponent from '../../../Players/Opponent/Opponent'
// import { Cards } from '../../../../tablesData'
import { useEffect } from 'react'
import { gsap } from 'gsap'
import { useNavigate } from 'react-router-dom'

const NavigateTableModal = ({openModal, setOpenModal, navigatingTimer, tableNumber, leaderboardData}) => {
    const cancelButtonRef = useRef(null)
    const [wid, setWid] = useState(100)

    useEffect(() => {
        if (navigatingTimer > 0) {
            const timer = (navigatingTimer - new Date().getTime())/1000

            gsap.context(() => {
                gsap.set("#keepPlayingTimer", { width: '100%' })

                gsap
                .timeline()
                .to("#keepPlayingTimer", { width: '0%', duration: timer - 2, ease: 'linear' }, 0)
            });

            setTimeout(() => {
                // setOpenModal(false)
            }, timer*1000)
        }
    }, [ navigatingTimer ])

    useEffect(() => {
        gsap.ticker.lagSmoothing(false);
    }, [])

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0 overflow-hidden'
                initialFocus={cancelButtonRef}
                onClose={() => 0}
            >
                <div className='h-screen px-4 pb-20 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[rgba(0,0,0,0.3)] bg-opacity-75 backdrop-blur-[10px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div style={{
                            borderRadius: '20px',
                            border: '1px solid rgba(255, 0, 0, 0.40)',
                            background: 'rgba(0, 0, 0, 0.90)',
                            backdropFilter: 'blur(8px)',
                            maxWidth: '530px'
                        }} className={`${styles.modal_background} inline-block bg-transparent rounded-lg text-left transform transition-all align-middle w-full`}>
                            <div
                                style={{ padding: '16px 18px' }}
                                className={`flex justify-center w-full items-center text-white h-full flex-col`}
                            >
                                <h1 style={{ fontFamily: 'Orbitron', fontWeight: 600, fontSize: '24px', width: '100%', textAlign: 'left', marginBottom: '12px' }}>Switching Table</h1>

                                <div style={{ width: '100%', height: '1px', background: 'rgba(255, 0, 0, 0.40)', marginBottom: '24px' }}></div>
                                <div style={{
                                    width: '440px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '146px',
                                    borderRadius: '9px',
                                    marginBottom: '20px',
                                    background: 'linear-gradient(95deg, rgba(255, 0, 0, 0.20) 2.22%, rgba(255, 0, 0, 0.00) 103.38%)'
                                }}>
                                    <img src="https://res.cloudinary.com/kuramaverse/image/upload/v1695677521/Group_1000002692_s9aub8.svg" />
                                </div>
                                <h1 style={{ fontFamily: 'Oxanium', fontWeight: 600, fontSize: '20px', marginBottom: '6px' }}>You are now shifted to Table {tableNumber}</h1>
                                {(leaderboardData?.length > 0) && <div style={{ display: 'flex', alignItems: 'center', gap: '8px', borderRadius: '4px', background: '#8C0000', padding: '4px 12px 2px', marginBottom: '20px' }}>
                                    <div style={{ width: '11px', height: '11px', background: '#00C514', borderRadius: '100%', marginTop: '-2px' }}></div>
                                    <h1 style={{ fontFamily: 'Oxanium', fontWeight: 600, fontSize: '14px' }}>{leaderboardData?.length} Players remaining</h1>
                                </div>}
                                <div className={styles.button_holder}>
                                    <button className={styles.playing_btn}>
                                        Navigating...
                                        <div id="keepPlayingTimer" className={styles.back_flow_color} style={{width: `${wid}%`}} />
                                        {/* <div className={styles.back_flow_color} style={{width: `${((Date.now() - userInfo?.lastClaimed) / 14400000) * 100}%`}} /> */}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default (NavigateTableModal)
