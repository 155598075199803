import React from 'react'
import styles from './CreateGame.module.css'
import Input from '../Input/Input'
import { useSelector } from 'react-redux'

const CreateGame = ({setTabNumber, info, setInfo, createCommunity}) => {
    const {loading} = useSelector((state) => state.createCommunityReducer)

    return <div className={styles.wrapper}>
    <h1>Create Game</h1>

    <div className={styles.form}>
        <Input label={'Table name'} value={info.tableName} onChange={(e) => {setInfo((state) => ({...state, tableName: e}))}} />
        <Input label={'Table mascot'} upload value={info.tableMascott} onChange={(e) => {setInfo((state) => ({...state, tableMascott: e}))}} />
        <Input label={'minimum bid'} value={info.minimumBid} onChange={(e) => {setInfo((state) => ({...state, minimumBid: e}))}} />
        <Input label={'maximum bid'} value={info.maximumBid} onChange={(e) => {setInfo((state) => ({...state, maximumBid: e}))}} />
        <Input label={'entry fees'} value={info.entryFees} onChange={(e) => {setInfo((state) => ({...state, entryFees: e}))}} />
        <Input label={'max players'} value={info.maxPlayers} onChange={(e) => {setInfo((state) => ({...state, maxPlayers: e}))}} />
    </div>

    <button className={styles.btn} onClick={() => createCommunity()} disabled={loading || !info.tableName || !info.tableMascott || !info.minimumBid || !info.maximumBid || !info.entryFees || !info.maxPlayers} >{loading ? 'Creating...' : 'Create Game'}</button>
</div>
}

export default CreateGame