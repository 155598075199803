import React, { useState } from "react";
import "./eventCard.css";
import { useCountdown } from "../../../redux/actions/otherAction";
import { Link } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import DeleteEventCard from "../../shared/modals/DeleteEventCard/DeleteEventCard";

const EventCard = ({ communityName, tournament, live, disabled, chipImg, isAdmin }) => {
  const {days, hours, minutes, seconds} = useCountdown(tournament?.time)

  const[openModal, setOpenModal] = useState(false)

  // function subofArray(x, y) {
  //   console.log(x, y)
  //   return x + parseInt(y?.prizeAmount);
  // }
  // console.log(tournament?.prizes?.reduce(subofArray, 0))

  return (
    <Link className="eventcard-btm-layer"
      to={disabled ? '' : `/tournament/${tournament?._id}`}
      state={tournament}
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),${tournament?.tableBackground?.backgroundColor}`,
        minWidth: disabled ? 'auto' : ''
      }}
    >
      <div className="eventcard-middle-layer" style={{background: tournament?.tableBackground?.backgroundColor}}>
        <div className="eventcard-top-layer">
          <div className="eventcard-top-layer-img-div">
            <img src={tournament?.cardPreview  || (typeof tournament?.bannerImg === 'object' && tournament?.bannerImg ? URL.createObjectURL(tournament?.bannerImg) : tournament?.bannerImg)} alt="" className="eventcard-top-layer-img" />

            {isAdmin && <button onClick={(e) => {e?.preventDefault(); e?.stopPropagation(); setOpenModal(true)}} className="eventcard-delete-button" style={tournament?.entryFees > 0 ? {background: tournament?.tableBackground?.backgroundColor, padding: '5px 14px'} : {background: tournament?.tableBackground?.backgroundColor, padding: '5px 14px'}}>
              <Delete />
            </button>}

            <div className="eventcard-coins-amount-wrapper" style={tournament?.entryFees > 0 ? {background: tournament?.tableBackground?.backgroundColor} : {background: tournament?.tableBackground?.backgroundColor, padding: '5px 14px'}}>
              {tournament?.entryFees > 0 && <img src={tournament?.chipImg || (tournament?.chipName === 'Play Chips' || tournament?.chipName === '') ? 'https://res.cloudinary.com/kuramaverse/image/upload/v1692310719/ArcadeVerse%20Tables/Play%20Chip%202.svg' : chipImg || 'https://res.cloudinary.com/kuramaverse/image/upload/v1692310719/ArcadeVerse%20Tables/Play%20Chip%202.svg'} alt="" style={{width: '18px'}} />}
              {tournament?.entryFees > 0 ? tournament?.entryFees : 'FREE'}
            </div>

            {live && <div className="eventcard-timer-wrapper-live">
              <div className="eventcard-time-live animate-pulse"></div>
              <span>LIVE</span>
            </div>}

            {!live && tournament?.time && <div className="eventcard-timer-wrapper" style={{background: `${tournament?.tableBackground?.backgroundColor}4d`}}>
              <span>starts in:</span>

              <div className="event-card-timer-time">
                {days !== 0 && <>
                  <span className="event-card-timer-time-big">{days}</span>
                  <span className="event-card-timer-time-small">D</span>
                  <span className="min-w-[4px]"></span>
                  <span className="event-card-timer-time-big">:</span>
                  <span className="min-w-[4px]"></span>
                </>}
                <span className="event-card-timer-time-big">{hours}</span>
                <span className="event-card-timer-time-small">H</span>
                <span className="min-w-[4px]"></span>
                <span className="event-card-timer-time-big">:</span>
                <span className="min-w-[4px]"></span>
                <span className="event-card-timer-time-big">{minutes}</span>
                <span className="event-card-timer-time-small">M</span>
                {days === 0 && <>
                  <span className="min-w-[4px]"></span>
                  <span className="event-card-timer-time-big">:</span>
                  <span className="min-w-[4px]"></span>
                  <span className="event-card-timer-time-big">{seconds}</span>
                  <span className="event-card-timer-time-small">S</span>
                </>}
              </div>
            </div>}
          </div>
          <div className="eventcard-top-layer-text">
            <div className="eventcard-top-layer-text-title">{tournament?.tournamentName ? (tournament?.tournamentName?.length > 28 ? tournament?.tournamentName?.substr(0, 25) + '...' : tournament?.tournamentName) : tournament?.name ? (tournament?.name?.length > 28 ? tournament?.name.substr(0, 25) + '...' : tournament?.name) : ''}</div>
            {<div className="eventcard-top-layer-text-subtitle">By {tournament?.communityName || communityName}</div>}
          </div>
          <div className="eventcard-top-layer-tags">
            {tournament?.discordRoleRequired ?
              <div className="eventcard-top-layer-tag">{`Member Ony`}</div>
              : tournament?.nftsRequired ?
              <div className="eventcard-top-layer-tag">{`Holder Only`}</div>
              : ''
            }
            {tournament?.tournamentType && <div className="eventcard-top-layer-tag">{tournament?.tournamentType}</div>}
            {tournament?.tournamentSpeed && <div className="eventcard-top-layer-tag">{tournament?.tournamentSpeed}</div>}
            {tournament?.prizes?.reduce((x, y) => x + parseInt(y.prizeAmount) || 0, 0) > 0 && <div className="eventcard-top-layer-tag">{`Prize Worth $${tournament?.prizes?.reduce((x, y) => x + parseInt(y.prizeAmount) || 0, 0)}`}</div>}
          </div>
        </div>
      </div>

      {openModal && <DeleteEventCard openModal={openModal} setOpenModal={setOpenModal} communitySlug={tournament?.slug} tournamentId={tournament?._id} />}
    </Link>
  );
};

export default EventCard;
