import React, { useEffect, useState } from 'react'
import communityImg from '../../Assets/Images/community.png'
import One from '../../Assets/svgs/One.svg'
import Two from '../../Assets/svgs/Two.svg'
import Three from '../../Assets/svgs/Three.svg'
import Four from '../../Assets/svgs/Four.svg'
import Five from '../../Assets/svgs/Five.svg'
import Six from '../../Assets/svgs/Six.svg'
import Seven from '../../Assets/svgs/Seven.svg'
import Eight from '../../Assets/svgs/Eight.svg'
import Nine from '../../Assets/svgs/Nine.svg'
import Ten from '../../Assets/svgs/Ten.svg'
import styles from './CommunitiesPage.module.css'
import CommunityCard from '../../Components/CommunitiesPage/CommunityCard/CommunityCard'
import { useDispatch, useSelector } from 'react-redux'
import { getCommunities } from '../../redux/actions/CommunityAction'
import CommunityCardSkelton from '../../Components/skeltons/CommunityCardSkelton/CommunityCardSkelton'
import { useNavigate } from 'react-router-dom'
import { DualArrow } from '../../Assets/svgs/DualArrow'
import LoginModal from '../../Components/shared/modals/LoginModal/LoginModal'
import PlayGameModal from '../../Components/shared/modals/PlayGameModal/PlayGameModal'
import CreateProfileModal from '../../Components/shared/modals/CreateProfile/CreateProfile'
import { fetchTodayMostPlayedCommunities, fetchMostRewardingTable } from '../../redux/actions/otherAction'
import CreateGuestModal from './../../Components/shared/modals/CreateGuestModal/CreateGuestModal';

// banner_community
// community_center

const CommunitiesPage = ({socketObj}) => {
    const socket = socketObj
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loading, data, newOnboarding} = useSelector((state) => state.getCommunityReducer)
    const {isLogin, accessToken, verificationHash} = useSelector((state) => state.userLoginReducer)
    const {fetched: rewardingFetched, loading: commLoading, table: rewardingTable} = useSelector((state) => state.mostRewardedCommunitiesReducer)
    const {fetched: playedFetched, loading: loadMostPlayed, communities: mostPlayed} = useSelector((state) => state.todayMostPlayedCommunitiesReducer)

    const [openModal, setOpenModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openGuestModal, setOpenGuestModal] = useState(false);
    const [openTableModal, setOpenTableModal] = useState(false);
    const [hovered, setHovered] = useState(false)
    const [checkOnlyWallet, setCheckOnlyWallet] = useState(false);
    const [checkOnlyDiscordConnect, setCheckOnlyDiscordConnect] = useState(false);
    const [searchText, setSearchText] = useState('')

    const connectBtn = () =>{
        setOpenTableModal(false)
        setOpenModal(true)
    }

    const handlePlayGame = () => {
        if(isLogin)
        setOpenTableModal(true)
        else
        setOpenModal(true)
    }

    const joinTable = (chipValue, tableId) => {
            socket.emit("joinTable", {
            tableId: tableId || rewardingTable._id,
            verificationHash,
            accessToken,
            socketId: socket.id,
            chipValue
        });
    }

    const joinSpectatorTable = () => {
        socket.emit("joinTable", {
            tableId: rewardingTable._id,
            verificationHash,
            accessToken,
            socketId: socket.id,
            isSpectator: true
        });
    }

    useEffect(() => {
        if(!playedFetched) dispatch(fetchTodayMostPlayedCommunities())
        if(!rewardingFetched) dispatch(fetchMostRewardingTable())
    },[dispatch, playedFetched, rewardingFetched])

    // useEffect(() => {
    //     const fetchTodaysMostPlayedCommunities = async () => {
    //         await axios.get(`${BACKEND_URL}/public/todayMostPlayedCommunities`)
    //         .then((res) => {
    //             setMostPlayed(res.data.communities)
    //         })
    //         .catch(err => console.log(err))
    //     }

    //     const fetchMostRewardingTable = async () => {
    //         await axios.get(`${BACKEND_URL}/public/mostRewardingTable`)
    //         .then((res) => {
    //             setRewardingTable(res.data.table)
    //         })
    //         .catch(err => console.log(err))
    //     }

    //     fetchTodaysMostPlayedCommunities()
    //     fetchMostRewardingTable()
    // },[])

    useEffect(() => {
        dispatch(getCommunities())
    }, [dispatch])

    useEffect(() => {
        window.scroll(0, 0)
    },[])

    return <div className={styles.community_page} id='scroll'>
        <div className={styles.top_header}>
            <div className={styles.top_text_wrappper}>
                <div className={styles.top_text_back}>
                    <h1 className='text-center'>Play with your community</h1>
                </div>

                <div className={styles.search_input_cntnr}>
                    <input placeholder='Search' type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                    <span>
                        <SearchSvg />
                    </span>
                </div>
            </div>
        </div>

        <div className={styles.main_wrapper}>
            {/* {isLogin && userInfo?.isAdmin && <div className={styles.top}>
                <h1>Communities</h1>

                <Link to='/createComunity'>
                    <button>Create</button>
                </Link>
            </div>} */}

            {searchText?.length === 0 && <div className={styles.main_info__}>
                <div className={styles.top_most_played}>
                    <div className={styles.top_txt}>Today's Most Played</div>

                    <div className={styles.most_played_main_wrpr}>
                    {loadMostPlayed
                    ? Array(3).fill(0, 0)?.map((_c, key) => {
                        const Icon = SvgData[key].Icon
                        return <div className={styles.wrappr_card_holder} key={key}>
                            <div className={styles.num_big}>
                                <img src={Icon} alt="" />
                            </div>

                            <div className={`animate-pulse ${styles.inside_wrpr_holder_otr_loader}`}>
                            </div>
                        </div>
                    })
                    : mostPlayed?.map((community, key) => {
                        const Icon = SvgData[key].Icon
                        return <div className={styles.wrappr_card_holder} key={key}>
                            <div className={styles.num_big}>
                                <img src={Icon} alt="" />
                            </div>

                            <div className={styles.inside_wrpr_holder_otr} onClick={() => {
                                navigate(`/${community?.slug}`)
                            }}>
                                <div className={styles.inside_wrpr_holder} style={{cursor: 'pointer'}}>
                                    <figure
                                        className={styles.holder_most_played_card}
                                    >
                                        <img style={{ objectFit: 'cover', width: '100%', height: '63.5px' }} src={community?.backgroundImage || communityImg} alt="" />
                                    </figure>

                                    <div className={styles.text_wrpper_inside_card}>
                                        <h1>{community?.communityName}</h1>
                                        {/* Arrow */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                    </div>
                </div>

                <div className={styles.most_rewarding}
                    onMouseEnter={() => {if(!commLoading)setHovered(true)}}
                    onMouseLeave={() => setHovered(false)}
                >
                    <div className={styles.top_txt}>Most Rewarding Table</div>
                    <div className={styles.rewarding_btm} onClick={handlePlayGame}>
                        <figure className={styles.rewarding_fig_wrapper}>
                        {commLoading ? <div className={`animate-pulse ${styles.loader_image}`} />
                        : <img src={rewardingTable?.cardPreview} alt="" className='w-full h-full object-cover' />}
                        </figure>
                        <div className={styles.wrapper_rewarding}>
                        {commLoading ? <div className={`animate-pulse ${styles.rewarding_head_loader}`} />
                        : <h2 className={styles.rewarding_head}>
                            {rewardingTable?.tableName}
                        </h2>}

                            <div className={styles.max_winning_wrapper}>
                                <div className={styles.max_winning_cnt}>
                                    <h2>Max Winnings</h2>
                                    <div className='flex items-center gap-3'>
                                        {commLoading ? <div className={`animate-pulse ${styles.loader_chip_image}`} />
                                        : <img src={rewardingTable?.chipImg} alt="" className='w-10 h-10'/>}
                                        {commLoading ? <div className={`animate-pulse ${styles.rewarding_head_loader}`} />
                                        : <h3>20K</h3>}
                                    </div>
                                </div>

                                {/* Arrow */}
                                {!commLoading && <button>
                                    <DualArrow fill={hovered ? '#F00' : '#FFF'} />
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {(loading || (newOnboarding?.length > 0)) && <div className={styles.type_wrapper}>
                <h1>New onboardings</h1>

                <div className={styles.bottom}>
                    {loading?
                        Array(3).fill(0, 0).map((community, key) => (
                            <CommunityCardSkelton key={key} />
                        ))
                    : data?.length === 0
                    ? <pre>No Community Found...</pre>
                    : newOnboarding?.filter((x) => {
                            if(searchText?.length > 0)
                                return x?.communityName?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1
                            else return true
                        })?.map((k, i) => (
                        <CommunityCard data={k} key={i} color={'#479BFF'} id={i} />
                    ))}
                </div>
            </div>}

            {/* <div className={styles.type_wrapper}>
                <h1>Top communities</h1>

                <div className={styles.bottom}>
                    {loading?
                        Array(3).fill(0, 0).map((community, key) => (
                            <CommunityCardSkelton key={key} />
                        ))
                    : data?.length === 0
                    ? <pre>No Community Found...</pre>
                    : data?.map((k, i) => (
                        <CommunityCard data={k} key={i} color={'#479BFF'} id={i} />
                    ))}
                </div>
            </div> */}

            <div className={styles.type_wrapper}>
                <div className={styles.flex__wrapp}>
                    <h1>All communities</h1>
                </div>

                <div className={styles.bottom}>
                    {loading?
                        Array(3).fill(0, 0).map((community, key) => (
                            <CommunityCardSkelton key={key} />
                        ))
                    : data?.length === 0
                    ? <pre>No Community Found...</pre>
                    : data?.filter((x) => {
                        if(searchText?.length > 0){
                            return x?.communityName?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1
                        }
                        else return true
                    })?.map((k, i) => (
                        <CommunityCard data={k} key={i} color={'#479BFF'} id={i} />
                    ))}
                </div>
            </div>
        </div>

        {openGuestModal && <CreateGuestModal setOpenConnectModal={setOpenModal} backUrl={`/pokertable/${rewardingTable?._id}`} openModal={openGuestModal} setOpenModal={setOpenGuestModal} setOpenCreateModal={setOpenCreateModal} />}
        <LoginModal checkOnlyDiscordConnect={checkOnlyDiscordConnect} checkOnlyWallet={checkOnlyWallet} openModal={openModal} setOpenModal={() => {setOpenModal(false)}} setOpenProfileModal={setOpenCreateModal} backUrl={`/pokertable/${rewardingTable?._id}`}/>
        {openTableModal && <PlayGameModal connectBtn={connectBtn} setCheckOnlyDiscordConnect={setCheckOnlyDiscordConnect} setCheckOnlyWallet={setCheckOnlyWallet} openModal={openTableModal} setOpenModal={() => setOpenTableModal(false)} table={rewardingTable} joinTable={joinTable} joinSpectatorTable={joinSpectatorTable} />}
        {openCreateModal && <CreateProfileModal openModal={openCreateModal} setOpenModal={setOpenCreateModal} />}
    </div>
}

export default CommunitiesPage

// const SearchSvg = () => {
//     return(<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M21 21.5098L17.5001 18.0098M20 12.0098C20 16.7042 16.1944 20.5098 11.5 20.5098C6.80558 20.5098 3 16.7042 3 12.0098C3 7.31534 6.80558 3.50977 11.5 3.50977C16.1944 3.50977 20 7.31534 20 12.0098Z" stroke="#A36969" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//     </svg>
//     )
// }


const SvgData = [
    {
        Icon: One,
    },
    {
        Icon: Two,
    },
    {
        Icon: Three,
    },
    {
        Icon: Four,
    },
    {
        Icon: Five,
    },
    {
        Icon: Six,
    },
    {
        Icon: Seven,
    },
    {
        Icon: Eight,
    },
    {
        Icon: Nine,
    },
    {
        Icon: Ten,
    },
]

const SearchSvg = () => {
    return(
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.7142 18.7132L15.7143 15.7132M17.8571 10.5703C17.8571 14.5941 14.5951 17.856 10.5714 17.856C6.54757 17.856 3.28564 14.5941 3.28564 10.5703C3.28564 6.54652 6.54757 3.2846 10.5714 3.2846C14.5951 3.2846 17.8571 6.54652 17.8571 10.5703Z" stroke="#930000" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}