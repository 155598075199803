export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const WALLET_NOT_FOUND = 'WALLET_NOT_FOUND'
export const USER_RESETTING_LOGIN = 'USER_RESETTING_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'

export const CONNECT_DISCORD_REQUEST = 'CONNECT_DISCORD_REQUEST'
export const CONNECT_DISCORD_PARTIAL = 'CONNECT_DISCORD_PARTIAL'
export const CONNECT_DISCORD_COMPLETED = 'CONNECT_DISCORD_COMPLETED'
export const CONNECT_DISCORD_FAILED = 'CONNECT_DISCORD_FAILED'

export const DELETE_DISCORD_REQUEST = 'DELETE_DISCORD_REQUEST'
export const DELETE_DISCORD_COMPLETED = 'DELETE_DISCORD_COMPLETED'
export const DELETE_DISCORD_FAILED = 'DELETE_DISCORD_FAILED'

export const ALL_CHAT_REQUEST = 'ALL_CHAT_REQUEST'
export const ALL_CHAT_SUCCESS = 'ALL_CHAT_SUCCESS'
export const ALL_CHAT_FAILED = 'ALL_CHAT_FAILED'