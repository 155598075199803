import React from 'react'
import styles from './CommunityCard.module.css'
import { Link } from 'react-router-dom'

const CommunityCard = ({data, id, color}) => {
    return (
    <Link to={`/${data?.slug}`}
        className={`community___wrapper ${styles.wrapper}`}
        style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${data?.theme?.backgroundColor || color}`,
            borderColor: data?.theme?.backgroundColor
        }}
        id={`colcard-btm-layer-${id}`}
    >
        <div className={styles.inner_wrapper} style={{
            border: `2px solid ${data?.theme?.backgroundColor}`
        }}>
            <figure className={styles.image_wrapper}>
                <img src={data?.pageBanners[data?.pageBannerId - 1] || data?.backgroundImage} alt="" className='w-full h-full object-cover' />
            </figure>

            <div className={styles.content_wrapper_outer} style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${data?.theme?.backgroundColor || color}`,
                borderColor: data?.theme?.backgroundColor
            }}>
                <div className={styles.content_wrapper} style={{background: `linear-gradient(289.86deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 63.27%), ${data?.theme?.backgroundColor || color}`}}>
                    <div className={styles.top_dsg}>
                        <figure className={`community___figure_holder ${styles.figure_holder}`}
                            style={{
                                border: `${data?.theme?.backgroundColor} 3.5px solid`
                            }}
                        >
                            <img src={data?.shortImage} alt="" className='w-full h-full object-cover' />
                        </figure>

                        <div className={styles.online_holder}>
                            {/* <div className={styles.online_prsn}>
                                <div className={styles.online_}></div>
                                <div className={styles.online_}></div>
                                <div className={styles.online_}></div>
                            </div> */}
                            {/* <span className={styles.online_cnt}>{data?.activePlayers || 0} Online</span> */}
                            <span style={{ height: '20px' }} className={styles.online_cnt}></span>
                        </div>
                    </div>

                    <div style={{ marginTop: '-3px' }} className={styles.med_dsg}>
                        <h1 className={styles.heading}>{data?.communityName}</h1>
                        <p style={{ marginTop: '5px', marginBottom: '1px' }} className={styles.active_table}>{data?.activeTables} ACTIVE TABLES</p>
                    </div>

                    <div className={styles.btm_dsg}>
                        <div className={styles.earn_coins_outer}>
                            <div className={styles.earn_coins_inner}>
                                <img src={data?.chipImg || "https://res.cloudinary.com/kuramaverse/image/upload/v1692310719/ArcadeVerse%20Tables/Play%20Chip%202.svg"} alt="" className='w-5 h-5'/>
                                <span>{data?.chipName || "Play Chips"}</span>
                            </div>
                        </div>
                        {/* <div className={styles.play_coins_outer}>
                            <div className={styles.play_coins_inner}>Play Chips</div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </Link>
    )
}

export default React.memo(CommunityCard)