import React from "react";
import "./EventCardSkelton.css";
import { Link } from "react-router-dom";

const EventCardSkelton = () => {
  return (
    <Link className="eventcard-btm-layer-skelton animate-pulse">
      <div className="eventcard-middle-layer-skelton animate-pulse">
        <div className="eventcard-top-layer">
          <div className="eventcard-top-layer-img-div-skelton">
            <div className="eventcard-top-layer-img-skelton animate-pulse" />
          </div>

          <div className="eventcard-top-layer-tags-skelton">
            <div style={{ width: '260px', height: '25px' }} className="eventcard-top-layer-tag-skelton animate-pulse"></div>
          </div>

          <div className="eventcard-top-layer-tags-skelton">
            <div style={{ width: '200px', height: '15px' }} className="eventcard-top-layer-tag-skelton animate-pulse"></div>
          </div>

          <div className="eventcard-top-layer-tags-skelton">
            <div className="eventcard-top-layer-tag-skelton animate-pulse"></div>
            <div className="eventcard-top-layer-tag-skelton animate-pulse"></div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EventCardSkelton;
