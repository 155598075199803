import styles from './PlayGameModal.module.css'
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
// import ModalBackgroundSvg  from './../../../../Assets/svgs/modal_background.svg';
import axios from 'axios';
import { BACKEND_URL } from './../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { activateTableId } from '../../../../redux/actions/otherAction';
import { updateLocalUserInfo } from './../../../../redux/actions/userAction';
import { SwipeableDrawer } from '@mui/material';
import { Close } from '@mui/icons-material';

const PlayGameModal = ({openModal, setCheckOnlyDiscordConnect, setCheckOnlyWallet, setOpenModal, table, navigationOff=false, validate, connectBtn, joinSpectatorTable, joinTable}) => {
    const chipType = table?.chipName === 'Play Chips' ? 1  : 2
    const cancelButtonRef = useRef(null)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const [value, setValue] = useState(table?.minBuyIn || 0)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const {userInfo, accessToken, verificationHash, isLogin} = useSelector((state) => state.userLoginReducer)
    const {allChips} = useSelector((state) => state.getUserCoinsForArcadeVerseReducer)

    useEffect(() => {
        // if(table?.discordRoleRequired && !userInfo?.isDiscordConnected){
        //     setCheckOnlyDiscordConnect(true)
        // }

        if(table?.nftsRequired && !userInfo?.walletConnected){
            setCheckOnlyWallet(true)
        }
    },[table, userInfo, setCheckOnlyDiscordConnect, setCheckOnlyWallet])

    useEffect(() => {
        const check = () => {
            try{
                const chips = (chipType !== 1 ? getCurrentChip?.value : userInfo?.playChips)
                // const range = document.getElementById('range')
                // range.style.backgroundSize = (value - range.min) * 100 / (range.max - range.min) + '% 100%'

                // if (Number(value) > Number(range.max)) {
                //     range.style.backgroundSize = 100 + '% 100%'
                // }

                // if (Number(range.max) === 0) {
                //     range.style.backgroundSize = 0 + '% 100%'
                // }
                if(isLogin && (chips !== undefined) && (chips < value)){
                    setError('Not enough chips. You can get more chips from exchange')
                    return
                } else {
                    setError(null)
                }
            } catch(err) {
                console.log(err)
            }
        }
        setTimeout(() => {
            check()
        },100)
    },[userInfo, isLogin])

    const handleSpectate = () => {
        dispatch(activateTableId(table?._id))
        if(!navigationOff) navigate(`/pokertable/${table?._id}`)
        else setOpenModal()
        setError(null)
        joinSpectatorTable()
    }

    // const getCurrentChip = () => {
    const arr = allChips.filter((chip) => chip?.slug === table?.slug)
    const getCurrentChip = arr?.length > 0 ? arr[0] : {}
    // }

    const handlePlay = async(e) => {
        const chips = (chipType !== 1 ? getCurrentChip?.value : userInfo?.playChips)

        if(chips < value){
            setError('Not enough chips. You can get more chips from exchange')
            return
        }
        setLoading(true)

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + accessToken,
            },
        }

        // if(table?.nftsRequired){
        await axios.post(`${BACKEND_URL}/community/playGame`,
        {
            verificationHash,
            tableID: table._id,
            ticketPrice: value
        },
        config).then((res) => {
            if(res.data?.status === 200){
                dispatch(activateTableId(res.data?.tableId))
                if(!navigationOff) navigate(`/pokertable/${res.data?.tableId}`, { state: { table, tournament: res.data?.tournamentId ? true : false } })
                else setOpenModal()
                setError(null)
                joinTable(value, res.data?.tableId)
            }else{
                setError(res.data?.msg)
                if (res.data?.msg.includes('Verification Hash')) {
                    dispatch(updateLocalUserInfo())
                }
            }
            setLoading(false)
        }).catch(err => {
            setError(err?.toString())
            console.log(err)
            setLoading(false)
        })
        // }else{
        //     dispatch(activateTableId(table?._id))
        //     setLoading(false)
        //     if(!navigationOff) navigate(`/pokertable/${table?._id}`)
        //     else setOpenModal()
        // }
    }

    if(window.innerWidth > 650)
    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-20 inset-0 overflow-y-auto'
                initialFocus={cancelButtonRef}
                onClose={() => {
                    if(!navigationOff || (isLogin && validate))
                        setOpenModal()
                }}
            >
                <div className='h-screen px-4 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 transition-opacity' style={{
                            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%)`
                        }} />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block bg-transparent rounded-lg text-left transform transition-all align-middle max-w-[520px] w-full'>
                            <div
                                className={`${styles.modal_background} text-white h-full`}
                            >
                                <>
                                    <div className={styles.background_black}></div>
                                    <div className={styles.profile_holder}>
                                        <img style={{ background: 'black' }} src={table?.cardPreview} alt="" className='w-[inherit] h-[inherit] object-contain' />
                                    </div>
                                    <div className={styles.main_holder_content}>
                                        <div className={styles.modal_bkg_svg}>
                                            <img src={'https://res.cloudinary.com/kuramaverse/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1694629721/playmodalimage_yty5tv.webp'} alt="" />
                                        </div>

                                        <div className='w-full flex flex-col items-center gap-5'>
                                            <div className={styles.top_header}>
                                                <button onClick={() => {
                                                    if(!navigationOff || (isLogin && validate))
                                                        setOpenModal(false)
                                                }}>
                                                    {/* <Close /> */}
                                                </button>
                                            </div>

                                            <div className='flex flex-col gap-1 items-center w-full'>
                                                <h1 className={styles.heading}>{table?.tableName}</h1>
                                                <h2 className={styles.subHeading}>{table?.communityName}</h2>
                                            </div>

                                            <div className={styles.header_info}>
                                                {table?.nftsRequired && <div className={styles.holder_only}>
                                                    <HoldersSvg />
                                                    <span>Holders Only</span>
                                                </div>}
                                                <div className={styles.live_playing}>
                                                    <div className='bg-[#14FF00] animate-pulse w-[10px] h-[10px] rounded-full'></div>
                                                    <span>{table?.activePlayers > 0 ? `${table?.activePlayers} Playing` : `Waiting for players`}</span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className={styles.bottom_info_section} style={{justifyContent: error ? 'space-between' : ''}}>
                                            <div className={styles.divider}></div>

                                            <div className={styles.align_in_line}>
                                                <div className={styles.section_cntnt}>
                                                    <h1>chips</h1>
                                                    <div className={styles.chipImgHolderOuter}>
                                                        <div className={styles.chipImgHolder}>
                                                            <img src={table?.chipImg} alt='' className={styles.chipImgMain} />
                                                            <h2 style={{ marginTop: '2px' }}>{table?.chipName}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.section_cntnt}>
                                                    <h1>Min. Bid</h1>
                                                    <h2>{table?.minBid*2}</h2>
                                                </div>
                                                <div className={styles.section_cntnt}>
                                                    <h1>Min. Buy In</h1>
                                                    <h2>{table?.minBuyIn}</h2>
                                                </div>
                                            </div>

                                            {(table?.nftsRequired || table?.roleRequired) && <div className={styles.align_in_line}>
                                                {table?.nftsRequired && <div className={styles.section_cntnt}>
                                                    <h1>Nfts Required</h1>
                                                    <h2>{table?.nftsRequired || 0}</h2>
                                                </div>}
                                                {table?.roleRequired && <div className={styles.section_cntnt}>
                                                    <h1>Role Required</h1>
                                                    <h2>{table?.roleRequired?.name}</h2>
                                                </div>}
                                            </div>}

                                            <div className={styles.align_in_line}>
                                                <div className={styles.section_cntnt} style={{width: '100%'}}>
                                                    <h1>Set Buy In amount</h1>
                                                    {/* <h2>7</h2> */}
                                                    <div className={styles.slider_container}>
                                                        <input
                                                            type="text"
                                                            className={styles.buyInput}
                                                            value={value}
                                                            min={table?.minBuyIn}
                                                            max={(chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0}
                                                            onChange={(e) => {
                                                                if (e.target.value > ((chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0)) {
                                                                    setValue((chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0)
                                                                    setError(null)

                                                                    // let target = document.getElementById('range')
                                                                    // const min = target.min
                                                                    // const max = target.max

                                                                    // target.style.backgroundSize = (((chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0) - min) * 100 / (max - min) + '% 100%'
                                                                } else {
                                                                    setValue(!e.target.value || parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value));
                                                                    setError(null)

                                                                    // let target = document.getElementById('range')
                                                                    // const min = target.min
                                                                    // const max = target.max
                                                                    // const val = e.target.value

                                                                    // target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
                                                                }
                                                            }}
                                                        />
                                                        <div className='flex items-center w-full gap-0'>
                                                            {/* <input type="range" defaultValue={value} min={0} max={(chipType !== 1 ? getCurrentChip?.value : userInfo?.playChips) || 0} id="range" className={styles.amnt_slider} onChange={handleInputChange} /> */}
                                                            <AmountSlider table={table} getCurrentChip={getCurrentChip} value={value} setValue={setValue} />
                                                            <span className={styles.slider_value}>{(chipType !== 1 ? getCurrentChip?.value : userInfo?.playChips) || ''}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {error && <div className={styles.errorWrapper}>
                                                <Alert />
                                                <span>{error}</span>
                                            </div>}
                                    {/*
                                            Holders Only -> WalletAddress is Required.
                                            If Discord Role Condition -> Discord Connect is Required.
                                        */}
                                            {(!isLogin || (table?.discordRoleRequired && !userInfo?.isDiscordConnected) || (table?.nftsRequired && !userInfo?.walletConnected)) ?
                                                <div className={styles.button_holder}>
                                                    <button className={`primary-custom-button w-full`} disabled={loading} onClick={() => {
                                                        if(isLogin && table?.discordRoleRequired && !userInfo?.isDiscordConnected){
                                                            localStorage.setItem('redirect_url', JSON.stringify(location.pathname))
                                                            window.location.assign(process.env.REACT_APP_DISCORD_URL)
                                                        }
                                                        else connectBtn()
                                                    }}>
                                                        <div className={`primary-custom-button-inner ${styles.play_btn}`}>{isLogin && table?.discordRoleRequired && !userInfo?.isDiscordConnected ? 'Connect Discord' : 'Connect'}</div>
                                                    </button>
                                                </div>
                                            :
                                                <div className={styles.button_holder}>
                                                    <button onClick={handleSpectate} className={`secondary-custom-button`}>
                                                        <div className={`secondary-custom-button-inner ${styles.spectate_btn}`}>Spectate</div>
                                                    </button>
                                                    <button className={`primary-custom-button w-full`} disabled={loading || (parseInt(value) < table?.minBuyIn) || error} onClick={handlePlay}>
                                                        <div className={`primary-custom-button-inner ${styles.play_btn}`}>Play</div>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
    else return <SwipeableDrawer
        anchor={'bottom'}
        open={openModal}
        onClose={() => {
            if(!navigationOff || (isLogin && validate))
                setOpenModal(false)
        }}
        transitionDuration={600}
        onOpen={(e) => setOpenModal(true)}
        className='transition-all ease-in-out'
        PaperProps={{
            sx: {
                backdropFilter: 'blur(0)',
                borderRadius: '0 !important',
                color: 'white',
                // height: '400px',
                width: '100%',
                background: 'transparent',
                // background: 'linear-gradient(167.1deg, #262626 36.57%, #1B1818 94.9%)',
                boxShadow: 'none',
                padding: '0',
                paddingBottom: '0',
            },
        }}
        ModalProps={{
            sx: {
                zIndex: '1280',
            },
        }}
    >
        <div className={styles.modal_background_swipe}>
        <>
            <div className={styles.background_black}></div>
            <div className={styles.profile_holder}>
                <img style={{ background: 'black' }} src={table?.cardPreview} alt="" className='w-[inherit] h-[inherit] object-contain' />
            </div>
            <div className={styles.main_holder_content}>
                <div className={styles.modal_bkg_svg}>
                    <img src={'https://res.cloudinary.com/kuramaverse/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1694629721/playmodalimage_yty5tv.webp'} alt="" />
                </div>

                <div className='w-full flex flex-col items-center gap-5'>
                    <div className={styles.top_header}>
                        <button onClick={() => {
                            if(!navigationOff || (isLogin && validate))
                                setOpenModal(false)
                        }}>
                            {(!navigationOff || (isLogin && validate)) && <Close />}
                        </button>
                    </div>

                    <div className='flex flex-col gap-1 items-center w-full'>
                        <h1 className={styles.heading}>{table?.tableName}</h1>
                        <h2 className={styles.subHeading}>{table?.communityName}</h2>
                    </div>

                    <div className={styles.header_info}>
                        {table?.nftsRequired && <div className={styles.holder_only}>
                            <HoldersSvg />
                            <span>Holders Only</span>
                        </div>}
                        <div className={styles.live_playing}>
                            <div className='bg-[#14FF00] animate-pulse w-[10px] h-[10px] rounded-full'></div>
                            <span>{table?.activePlayers > 0 ? `${table?.activePlayers} Playing` : `Waiting for players`}</span>
                        </div>
                    </div>

                </div>

                <div className={styles.bottom_info_section} style={{justifyContent: error ? 'space-between' : ''}}>
                    <div className={styles.divider}></div>

                    <div className={styles.align_in_line}>
                        <div className={styles.section_cntnt}>
                            <h1>chips</h1>
                            <div className={styles.chipImgHolderOuter}>
                                <div className={styles.chipImgHolder}>
                                    <img src={table?.chipImg} alt='' className={styles.chipImgMain} />
                                    <h2 style={{ marginTop: '2px' }}>{table?.chipName}</h2>
                                </div>
                            </div>
                        </div>
                        <div className={styles.section_cntnt}>
                            <h1>Min. Bid</h1>
                            <h2>{table?.minBid*2}</h2>
                        </div>
                        <div className={styles.section_cntnt}>
                            <h1>Min. Buy In</h1>
                            <h2>{table?.minBuyIn}</h2>
                        </div>
                    </div>

                    {(table?.nftsRequired || table?.roleRequired) && <div className={styles.align_in_line}>
                        {table?.nftsRequired && <div className={styles.section_cntnt}>
                            <h1>Nfts Required</h1>
                            <h2>{table?.nftsRequired || 0}</h2>
                        </div>}
                        {table?.roleRequired && <div className={styles.section_cntnt}>
                            <h1>Role Required</h1>
                            <h2>{table?.roleRequired?.name}</h2>
                        </div>}
                    </div>}

                    <div className={styles.align_in_line}>
                        <div className={styles.section_cntnt} style={{width: '100%'}}>
                            <h1>Set Buy In amount</h1>
                            {/* <h2>7</h2> */}
                            <div className={styles.slider_container}>
                                <input
                                    type="text"
                                    className={styles.buyInput}
                                    value={value}
                                    min={table?.minBuyIn}
                                    max={(chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0}
                                    onChange={(e) => {
                                        if (e.target.value > ((chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0)) {
                                            setValue((chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0)
                                            setError(null)

                                            // let target = document.getElementById('range')
                                            // const min = target.min
                                            // const max = target.max

                                            // target.style.backgroundSize = (((chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0) - min) * 100 / (max - min) + '% 100%'
                                        } else {
                                            setValue(!e.target.value || parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value));
                                            setError(null)

                                            // let target = document.getElementById('range')
                                            // const min = target.min
                                            // const max = target.max
                                            // const val = e.target.value

                                            // target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
                                        }
                                    }}
                                />
                                <div className='flex items-center w-full gap-0'>
                                    {/* <input type="range" defaultValue={value} min={0} max={(chipType !== 1 ? getCurrentChip?.value : userInfo?.playChips) || 0} id="range" className={styles.amnt_slider} onChange={handleInputChange} /> */}
                                    <AmountSlider table={table} getCurrentChip={getCurrentChip} value={value} setValue={setValue} />
                                    <span className={styles.slider_value}>{(chipType !== 1 ? getCurrentChip?.value : userInfo?.playChips) || ''}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {error && <div className={styles.errorWrapper}>
                        <Alert />
                        <span>{error}</span>
                    </div>}
            {/*
                    Holders Only -> WalletAddress is Required.
                    If Discord Role Condition -> Discord Connect is Required.
                */}
                    {(!isLogin || (table?.discordRoleRequired && !userInfo?.isDiscordConnected) || (table?.nftsRequired && !userInfo?.walletConnected)) ?
                        <div className={styles.button_holder}>
                            <button className={`primary-custom-button w-full`} disabled={loading} onClick={() => {
                                if(isLogin && table?.discordRoleRequired && !userInfo?.isDiscordConnected){
                                    localStorage.setItem('redirect_url', JSON.stringify(location.pathname))
                                    window.location.assign(process.env.REACT_APP_DISCORD_URL)
                                }
                                else connectBtn()
                            }}>
                                <div className={`primary-custom-button-inner ${styles.play_btn}`}>{isLogin && table?.discordRoleRequired && !userInfo?.isDiscordConnected ? 'Connect Discord' : 'Connect'}</div>
                            </button>
                        </div>
                    :
                        <div className={styles.button_holder}>
                            <button onClick={handleSpectate} className={`secondary-custom-button`}>
                                <div className={`secondary-custom-button-inner ${styles.spectate_btn}`}>Spectate</div>
                            </button>
                            <button className={`primary-custom-button w-full`} disabled={loading || (parseInt(value) < table?.minBuyIn) || error} onClick={handlePlay}>
                                <div className={`primary-custom-button-inner ${styles.play_btn}`}>Play</div>
                            </button>
                        </div>
                    }
                </div>
            </div>
        </>
        </div>
    </SwipeableDrawer>
}

export default React.memo(PlayGameModal)

// const Close = () =>{
//     return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//     </svg>
//     )
// }

const HoldersSvg = () => {
    return(<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="7.76621" height="9.66772" rx="0.691744" transform="matrix(0.979925 0.199367 -0.199369 0.979925 3.38867 0)" fill="#FF5050"/>
    <rect width="6.82451" height="6.86618" rx="0.172936" transform="matrix(0.979925 0.199367 -0.199369 0.979925 3.75195 0.583923)" fill="url(#paint0_linear_2943_49338)"/>
    <rect x="1.30469" y="1.35461" width="7.76621" height="9.66772" rx="0.691744" fill="#FF5050"/>
    <rect x="1.77734" y="1.85516" width="6.82451" height="6.86618" rx="0.172936" fill="url(#paint1_linear_2943_49338)"/>
    <rect x="1.77734" y="1.85516" width="6.82451" height="6.86618" rx="0.172936" fill="black" fillOpacity="0.2"/>
    <defs>
    <linearGradient id="paint0_linear_2943_49338" x1="6.82444" y1="9.07688e-08" x2="3.41228" y2="6.8662" gradientUnits="userSpaceOnUse">
    <stop/>
    <stop offset="1" stopColor="#394740"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2943_49338" x1="8.60179" y1="1.85516" x2="5.18963" y2="8.72136" gradientUnits="userSpaceOnUse">
    <stop stopColor="#0000FF"/>
    <stop offset="1" stopColor="#394740"/>
    </linearGradient>
    </defs>
    </svg>
    )
}

const Alert = () => {
    return(<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99951 6.00003V8.6667M7.99951 11.3334H8.00618M7.07639 2.59451L1.59313 12.0656C1.289 12.5909 1.13693 12.8536 1.1594 13.0692C1.17901 13.2572 1.27752 13.4281 1.43043 13.5392C1.60573 13.6667 1.90924 13.6667 2.51625 13.6667H13.4828C14.0898 13.6667 14.3933 13.6667 14.5686 13.5392C14.7215 13.4281 14.82 13.2572 14.8396 13.0692C14.8621 12.8536 14.71 12.5909 14.4059 12.0656L8.92263 2.59451C8.61959 2.07107 8.46806 1.80935 8.27038 1.72145C8.09794 1.64477 7.90108 1.64477 7.72864 1.72145C7.53096 1.80935 7.37944 2.07107 7.07639 2.59451Z" stroke="#F70000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
    </svg>
    )
}

const AmountRangeComponent = React.memo((props) => {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <span className={styles.custom_bar} />
            <span className={styles.custom_bar} />
            <span className={styles.custom_bar} />
        </SliderThumb>
    );
})


const AmountRangeSlider = styled(Slider)(({ theme }) => ({
    color: '#970101',
    height: 4,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 18,
        width: 24,
        backgroundColor: '#240000',
        border: '0.615px solid #5C5C5C',
        borderRadius: 1,
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
    },
    '& .MuiSlider-track': {
        height: 4,
    },
    '& .MuiSlider-rail': {
        color: '#FFFFFF33',
        opacity: 1,
        height: 4,
    },
}));


const AmountSlider = React.memo(({table, getCurrentChip, value, setValue}) => {
    const chipType = table?.chipName === 'Play Chips' ? 1  : 2
    const {userInfo} = useSelector((state) => state.userLoginReducer)

    function handleInputChange(e) {
        let target = e.target
        // const min = target.min
        // const max = 100000
        const val = target.value
        if (val > ((chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0)) {
            const thisAmt = (chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0
            setValue(thisAmt)
            // target.style.backgroundSize = (((chipType !== 1 ? (getCurrentChip?.value > 100000 ? 100000 : getCurrentChip?.value) : (userInfo?.playChips > 100000 ? 100000 : userInfo?.playChips)) || 0) - min) * 100 / (max - min) + '% 100%'
        } else {
            setValue(val);
            // target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
        }

        // if (Number(val) > Number(max)) {
        //     target.style.backgroundSize = 100 + '% 100%'
        // }

        // setError(null)
    }

    return(
    <AmountRangeSlider
        aria-label="slider"
        slots={{ thumb: AmountRangeComponent }}
        min={0}
        max={(chipType !== 1 ? getCurrentChip?.value : userInfo?.playChips) || 0}
        value={value || 0}
        defaultValue={table?.minBuyIn || 0}
        onChange={handleInputChange}
    />)
})