import React from 'react'

const DashboardSvg = ({active}) => {
    return (
        <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter={active ? '' : 'url(#filter0_d_5356_2950)'}>
                <mask
                    id='mask0_5356_2950'
                    style={{ maskType: 'alpha' }}
                    maskUnits='userSpaceOnUse'
                    x='0'
                    y='0'
                    width='32'
                    height='32'
                >
                    <rect width='32' height='32' fill={active ? '#A01F1F' : '#7D7D7D'} />
                </mask>
                <g mask='url(#mask0_5356_2950)'>
                    <path
                        d='M8.24851 23.7537H15.2741V18.728H8.24851V23.7537ZM8.24851 17.2703H15.2741V8.24474H8.24851V17.2703ZM16.7318 23.7537H23.7574V14.728H16.7318V23.7537ZM16.7318 13.2703H23.7574V8.24474H16.7318V13.2703ZM7.18138 27.0934C6.55147 27.0934 6.01522 26.8721 5.57265 26.4295C5.13009 25.987 4.90881 25.4507 4.90881 24.8208V7.184C4.90881 6.55409 5.13009 6.01785 5.57265 5.57527C6.01522 5.13271 6.55147 4.91144 7.18138 4.91144H24.8182C25.4481 4.91144 25.9843 5.13271 26.4269 5.57527C26.8695 6.01785 27.0907 6.5551 27.0907 7.18704V9.82167H29.3151V11.687H27.0907V15.0665H29.3151V16.9319H27.0907V20.3178H29.3151V22.1831H27.0907V24.8178C27.0907 25.4497 26.8695 25.987 26.4269 26.4295C25.9843 26.8721 25.4481 27.0934 24.8182 27.0934H7.18138Z'
                        fill={active ? '#A01F1F' : '#7D7D7D'}
                    />
                </g>
            </g>
            {active && <defs>
                <filter
                    id='filter0_d_5356_2950'
                    x='0.908813'
                    y='4.91144'
                    width='32.4062'
                    height='30.1819'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='4' />
                    <feGaussianBlur stdDeviation='2' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_5356_2950'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_5356_2950'
                        result='shape'
                    />
                </filter>
            </defs>}
        </svg>
    )
}

export default DashboardSvg
