import styles from './TablePreview.module.css'
import React, { useEffect } from 'react'
import card from '../../../Assets/Images/other/card_.png'
import logo from '../../../Assets/Images/other/logo_.png'
import dealer from '../../../Assets/Images/other/person_.png'
import backdrop from '../../../Assets/Images/other/backdrop_.png'
import { useSelector } from 'react-redux'

const TablePreview = ({tableData, edit, setTableData}) => {
    const {tableData:storedTableData} = useSelector((state) => state.saveTableReducer)

    useEffect(() => {
        if(edit && setTableData){
            setTableData({
                background: edit?.backgroundImage,
                card: edit?.cardImage,
                logo: edit?.logoImage,
                backdrop: edit?.backdropImage,
                dealer: edit?.dealerImage,
                carpet: edit?.carpetImage,
                material: edit?.materialImage ,
            })
        }
    },[edit])

    useEffect(() => {
        if(storedTableData){
            setTableData({
                background: storedTableData?.background,
                card: storedTableData?.card,
                logo: storedTableData?.logo,
                backdrop: storedTableData?.backdrop,
                dealer: storedTableData?.dealer,
                carpet: storedTableData?.carpet,
                material: storedTableData?.material
            })
        }
    }, [storedTableData])

    return <div className='w-full flex flex-col h-full'>
        {/* Top Backdrop */}
        {tableData?.backdrop && <img src={typeof tableData?.backdrop !== 'string' ? URL.createObjectURL(tableData?.backdrop) : tableData?.backdrop || backdrop} alt="" className={styles.backdrop}/>}
        {!tableData?.backdrop && <div className={styles.backdrop}/>}
    <div className={styles.background_screen}
        style={{
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            background: typeof tableData.background !== 'string' ? `radial-gradient(
                    53% 56.35% at 50% 55.6%,
                    rgba(0, 0, 0, 0.2) 0%,
                    rgba(0, 0, 0, 0.15) 24.84%,
                    rgba(0, 0, 0, 0.98) 100%
                ),  url(${URL.createObjectURL(tableData.background) || '../../Assets/Images/other/background_.png'}) no-repeat top center / cover`
                :`radial-gradient(
                    53% 56.35% at 50% 55.6%,
                    rgba(0, 0, 0, 0.2) 0%,
                    rgba(0, 0, 0, 0.15) 24.84%,
                    rgba(0, 0, 0, 0.98) 100%
                ),  url(${tableData.background || '../../Assets/Images/other/background_.png'}) no-repeat top center / cover`,
        }}
    >
        {/* Logo */}
        <div className={styles.logo_dealer_wrapper}>
            <img src={typeof tableData.logo !== 'string' ? URL.createObjectURL(tableData.logo) : tableData.logo || logo} alt="" className={styles.logo} />
            <img src={typeof tableData.dealer !== 'string' ? URL.createObjectURL(tableData.dealer) : tableData.dealer || dealer} alt="" className={styles.dealer} />
        </div>

        {/* Center Table */}
        <div className={styles.table_container}>
            {/* <img src={table} alt="" className={styles.table}/> */}
            <div className={styles.table}>
                {/* <img src={tableData.material} alt="" className={styles.table}/> */}
                <img src={typeof tableData.material !== 'string' ? URL.createObjectURL(tableData.material) : tableData.material} alt="" className={styles.table}/>
                <img src={typeof tableData.carpet !== 'string' ? URL.createObjectURL(tableData.carpet) : tableData.carpet} alt="" className={styles.table_carpet}/>
            </div>

            <div className={styles.card_wrapper}>
                <img src={typeof tableData.card !== 'string' ? URL.createObjectURL(tableData.card) : tableData.card || card} alt="" className={styles.card} />
                <img src={typeof tableData.card !== 'string' ? URL.createObjectURL(tableData.card) : tableData.card || card} alt="" className={styles.card} />
                <img src={typeof tableData.card !== 'string' ? URL.createObjectURL(tableData.card) : tableData.card || card} alt="" className={styles.card} />
                <img src={typeof tableData.card !== 'string' ? URL.createObjectURL(tableData.card) : tableData.card || card} alt="" className={styles.card} />
            </div>
        </div>
    </div>
    </div>
}

export default React.memo(TablePreview)
