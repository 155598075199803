import styles from './DeleteAdmin.module.css'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAdmin } from '../../../../redux/actions/AdminAction'

const DeleteAdmin = ({openModal, setOpenModal, data, communitySlug, communityId}) => {
    const dispatch = useDispatch()
    const cancelButtonRef = useRef(null)

    const {loading} = useSelector((state) => state.deleteAdminReducer)

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0 overflow-y-auto'
                initialFocus={cancelButtonRef}
                onClose={setOpenModal}
            >
                <div className='h-screen px-4 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[#0c0c0d9a] bg-opacity-75 backdrop-blur-[3px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block bg-transparent rounded-lg text-left transform transition-all my-8 align-middle max-w-[540px] w-full'>
                            <div
                                className={`${styles.modal_background} flex justify-center w-full items-center rounded-lg text-white h-full p-10 flex-col gap-y-8 border border-[#3D3D3D]`}
                            >
                                <div className={styles.top_header}>
                                    <h1>Delete Admin</h1>

                                    <button onClick={() => setOpenModal(false)}>
                                        <Close />
                                    </button>
                                </div>

                                <div className={styles.divider}></div>

                                <div className={styles.modal_main_cntnt}>
                                    {/* heading */}
                                    <h1 className={styles.heading}>Are you sure you want to delete this admin?</h1>

                                    <div className={styles.admin_back}>
                                        <figure className={styles.admin_img_holder}>
                                            {data?.image && <img src={data?.image} alt="" />}
                                        </figure>
                                        <div className={styles.vertical_align}>
                                            <h1 className={data?.username ? '' : styles.null}>{data?.username || 'No Name'}</h1>
                                            <h2>{data?.walletAddress?.substring(0, 10) + '......' + data?.walletAddress?.substring(data?.walletAddress?.length - 10, data?.walletAddress?.length)}</h2>
                                        </div>
                                    </div>

                                    {/* main-part */}
                                    <div className={styles.btn_holder}>
                                        <button className={`secondary-custom-button w-full`} onClick={() => setOpenModal(false)}>
                                            <button className={`secondary-custom-button-inner ${styles.cancel_btn}`}>
                                                Keep
                                            </button>
                                        </button>

                                        <button className={`primary-custom-button w-full`} disabled={loading} onClick={() => {
                                                dispatch(deleteAdmin(data?.walletAddress, communitySlug, communityId))
                                                setOpenModal(false)
                                            }}
                                        >
                                            <button className={`primary-custom-button-inner ${styles.success_btn} `}>
                                                {loading ? 'Deleting...' : 'Delete'}
                                            </button>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default React.memo(DeleteAdmin)

const Close = () =>{
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}