import React from 'react'
import coin from '../../../Assets/Images/setup_coins.png'
import styles from './CommunityPreview.module.css'
import { floors, tableSetting } from '../../../data'

const CommunityPreview = ({tabNumber, communityInfo, tableTemplate, createGame, coinSetup, setupData}) => {

    if(tabNumber === 2)
        return <div className={styles.wrapper}>
            <div className={styles.table_container}>
                <div className={styles.table_floor}>
                    <img src={floors[tableTemplate?.background]} alt="" style={{width: 'inherit', height: 'inherit'}}/>
                </div>

                <div className={styles.table_holder}>
                    <img src={typeof tableTemplate?.dealersImage === 'string' ? tableTemplate?.dealersImage : URL.createObjectURL(tableTemplate?.dealersImage)} alt="" className={styles.dealer_image} />
                    <img src={typeof tableTemplate?.backgroundSymbol === 'string' ? tableTemplate?.backgroundSymbol : URL.createObjectURL(tableTemplate?.backgroundSymbol)} alt="" className={styles.background_symbol}/>
                    <img src={tableSetting[tableTemplate?.tableFrameMaterial]?.table} alt="" style={{width: 'inherit', height: 'inherit', position: 'absolute', top: 0, left: 0}}/>
                    <div className={styles.table}></div>
                    <div className={styles.table}></div>
                    <div className={styles.table}></div>
                    <div className={styles.table}></div>
                    <div className={styles.table}></div>
                </div>
            </div>

            <button className={styles.view_full}>View full Preview</button>
        </div>
    else if(tabNumber === 3)
        return <div className={styles.wrapper}>
            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img src={coin} alt="" />
            </div>
        </div>
    else if(tabNumber === 4)
        return <div className={styles.wrapper}>
            <div className={styles.table_container}>
                <div className={styles.table_floor}>
                    <img src={floors[tableTemplate?.background]} alt="" style={{width: 'inherit', height: 'inherit'}}/>
                </div>

                <div className={styles.table_holder}>
                    <img src={typeof tableTemplate?.dealersImage === 'string' ? tableTemplate?.dealersImage : URL.createObjectURL(tableTemplate?.dealersImage)} alt="" className={styles.dealer_image} />
                    <img src={typeof tableTemplate?.backgroundSymbol === 'string' ? tableTemplate?.backgroundSymbol : URL.createObjectURL(tableTemplate?.backgroundSymbol)} alt="" className={styles.background_symbol}/>
                    <img src={tableSetting[tableTemplate?.tableFrameMaterial]?.table} alt="" style={{width: 'inherit', height: 'inherit', position: 'absolute', top: 0, left: 0}}/>
                    <div className={styles.table}></div>
                    <div className={styles.table}></div>
                    <div className={styles.table}></div>
                    <div className={styles.table}></div>
                    <div className={styles.table}></div>
                </div>
            </div>

            <button className={styles.view_full}>View full Preview</button>
        </div>
    else  return <div className={styles.wrapper_dummy}></div>
}

export default CommunityPreview
