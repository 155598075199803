export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5007'
export const KURAMA_BACKEND_URL = process.env.REACT_APP_KURAMA_BACKEND_URL || 'http://localhost:5001'
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL || 'https://kurama.io'
export const REACT_APP_DISCORD_URL = process.env.REACT_APP_DISCORD_URL

export const ErrorMessage = (error) => {
    return error.response && error.response?.data?.msg
        ? error.response.data.msg
        : error.message
}

export const getCurrentLevel=(XP)=>{
    return Math.ceil(Math.sqrt((XP - 20) / 10)) || 0
}

export const getNextXP = (XP) => {
    const Current_Level = getCurrentLevel(XP) || 0;
    return Current_Level * (Current_Level + 1) * 10 || 10
}

export const getWalletAddress= (walletAddress) => {
    return walletAddress?.substring(0, 6) + '...' + walletAddress?.substring(walletAddress?.length - 4, walletAddress?.length)
}