import React, { useEffect } from "react";

const BettingButton = ({
  bet,
  setBet,
  hasFold,
  user,
  minBid,
  playerTurn,
  winnerAnnounced,
  turnAmount,
  raisedBy,
  walletAddress,
}) => {
  useEffect(() => {
    if ((playerTurn == walletAddress) && (raisedBy > turnAmount)) {
      if ((raisedBy - turnAmount) > user.chips) {
        setBet(user.chips)
      } else {
        setBet(raisedBy - turnAmount)
      }
    }
  }, [ playerTurn ])

  const decreaseBet = () => {
    console.log(bet, raisedBy, ((bet - raisedBy) >= raisedBy))
    if (((turnAmount > 0) ? (bet > minBid) : (bet > minBid*2)) && (playerTurn == walletAddress) && ((bet - minBid + turnAmount) >= raisedBy)) {
      document.getElementById("button-audio").play();
      setBet(bet - minBid);
    }
  };

  const increaseBet = () => {
    if ((playerTurn == walletAddress) && (bet < user?.chips)) {
      document.getElementById("button-audio").play();
      setBet(bet + minBid);
    }
  };

  return (
    <button
      className="button-betting"
      disabled={playerTurn !== walletAddress || winnerAnnounced || hasFold}
    >
      <div className="button-bottom-layer-medium">
        <div className="button-border-layer">
          <div className="button-bet-flex">
            {window.innerWidth >= 650 && <div className="button-bet-action" onClick={() => decreaseBet()}>
              -
            </div>}
            {window.innerWidth >= 650 ? <input className="button-bet-number" value={bet} onChange={(e) => setBet(parseInt(e.target.value || minBid) < minBid ? minBid : (parseInt(e.target.value || minBid) > user?.chips ? user?.chips : (parseInt(e.target.value || minBid))))} min={minBid} tabIndex={-1}/> : 
              <span className="button-bet-number">{bet}</span>
            }
            {window?.innerWidth >= 650 && <div className="button-bet-action" onClick={() => increaseBet()}>
              +
            </div>}
          </div>
        </div>
      </div>
    </button>
  );
};

export default BettingButton;
