import { Link } from 'react-router-dom'
import styles from './Footer.module.css'
import twitter from '../../Assets/svgs/Twitter.svg'
import discord from '../../Assets/svgs/discord.svg'
import facebook from '../../Assets/svgs/facebook.svg'
import React from 'react'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.top}>
                <Link to='/dashboard'>
                    <img style={{ width: '140px' }} src={"https://res.cloudinary.com/kuramaverse/image/upload/v1695756520/ArcadeVerse_Logo_vujobz.svg"} alt="" />
                </Link>

                <div className={styles.link_wrapper}>
                    <div className={styles.social_link_holder}>
                        <a href="https://twitter.com/KuramaVerse" target='_blank' rel="noreferrer">
                            <img src={twitter} alt="" />
                        </a>
                        <a href="https://discord.gg/SK8UQyP3GU" target='_blank' rel="noreferrer">
                            <img src={discord} alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.external_links}>
                    <a href="https://kurama.io/marketplace" target='_blank' rel="noreferrer">Kurama Marketplace</a>
                    <a href="https://discord.gg/SK8UQyP3GU" target='_blank' rel="noreferrer">Join Arcadeverse</a>
                    <Link to="/community">Communities</Link>
                </div>
                <div className={styles.copyright}>
                    FoxLedger Ltd 2023©, All rights reserved.
                </div>
            </div>
        </footer>
    )
}

export default Footer