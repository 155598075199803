import React, {useState } from 'react'
import styles from './DashboardMobile.module.css'
import gameCoin from "../../Assets/Images/gameCoin.svg";
import mobile_logo from "../../Assets/svgs/mobile_logo.svg";
import { useSelector } from 'react-redux'
import PlayCoins from '../shared/modals/PlayCoins/PlayCoins';
import ExchangeModal from '../shared/modals/ExchangeModal/ExchangeModal';
import LoginModal from '../shared/modals/LoginModal/LoginModal';
import CreateGuestModal from '../shared/modals/CreateGuestModal/CreateGuestModal';
import CreateProfileModal from '../shared/modals/CreateProfile/CreateProfile';
import { Link, useLocation } from 'react-router-dom';

const DashboardMobile = ({backUrl: propsBackUrl, title: propsTitle, disabled}) => {
    const location = useLocation()

    const {isLogin, userInfo} = useSelector((state) => state.userLoginReducer)
    const {allChips} = useSelector((state) => state.getUserCoinsForArcadeVerseReducer)
    const { title } = useSelector((state) => state.NavbarReduer)

    const [openModal, setOpenModal] = useState(false);
    const [exchangeModal, setExchangeModal] = useState(false);
    const [playCoinsModal, setPlayCoinsModal] = useState(false);
    const [checkOnlyWallet, setCheckOnlyWallet] = useState(false);
    const [openGuestModal, setOpenGuestModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);

    const getCurrentChip = () => {
        const arr = allChips.filter((chip) => chip?.slug === (title || 'kuramaverse'))
        return arr?.length > 0 ? arr[0] : {}
    }

    if(location.pathname.split('/')[1] === 'pokertable') return ''

    return <div className={styles.container}
        // style={{
        //     background: getCurrentChip()?.theme ? getCurrentChip()?.theme?.backgroundColor : ''
        // }}
    >
        <Link to={disabled ? '#' : '/'} style={{width: disabled ? 'auto' : '', paddingTop: title || propsTitle ? '12px' : '', cursor: disabled ? 'default' : ''}}>
            <img src={mobile_logo} alt="poker"/>
        </Link>

        {isLogin && <div className={styles.align_right}>
            <div className={styles.chips_btn_outer}>
                <div className={styles.chips_btn_inner}>
                    <h1>{userInfo?.playChips > 10000 ? (Math.floor(userInfo?.playChips / 1000)) + 'K' : userInfo?.playChips}</h1>
                    <h2>XP</h2>
                </div>
            </div>
            {getCurrentChip()?.slug && title ?
                <div className={styles.exchange_btn_outer} onClick={() => {if(!disabled) setExchangeModal(true)}} style={{cursor: disabled ? 'default' : ''}}>
                    <h1>{(getCurrentChip()?.value > 10000 ? (Math.floor(getCurrentChip()?.value / 1000)) + 'K' : getCurrentChip()?.value) || 0}</h1>
                    <img src={getCurrentChip()?.chipImg || gameCoin} alt="coins" className="dash-utils-img" />
                </div>
            :
                (!title && <div className={styles.exchange_btn_outer} onClick={() => {if(!disabled) setExchangeModal(true)}} style={{cursor: disabled ? 'default' : ''}}>
                    <h1>{(getCurrentChip()?.value > 10000 ? (Math.floor(getCurrentChip()?.value / 1000)) + 'K' : getCurrentChip()?.value) || 0}</h1>
                    <img src={gameCoin} alt="coins" className="dash-utils-img" />
                </div>)
            }
        </div>}

        {!isLogin && <button className={styles.connect_btn} onClick={() => {if(!disabled) setOpenModal(true)}}>
            Connect
        </button>}


        {playCoinsModal && !disabled && <PlayCoins setCheckOnlyWallet={setCheckOnlyWallet} setOpenLoginModal={setOpenModal} openModal={playCoinsModal} setOpenModal={() => {setPlayCoinsModal(false)}} />}
        {exchangeModal && !disabled && <ExchangeModal openModal={exchangeModal} setOpenModal={() => {setExchangeModal(false)}} />}
        {openModal && !disabled && <LoginModal checkOnlyWallet={checkOnlyWallet} setOpenGuestModal={setOpenGuestModal} setOpenProfileModal={setOpenCreateModal} openModal={openModal} setOpenModal={() => {setOpenModal(false)}} />}
        {openGuestModal && <CreateGuestModal setOpenConnectModal={setOpenModal} openModal={openGuestModal} setOpenModal={setOpenGuestModal} setOpenCreateModal={setOpenCreateModal} />}
        {openCreateModal && <CreateProfileModal openModal={openCreateModal} setOpenModal={setOpenCreateModal} />}
    </div>
}

export default DashboardMobile
