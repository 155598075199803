import React, { useState, useEffect } from "react";
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import "./playingArea.css";
import OnTableContent from "../../Components/OnTableContent/OnTableContent";
import Opponent from "../../Components/Players/Opponent/Opponent";
import chatMobile from '../../Assets/svgs/chat-mobile.svg'
import summaryMobile from '../../Assets/svgs/summary-mobile.svg'
import quitMobile from '../../Assets/svgs/quit-mobile.svg'
import User from "../../Components/Players/User/User";
import Controls from "../../Components/Controls/Controls";
import Button from "../../Components/Button/Button";
import Summary from "../../Components/Summary/Summary";
import Chat from "../../Components/Chat/Chat";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from './../Loader/Loader';
// const socket = io("https://poker.foxledger.io");
import axios from "axios";
import { BACKEND_URL } from "../../constants";
import { SwipeableDrawer } from "@mui/material";
import CreateProfileModal from "../../Components/shared/modals/CreateProfile/CreateProfile";
import QuitModal from "../../Components/shared/modals/QuitModal/QuitModal";
import WinnerRoundModal from "../../Components/shared/modals/WinnerRoundModal/WinnerRoundModal";
import LoginModal from "../../Components/shared/modals/LoginModal/LoginModal";
import PlayGameModal from "../../Components/shared/modals/PlayGameModal/PlayGameModal";
import { deactivateTableId } from "../../redux/actions/otherAction";
// import WinnerRoundModal from "../../Components/shared/modals/WinnerRoundModal/WinnerRoundModal"
import { gsap } from 'gsap'
import { MotionPathPlugin } from "gsap/all";
import DealerPositionPath from './DealerPositionPath'
import InsufficeintFundModal from "../../Components/shared/modals/InsufficeintFundModal/InsufficeintFundModal";
import { emptyRound } from "../../redux/actions/CommunityAction";
import CreateGuestModal from "../../Components/shared/modals/CreateGuestModal/CreateGuestModal";
import { useNavigate, useLocation } from 'react-router-dom';
import TableLeaderboard from "../../Components/TableLeaderboard/TableLeaderboard";
import TournamentWinnerModal from "../../Components/shared/modals/TouramentWinnerModal/TournamentWinnerModal";
import NavigateTableModal from "../../Components/shared/modals/NavugateTableModal/NavigateTableModal";
gsap.registerPlugin(MotionPathPlugin);

const PlayingArea = ({ socketObj, activeUsers }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const params = useParams()
  const {isLogin, userInfo, accessToken, verificationHash} = useSelector((state) => state.userLoginReducer);
  const socket = socketObj
  const socketId = socketObj.id
  const walletAddress = userInfo?.walletAddress
  const tableId = params.tableId
  const [ElectrolizeLoading, setElectrolizeLoading] = useState(true);
  const [PoppinsLoading, setPoppinsLoading] = useState(true);
  const [OxaniumLoading, setOxaniumLoading] = useState(true);
  const [OrbitronLoading, setOrbitronLoading] = useState(true);
  const [backgroundImageLoading, setbackgroundImageLoading] = useState(true);
  const [backdropImageLoading, setbackdropImageLoading] = useState(true);
  const [carpetImageLoading, setcarpetImageLoading] = useState(true);
  const [materialImageLoading, setmaterialImageLoading] = useState(true);
  const [playModalImageLoading, setplayModalImageLoading] = useState(true);
  const [logoImageLoading, setlogoImageLoading] = useState(true);
  const [cardPreviewLoading, setcardPreviewLoading] = useState(true);
  const [dealerImageLoading, setdealerImageLoading] = useState(true);
  const [chipImageLoading, setchipImageLoading] = useState(true);
  const [cardImageLoading, setcardImageLoading] = useState(true);
  const [summary, setSummary] = useState(false);
  const [openLeaderboard, setopenLeaderboard] = useState(false);
  const [leaderboardData, setleaderboardData] = useState([]);
  // const [chat, setChat] = useState(false);
  const [pot, setpot] = useState(0);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [amount, setamount] = useState(50);
  const [raisedBy, setraisedBy] = useState(0);
  const [userFirstJoined, setuserFirstJoined] = useState(false);
  const [displayAnimation, setdisplayAnimation] = useState(false)
  const [displayAnimationChanged, setdisplayAnimationChanged] = useState()
  const [winnerAnnounced, setwinnerAnnounced] = useState(false);
  const [nextRoundTimer, setnextRoundTimer] = useState(0);
  const [playerHandRank, setplayerHandRank] = useState();
  const [openModalWinner, setopenModalWinner] = useState(false);
  const [openTournamentWinner, setopenTournamentWinner] = useState(false);
  const [openNavigateTable, setopenNavigateTable] = useState(false);
  const [allPlayerHands, setallPlayerHands] = useState([]);
  const [openQuitModal, setOpenQuitModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [checkOnlyWallet, setCheckOnlyWallet] = useState(false);
  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [checkOnlyDiscordConnect, setCheckOnlyDiscordConnect] = useState(false);
  const [openTableModal, setOpenTableModal] = useState(false);
  const [openInsufficientBalanceModal, setOpenInsufficientBalanceModal] = useState(false);
  const [user, setUser] = useState();
  const [table, settable] = useState([]);
  const [opponents, setOpponents] = useState([]);
  const [winners, setwinners] = useState([]);
  const [activePlayers, setactivePlayers] = useState([]);
  const [flipOpponentCard, setflipOpponentCard] = useState(false);
  const [spectators, setspectators] = useState([]);
  const [isSpectator, setisSpectator] = useState(false);
  const [firstTimeLoadCards, setfirstTimeLoadCards] = useState(true);
  const [playerTurn, setplayerTurn] = useState();
  const [turnChanged, setturnChanged] = useState(false);
  const [removeAnimationCircle, setremoveAnimationCircle] = useState(false);
  const [playerHands, setplayerHands] = useState([]);
  const [currentRound, setcurrentRound] = useState(1);
  const [updateState, setupdateState] = useState(false);
  const [currentRoundActivity, setcurrentRoundActivity] = useState([]);
  const [tableCards, settableCards] = useState([]);
  const [tableCardsWinnerAnnounced, settableCardsWinnerAnnounced] = useState(false);
  const [initialTableCardsLength, setinitialTableCardsLength] = useState(0);
  const [loading, setloading] = useState(true);
  const [socketGameTimer, setsocketGameTimer] = useState(0);
  const [startingNewRound, setstartingNewRound] = useState(0);
  const [startingNewRoundType, setstartingNewRoundType] = useState(0);
  const [minBid, setminBid] = useState(0);
  const [minBidGoingUp, setminBidGoingUp] = useState(false);
  const [minBidTimer, setminBidTimer] = useState(0);
  const [navigatingTimer, setnavigatingTimer] = useState(0);
  const [upcomingBid, setupcomingBid] = useState(0);
  const [showNumber, setshowNumber] = useState(0);
  const [tableNumber, settableNumber] = useState(0);
  const [tournamentRank, settournamentRank] = useState(0);
  const {tableId: currentTableId} = useSelector((state) => state.currentValidTableReducer)
  const [value, setValue] = useState(table?.minBuyIn || 0)

  // Get table API to get min. bid, Table and Background UI
  // and a loader will be there until join table finishes
  useEffect(() => {
    gsap.ticker.lagSmoothing(false);
  }, [])

  const setupSounds = () => {
    document.getElementById("button-audio").volume = 0;
    document.getElementById("cardDistribute-audio").volume = 0;
    document.getElementById("cardPlaced-audio").volume = 0;
    document.getElementById("win-audio").volume = 0;
    document.getElementById("countdownStart-audio").volume = 0;
    document.getElementById("countdownEnd-audio").volume = 0;
    document.getElementById("turn-audio").volume = 0;
    document.getElementById("cardFlick-audio").volume = 0;
    document.getElementById("chipThrow-audio").volume = 0;
    document.getElementById("fold-audio").volume = 0;
    document.getElementById("check-audio").volume = 0;
    document.getElementById("fourBeeps-audio").volume = 0;
    document.getElementById("button-audio").play();
    document.getElementById("cardDistribute-audio").play();
    document.getElementById("cardPlaced-audio").play();
    document.getElementById("win-audio").play();
    document.getElementById("countdownStart-audio").play();
    document.getElementById("countdownEnd-audio").play();
    document.getElementById("turn-audio").play();
    document.getElementById("cardFlick-audio").play();
    document.getElementById("chipThrow-audio").play();
    document.getElementById("fold-audio").play();
    document.getElementById("check-audio").play();
    document.getElementById("fourBeeps-audio").play();
    setTimeout(() => {
      document.getElementById("button-audio").volume = 1;
      document.getElementById("cardDistribute-audio").volume = 1;
      document.getElementById("cardPlaced-audio").volume = 1;
      document.getElementById("win-audio").volume = 1;
      document.getElementById("countdownStart-audio").volume = 1;
      document.getElementById("countdownEnd-audio").volume = 1;
      document.getElementById("turn-audio").volume = 1;
      document.getElementById("cardFlick-audio").volume = 1;
      document.getElementById("chipThrow-audio").volume = 1;
      document.getElementById("fold-audio").volume = 1;
      document.getElementById("check-audio").volume = 1;
      document.getElementById("fourBeeps-audio").volume = 1;
    }, 1000)
  }

  const playcardsounds = (totalTime) => {
    setTimeout(() => {
      if ((document.getElementsByClassName('opponent-card-1-1').length > 0) || (document.getElementsByClassName('opponent-card-6-1').length > 0)) {
        document.getElementById("cardDistribute-audio").play();
      }
    }, totalTime + 250)

    setTimeout(() => {
      if ((document.getElementsByClassName('opponent-card-1-1').length > 0) || (document.getElementsByClassName('opponent-card-6-1').length > 0)) {
        document.getElementById("cardDistribute-audio").play();
      }
    }, totalTime + 500)

    setTimeout(() => {
      if ((document.getElementsByClassName('opponent-card-2-1').length > 0) || (document.getElementsByClassName('opponent-card-5-1').length > 0)) {
        document.getElementById("cardDistribute-audio").play();
      }
    }, totalTime + 750)

    setTimeout(() => {
      if ((document.getElementsByClassName('opponent-card-3-1').length > 0) || (document.getElementsByClassName('opponent-card-4-1').length > 0)) {
        document.getElementById("cardDistribute-audio").play();
      }
    }, totalTime + 1000)

    setTimeout(() => {
      if ((document.getElementsByClassName('opponent-card-1-1').length > 0) || (document.getElementsByClassName('opponent-card-6-1').length > 0)) {
        document.getElementById("cardDistribute-audio").play();
      }
    }, totalTime + 750)
    
    setTimeout(() => {
      if ((document.getElementsByClassName('opponent-card-1-1').length > 0) || (document.getElementsByClassName('opponent-card-6-1').length > 0)) {
        document.getElementById("cardDistribute-audio").play();
      }
    }, totalTime + 1000)

    setTimeout(() => {
      if ((document.getElementsByClassName('opponent-card-2-1').length > 0) || (document.getElementsByClassName('opponent-card-5-1').length > 0)) {
        document.getElementById("cardDistribute-audio").play();
      }
    }, totalTime + 1250)

    setTimeout(() => {
      if ((document.getElementsByClassName('opponent-card-3-1').length > 0) || (document.getElementsByClassName('opponent-card-4-1').length > 0)) {
        document.getElementById("cardDistribute-audio").play();
      }
    }, totalTime + 1500)

    setTimeout(() => {
      document.getElementById("cardFlick-audio").play();
    }, totalTime + 1700)
  }

  const playchipsound = () => {
    document.getElementById("chipThrow-audio").play();
  }

  const playchecksound = () => {
    document.getElementById("check-audio").play();
  }

  const playfoldsound = () => {
    document.getElementById("fold-audio").play();
  }

  const playturn = () => {
    document.getElementById("turn-audio").play();
  }

  useEffect(() => {
    socket.on("startingNewRound", (serverData) => {
      if (serverData.players) {
        serverData?.players?.map((player) => {
          if (player.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
            setUser(player);
            setisSpectator(false)
            localStorage.setItem('playingPlayerInfo', JSON.stringify(player))
          }
        })
        setAllOpponents(serverData?.players);
        setactivePlayers(serverData.players);
      }
      if (serverData.spectators) {
        setspectators(serverData.spectators)
      }
      setstartingNewRound(serverData?.socketGameTimer)
      setstartingNewRoundType(serverData?.startingNewRoundType)
    });

    socket.on("navigatingTable", (serverData) => {
      settableNumber(serverData?.tableData?.tableNumber)
      setnavigatingTimer(serverData?.socketGameTimer)
      setopenNavigateTable(true)
    });

    socket.on("navigateTable", (serverData) => {
      console.log('navigateTable', serverData)
      navigate(`/pokerTable/${serverData?.tableData?._id}`)
      localStorage.setItem('startPath', 0)
      localStorage.setItem('newMinBid', 0)
      localStorage.setItem('tableCardsLength', 0)
      setwinnerAnnounced(false)
      setwinners([])
      settableCards([])
      setplayerHands([])
      setallPlayerHands([])
      setinitialTableCardsLength(0)
      settableCardsWinnerAnnounced(false)
      localStorage.setItem('navigateTable', true)
      socket.emit("joinTable", {
        tableId: serverData?.tableData?._id,
        verificationHash,
        accessToken,
        socketId: socket.id,
        chipValue: serverData?.chipValue,
        tournamentId: serverData?.tableData?.tournamentId,
        tournamentType: serverData?.tableData?.tournamentType,
        tournamentTime: serverData?.tableData?.tournamentTime
      });
      setopenNavigateTable(false)
    });

    socket.on("tableJoined", (serverData) => {
      var isActivePlayer
      serverData?.players?.map((player) => {
        if (player.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
          isActivePlayer = true
          setUser(player);
          setisSpectator(false)
          localStorage.setItem('playingPlayerInfo', JSON.stringify(player))
        }
      })

      if (!isActivePlayer) {
        let findPlayer = serverData?.spectators?.find((spectator) => spectator.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress)
        setUser(findPlayer);
        localStorage.setItem('playingPlayerInfo', JSON.stringify(findPlayer))
        setisSpectator(true)
      }

      setpot((serverData?.players?.length > 1) && (serverData?.pot || 0))
      setminBidTimer(serverData?.minBidTimer)
      setupcomingBid(serverData?.upcomingBid)
      setAllOpponents(serverData?.players);
      setspectators(serverData?.spectators)
      setactivePlayers(serverData.players);
      setplayerTurn(serverData?.playerTurn);
      localStorage.setItem('playerTurn', serverData?.playerTurn)
      setdisplayAnimationChanged(serverData?.playerTurn)
      if (!socketGameTimer) {
        setsocketGameTimer(serverData?.socketGameTimer)
      }

      if ((localStorage.getItem('tableCardsLength') == '0') && (serverData?.players?.length > 1)) {
        settableCards(serverData?.tableCards || []);
        localStorage.setItem('tableCardsLength', serverData?.tableCards?.length || 0)
        setinitialTableCardsLength(serverData?.tableCards?.length)
        
        if (serverData?.tableCards?.length == 3) {
          gsap
          .timeline()
          .to(".card-filled-0", { opacity: 1, left: '10px', top: '0', rotateY: 0 }, 0)
          .to(".card-filled-1", { opacity: 1, left: '79px', top: '0', rotateY: 0 }, 0)
          .to(".card-filled-2", { opacity: 1, left: '148px', top: '0', rotateY: 0 }, 0)
        } else if (serverData?.tableCards?.length == 4) {
          gsap
          .timeline()
          .to(".card-filled-0", { opacity: 1, left: '10px', top: '0', rotateY: 0 }, 0)
          .to(".card-filled-1", { opacity: 1, left: '79px', top: '0', rotateY: 0 }, 0)
          .to(".card-filled-2", { opacity: 1, left: '148px', top: '0', rotateY: 0 }, 0)
          .to(".card-filled-3", { opacity: 1, left: '217px', top: '0', rotateY: 0 }, 0)
        } else if (serverData?.tableCards?.length == 5) {
          gsap
          .timeline()
          .to(".card-filled-0", { opacity: 1, left: '10px', top: '0', rotateY: 0 }, 0)
          .to(".card-filled-1", { opacity: 1, left: '79px', top: '0', rotateY: 0 }, 0)
          .to(".card-filled-2", { opacity: 1, left: '148px', top: '0', rotateY: 0 }, 0)
          .to(".card-filled-3", { opacity: 1, left: '217px', top: '0', rotateY: 0 }, 0)
          .to(".card-filled-4", { opacity: 1, left: '286px', top: '0', rotateY: 0 }, 0)
        }
      }

      setcurrentRound(serverData?.currentRound)
      setcurrentRoundActivity(serverData?.currentRoundActivity)

      if (serverData?.dealerTablePosition && (Number(localStorage.getItem('startPath')) == 0)) {
        setupSounds()
        var endPath
        if (serverData?.dealerTablePosition == 1) {
          endPath = 0.2927
        } else if (serverData?.dealerTablePosition == 2) {
          endPath = 0.3651
        } else if (serverData?.dealerTablePosition == 3) {
          endPath = 0.428
        } else if (serverData?.dealerTablePosition == 4) {
          endPath = 0.014
        } else if (serverData?.dealerTablePosition == 5) {
          endPath = 0.1136
        } else if (serverData?.dealerTablePosition == 5) {
          endPath = 0.204
        } else if (serverData?.dealerTablePosition == 6) {
          endPath = 0.2663
        }

        gsap.to("#runningSVG", {
          ease: "linear",
          duration: 0,
          motionPath:{
            path: "#runningPath",
            align: "#runningPath",
            start: localStorage.getItem('startPath'),
            end: endPath,
            alignOrigin: [0.5, 0.5]
          }
        });

        localStorage.setItem('startPath', endPath)
      }
      localStorage.setItem('roundStartedChips1', false)
      localStorage.setItem('roundStartedChips2', false)
    });

    socket.on("disconnected", (serverData) => {
      if (serverData?.spectators) {
        setspectators(serverData?.spectators)
      } else {
        setactivePlayers(serverData?.players);
        serverData?.players?.map((player) => {
          if (player.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
            setUser(player);
            localStorage.setItem('playingPlayerInfo', JSON.stringify(player))
          }
        })
        setAllOpponents(serverData?.players);
      }
    });

    socket.on("setMinBidTimer", (serverData) => {
      localStorage.setItem('newMinBid', serverData?.minBid)
      setminBidTimer(serverData?.minBidTimer)
      setupcomingBid(serverData?.upcomingBid)
    });

    socket.on("roundStarted", (serverData) => {
      setplayerHands([])
      localStorage.setItem('roundStartedChips1', true)
      localStorage.setItem('roundStartedChips2', true)
      setupdateState((prev) => !prev)

      var isActivePlayer
      serverData?.players?.map((player) => {
        if (player.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
          isActivePlayer = true
          setisSpectator(false)
          setUser(player);
          localStorage.setItem('playingPlayerInfo', JSON.stringify(player))
        }
      })
      
      if (!isActivePlayer) {
        let findPlayer = serverData?.spectators?.find((spectator) => spectator.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress)
        setUser(findPlayer);
        localStorage.setItem('playingPlayerInfo', JSON.stringify(findPlayer))
        setisSpectator(true)
      }

      if (Number(localStorage.getItem('newMinBid')) !== 0) {
        setamount(Number(localStorage.getItem('newMinBid')))
        setpot(Number(localStorage.getItem('newMinBid')) + (Number(localStorage.getItem('newMinBid'))*2))
        setminBid(Number(localStorage.getItem('newMinBid')))
        localStorage.setItem('minBid', Number(localStorage.getItem('newMinBid')))
        setminBidGoingUp(true)
        setTimeout(() => {
          setminBidGoingUp(false)
        }, 3000)
      } else {
        if (JSON.parse(localStorage.getItem('playingPlayerInfo')).turnAmount >= Number(localStorage.getItem('minBid'))) {
          setamount(Number(localStorage.getItem('minBid')))
        } else {
          setamount(Number(localStorage.getItem('minBid'))*2)
        }
        setpot(serverData?.pot)
        setminBid(Number(localStorage.getItem('minBid')))
      }

      setspectators(serverData?.spectators)
      setactivePlayers(serverData?.players);
      setAllOpponents(serverData?.players);
      setsocketGameTimer(serverData?.socketGameTimer)
      setstartingNewRound(0)
      setwinners([])
      setwinnerAnnounced(false);
      localStorage.setItem('winnerAnnounced', false)
      settableCardsWinnerAnnounced(false)
      setallPlayerHands([])
      setcurrentRound(serverData?.currentRound)
      setcurrentRoundActivity(serverData?.currentRoundActivity)
      setraisedBy(serverData.raisedBy)
      settableCards([]);
      setinitialTableCardsLength(0);
      localStorage.setItem('tableCardsLength', 0)
      setopenModalWinner(false)

      gsap.ticker.lagSmoothing(false);

      var endPath
      if ((JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition == serverData?.dealerTablePosition) || ((JSON.parse(localStorage.getItem('playingPlayerInfo')).status == 'Spectator') && (serverData?.dealerTablePosition == 1))) {
        endPath = 0.2927
      } else if (((serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition) == 1) || ((serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition) === -6) || ((JSON.parse(localStorage.getItem('playingPlayerInfo')).status == 'Spectator') && (serverData?.dealerTablePosition == 2))) {
        endPath = 0.3651
      } else if (serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition == 2 || ((serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition) === -5) || ((JSON.parse(localStorage.getItem('playingPlayerInfo')).status == 'Spectator') && (serverData?.dealerTablePosition == 3))) {
        endPath = 0.428
      } else if (serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition == 3 || ((serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition) === -4) || ((JSON.parse(localStorage.getItem('playingPlayerInfo')).status == 'Spectator') && (serverData?.dealerTablePosition == 4))) {
        endPath = 0.014
      } else if (serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition == 4 || ((serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition) === -3) || ((JSON.parse(localStorage.getItem('playingPlayerInfo')).status == 'Spectator') && (serverData?.dealerTablePosition == 5))) {
        endPath = 0.1136
      } else if (serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition == 5 || ((serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition) === -2) || ((JSON.parse(localStorage.getItem('playingPlayerInfo')).status == 'Spectator') && (serverData?.dealerTablePosition == 6))) {
        endPath = 0.204
      } else if (serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition == 6 || ((serverData?.dealerTablePosition - JSON.parse(localStorage.getItem('playingPlayerInfo'))?.tablePosition) === -1) || ((JSON.parse(localStorage.getItem('playingPlayerInfo')).status == 'Spectator') && (serverData?.dealerTablePosition == 7))) {
        endPath = 0.2663
      }

      var totalTime
      if ((endPath - localStorage.getItem('startPath')) > 0) {
        totalTime = ((endPath - localStorage.getItem('startPath'))/0.00025)/1000
        if (totalTime > 0.7) {
          totalTime = ((endPath - localStorage.getItem('startPath'))/0.0005)/1000
        }
        gsap.to("#runningSVG", {
          ease: "linear",
          duration: localStorage.getItem('startPath') == 0 ? 0 : (totalTime > 0.7 ? ((endPath - localStorage.getItem('startPath'))/0.0005)/1000 : ((endPath - localStorage.getItem('startPath'))/0.00025)/1000),
          motionPath:{
            path: "#runningPath",
            align: "#runningPath",
            start: localStorage.getItem('startPath'),
            end: endPath,
            alignOrigin: [0.5, 0.5]
          }
        });
      } else {
        totalTime = (((0.514 - localStorage.getItem('startPath')) + endPath)/0.00025)/1000
        if (totalTime > 0.7) {
          totalTime = (((0.514 - localStorage.getItem('startPath')) + endPath)/0.0005)/1000
        }
        if (endPath == 0.014) {
          gsap.to("#runningSVG", {
            ease: "linear",
            duration: localStorage.getItem('startPath') == 0 ? 0 : (totalTime > 0.7 ? ((endPath - localStorage.getItem('startPath'))/0.0005)/1000 : ((endPath - localStorage.getItem('startPath'))/0.00025)/1000),
            motionPath:{
              path: "#runningPath",
              align: "#runningPath",
              start: localStorage.getItem('startPath'),
              end: 0.514,
              alignOrigin: [0.5, 0.5]
            }
          });
        } else {
          gsap.timeline().to("#runningSVG", {
            ease: "linear",
            duration: localStorage.getItem('startPath') == 0 ? 0 : (totalTime > 0.7 ? ((0.514 - localStorage.getItem('startPath'))/0.0005)/1000 : ((0.514 - localStorage.getItem('startPath'))/0.00025)/1000),
            motionPath:{
              path: "#runningPath",
              align: "#runningPath",
              start: localStorage.getItem('startPath'),
              end: 0.514,
              alignOrigin: [0.5, 0.5]
            }
          }).to("#runningSVG", {
            ease: "linear",
            duration: localStorage.getItem('startPath') == 0 ? 0 : (totalTime > 0.7 ? ((endPath - 0.014)/0.0005)/1000 : ((endPath - 0.014)/0.00025)/1000),
            motionPath:{
              path: "#runningPath",
              align: "#runningPath",
              start: 0.014,
              end: endPath,
              alignOrigin: [0.5, 0.5]
            }
          });
        }        
      }

      if (localStorage.getItem('startPath') == 0 ) {
        totalTime = 0
      }

      localStorage.setItem('startPath', endPath)

      setTimeout(() => {
        localStorage.setItem('roundStartedChips1', false)
        setupdateState((prev) => !prev)
      }, totalTime + 500)

      setTimeout(() => {
        localStorage.setItem('roundStartedChips2', false)
        setupdateState((prev) => !prev)
      }, totalTime + 1000)

      setTimeout(() => {
        gsap.context(() => {
          gsap.set(".user-card-1", { opacity: 0, height: '115px', width: '80px', left: '35px', top: '-335px', rotateY: 180, rotateZ: 0 })
          gsap.set(".user-card-2", { opacity: 0, height: '115px', width: '80px', left: '35px', top: '-335px', rotateY: 180, rotateZ: 0 })
          gsap.set(".flip-card-front", { backfaceVisibility: 'unset' })
          gsap.set(".flip-card-back", { backfaceVisibility: 'unset' })
          gsap.set(".flip-card-opponent-front", { backfaceVisibility: 'unset' })
          gsap.set(".flip-card-opponent-back", { backfaceVisibility: 'unset' })
          gsap.set(".opponent-card-1-1", { opacity: 0, height: '115px', width: '80px', left: '-244px', top: '-238px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-1-2", { opacity: 0, height: '115px', width: '80px', left: '-244px', top: '-238px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-2-1", { opacity: 0, height: '115px', width: '80px', left: '-482px', top: '-59px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-2-2", { opacity: 0, height: '115px', width: '80px', left: '-482px', top: '-59px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-3-1", { opacity: 0, height: '115px', width: '80px', left: '-244px', top: '118px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-3-2", { opacity: 0, height: '115px', width: '80px', left: '-244px', top: '118px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-4-1", { opacity: 0, height: '115px', width: '80px', left: '293px', top: '118px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-4-2", { opacity: 0, height: '115px', width: '80px', left: '293px', top: '118px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-5-1", { opacity: 0, height: '115px', width: '80px', left: '530px', top: '-59px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-5-2", { opacity: 0, height: '115px', width: '80px', left: '530px', top: '-59px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-6-1", { opacity: 0, height: '115px', width: '80px', left: '293px', top: '-238px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-6-2", { opacity: 0, height: '115px', width: '80px', left: '293px', top: '-238px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-7-1", { opacity: 0, height: '115px', width: '80px', left: '55px', top: '-274px', rotateY: 180, rotateZ: 0 })
          gsap.set(".opponent-card-7-2", { opacity: 0, height: '115px', width: '80px', left: '55px', top: '-274px', rotateY: 180, rotateZ: 0 })

          gsap
          .timeline()
          .to(".flip-card-opponent-front", { backfaceVisibility: 'unset' }, 0)
          .to(".flip-card-opponent-back", { backfaceVisibility: 'unset' }, 0)
          .to(".user-card-1", { opacity: 1, height: '150px', width: '105px', left: '12px', top: '-50px', duration: 0.5, rotateZ: -5 }, 0)
          .to(".user-card-2", { opacity: 1, height: '150px', width: '105px', left: '32px', top: '-45px', duration: 0.5, rotateZ: 18 }, 0.5)
          .to(".flip-card-front", { backfaceVisibility: 'hidden' }, 1)
          .to(".flip-card-back", { backfaceVisibility: 'hidden' }, 1)
          .to(".user-card-1", { rotateY: 0, duration: 0.6 }, 1.4)
          .to(".user-card-2", { rotateY: 0, duration: 0.6 }, 1.4)
          .to(".user-card-1", { clearProps: "all" }, 1.5)
          .to(".user-card-2", { clearProps: "all" }, 1.5)
          .to(".opponent-card-1-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.25)
          .to(".opponent-card-1-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.75)
          .to(".opponent-card-2-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.5)
          .to(".opponent-card-2-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.1)
          .to(".opponent-card-3-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.75)
          .to(".opponent-card-3-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.125)
          .to(".opponent-card-4-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.75)
          .to(".opponent-card-4-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.125)
          .to(".opponent-card-5-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.5)
          .to(".opponent-card-5-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.1)
          .to(".opponent-card-6-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.25)
          .to(".opponent-card-6-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.75)
          .to(".opponent-card-7-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0)
          .to(".opponent-card-7-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.5)

        });

      }, totalTime + 1500)

      playcardsounds(totalTime + 1000)
      
      setTimeout(() => {
        if (localStorage.getItem('playerTurn') == serverData?.playerTurn) {
          setremoveAnimationCircle(true)
          setturnChanged((prev) => !prev)
          localStorage.setItem('clearInterval', true)
          localStorage.setItem('clearIntervalOpponent-1', true)
          localStorage.setItem('clearIntervalOpponent-2', true)
          localStorage.setItem('clearIntervalOpponent-3', true)
          localStorage.setItem('clearIntervalOpponent-4', true)
          localStorage.setItem('clearIntervalOpponent-5', true)
          localStorage.setItem('clearIntervalOpponent-6', true)
          localStorage.setItem('clearIntervalOpponent-7', true)
        }
        if ((serverData?.playerTurn == JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) && (localStorage.getItem('tableCardsLength') !== 5)) {
          playturn()
        }
        setplayerTurn(serverData?.playerTurn);
        localStorage.setItem('playerTurn', serverData?.playerTurn)
        setdisplayAnimationChanged(serverData?.playerTurn)
      }, totalTime + 1500 + 1500)
    });

    socket.on("roundStartedFailed", (serverData) => {
      var isActivePlayer
      serverData?.players?.map((player) => {
        if (player.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
          isActivePlayer = true
          setisSpectator(false)
          setUser(player);
          localStorage.setItem('playingPlayerInfo', JSON.stringify(player))
        }
      })

      if (!isActivePlayer) {
        let findPlayer = serverData?.spectators?.find((spectator) => spectator.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress)
        setUser(findPlayer);
        localStorage.setItem('playingPlayerInfo', JSON.stringify(findPlayer))
        setisSpectator(true)
      }

      if (serverData?.spectators) {
        setspectators(serverData?.spectators)
      }

      setplayerTurn('')
      localStorage.setItem('playerTurn', '')
      setactivePlayers(serverData?.players);
      setAllOpponents(serverData?.players);
      if (JSON.parse(localStorage.getItem('playingPlayerInfo')).turnAmount >= Number(localStorage.getItem('minBid'))) {
        setamount((JSON.parse(localStorage.getItem('playingPlayerInfo')).chips > Number(localStorage.getItem('minBid'))) ? Number(localStorage.getItem('minBid')) : JSON.parse(localStorage.getItem('playingPlayerInfo')).chips)
      } else {
        setamount((JSON.parse(localStorage.getItem('playingPlayerInfo')).chips > Number(localStorage.getItem('minBid'))*2) ? Number(localStorage.getItem('minBid'))*2 : JSON.parse(localStorage.getItem('playingPlayerInfo')).chips)
      }
      setpot(0)
      setraisedBy(0)
      setwinners([])
      setwinnerAnnounced(false);
      localStorage.setItem('winnerAnnounced', false)
      settableCards([]);
      setplayerHands([])
      setopenModalWinner(false)
    });

    socket.on("playerHands", (serverData) => {
      gsap.set(".user-card-1", { opacity: 0, height: '115px', width: '80px', left: '35px', top: '-335px', rotateY: 180, rotateZ: 0 })
      gsap.set(".user-card-2", { opacity: 0, height: '115px', width: '80px', left: '35px', top: '-335px', rotateY: 180, rotateZ: 0 })
      setwinnerAnnounced(false);
      localStorage.setItem('winnerAnnounced', false)
      setplayerHands(serverData.playerHands);
    });

    socket.on("leaderboardData", (serverData) => {
      setleaderboardData(serverData?.tournamentPlayers)
      console.log(serverData)
    });

    socket.on("tableCards", (serverData) => {
      settableCardsWinnerAnnounced(serverData?.tableCardsWinnerAnnounced)
      setinitialTableCardsLength(localStorage.getItem('tableCardsLength') || 0)
      settableCards(serverData?.tableCards);
      localStorage.setItem('tableCardsLength', serverData?.tableCards?.length)
    });

    socket.on("buyInChips", (serverData) => {
      var isActivePlayer
      serverData?.players?.map((player) => {
        if (player.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
          isActivePlayer = true
          setisSpectator(false)
          setUser(player);
          localStorage.setItem('playingPlayerInfo', JSON.stringify(player))
        }
      })

      if (!isActivePlayer) {
        let findPlayer = serverData?.spectators?.find((spectator) => spectator.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress)
        setUser(findPlayer);
        localStorage.setItem('playingPlayerInfo', JSON.stringify(findPlayer))
        setisSpectator(true)
        setspectators(serverData?.spectators)
      }

      console.log(JSON.parse(localStorage.getItem('playingPlayerInfo')))

      setactivePlayers(serverData?.players);
      setAllOpponents(serverData?.players);
    });

    socket.on("check", (serverData) => {
      if (localStorage.getItem('playerTurn') !== JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
        playchecksound()
      }
      setdisplayAnimation('Check')
      setTimeout(() => {
        setdisplayAnimation(false)
        setdisplayAnimationChanged(serverData?.playerTurn)
      }, 2000)
      setTimeout(() => {
        if (localStorage.getItem('winnerAnnounced') !== 'true') {
          if (localStorage.getItem('playerTurn') == serverData?.playerTurn) {
            setremoveAnimationCircle(true)
            setturnChanged((prev) => !prev)
            localStorage.setItem('clearInterval', true)
            localStorage.setItem('clearIntervalOpponent-1', true)
            localStorage.setItem('clearIntervalOpponent-2', true)
            localStorage.setItem('clearIntervalOpponent-3', true)
            localStorage.setItem('clearIntervalOpponent-4', true)
            localStorage.setItem('clearIntervalOpponent-5', true)
            localStorage.setItem('clearIntervalOpponent-6', true)
            localStorage.setItem('clearIntervalOpponent-7', true)
          }
          if ((serverData?.playerTurn == JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) && (localStorage.getItem('tableCardsLength') !== 5)) {
            playturn()
          }
          setplayerTurn(serverData?.playerTurn);
          setsocketGameTimer(serverData?.socketGameTimer)
          localStorage.setItem('playerTurn', serverData?.playerTurn)
        }
      }, (serverData?.tableCards || 0)*300 + 100)
      setactivePlayers(serverData?.players);
      serverData?.players?.map((player) => {
        if (player.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
          setUser(player);
          localStorage.setItem('playingPlayerInfo', JSON.stringify(player))
        }
      })
      if (JSON.parse(localStorage.getItem('playingPlayerInfo')).turnAmount >= Number(localStorage.getItem('minBid'))) {
        setamount((JSON.parse(localStorage.getItem('playingPlayerInfo')).chips > Number(localStorage.getItem('minBid'))) ? Number(localStorage.getItem('minBid')) : JSON.parse(localStorage.getItem('playingPlayerInfo')).chips)
      } else {
        setamount((JSON.parse(localStorage.getItem('playingPlayerInfo')).chips > Number(localStorage.getItem('minBid'))*2) ? Number(localStorage.getItem('minBid'))*2 : JSON.parse(localStorage.getItem('playingPlayerInfo')).chips)
      }
      setAllOpponents(serverData?.players);
      setraisedBy(serverData?.raisedBy)
      setcurrentRoundActivity(serverData?.currentRoundActivity)
    });

    socket.on("raise", (serverData) => {
      if (localStorage.getItem('playerTurn') !== JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
        playchipsound()
      }
      setdisplayAnimation(serverData?.displayAnimation)
      setTimeout(() => {
        setdisplayAnimation(false)
        setdisplayAnimationChanged(serverData?.playerTurn)
      }, 2000)
      setTimeout(() => {
        if (localStorage.getItem('winnerAnnounced') !== 'true') {
          if (localStorage.getItem('playerTurn') == serverData?.playerTurn) {
            setremoveAnimationCircle(true)
            setturnChanged((prev) => !prev)
            localStorage.setItem('clearInterval', true)
            localStorage.setItem('clearIntervalOpponent-1', true)
            localStorage.setItem('clearIntervalOpponent-2', true)
            localStorage.setItem('clearIntervalOpponent-3', true)
            localStorage.setItem('clearIntervalOpponent-4', true)
            localStorage.setItem('clearIntervalOpponent-5', true)
            localStorage.setItem('clearIntervalOpponent-6', true)
            localStorage.setItem('clearIntervalOpponent-7', true)
          }
          if ((serverData?.playerTurn == JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) && ((localStorage.getItem('tableCardsLength') !== 5))) {
            playturn()
          }
          setplayerTurn(serverData?.playerTurn);
          setsocketGameTimer(serverData?.socketGameTimer)
          localStorage.setItem('playerTurn', serverData?.playerTurn)
        }
      }, (serverData?.tableCards || 0)*300 + 100)
      setpot(serverData.pot);
      setraisedBy(serverData.raisedBy)
      setactivePlayers(serverData.players);
      setAllOpponents(serverData?.players);
      serverData?.players?.map((player) => {
        if (player.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
          setUser(player);
          localStorage.setItem('playingPlayerInfo', JSON.stringify(player))
        }
      })
      if (JSON.parse(localStorage.getItem('playingPlayerInfo')).turnAmount >= Number(localStorage.getItem('minBid'))) {
        setamount((JSON.parse(localStorage.getItem('playingPlayerInfo')).chips > Number(localStorage.getItem('minBid'))) ? Number(localStorage.getItem('minBid')) : JSON.parse(localStorage.getItem('playingPlayerInfo')).chips)
      } else {
        setamount((JSON.parse(localStorage.getItem('playingPlayerInfo')).chips > Number(localStorage.getItem('minBid'))*2) ? Number(localStorage.getItem('minBid'))*2 : JSON.parse(localStorage.getItem('playingPlayerInfo')).chips)
      }
      setcurrentRoundActivity(serverData?.currentRoundActivity)
    });

    socket.on("fold", (serverData) => {
      if (localStorage.getItem('playerTurn') !== JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
        playfoldsound()
      }
      setTimeout(() => {
        if (localStorage.getItem('winnerAnnounced') !== 'true') {
          if (localStorage.getItem('playerTurn') == serverData?.playerTurn) {
            setremoveAnimationCircle(true)
            setturnChanged((prev) => !prev)
            localStorage.setItem('clearInterval', true)
            localStorage.setItem('clearIntervalOpponent-1', true)
            localStorage.setItem('clearIntervalOpponent-2', true)
            localStorage.setItem('clearIntervalOpponent-3', true)
            localStorage.setItem('clearIntervalOpponent-4', true)
            localStorage.setItem('clearIntervalOpponent-5', true)
            localStorage.setItem('clearIntervalOpponent-6', true)
            localStorage.setItem('clearIntervalOpponent-7', true)
          }
          if ((serverData?.playerTurn == JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) && (localStorage.getItem('tableCardsLength') !== 5)) {
            playturn()
          }
          setplayerTurn(serverData?.playerTurn);
          setsocketGameTimer(serverData?.socketGameTimer)
          localStorage.setItem('playerTurn', serverData?.playerTurn)
        }
      }, (serverData?.tableCards || 0)*300 + 100)
      setdisplayAnimationChanged(serverData?.playerTurn)
      setactivePlayers(serverData.players);
      serverData?.players?.map((player) => {
        if (player.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
          setUser(player);
          localStorage.setItem('playingPlayerInfo', JSON.stringify(player))
        }
      })
      if (JSON.parse(localStorage.getItem('playingPlayerInfo')).turnAmount >= Number(localStorage.getItem('minBid'))) {
        setamount((JSON.parse(localStorage.getItem('playingPlayerInfo')).chips > Number(localStorage.getItem('minBid'))) ? Number(localStorage.getItem('minBid')) : JSON.parse(localStorage.getItem('playingPlayerInfo')).chips)
      } else {
        setamount((JSON.parse(localStorage.getItem('playingPlayerInfo')).chips > Number(localStorage.getItem('minBid'))*2) ? Number(localStorage.getItem('minBid'))*2 : JSON.parse(localStorage.getItem('playingPlayerInfo')).chips)
      }
      setAllOpponents(serverData?.players);
      setraisedBy(serverData.raisedBy)
      setcurrentRoundActivity(serverData?.currentRoundActivity)
    });

    socket.on("winner", (serverData) => {
      setTimeout(() => {
        setwinners(serverData?.winners);
      }, (serverData?.tableCards || 0)*300 + 1000)
      
      setwinnerAnnounced(true)
      localStorage.setItem('winnerAnnounced', true)
      setnextRoundTimer(serverData?.socketGameTimer)
      setallPlayerHands(serverData?.allPlayerHands)
      setcurrentRoundActivity(serverData?.currentRoundActivity)

      serverData?.players?.map((player) => {
        if (player.walletAddress === JSON.parse(localStorage.getItem('user'))?.user?.walletAddress) {
          setUser(player);
          localStorage.setItem('playingPlayerInfo', JSON.stringify(player))
        }
      })
      
      setactivePlayers(serverData?.players);
      setAllOpponents(serverData?.players);

      setplayerTurn('')
      localStorage.setItem('playerTurn', '')
    });

    socket.on("tournamentWinner", (serverData) => {
      settournamentRank(serverData?.rank)
      setopenModalWinner(false)
      setopenTournamentWinner(true)
    });

    socket.on("tournamentLost", (serverData) => {
      settournamentRank(serverData?.rank)
      localStorage.setItem('openTournamentWinner', true)
    });

  }, []);

  const handleStartNewRound = () => {
    socket.emit("startNewRound", { tableId });
  };

  const handleTest = () => {
    gsap.context(() => {
      gsap.set(".user-card-1", { opacity: 0, height: '115px', width: '80px', left: '35px', top: '-335px', rotateY: 180, rotateZ: 0 })
      gsap.set(".user-card-2", { opacity: 0, height: '115px', width: '80px', left: '35px', top: '-335px', rotateY: 180, rotateZ: 0 })
      gsap.set(".flip-card-front", { backfaceVisibility: 'unset' })
      gsap.set(".flip-card-back", { backfaceVisibility: 'unset' })
      gsap.set(".flip-card-opponent-front", { backfaceVisibility: 'unset' })
      gsap.set(".flip-card-opponent-back", { backfaceVisibility: 'unset' })
      gsap.set(".opponent-card-1-1", { opacity: 0, height: '115px', width: '80px', left: '-244px', top: '-238px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-1-2", { opacity: 0, height: '115px', width: '80px', left: '-244px', top: '-238px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-2-1", { opacity: 0, height: '115px', width: '80px', left: '-482px', top: '-59px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-2-2", { opacity: 0, height: '115px', width: '80px', left: '-482px', top: '-59px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-3-1", { opacity: 0, height: '115px', width: '80px', left: '-244px', top: '118px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-3-2", { opacity: 0, height: '115px', width: '80px', left: '-244px', top: '118px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-4-1", { opacity: 0, height: '115px', width: '80px', left: '293px', top: '118px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-4-2", { opacity: 0, height: '115px', width: '80px', left: '293px', top: '118px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-5-1", { opacity: 0, height: '115px', width: '80px', left: '530px', top: '-59px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-5-2", { opacity: 0, height: '115px', width: '80px', left: '530px', top: '-59px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-6-1", { opacity: 0, height: '115px', width: '80px', left: '293px', top: '-238px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-6-2", { opacity: 0, height: '115px', width: '80px', left: '293px', top: '-238px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-7-1", { opacity: 0, height: '115px', width: '80px', left: '55px', top: '-274px', rotateY: 180, rotateZ: 0 })
      gsap.set(".opponent-card-7-2", { opacity: 0, height: '115px', width: '80px', left: '55px', top: '-274px', rotateY: 180, rotateZ: 0 })

      gsap
      .timeline()
      .to(".flip-card-opponent-front", { backfaceVisibility: 'unset' }, 0)
      .to(".flip-card-opponent-back", { backfaceVisibility: 'unset' }, 0)
      .to(".user-card-1", { opacity: 1, height: '150px', width: '105px', left: '12px', top: '-50px', duration: 0.5, rotateZ: -5 }, 0)
      .to(".user-card-2", { opacity: 1, height: '150px', width: '105px', left: '32px', top: '-45px', duration: 0.5, rotateZ: 18 }, 0.5)
      .to(".flip-card-front", { backfaceVisibility: 'hidden' }, 1)
      .to(".flip-card-back", { backfaceVisibility: 'hidden' }, 1)
      .to(".user-card-1", { rotateY: 0, duration: 0.6 }, 1.4)
      .to(".user-card-2", { rotateY: 0, duration: 0.6 }, 1.4)
      .to(".user-card-1", { clearProps: "all" }, 1.5)
      .to(".user-card-2", { clearProps: "all" }, 1.5)
      .to(".opponent-card-1-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.25)
      .to(".opponent-card-1-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.75)
      .to(".opponent-card-2-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.5)
      .to(".opponent-card-2-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.1)
      .to(".opponent-card-3-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.75)
      .to(".opponent-card-3-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.125)
      .to(".opponent-card-4-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.75)
      .to(".opponent-card-4-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.125)
      .to(".opponent-card-5-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.5)
      .to(".opponent-card-5-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.1)
      .to(".opponent-card-6-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0.25)
      .to(".opponent-card-6-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.75)
      .to(".opponent-card-7-1", { opacity: 1, height: '115px', width: '80px', left: '16px', top: '-40px', duration: 0.5, rotateZ: -5 }, 0)
      .to(".opponent-card-7-2", { opacity: 1, height: '115px', width: '80px', left: '30px', top: '-35px', duration: 0.5, rotateZ: 15 }, 0.5)

    });

    playcardsounds(0)
  };

  const handleCheck = () => {
    setplayerTurn('')
    localStorage.setItem('playerTurn', '')
    socket.emit("check", { tableId, walletAddress: JSON.parse(localStorage.getItem('user'))?.user?.walletAddress, accessToken, verificationHash, tournamentId: table?.tournamentId });
  };

  const handleFold = () => {
    setplayerTurn('')
    localStorage.setItem('playerTurn', '')
    socket.emit("fold", { tableId, walletAddress: JSON.parse(localStorage.getItem('user'))?.user?.walletAddress, accessToken, verificationHash, tournamentId: table?.tournamentId });
  };

  const handleRaise = () => {
    setplayerTurn('')
    localStorage.setItem('playerTurn', '')
    socket.emit("raise", { tableId, walletAddress: JSON.parse(localStorage.getItem('user'))?.user?.walletAddress, accessToken, verificationHash, amount, tournamentId: table?.tournamentId });
  };

  // const handleGetWinner = () => {
  //   socket.emit("getWinner", { tableId });
  // };

  const setAllOpponents = (allPlayers) => {
    if (allPlayers?.length > 0) {
      const opponentData = allPlayers?.filter(
        (player) => player.walletAddress !== JSON.parse(localStorage.getItem('user'))?.user?.walletAddress
      );
      setOpponents(opponentData);
    } else {
      setOpponents([])
    }
  };

  useEffect(() => {
    if(params && params?.tableId && socketId && !userFirstJoined) {
      if (!location.state || (location.state && location.state.tournament)) {
        axios.post(
          `${BACKEND_URL}/community/getTable`,
          {
              tableId
          }
      ).then((res) => {
        if (res?.data?.msg == 'Table not found') {
          navigate('/dashboard')
        }

        if (res.data && res.data?.table) {
          if (res.data?.table?.tournamentId && (!location.state || (location.state && !location.state.tournament))) {
            navigate(`/tournament/${res.data.table.tournamentId}`)
          }
          settable(res.data?.table)
          localStorage.setItem('table', JSON.stringify(res.data?.table))
          setuserFirstJoined(true)
          localStorage.setItem('startPath', 0)
          localStorage.setItem('newMinBid', 0)
          localStorage.setItem('minBid', res.data?.table?.tournamentId ? 20 : res.data?.table?.minBid)
          setminBid(res.data?.table?.tournamentId ? 20 : res.data?.table?.minBid)
          localStorage.setItem('tableCardsLength', 0)
          setwinners([])
          setwinnerAnnounced(false);
          localStorage.setItem('winnerAnnounced', false)
          setplayerHands([])
          setallPlayerHands([])
          if (res.data?.table?.tournamentId) {
            setamount(20)
          }
          setloading(false)
          window.history.replaceState({}, document.title)
        }
      })
      } else {
        settable(location.state.table)
        localStorage.setItem('table', JSON.stringify(location.state.table))
        setuserFirstJoined(true)
        localStorage.setItem('startPath', 0)
        localStorage.setItem('newMinBid', 0)
        localStorage.setItem('minBid', location.state.table?.tournamentId ? 20 : location.state.table?.minBid)
        setminBid(location.state.table?.tournamentId ? 20 : location.state.table?.minBid)
        localStorage.setItem('tableCardsLength', 0)
        setloading(false)
        window.history.replaceState({}, document.title)
      }
    }

    return () => {
      socket.emit("disconnectRoom", {
        tableId,
        socketId: socket.id
      });
    };
  },[accessToken, socketId])

  useEffect(() => {
    if (firstTimeLoadCards && (opponents.length > 0) && playerTurn) {
      gsap.set(".flip-card-opponent-front", { backfaceVisibility: 'unset' })
      gsap.set(".flip-card-opponent-back", { backfaceVisibility: 'unset' })
      gsap.set(".opponent-card-1-1", { opacity: 1 })
      gsap.set(".opponent-card-1-2", { opacity: 1 })
      gsap.set(".opponent-card-2-1", { opacity: 1 })
      gsap.set(".opponent-card-2-2", { opacity: 1 })
      gsap.set(".opponent-card-3-1", { opacity: 1 })
      gsap.set(".opponent-card-3-2", { opacity: 1 })
      gsap.set(".opponent-card-4-1", { opacity: 1 })
      gsap.set(".opponent-card-4-2", { opacity: 1 })
      gsap.set(".opponent-card-5-1", { opacity: 1 })
      gsap.set(".opponent-card-5-2", { opacity: 1 })
      gsap.set(".opponent-card-6-1", { opacity: 1 })
      gsap.set(".opponent-card-6-2", { opacity: 1 })
      gsap.set(".opponent-card-7-1", { opacity: 1 })
      gsap.set(".opponent-card-7-2", { opacity: 1 })
      gsap.set(".flip-card-front", { backfaceVisibility: 'hidden' })
      gsap.set(".flip-card-back", { backfaceVisibility: 'hidden' })
      gsap.set(".user-card-1", { opacity: 1 })
      gsap.set(".user-card-2", { opacity: 1 })
    } else if (user || isSpectator) {
      setfirstTimeLoadCards(false)
    }
    
    if (localStorage.getItem('navigateTable') == 'true') {
      gsap.set(".flip-card-opponent-front", { backfaceVisibility: 'unset' })
      gsap.set(".flip-card-opponent-back", { backfaceVisibility: 'unset' })
      gsap.set(".opponent-card-1-1", { opacity: 1 })
      gsap.set(".opponent-card-1-2", { opacity: 1 })
      gsap.set(".opponent-card-2-1", { opacity: 1 })
      gsap.set(".opponent-card-2-2", { opacity: 1 })
      gsap.set(".opponent-card-3-1", { opacity: 1 })
      gsap.set(".opponent-card-3-2", { opacity: 1 })
      gsap.set(".opponent-card-4-1", { opacity: 1 })
      gsap.set(".opponent-card-4-2", { opacity: 1 })
      gsap.set(".opponent-card-5-1", { opacity: 1 })
      gsap.set(".opponent-card-5-2", { opacity: 1 })
      gsap.set(".opponent-card-6-1", { opacity: 1 })
      gsap.set(".opponent-card-6-2", { opacity: 1 })
      gsap.set(".opponent-card-7-1", { opacity: 1 })
      gsap.set(".opponent-card-7-2", { opacity: 1 })
      localStorage.setItem('navigateTable', false)
    }
  },[opponents])

  const joinTable = (chipValue, joinTableId) => {
    socket.emit("joinTable", {
      tableId: joinTableId || tableId,
      verificationHash,
      accessToken,
      socketId: socket.id,
      chipValue
    });
  }

  const buyInChips = (chipValue) => {
    socket.emit("buyInChips", {
      tableId,
      verificationHash,
      walletAddress,
      accessToken,
      chipValue
    });
  }

  const joinSpectatorTable = () => {
    socket.emit("joinTable", {
      tableId,
      verificationHash,
      accessToken,
      socketId: socket.id,
      isSpectator: true
    });
  }

  function isRoyalFlush(hands, tableCards) {
      const allCards = [ ...hands, ...tableCards ];

      const royalFlushSuits = new Set(['Spade', 'Heart', 'Club', 'Diamond']);
      var foundRoyalFlush = false;

      for (const suit of royalFlushSuits) {
          const suitCards = allCards.filter(card => card[1] === suit);

          if (suitCards.length < 5) continue;
          const sortedSuitCards = suitCards.sort((a, b) => a[0] - b[0]);

          // sortedSuitCards can have 7 cards at max (tableCards + hands)
          if ((sortedSuitCards[0][0] === 1 && sortedSuitCards[1][0] === 10 && sortedSuitCards[2][0] === 11 && sortedSuitCards[3][0] === 12 && sortedSuitCards[4][0] === 13) || (sortedSuitCards.length > 5 && (sortedSuitCards[0][0] === 1 && sortedSuitCards[2][0] === 10 && sortedSuitCards[3][0] === 11 && sortedSuitCards[4][0] === 12 && sortedSuitCards[5][0] === 13)) || (sortedSuitCards.length > 6 &&(sortedSuitCards[0][0] === 1 && sortedSuitCards[3][0] === 10 && sortedSuitCards[4][0] === 11 && sortedSuitCards[5][0] === 12 && sortedSuitCards[6][0] === 13))) {
              foundRoyalFlush = true;
              break;
          }
      }

      return { isRoyalFlush: foundRoyalFlush };
  }

  function isStraightFlush(hands, tableCards) {
      const allCards = [ ...hands, ...tableCards ];

      const straightFlushSuits = new Set(['Spade', 'Heart', 'Club', 'Diamond']);
      var foundStraightFlush = false, straightPoint = 0

      for (const suit of straightFlushSuits) {
          const suitCards = allCards.filter(card => card[1] === suit);

          if (suitCards.length < 5) continue;
          const sortedSuitCards = suitCards.sort((a, b) => a[0] - b[0]);

          for (let i = 0; i < sortedSuitCards.length - 1; i++) {

              if ((sortedSuitCards[i][0] + 1) === sortedSuitCards[i + 1][0]) {
                  straightPoint = straightPoint + 1

                  if (i === (sortedSuitCards.length - 2)) {
                      foundStraightFlush = true
                      break;
                  } else {
                      continue;
                  }
              } else {
                  if (((sortedSuitCards.length === 6) && (i === 0)) || ((sortedSuitCards.length === 7) && ((i === 0) || (i === 1)))) {
                      straightPoint = 0
                      continue;
                  }

                  if (straightPoint >= 4) {
                      foundStraightFlush = true
                  }

                  break;
              }
          }
      }

      return { isStraightFlush: foundStraightFlush };
  }

  function isFourOfAKind(hands, tableCards) {
      const allCards = [ ...hands, ...tableCards ];
      var foundFourOfAKind = false, cards = []

      for (let i = 0; i < allCards.length; i++) {
          cards.push(allCards[i][0])
      }

      // Max number and lenth will be last
      const mostOccurranceNumber = cards.sort((a, b) => {
          return a - b
      }).sort((a, b) => {
          return cards.filter(v => v === a).length - cards.filter(v => v === b).length
      })

      if (cards.filter(v => v === mostOccurranceNumber[mostOccurranceNumber.length - 1]).length === 4) {
          foundFourOfAKind = true
      }

      return { isFourOfAKind: foundFourOfAKind }
  }

  function isFullHouse(hands, tableCards) {
      const allCards = [ ...hands, ...tableCards ];
      var foundFullHouse = false, cards = []

      for (let i = 0; i < allCards.length; i++) {
          cards.push(allCards[i][0])
      }

      const mostOccurranceNumber = cards.sort((a, b) => {
          return a - b
      }).sort((a, b) => {
          return cards.filter(v => v === a).length - cards.filter(v => v === b).length
      })

      if (cards.filter(v => v === mostOccurranceNumber[mostOccurranceNumber.length - 1]).length === 3) {
          if (cards.filter(v => v === mostOccurranceNumber[mostOccurranceNumber.length - 4]).length > 1) {
              foundFullHouse = true
          }
      }

      return { isFullHouse: foundFullHouse }
  }

  function isFlush(hands, tableCards) {
      const allCards = [ ...hands, ...tableCards ];
      const flushSuits = new Set(['Spade', 'Heart', 'Club', 'Diamond']);
      var foundFlush = false

      for (const suit of flushSuits) {
          const suitCards = allCards.filter(card => card[1] === suit);

          if (suitCards.length < 5) continue;
          foundFlush = true
      }

      return { isFlush: foundFlush };
  }

  function isStraight(hands, tableCards) {
      const allCards = [ ...hands, ...tableCards ];
      var foundStraight = false, straightPoint = 0

      const sortedSuitCards = allCards.sort((a, b) => a[0] - b[0]);

      for (let i = 0; i < sortedSuitCards.length; i++) {
          if ((sortedSuitCards[i][0] + 1) === sortedSuitCards[i + 1][0]) {
              straightPoint = straightPoint + 1

              if (i === (sortedSuitCards.length - 2)) {
                  foundStraight = true
                  break;
              } else {
                  continue;
              }
          } else {
              if (((sortedSuitCards.length === 6) && (i === 0)) || ((sortedSuitCards.length === 7) && ((i === 0) || (i === 1)))) {
                  straightPoint = 0
                  continue;
              }

              if (straightPoint >= 4) {
                  foundStraight = true
              }
              break;
          }
      }

      var uniqueSortedCards = []

      for (let i = 0; i < sortedSuitCards.length; i++) {
          var findIndex = uniqueSortedCards.findIndex((card) => sortedSuitCards[i][0] == card[0])

          if (findIndex == -1) {
              uniqueSortedCards.push(sortedSuitCards[i])
          }
      }

      if (uniqueSortedCards.length > 4 && (uniqueSortedCards[0][0] === 1 && uniqueSortedCards[1][0] === 10 && uniqueSortedCards[2][0] === 11 && uniqueSortedCards[3][0] === 12 && uniqueSortedCards[4][0] === 13) || (uniqueSortedCards.length > 5 && (uniqueSortedCards[0][0] === 1 && uniqueSortedCards[2][0] === 10 && uniqueSortedCards[3][0] === 11 && uniqueSortedCards[4][0] === 12 && uniqueSortedCards[5][0] === 13)) || (uniqueSortedCards.length > 6 && (uniqueSortedCards[0][0] === 1 && uniqueSortedCards[3][0] === 10 && uniqueSortedCards[4][0] === 11 && uniqueSortedCards[5][0] === 12 && uniqueSortedCards[6][0] === 13))) {
        foundStraight = true
    }

      return { isStraight: foundStraight }
  }

  function isThreeOfAKind(hands, tableCards) {
      const allCards = [ ...hands, ...tableCards ];
      var foundThreeOfAKind = false;
      var cards = []

      for (let i = 0; i < allCards.length; i++) {
          cards.push(allCards[i][0])
      }

      const mostOccurranceNumber = cards.sort((a, b) => {
          return a - b
      }).sort((a, b) => {
          return cards.filter(v => v === a).length - cards.filter(v => v === b).length
      })

      if (cards.filter(v => v === mostOccurranceNumber[mostOccurranceNumber.length - 1]).length === 3) {
          foundThreeOfAKind = true
      }

      return { isThreeOfAKind: foundThreeOfAKind };
  }

  function isTwoPairs(hands, tableCards) {
      const allCards = [ ...hands, ...tableCards ];
      var foundTwoPairs = false, cards = []

      for (let i = 0; i < allCards.length; i++) {
          cards.push(allCards[i][0])
      }

      const mostOccurranceNumber = cards.sort((a, b) => {
          return a - b
      }).sort((a, b) => {
          return cards.filter(v => v === a).length - cards.filter(v => v === b).length
      })

      if (cards.filter(v => v === mostOccurranceNumber[mostOccurranceNumber.length - 1]).length === 2) {
          if (cards.filter(v => v === mostOccurranceNumber[mostOccurranceNumber.length - 3]).length === 2) {
              foundTwoPairs = true
          }
      }

      return { isTwoPairs: foundTwoPairs }
  }

  function isOnePair(hands, tableCards) {
      const allCards = [ ...hands, ...tableCards ];
      var foundOnePair = false, cards = []

      for (let i = 0; i < allCards.length; i++) {
          cards.push(allCards[i][0])
      }

      const mostOccurranceNumber = cards.sort((a, b) => {
          return a - b
      }).sort((a, b) => {
          return cards.filter(v => v === a).length - cards.filter(v => v === b).length
      })

      if (cards.filter(v => v === mostOccurranceNumber[mostOccurranceNumber.length - 1]).length === 2) {
          foundOnePair = true
      }

      return { isOnePair: foundOnePair };
  }

  function getHandRank(hands, secretTableCards) {

    if (isRoyalFlush(hands, secretTableCards).isRoyalFlush) {

        return { handRank: 'Royal Flush' };

    } else if (isStraightFlush(hands, secretTableCards).isStraightFlush) {

        return { handRank: 'Straight Flush' };

    } else if (isFourOfAKind(hands, secretTableCards).isFourOfAKind) {

        return { handRank: 'Four of a Kind' };

    } else if (isFullHouse(hands, secretTableCards).isFullHouse) {

        return { handRank: 'Full House' };

    } else if (isFlush(hands, secretTableCards).isFlush) {

        return { handRank: 'Flush' };

    } else if (isStraight(hands, secretTableCards).isStraight) {

        return { handRank: 'Straight' };

    } else if (isThreeOfAKind(hands, secretTableCards).isThreeOfAKind) {

        return { handRank: 'Three of a Kind' };

    } else if (isTwoPairs(hands, secretTableCards).isTwoPairs) {

        return { handRank: 'Two Pairs' };

    } else if (isOnePair(hands, secretTableCards).isOnePair) {

        return { handRank: 'One Pair' };

    } else {

        return { handRank: 'High Card' };

    }
  }

  useEffect(() => {
    const playerRank = getHandRank(playerHands, tableCards)
    setplayerHandRank(playerRank.handRank)
  }, [tableCards])

  useEffect(() => {
    setTimeout(() => {
      if (initialTableCardsLength == 0) {
        if (tableCards.length == 3) {
          gsap.set(".card-filled-0", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
          gsap.set(".card-filled-1", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
          gsap.set(".card-filled-2", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
  
          gsap
          .timeline()
          .to(".card-filled-0", { opacity: 1, left: '10px', top: '0', rotateY: 0, duration: 0.3 }, 0)
          .to(".card-filled-1", { opacity: 1, left: '79px', top: '0', rotateY: 0, duration: 0.3 }, 0.3)
          .to(".card-filled-2", { opacity: 1, left: '148px', top: '0', rotateY: 0, duration: 0.3 }, 0.6)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 0)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 300)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 600)
        } else if (tableCards.length == 5) {
          gsap.set(".card-filled-0", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
          gsap.set(".card-filled-1", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
          gsap.set(".card-filled-2", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
          gsap.set(".card-filled-3", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
          gsap.set(".card-filled-4", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
  
          gsap
          .timeline()
          .to(".card-filled-0", { opacity: 1, left: '10px', top: '0', rotateY: 0, duration: 0.3 }, 0)
          .to(".card-filled-1", { opacity: 1, left: '79px', top: '0', rotateY: 0, duration: 0.3 }, 0.3)
          .to(".card-filled-2", { opacity: 1, left: '148px', top: '0', rotateY: 0, duration: 0.3 }, 0.6)
          .to(".card-filled-3", { opacity: 1, left: '217px', top: '0', rotateY: 0, duration: 0.3 }, 0.9)
          .to(".card-filled-4", { opacity: 1, left: '286px', top: '0', rotateY: 0, duration: 0.3 }, 1.2)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 0)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 300)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 600)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 900)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 1200)
  
          setTimeout(() => {
            setflipOpponentCard(true)
          }, 1500 + 500)
  
          setTimeout(() => {
            if((JSON.parse(localStorage.getItem('playingPlayerInfo')) && (JSON.parse(localStorage.getItem('playingPlayerInfo')).chips <= Number(localStorage.getItem('minBid')*2))) && (!JSON.parse(localStorage.getItem('table'))?.tournamentId)) {
              setOpenInsufficientBalanceModal(true)
            } else {
              if (localStorage.getItem('openTournamentWinner') == 'true') {
                localStorage.setItem('openTournamentWinner', false)
                setopenTournamentWinner(true)
              } else {
                setopenModalWinner(true)
              }
            }
          }, 5500)
        }
      } else if (initialTableCardsLength == 3) {
        if (tableCards.length == 4) {
          gsap.set(".card-filled-3", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
  
          gsap
          .timeline()
          .to(".card-filled-3", { opacity: 1, left: '217px', top: '0', rotateY: 0, duration: 0.3 }, 0)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 0)
        } else if (tableCards.length == 5) {
          gsap.set(".card-filled-3", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
          gsap.set(".card-filled-4", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
  
          gsap
          .timeline()
          .to(".card-filled-3", { opacity: 1, left: '217px', top: '0', rotateY: 0, duration: 0.3 }, 0)
          .to(".card-filled-4", { opacity: 1, left: '286px', top: '0', rotateY: 0, duration: 0.3 }, 0.3)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 0)
  
          setTimeout(() => {
            document.getElementById("cardPlaced-audio").play();
          }, 300)
  
          setTimeout(() => {
            setflipOpponentCard(true)
          }, 600 + 500)
  
          setTimeout(() => {
            if((JSON.parse(localStorage.getItem('playingPlayerInfo')) && (JSON.parse(localStorage.getItem('playingPlayerInfo')).chips <= Number(localStorage.getItem('minBid')*2))) && (!JSON.parse(localStorage.getItem('table'))?.tournamentId)) {
              setOpenInsufficientBalanceModal(true)
            } else {
              if (localStorage.getItem('openTournamentWinner') == 'true') {
                localStorage.setItem('openTournamentWinner', false)
                setopenTournamentWinner(true)
              } else {
                setopenModalWinner(true)
              }
            }
          }, 4600)
        }
      } else if (initialTableCardsLength == 4) {
        gsap.set(".card-filled-4", { left: '146px', top: '-94px', rotateY: 180, opacity: 0 })
  
        gsap
        .timeline()
        .to(".card-filled-4", { opacity: 1, left: '286px', top: '0', rotateY: 0, duration: 0.3 }, 0)
  
        setTimeout(() => {
          document.getElementById("cardPlaced-audio").play();
        }, 0)
  
        setTimeout(() => {
          if (tableCardsWinnerAnnounced) {
            setflipOpponentCard(true)
          }
        }, 300 + 500)
  
        setTimeout(() => {
          if (tableCardsWinnerAnnounced) {
            if((JSON.parse(localStorage.getItem('playingPlayerInfo')) && (JSON.parse(localStorage.getItem('playingPlayerInfo')).chips <= Number(localStorage.getItem('minBid')*2))) && (!JSON.parse(localStorage.getItem('table'))?.tournamentId)) {
              setOpenInsufficientBalanceModal(true)
            } else {
              if (localStorage.getItem('openTournamentWinner') == 'true') {
                localStorage.setItem('openTournamentWinner', false)
                setopenTournamentWinner(true)
              } else {
                setopenModalWinner(true)
              }
            }
          }
        }, 4300)
      } else if (initialTableCardsLength == 5) {
        setTimeout(() => {
          setflipOpponentCard(true)
        }, 1000)
  
        setTimeout(() => {
          if((JSON.parse(localStorage.getItem('playingPlayerInfo')) && (JSON.parse(localStorage.getItem('playingPlayerInfo')).chips <= Number(localStorage.getItem('minBid')*2))) && (!JSON.parse(localStorage.getItem('table'))?.tournamentId)) {
            setOpenInsufficientBalanceModal(true)
          } else {
            if (localStorage.getItem('openTournamentWinner') == 'true') {
              localStorage.setItem('openTournamentWinner', false)
              setopenTournamentWinner(true)
            } else {
              setopenModalWinner(true)
            }
          }
        }, 4000)
      }
    }, 100)
  }, [ initialTableCardsLength ])

  useEffect(() => {
    if (allPlayerHands?.length > 0 && flipOpponentCard) {
      gsap.context(() => {
        gsap
        .timeline()
        .to(".flip-card-opponent-front", { backfaceVisibility: 'hidden' }, 0)
        .to(".flip-card-opponent-back", { backfaceVisibility: 'hidden' }, 0)
        .to(".opponent-card-1", { rotateY: 0, duration: 0.6 }, 0.2)
        .to(".opponent-card-2", { rotateY: 0, duration: 0.6 }, 0.2)
      });

      setflipOpponentCard(false)
    }
  }, [ allPlayerHands, flipOpponentCard ])

  const connectBtn = () =>{
    setOpenLoginModal(true)
  }

  useEffect(() => {
    if((!isLogin || currentTableId !== table?._id) && (table && !table.tournamentId)){
      if (!location.state) {
        setOpenTableModal(true)
      }
    }else{
      setOpenTableModal(false)
    }
  },[isLogin, table, table?._id, currentTableId])

  useEffect(() => {
    if ((minBidTimer > 0) && (Math.ceil((minBidTimer - (new Date().getTime()))/1000) > 0)) {
      const interval = setInterval(() => {
        // console.log(Math.ceil((minBidTimer - (new Date().getTime()))/1000), showNumber)
        if (Math.ceil((minBidTimer - (new Date().getTime()))/1000) > 0){
          setshowNumber(Math.ceil((minBidTimer - (new Date().getTime()))/1000))
        } else {
          setminBidTimer(0)
          clearInterval(interval)
          return
        }
      }, 1000)

      return () => {
        window.clearInterval(interval);
      };
    }
  }, [ minBidTimer ])

  const {allChips} = useSelector((state) => state.getUserCoinsForArcadeVerseReducer)

  const arr = allChips.filter((chip) => chip?.slug === table?.slug)
  const getCurrentChip = arr?.length > 0 ? arr[0] : {}

  if (loading || OxaniumLoading || ElectrolizeLoading || PoppinsLoading || OrbitronLoading || backgroundImageLoading || backdropImageLoading || logoImageLoading || dealerImageLoading || carpetImageLoading || materialImageLoading || cardImageLoading || playModalImageLoading || cardPreviewLoading) {
    const FontFaceObserver = require('fontfaceobserver')
    var Electrolize = new FontFaceObserver('Electrolize')
    Electrolize.load().then(async () => {
        setElectrolizeLoading(false)
    })
    .catch((error) => {
        console.log(error)
    })

    var Oxanium = new FontFaceObserver('Oxanium')
    Oxanium.load().then(async () => {
        setOxaniumLoading(false)
    })
    .catch((error) => {
        console.log(error)
    })

    var Poppins = new FontFaceObserver('Poppins')
    Poppins.load().then(async () => {
        setPoppinsLoading(false)
    })
    .catch((error) => {
        console.log(error)
    })

    var Orbitron = new FontFaceObserver('Orbitron')
    Orbitron.load().then(async () => {
        setOrbitronLoading(false)
    })
    .catch((error) => {
        console.log(error)
    })

    return (
        <div>
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694170035/ArcadeVerse%20Sounds/buttonClick_wqaclp.mp3'} id="button-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694346329/ArcadeVerse%20Sounds/CardDistribute_mp3cut.net_l4h9sr.mp3'} id="cardDistribute-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694347893/ArcadeVerse%20Sounds/cardplaced_DoNNiiX9_pmopsq.mp3'} id="cardPlaced-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694169828/ArcadeVerse%20Sounds/Positive_Win_obx6wo.mp3'} id="win-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694337648/ArcadeVerse%20Sounds/game_countdown_start_xebrla.mp3'} id="countdownStart-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694337664/ArcadeVerse%20Sounds/game_countdown_end_h8i6qh.mp3'} id="countdownEnd-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694169827/ArcadeVerse%20Sounds/Message_Notification_necnkl.mp3'} id="turn-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694169827/ArcadeVerse%20Sounds/Poker_Card_Flick_03_j9adhd.mp3'} id="cardFlick-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694189384/ArcadeVerse%20Sounds/object-table-game-poker-chip-stack-rattle-short-04_C2RopsMR_oxaq4v.mp3'} id="chipThrow-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694348345/ArcadeVerse%20Sounds/handstable-9uvx2-04_xh8xRFUO_o0rcwr.mp3'} id="fold-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694189384/ArcadeVerse%20Sounds/handstable-9uvx2-01_uQ57FCUg_eb8yig.mp3'} id="check-audio" />
          <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694169826/ArcadeVerse%20Sounds/Four_Beeps_cujwza.mp3'} id="fourBeeps-audio" />

          <img
              style={{ display: 'none' }}
              onLoad={() => setbackgroundImageLoading(false)}
              src={table?.backgroundImage}
              alt=''
          />
          <img
              style={{ display: 'none' }}
              onLoad={() => setbackdropImageLoading(false)}
              src={table.backdropImage ? table.backdropImage : table?.carpetImage}
              alt=''
          />
          <img
              style={{ display: 'none' }}
              onLoad={() => setcarpetImageLoading(false)}
              src={table?.carpetImage}
              alt=''
          />
          <img
              style={{ display: 'none' }}
              onLoad={() => setcardImageLoading(false)}
              src={table?.cardImage}
              alt=''
          />
          <img
              style={{ display: 'none' }}
              onLoad={() => setlogoImageLoading(false)}
              src={table?.logoImage}
              alt=''
          />
          <img
              style={{ display: 'none' }}
              onLoad={() => setdealerImageLoading(false)}
              src={table?.dealerImage}
              alt=''
          />
          <img
              style={{ display: 'none' }}
              onLoad={() => setchipImageLoading(false)}
              src={table?.chipImage}
              alt=''
          />
          {/* <img
              style={{ display: 'none' }}
              onLoad={() => setcoinImageLoading(false)}
              src={table?.coinImage}
              alt=''
          /> */}
          <img
              style={{ display: 'none' }}
              onLoad={() => setmaterialImageLoading(false)}
              src={table?.materialImage}
              alt=''
          />
          <img
              style={{ display: 'none' }}
              onLoad={() => setcardPreviewLoading(false)}
              src={table?.cardPreview}
              alt=''
          />
          <img
              style={{ display: 'none' }}
              onLoad={() => setplayModalImageLoading(false)}
              src={'https://res.cloudinary.com/kuramaverse/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1694629721/playmodalimage_yty5tv.webp'}
              alt=''
          />
          <Loader />
        </div>
    )
  }

  // if(error) return <pre>{error}</pre>
  // if(window.innerHeight > window.innerWidth){
  //   alert("Please use Landscape!");
  //   return ''
  // }

  return (
    <div style={window.innerWidth < 1100 ? {
      transform: 'rotate(90deg)',
      transformOrigin: 'bottom left',
      position: 'absolute',
      top: '-100vw',
      height: '100vw',
      width: '100vh',
    } : {}} className="plArea">
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694170035/ArcadeVerse%20Sounds/buttonClick_wqaclp.mp3'} id="button-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694346329/ArcadeVerse%20Sounds/CardDistribute_mp3cut.net_l4h9sr.mp3'} id="cardDistribute-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694347893/ArcadeVerse%20Sounds/cardplaced_DoNNiiX9_pmopsq.mp3'} id="cardPlaced-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694169828/ArcadeVerse%20Sounds/Positive_Win_obx6wo.mp3'} id="win-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694337648/ArcadeVerse%20Sounds/game_countdown_start_xebrla.mp3'} id="countdownStart-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694337664/ArcadeVerse%20Sounds/game_countdown_end_h8i6qh.mp3'} id="countdownEnd-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694169827/ArcadeVerse%20Sounds/Message_Notification_necnkl.mp3'} id="turn-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694169827/ArcadeVerse%20Sounds/Poker_Card_Flick_03_j9adhd.mp3'} id="cardFlick-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694189384/ArcadeVerse%20Sounds/object-table-game-poker-chip-stack-rattle-short-04_C2RopsMR_oxaq4v.mp3'} id="chipThrow-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694348345/ArcadeVerse%20Sounds/handstable-9uvx2-04_xh8xRFUO_o0rcwr.mp3'} id="fold-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694189384/ArcadeVerse%20Sounds/handstable-9uvx2-01_uQ57FCUg_eb8yig.mp3'} id="check-audio" />
      <audio src={'https://res.cloudinary.com/kuramaverse/video/upload/v1694169826/ArcadeVerse%20Sounds/Four_Beeps_cujwza.mp3'} id="fourBeeps-audio" />

      {/* {(!isLogin || currentTableId !== table?._id)&& <div className="plArea_connect_button_show">
        {!isLogin ? <Button text={'Connect'} onClick={() => {setOpenLoginModal(true)}} />
        :
        currentTableId !== table?._id && <Button text={'Play Game'} onClick={() => {setOpenTableModal(true)}} />
      }
      </div>} */}
      {/* <audio src={dealingSound} id="button-audio" /> */}
      {/* <audio src={playerCardFlip} id="button-audio" /> */}
      {/* {isLogin && <button className="btn-temp-2 z-2" onClick={handleStartNewRound}>
        Start
      </button>} */}
      {/* {isLogin && <button style={{ top: '-50px', left: '20px' }} className="btn-temp-2 z-2" onClick={handleTest}>
        Test
      </button>} */}

      {(spectators?.length > 0) && (!table?.tournamentId) && <button className="spectatre-playing-area">
        <Eye />
        <div className="person_wrapper">
          <img style={{ zIndex: 100, borderRadius: '100%', width: '23px' }} src={spectators[0].image} alt='' />
          {spectators?.length > 1 && <img style={{ zIndex: 50, borderRadius: '100%', width: '23px', marginLeft: '-15px' }} src={spectators[1].image} alt='' />}
          {spectators?.length > 2 && <img style={{ borderRadius: '100%', width: '23px', marginLeft: '-15px' }} src={spectators[2].image} alt='' />}
        </div>
        <h1>{spectators?.length} Spectating</h1>
      </button>}

      <div style={{ display: 'flex', alignItems: 'strech' }}>
      {table?.tableNumber && <button style={{ border: '1px solid rgba(255, 255, 255, 0.3)', padding: '9px 17px 5px 17px', width: 'max-content', overflow: 'hidden', height: '42px', cursor: 'pointer' }} className="spectatre-playing-area">
        <img alt='' style={{ position: 'absolute', top: '-16px', left: '0', width: '200%', height: '200%', objectFit: 'cover', zIndex: '-1', opacity: '0.3' }} src={table?.backgroundImage} />
        <h1 style={{ fontFamily: 'Oxanium', fontSize: '18px', color: 'white' }}>Table {tableNumber || table?.tableNumber}</h1>
      </button>}

      {table?.tournamentId && (upcomingBid > 0) && (startingNewRound <= 0) && (showNumber > 0) && (showNumber < 181) && <button style={{ background: 'linear-gradient(90deg, rgba(255, 30, 0, 0.45) 0%, rgba(255, 30, 0, 0.22) 100%), #000', border: 'none', padding: '3px 7px 0px 5px', display: 'flex', alignItems: 'center', color: 'white', fontFamily: 'Orbitron', left: '125px', height: 'auto', gap: '6px', cursor: 'pointer' }} className="spectatre-playing-area">
        <div style={{ widht: '10px', padding: '6px', background: 'black', color: 'red', fontSize: '14px', fontWeight: 500, marginTop: '-2px', borderRadius: '3px' }}>{showNumber}</div>
        <div>
          <h2 style={{ fontSize: '10px', color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>Next Min. Bid</h2>
          <h2 style={{ fontSize: '16px', display: 'flex', alignItems: 'cemter', gap: '6px', fontWeight: 500, }}>{upcomingBid}<img alt='' style={{ width: '15px' }} src={table?.chipImg} /></h2>
        </div>
      </button>}
      </div>

      {/* <button style={{ position: 'absolute', zIndex: 10, zoom: `${window.innerWidth > 1100 ? (window.innerHeight < 750 ? (56 + ((window.innerHeight - 400)*0.13333333333333333)) : (98 + ((window.innerHeight - 715)*0.13618677042801555))) : (40 + ((window.innerWidth - 300)*0.13333333333))}%` }} className="chat-btn-playing-area-mobile" onClick={() => setOpenQuitModal(true)}>
        <div className="chat-btn-playing-area-inner-mobile"><img src={chatMobile} alt=''/></div>
      </button> */}

      <button style={{ position: 'absolute', zIndex: 10, zoom: `${window.innerWidth > 1100 ? (window.innerHeight < 750 ? (56 + ((window.innerHeight - 400)*0.13333333333333333)) : (98 + ((window.innerHeight - 715)*0.13618677042801555))) : (40 + ((window.innerWidth - 300)*0.13333333333))}%` }} className="summary-btn-playing-area-mobile" onClick={() => setSummary(true)}>
        <div className="summary-btn-playing-area-inner-mobile"><img src={summaryMobile} alt=''/></div>
      </button>

      <button style={{ position: 'absolute', zIndex: 10, zoom: `${window.innerWidth > 1100 ? (window.innerHeight < 750 ? (56 + ((window.innerHeight - 400)*0.13333333333333333)) : (98 + ((window.innerHeight - 715)*0.13618677042801555))) : (40 + ((window.innerWidth - 300)*0.13333333333))}%` }} className="quit-btn-playing-area" onClick={() => setOpenQuitModal(true)}>
        <div className="quit-btn-playing-area-inner"><img src="https://res.cloudinary.com/kuramaverse/image/upload/v1695670959/log-out-03_ckjgho.svg" alt=''/></div>
      </button>

      <button style={{ position: 'absolute', zIndex: 10, zoom: `${window.innerWidth > 1100 ? (window.innerHeight < 750 ? (56 + ((window.innerHeight - 400)*0.13333333333333333)) : (98 + ((window.innerHeight - 715)*0.13618677042801555))) : (40 + ((window.innerWidth - 300)*0.13333333333))}%` }} className="quit-btn-playing-area-mobile" onClick={() => setOpenQuitModal(true)}>
        <div className="quit-btn-playing-area-inner-mobile"><img src={quitMobile} alt=''/></div>
      </button>

      {/* {summary ? <Summary SetSummary={setSummary} /> : ""} */}
      <div className="page-overlay" />
      <div className="top-gap" id="backDrop">
        {table?.backdropImage && <img style={{ width: '100%', position: 'absolute', height: '15vh' }} src={table?.backdropImage} alt='' />}
        {!table?.backdropImage && <div style={{ width: '100%', position: 'absolute', height: '15vh' }}/>}
      </div>
      <div className="floor" id="floor" style={{
        background: `radial-gradient(
          44% 58% at 50% 50%,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.15) 24.84%,
          rgba(0, 0, 0, 0.98) 100%
        ),url(${table?.backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        zoom: `${window.innerWidth > 1100 ? (window.innerHeight < 750 ? (56 + ((window.innerHeight - 400)*0.13333333333333333)) : (98 + ((window.innerHeight - 715)*0.13618677042801555))) : (40 + ((window.innerWidth - 300)*0.13333333333))}%`
      }} >
        {minBidGoingUp && (minBid > 20) && table?.tournamentId && <div className="slowOpacityDown" style={{
          position: 'absolute',
          color: 'white',
          background: '#565656',
          borderRadius: '8px',
          zIndex: '100000',
          top: '53%',
          fontFamily: 'Oxanium',
          padding: '8px 20px 6px',
          fontSize: '20px',
        }}>Min. bid going up: {minBid}/{minBid*2}</div>}
        <div className="player-grid">
          <div style={isSpectator ? { marginTop: '-55px', gap: '460px' } : { marginTop: '-55px' }} className="player-sub-grid-edge">
            {((((opponents[opponents.length - 1]?.tablePosition - user?.tablePosition) > 3) || (user?.tablePosition > 3)) || (isSpectator && opponents[opponents.length - 1]?.tablePosition > 4)) ? (
              <Opponent
                cardImg = {table?.cardImage}
                Data={opponents.find((player) => {
                  if (!isSpectator) {
                    return ((player.tablePosition - user?.tablePosition === 4) || ((player.tablePosition - user?.tablePosition) === -3))
                  } else {
                    return (player.tablePosition === 5)
                  }
                } )}
                allPlayerHands={allPlayerHands}
                updateState={updateState}
                playerTurn={playerTurn}
                turnChanged={turnChanged}
                winners={winners}
                tableChipImage={table?.tableChipImage}
                chipImg={table?.chipImg}
                removeAnimationCircle={removeAnimationCircle}
                setremoveAnimationCircle={setremoveAnimationCircle}
                winnerAnnounced={winnerAnnounced}
                opponentPosition={4}
                displayAnimationChanged={displayAnimationChanged}
                displayAnimation={displayAnimation}
                socketGameTimer={socketGameTimer}
              />
            ) : (user ? 
              <Opponent
                cardImg = {table?.cardImage}
                isEmpty={true} 
                setremoveAnimationCircle={setremoveAnimationCircle}
              /> : ''
            )}
            {((((opponents[opponents.length - 1]?.tablePosition - user?.tablePosition) > 2) || (user?.tablePosition > 4)) || (isSpectator && opponents[opponents.length - 1]?.tablePosition > 3)) ? (
              <Opponent
                cardImg = {table?.cardImage}
                Data={opponents.find((player) => {
                  if (!isSpectator) {
                    return ((player.tablePosition - user?.tablePosition === 3) || ((player.tablePosition - user?.tablePosition) === -4))
                  } else {
                    return (player.tablePosition === 4)
                  }
                } )}
                playerTurn={playerTurn}
                allPlayerHands={allPlayerHands}
                updateState={updateState}
                turnChanged={turnChanged}
                winners={winners}
                tableChipImage={table?.tableChipImage}
                chipImg={table?.chipImg}
                removeAnimationCircle={removeAnimationCircle}
                setremoveAnimationCircle={setremoveAnimationCircle}
                winnerAnnounced={winnerAnnounced}
                opponentPosition={3}
                displayAnimationChanged={displayAnimationChanged}
                displayAnimation={displayAnimation}
                socketGameTimer={socketGameTimer}
              />
            ) : (user ? 
              <Opponent
                cardImg = {table?.cardImage}
                isEmpty={true} 
                setremoveAnimationCircle={setremoveAnimationCircle}
              /> : ''
            )}
          </div>
          <div style={{ marginTop: '50px', marginBottom: '63px' }}  className="player-sub-grid-center">
            {((((opponents[opponents.length - 1]?.tablePosition - user?.tablePosition) > 4) || (user?.tablePosition > 2)) || (isSpectator && opponents[opponents.length - 1]?.tablePosition > 5)) ? (
              <Opponent
                cardImg = {table?.cardImage}
                turnChanged={turnChanged}
                winners={winners}
                tableChipImage={table?.tableChipImage}
                chipImg={table?.chipImg}
                allPlayerHands={allPlayerHands}
                updateState={updateState}
                removeAnimationCircle={removeAnimationCircle}
                setremoveAnimationCircle={setremoveAnimationCircle}
                Data={opponents.find((player) => {
                  if (!isSpectator) {
                    return ((player.tablePosition - user?.tablePosition === 5) || ((player.tablePosition - user?.tablePosition) === -2))
                  } else {
                    return (player.tablePosition === 6)
                  }
                } )}
                playerTurn={playerTurn}
                winnerAnnounced={winnerAnnounced}
                opponentPosition={5}
                displayAnimationChanged={displayAnimationChanged}
                displayAnimation={displayAnimation}
                socketGameTimer={socketGameTimer}
              />
            ) : (user ? 
              <Opponent
                cardImg = {table?.cardImage}
                isEmpty={true} 
                setremoveAnimationCircle={setremoveAnimationCircle}
              /> : ''
            )}
            {((((opponents[opponents.length - 1]?.tablePosition - user?.tablePosition) > 1) || (user?.tablePosition > 5)) || (isSpectator && opponents[opponents.length - 1]?.tablePosition > 2)) ? (
              <Opponent
                cardImg = {table?.cardImage}
                Data={opponents.find((player) => {
                  if (!isSpectator) {
                    return ((player.tablePosition - user?.tablePosition === 2) || ((player.tablePosition - user?.tablePosition) === -5))
                  } else {
                    return (player.tablePosition === 3)
                  }
                } )}
                playerTurn={playerTurn}
                allPlayerHands={allPlayerHands}
                updateState={updateState}
                turnChanged={turnChanged}
                winners={winners}
                tableChipImage={table?.tableChipImage}
                chipImg={table?.chipImg}
                removeAnimationCircle={removeAnimationCircle}
                setremoveAnimationCircle={setremoveAnimationCircle}
                winnerAnnounced={winnerAnnounced}
                opponentPosition={2}
                displayAnimationChanged={displayAnimationChanged}
                displayAnimation={displayAnimation}
                socketGameTimer={socketGameTimer}
              />
            ) : (user ? 
              <Opponent
                cardImg = {table?.cardImage}
                isEmpty={true} 
                setremoveAnimationCircle={setremoveAnimationCircle}
              /> : ''
            )}
          </div>
          <div style={isSpectator ? { gap: '460px' } : {}} className="player-sub-grid-edge">
            {((((opponents[opponents.length - 1]?.tablePosition - user?.tablePosition) > 5) || (user?.tablePosition > 1)) || (isSpectator && opponents[opponents.length - 1]?.tablePosition > 6))? (
              <Opponent
                cardImg = {table?.cardImage}
                Data={opponents.find((player) => {
                  if (!isSpectator) {
                    return ((player.tablePosition - user?.tablePosition === 6) || ((player.tablePosition - user?.tablePosition) === -1))
                  } else {
                    return (player.tablePosition === 7)
                  }
                 })}
                playerTurn={playerTurn}
                allPlayerHands={allPlayerHands}
                updateState={updateState}
                turnChanged={turnChanged}
                winners={winners}
                tableChipImage={table?.tableChipImage}
                chipImg={table?.chipImg}
                removeAnimationCircle={removeAnimationCircle}
                setremoveAnimationCircle={setremoveAnimationCircle}
                winnerAnnounced={winnerAnnounced}
                opponentPosition={6}
                displayAnimationChanged={displayAnimationChanged}
                displayAnimation={displayAnimation}
                socketGameTimer={socketGameTimer}
              />
            ) : (user ? 
              <Opponent
                cardImg = {table?.cardImage}
                isEmpty={true} 
                setremoveAnimationCircle={setremoveAnimationCircle}
              /> : ''
            )}
            {((((opponents[opponents.length - 1]?.tablePosition - user?.tablePosition) > 0) || (user?.tablePosition > 6)) || (isSpectator && opponents[opponents.length - 1]?.tablePosition > 1)) ? (
              <Opponent
                cardImg = {table?.cardImage}
                Data={opponents.find((player) => {
                  if (!isSpectator) {
                    return ((player.tablePosition - user?.tablePosition === 1) || ((player.tablePosition - user?.tablePosition) === -6)) 
                  } else {
                    return (player.tablePosition === 2)
                  }
                })}
                playerTurn={playerTurn}
                allPlayerHands={allPlayerHands}
                updateState={updateState}
                turnChanged={turnChanged}
                winners={winners}
                tableChipImage={table?.tableChipImage}
                chipImg={table?.chipImg}
                removeAnimationCircle={removeAnimationCircle}
                setremoveAnimationCircle={setremoveAnimationCircle}
                winnerAnnounced={winnerAnnounced}
                opponentPosition={1}
                displayAnimationChanged={displayAnimationChanged}
                displayAnimation={displayAnimation}
                socketGameTimer={socketGameTimer}
              />
            ) : (user ? 
              <Opponent
                cardImg = {table?.cardImage}
                isEmpty={true} 
                setremoveAnimationCircle={setremoveAnimationCircle}
              /> : ''
            )}
          </div>
        </div>
        <img src={table?.logoImage} alt="BLVCK" className="banner" id="banner" />
        <div className="table" id="table">
          <img src={table?.materialImage} alt="" className="table" style={{zIndex: 0}}/>
          <img src={table?.carpetImage} alt="" className="carpet-image-in-table"/>
          <DealerPositionPath activePlayers={activePlayers} />
          <div className="dealer" id="dealer">
            <img src={table?.dealerImage} alt="" />
          </div>
          <OnTableContent tableChipImage={table?.tableChipImage} startingNewRoundType={startingNewRoundType}
          chipImg={table?.chipImg} cardImg={table?.cardImage} TableCards={tableCards} Pot={pot} RaisedBy={raisedBy} initialTableCardsLength={initialTableCardsLength} activePlayers={activePlayers} startingNewRound={startingNewRound} setstartingNewRound={setstartingNewRound} isTournament={table?.tournamentId ? true : false} />
        </div>
        {user && <div style={isSpectator ? (window.innerHeight > 730 ? { bottom: '93px' } : { bottom: '63px' }) : {}} className="user">
          {!isSpectator ? <User
            socketGameTimer={socketGameTimer}
            cardImg = {table?.cardImage}
            Player={user}
            hand={playerHands}
            hasTurn={playerTurn === walletAddress}
            turnChanged={turnChanged}
            winners={winners}
            tableChipImage={table?.tableChipImage}
            chipImg={table?.chipImg}
            updateState={updateState}
            removeAnimationCircle={removeAnimationCircle}
            setremoveAnimationCircle={setremoveAnimationCircle}
            hasFolded={user?.status === "Fold"}
            playerHandRank={tableCards.length > 0 ? playerHandRank : ""}
            winnerAnnounced={winnerAnnounced}
          /> : <Opponent
            cardImg = {table?.cardImage}
            Data={opponents.find((player) => (player.tablePosition === 1) )}
            playerTurn={playerTurn}
            allPlayerHands={allPlayerHands}
            updateState={updateState}
            turnChanged={turnChanged}
            winners={winners}
            tableChipImage={table?.tableChipImage}
            chipImg={table?.chipImg}
            removeAnimationCircle={removeAnimationCircle}
            setremoveAnimationCircle={setremoveAnimationCircle}
            opponentPosition={7}
            displayAnimationChanged={displayAnimationChanged}
            displayAnimation={displayAnimation}
            socketGameTimer={socketGameTimer}
        />}

        {!isSpectator && <div className="mobile-extra-type-print">{''}</div>}
        </div>}
        {!isSpectator && user && <div className="controls">
          <Controls
            Bet={amount}
            minBid={minBid}
            SetBet={setamount}
            playerTurn={playerTurn}
            winnerAnnounced={winnerAnnounced}
            socketId={socketId}
            walletAddress={walletAddress}
            fold={handleFold}
            check={handleCheck}
            raisedBy={raisedBy}
            user={user}
            raise={handleRaise}
            value={value}
          />
        </div>}

        <div className="utils-left">
          <div onClick={() => setSummary(true)}>
            <Button type="small" text={'Summary'} />
          </div>
          {table?.tournamentId && <div onClick={() => setopenLeaderboard(true)}>
            <Button type="small" text={'Leaderboard'} />
          </div>}
        </div>
        {/* <div className="utils-right"> */}
          {/* <div onClick={() => setChat(true)}>
            <Button type="small" icon={icon3} />
          </div> */}
          {/* <div onClick={() => setEdit(true)}>
            <Button type="small" icon={icon4} />
          </div> */}
          {/* <SitOut />
          <div className="sitout-text">sit out next round</div> */}
        {/* </div> */}
      </div>

      {(window.innerWidth < 1100) && !(playerTurn !== walletAddress || winnerAnnounced || user?.status === "Fold") && <div className="slider-amount-in-mobile">
        <AmountSlider minBid={minBid}  user={user} table={table} getCurrentChip={getCurrentChip} value={amount} setValue={setamount} />
      </div>}

      {/* {chat ? <Chat SetChat={setChat} /> : ""} */}
      {window?.innerWidth >= 1100 && <Chat socketObj={socket} tableId={tableId} isSpectator={isSpectator} />}
      {/* {edit ? <Edit SetEdit={setEdit} /> : ""} */}
      <SwipeableDrawer
            anchor={window?.innerWidth > 1100 ? 'left' : 'top'}
            open={summary}
            onClose={(e) => {
              setSummary(false)
              setTimeout(() => {
                dispatch(emptyRound())
              }, 1000)
            }}
            transitionDuration={750}
            onOpen={(e) => setSummary(true)}
            className='bg-[#0000009C] transition-opacity ease-in-out'
            PaperProps={{
                sx: {
                    backdropFilter: 'blur(50px)',
                    borderRadius: '0 !important',
                    color: 'black',
                    zIndex: '2000',
                    // height: '100%',
                    height: window?.innerWidth > 1100 ? '100%' : '440px',
                    width: window?.innerWidth > 1100 ? '440px' : '100%',
                    // width: '440px',
                    background: 'linear-gradient(150.54deg, #121212 4.78%, #000000 100.46%)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    padding: '16px',
                },
            }}
            ModalProps={{
                sx: {
                    zIndex: '1250',
                },
            }}
        >
              <Summary summary={summary} activePlayers={activePlayers} SetSummary={setSummary} table={table} tableCards={tableCards} rounds={currentRound} playerHands={playerHands} currentRoundActivity={currentRoundActivity} winners={winners} allPlayerHands={allPlayerHands} Pot={pot} />
        </SwipeableDrawer>

      <SwipeableDrawer
            anchor={'left'}
            open={openLeaderboard}
            onClose={(e) => {
              setopenLeaderboard(false)
              setTimeout(() => {
                dispatch(emptyRound())
              }, 1000)
            }}
            transitionDuration={750}
            onOpen={(e) => setopenLeaderboard(true)}
            className='bg-[#0000009C] transition-opacity ease-in-out'
            PaperProps={{
                sx: {
                    backdropFilter: 'blur(50px)',
                    borderRadius: '0 !important',
                    color: 'black',
                    zIndex: '2000',
                    height: '100%',
                    width: '440px',
                    background: 'linear-gradient(150.54deg, #121212 4.78%, #000000 100.46%)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    padding: '16px',
                },
            }}
            ModalProps={{
                sx: {
                    zIndex: '1250',
                },
            }}
        >
              <TableLeaderboard summary={openLeaderboard} SetopenLeaderboard={setopenLeaderboard} leaderboardData={leaderboardData} chipImg={table?.chipImg} />
        </SwipeableDrawer>

        <QuitModal table={table} openModal={openQuitModal} setOpenModal={setOpenQuitModal} slug={table?.slug} />
        <LoginModal setOpenGuestModal={setOpenGuestModal} checkOnlyDiscordConnect={checkOnlyDiscordConnect} checkOnlyWallet={checkOnlyWallet} openModal={openLoginModal} setOpenModal={() => {setOpenLoginModal(false)}} backUrl={`/pokertable/${table?._id}`} setOpenProfileModal={setOpenCreateModal} />
        {openGuestModal && <CreateGuestModal setOpenConnectModal={setOpenLoginModal} backUrl={`/pokertable/${table?._id}`} openModal={openGuestModal} setOpenModal={setOpenGuestModal} setOpenCreateModal={setOpenCreateModal} />}
        {(!openTournamentWinner && openModalWinner && (winners?.length > 0)) && <WinnerRoundModal table={table} winners={winners} setremoveAnimationCircle={setremoveAnimationCircle} openModal={openModalWinner} setOpenModal={setopenModalWinner} currentRound={currentRound} cardImg={table?.cardImage} nextRoundTimer={nextRoundTimer} />}
        {openTournamentWinner && <TournamentWinnerModal table={table} openModal={openTournamentWinner} setOpenModal={setopenTournamentWinner} user={user} leaderboardData={leaderboardData} tournamentRank={tournamentRank} />}
        {openNavigateTable && <NavigateTableModal openModal={openNavigateTable} setOpenModal={setopenNavigateTable} navigatingTimer={navigatingTimer} tableNumber={tableNumber} leaderboardData={leaderboardData} />}
        {openInsufficientBalanceModal && <InsufficeintFundModal buyInChips={buyInChips} joinTable={joinTable} joinSpectatorTable={joinSpectatorTable} connectBtn={connectBtn} validate={currentTableId === table?._id} openModal={openInsufficientBalanceModal} navigationOff setOpenModal={() => setOpenInsufficientBalanceModal(false)} table={table} />}
        {openTableModal && <PlayGameModal setCheckOnlyDiscordConnect={setCheckOnlyDiscordConnect} setCheckOnlyWallet={setCheckOnlyWallet} joinTable={joinTable} joinSpectatorTable={joinSpectatorTable} connectBtn={connectBtn} validate={currentTableId === table?._id} openModal={openTableModal} navigationOff setOpenModal={() => setOpenTableModal(false)} table={table} />}
        {openCreateModal && <CreateProfileModal openModal={openCreateModal} setOpenModal={setOpenCreateModal} />}

      {/* Poker User Cards */}
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-1.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-2.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-3.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-4.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-5.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-6.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-7.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-8.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-9.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-10.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-11.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-12.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Club-13.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-1.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-2.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-3.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-4.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-5.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-6.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-7.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-8.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-9.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-10.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-11.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-12.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Heart-13.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-1.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-2.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-3.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-4.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-5.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-6.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-7.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-8.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-9.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-10.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-11.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-12.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Diamond-13.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-1.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-2.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-3.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-4.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-5.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-6.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-7.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-8.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-9.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-10.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-11.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-12.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693861020/ArcadeVerse%20User%20Cards/Spade-13.svg" alt='' />

      {/* Poker Table Cards */}
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-1.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-2.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-3.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-4.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-5.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-6.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-7.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-8.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-9.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-10.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-11.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-12.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Club-13.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-1.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-2.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-3.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-4.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-5.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-6.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-7.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-8.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-9.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-10.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-11.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-12.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Heart-13.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-1.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-2.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-3.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-4.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-5.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-6.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-7.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-8.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-9.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-10.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-11.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-12.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Diamond-13.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-1.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-2.svg"  alt=''/>
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-3.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-4.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-5.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-6.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-7.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-8.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-9.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-10.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-11.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-12.svg" alt='' />
      <img style={{ display: 'none' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/Spade-13.svg" alt='' />
    </div>
  );
};

export default PlayingArea;

const Eye = () => {
  return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  )
}

const AmountRangeSlider = styled(Slider)(({ theme }) => ({
  color: '#FFFFFF8F',
  height: 7,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
      height: 29,
      width: 21,
      backgroundColor: '#E6C8FF',
      border: 'none',
      borderRadius: 4,
      '&:hover': {
          boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
      },
  },
  '& .MuiSlider-track': {
      height: 7,
  },
  '& .MuiSlider-rail': {
      color: '#FFFFFF8F',
      opacity: 1,
      height: 7,
  },
}));


const AmountSlider = React.memo(({table, getCurrentChip, value, setValue, user, minBid}) => {
  const chipType = table?.chipName === 'Play Chips' ? 1  : 2
  const {userInfo} = useSelector((state) => state.userLoginReducer)

  function handleInputChange(e) {
      let target = e.target

      const val = target.value

      if (val >= minBid) {
        setValue(val);
      }
  }

  return(
    <div className="slider-wrapper-custom">
      <AmountRangeSlider
        aria-label="slider"
        slots={{ thumb: AmountRangeComponent }}
        min={minBid}
        max={user?.chips}
        value={value || 0}
        defaultValue={minBid}
        onChange={handleInputChange}
      />
    </div>
  )
})

const AmountRangeComponent = React.memo((props) => {
  const { children, ...other } = props;
  return (
      <SliderThumb {...other}>
          {children}
          <span className={'custom-bar-mobile-slider'} />
          <span className={'custom-bar-mobile-slider'} />
          <span className={'custom-bar-mobile-slider'} />
      </SliderThumb>
  );
})
