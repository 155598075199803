import React from "react";

const FoldButton = ({ text, fold, hasFold, playerTurn, winnerAnnounced, walletAddress }) => {
  return (
    <button
      className="button-medium"
      onClick={() => {
        document.getElementById("fold-audio").play();
        fold();
      }}
      disabled={playerTurn !== walletAddress || winnerAnnounced || hasFold}
    >
      <div className="button-bottom-layer-medium-fold">
        <div className="button-border-layer">
          <div className="button-top-layer-medium-fold">{text}</div>
        </div>
      </div>
    </button>
  );
};

export default FoldButton;
