import { Popover } from '@mui/material'
import styles from './SelectCoinPopover.module.css'
import React from 'react'

const SelectCoinPopover = ({
    id,
    open,
    handleClose,
    anchorEl,
    outputIndex,
    setOutputIndex,
    coins
}) => {
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                top: '10px',
                '& .MuiPopover-paper': {
                    background: 'transparent',
                },
            }}
        >
            <div className={`${styles.popover_wrapper}`}>
                {coins?.map((coin, key) => (
                    <PopRow
                        coin={coin}
                        key={key}
                        handleClose={handleClose}
                        index={outputIndex}
                        setIndex={setOutputIndex}
                        thisIndex={key}
                    />
                ))}
            </div>
        </Popover>
    )
}

export default SelectCoinPopover

const PopRow = ({coin, setIndex, thisIndex, index, handleClose}) =>{
    return(
        <div className={index === thisIndex ? styles.selected_button_holder : styles.button_holder}
            onClick={(e) => {setIndex(thisIndex); handleClose(e)}}
        >
            <figure className={styles.fig_wrapper}>
                <img src={coin?.coin} alt="" />
            </figure>
            <div className='flex flex-col gap-[10px]'>
                <h2 className={styles.coinName}>{coin?.name}</h2>
                <h3 className={styles.coinValue}>{coin?.value}</h3>
            </div>
        </div>
    )
}