import texture1 from './Assets/Images/Tables/texture_1.png'
import texture2 from './Assets/Images/Tables/texture_2.png'
import texture3 from './Assets/Images/Tables/texture_3.png'
import texture4 from './Assets/Images/Tables/texture_4.png'
import texture5 from './Assets/Images/Tables/texture_5.png'
import texture6 from './Assets/Images/Tables/texture_6.png'
import texture7 from './Assets/Images/Tables/texture_7.png'
import table1 from './Assets/Images/Tables/table_1.png'
import table2 from './Assets/Images/Tables/table_2.png'
import table3 from './Assets/Images/Tables/table_3.png'
import table4 from './Assets/Images/Tables/table_4.png'
import table5 from './Assets/Images/Tables/table_5.png'
import table6 from './Assets/Images/Tables/table_6.png'
import table7 from './Assets/Images/Tables/table_7.png'
import floor1 from './Assets/Images/Tables/floor_1.png'
import floor2 from './Assets/Images/Tables/floor_2.png'
import floor3 from './Assets/Images/Tables/floor_3.png'
import floor4 from './Assets/Images/Tables/floor_4.png'
import floor5 from './Assets/Images/Tables/floor_5.png'

export const tableSetting = [
    {
        _id: 1,
        texture: texture1,
        table: table1
    },
    {
        _id: 2,
        texture: texture2,
        table: table2
    },
    {
        _id: 3,
        texture: texture3,
        table: table3
    },
    {
        _id: 4,
        texture: texture4,
        table: table4
    },
    {
        _id: 5,
        texture: texture5,
        table: table5
    },
    {
        _id: 6,
        texture: texture6,
        table: table6
    },
    {
        _id: 7,
        texture: texture7,
        table: table7
    },
]

export const floors = [
    floor1,
    floor2,
    floor3,
    floor4,
    floor5
]

export const textures = [
    texture1, texture2, texture3, texture4, texture5, texture6, texture7
]

// export const tables = [
//     texture1, texture2, texture3, texture4, texture5, texture6, texture7
// ]