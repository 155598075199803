import React from "react";

const CheckButton = ({
  text,
  check,
  hasFold,
  playerTurn,
  user,
  winnerAnnounced,
  walletAddress,
  raisedBy
}) => {

  return (
    <button
      className="button-medium"
      onClick={() => {
        document.getElementById("check-audio").play();
        check();
      }}
      disabled={(playerTurn !== walletAddress) || winnerAnnounced || (raisedBy > user?.turnAmount) || hasFold}
    >
      <div className="button-bottom-layer-medium-check">
        <div className="button-border-layer">
          <div className="button-top-layer-medium-check">{text}</div>
        </div>
      </div>
    </button>
  );
};

export default CheckButton;
