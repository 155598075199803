import React from 'react'
import styles from './Table.module.css'
import player from '../../../Assets/Images/playerModel2.png'
import { useSelector } from 'react-redux'

// const data = [
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
//     {
//         name: 'Linkin Park',
//         walletAddress: '0x543534543543543433',
//         gamesPlayed: 350,
//         gamesWon: 600
//     },
// ]

const Table = () => {
    const {isLogin, userInfo} = useSelector((state) => state.userLoginReducer)
    const {leaderboardData, loading} = useSelector((state) => state.getLeaderboardReducer)

    return (
        <div className={styles.table_wrapper}>
            <table>
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>username</th>
                        <th>XP</th>
                        {/* <th>games won</th> */}
                    </tr>
                </thead>
                <tbody>
                    {leaderboardData?.map((user, key) => {
                            if(isLogin && userInfo?.walletAddress === user?.walletAddress){
                                return(
                                    <tr className={styles.make_it_fix} key={key}>
                                        <td>{key+1}.</td>
                                        <td>
                                            <div className={styles.holder_item_td}>
                                                <figure className={styles.image_holder}>
                                                    <img src={user?.image} alt="" />
                                                </figure>
                                                <div className={styles.align_items_td}>
                                                    <h1>{user?.username}</h1>
                                                    <p>{user?.walletAddress?.substring(0, 7) + '...' + user?.walletAddress?.substring(user?.walletAddress?.length - 3, user?.walletAddress?.length)}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{user?.weeklyXP}</td>
                                        {/* <td>{user?.gamesPlayed}</td> */}
                                    </tr>
                                )
                            }else{
                                return(
                                    <tr key={key}>
                                        <td>{key+1}.</td>
                                        <td>
                                            <div className={styles.holder_item_td}>
                                                <figure className={styles.image_holder}>
                                                    <img src={user?.image} alt="" />
                                                </figure>
                                                <div className={styles.align_items_td}>
                                                    <h1>{user?.username}</h1>
                                                    <p>{user?.walletAddress?.substring(0, 7) + '...' + user?.walletAddress?.substring(user?.walletAddress?.length - 3, user?.walletAddress?.length)}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{user?.weeklyXP}</td>
                                        {/* <td>{user?.gamesPlayed}</td> */}
                                    </tr>
                                )
                            }
                        })}
                    {loading && Array(3).fill(0, 0)?.map((i, k) => <tr key={k} className={`animate-pulse`} style={{
                        height: '50px',
                        background: 'linear-gradient(270deg, rgba(149, 149, 149, 0.03) 0%, rgba(61, 61, 61, 0.64) 100%), #010101',
                        boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0.65)',
                        border: 'none',
                        outline: 'none',
                    }}>
                        <td>
                            <div className='animate-pulse' style={{
                                width: '23px',
                                height: '13px',
                                borderRadius: '8px',
                                background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                            }} />
                        </td>
                        <td>
                            <div className={styles.holder_item_td}>
                                <figure className={`animate-pulse ${styles.image_holder}`} style={{
                                    background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                                }} />
                                <div className={`gap-1 ${styles.align_items_td}`}>
                                    <div className='animate-pulse' style={{
                                        width: '71px',
                                        height: '14px',
                                        borderRadius: '8px',
                                        background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                                    }} />
                                    <div className='animate-pulse' style={{
                                        width: '154px',
                                        height: '10.5px',
                                        borderRadius: '8px',
                                        background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                                    }} />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className='animate-pulse' style={{
                                width: '23px',
                                height: '13px',
                                marginLeft: 'auto',
                                borderRadius: '8px',
                                background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                            }} />
                        </td>
                        {/* <td>
                            <h1 className='animate-pulse' style={{
                                width: '35px',
                                height: '13px',
                                borderRadius: '8px',
                                marginLeft: 'auto',
                                background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                            }}>{''}</h1>
                        </td> */}
                    </tr>)}
                    {/* {isLogin && <tr className={styles.make_it_fix}>
                        <td>8.</td>
                        <td>
                            <div className={styles.holder_item_td}>
                                <figure className={styles.image_holder}>
                                    <img src={player} alt="" />
                                </figure>
                                <div className={styles.align_items_td}>
                                    <h1>{userInfo?.username}</h1>
                                    <p>{userInfo?.walletAddress}</p>
                                </div>
                            </div>
                        </td>
                        <td>{100}</td>
                        <td>{300}</td>
                    </tr>} */}
                </tbody>
            </table>
        </div>
    )
}

export default Table
