export const PlayButtonSvg = ({isActive}) => {
    return (
        <svg
            width='220'
            height='56'
            viewBox='0 0 220 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M9.21466 56H210.785L220 49.1429H0L9.21466 56Z'
                fill='black'
            />
            <path
                d='M13.1955 7.84997C14.2549 4.535 17.3357 2.28516 20.8159 2.28516H199.184C202.664 2.28516 205.745 4.535 206.804 7.84997L220 49.1423H0L13.1955 7.84997Z'
                fill='#1B1B1B'
            />
            <path
                d='M88.0034 32.2852V20.7812H97.2834C97.6888 20.7812 98.0621 20.8825 98.4034 21.0852C98.7448 21.2878 99.0168 21.5598 99.2194 21.9012C99.4221 22.2318 99.5234 22.5998 99.5234 23.0052V26.1092C99.5234 26.5145 99.4221 26.8878 99.2194 27.2292C99.0168 27.5705 98.7448 27.8425 98.4034 28.0452C98.0621 28.2372 97.6888 28.3332 97.2834 28.3332L90.1154 28.3492V32.2852H88.0034ZM90.3074 26.2052H97.1874C97.2408 26.2052 97.2888 26.1892 97.3314 26.1572C97.3741 26.1252 97.3954 26.0825 97.3954 26.0292V23.0852C97.3954 23.0318 97.3741 22.9892 97.3314 22.9572C97.2888 22.9145 97.2408 22.8932 97.1874 22.8932H90.3074C90.2541 22.8932 90.2061 22.9145 90.1634 22.9572C90.1314 22.9892 90.1154 23.0318 90.1154 23.0852V26.0292C90.1154 26.0825 90.1314 26.1252 90.1634 26.1572C90.2061 26.1892 90.2541 26.2052 90.3074 26.2052ZM101.676 32.2852V20.7492H103.788V30.1572H113.196V32.2852H101.676ZM115.16 32.2852V22.9892C115.16 22.5838 115.262 22.2158 115.464 21.8852C115.667 21.5438 115.934 21.2718 116.264 21.0692C116.606 20.8665 116.979 20.7652 117.384 20.7652H124.44C124.846 20.7652 125.219 20.8665 125.56 21.0692C125.902 21.2718 126.174 21.5438 126.376 21.8852C126.579 22.2158 126.68 22.5838 126.68 22.9892V32.2852H124.552V28.4132H117.272V32.2852H115.16ZM117.272 26.3012H124.552V23.0852C124.552 23.0318 124.531 22.9892 124.488 22.9572C124.446 22.9145 124.398 22.8932 124.344 22.8932H117.464C117.411 22.8932 117.363 22.9145 117.32 22.9572C117.288 22.9892 117.272 23.0318 117.272 23.0852V26.3012ZM133.687 32.2852V27.9652L128.375 20.7652H130.871L134.759 25.6452L138.599 20.7652H141.143L135.815 27.9812V32.2852H133.687Z'
                fill='white'
            />
        </svg>
    )
}
