import styles from './DeleteProfileModal.module.css'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentLevel } from '../../../../constants'
import { deleteUser } from '../../../../redux/actions/userAction'

const DeleteProfileModal = ({openModal, setOpenModal}) => {
    const dispatch = useDispatch()
    const cancelButtonRef = useRef(null)

    const { userInfo } = useSelector((state) => state.userLoginReducer)
    const [loading, setLoading] = useState(false)

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0 overflow-y-auto'
                initialFocus={cancelButtonRef}
                onClose={loading ? () => {} : setOpenModal}
            >
                <div className='h-screen px-4 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[#0c0c0d9a] bg-opacity-75 backdrop-blur-[3px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block bg-transparent rounded-lg text-left transform transition-all my-8 align-middle max-w-[500px] w-full'>
                            <div
                                className={`${styles.modal_background} flex justify-center w-full items-center rounded-lg text-white h-full p-10 flex-col gap-y-8 border border-[#3D3D3D]`}
                            >
                                <div className={styles.top_header}>
                                    <h1>Delete Profile</h1>

                                    {!loading && <button onClick={() => setOpenModal(false)}>
                                        <Close />
                                    </button>}
                                </div>

                                <div className={styles.divider}></div>

                                <div className={styles.modal_main_cntnt}>
                                    <div className={styles.admin_back}>
                                        <div className='flex items-center gap-3'>
                                            <figure className={styles.admin_img_holder}>
                                                {userInfo?.image && <img src={userInfo?.image} alt="" />}
                                            </figure>
                                            <div className={styles.vertical_align}>
                                                <h1 className={userInfo?.username ? '' : styles.null}>{userInfo?.username || 'No Name'}</h1>
                                                <h2>{userInfo?.walletAddress?.substring(0, 4) + '......' + userInfo?.walletAddress?.substring(userInfo?.walletAddress?.length - 3, userInfo?.walletAddress?.length)}</h2>
                                            </div>
                                        </div>
                                        <div className={styles.user_level_details}>
                                            <Crown />
                                            <span>Level {getCurrentLevel(userInfo?.overallXP || 0)}</span>
                                        </div>
                                    </div>

                                    <div className={styles.card_details}>
                                        <h1>Are you sure you want to delete your profile? </h1>
                                        <p>Your socials will be disconnected & all your game progress will be lost.</p>
                                    </div>

                                    {/* main-part */}
                                    <div className={styles.btn_holder}>
                                        <button className={`secondary-custom-button w-full`} onClick={() => setOpenModal(false)}>
                                            <div className={` ${styles.cancel_btn} secondary-custom-button-inner`}>Go Back</div>
                                        </button>
                                        <button className={`primary-custom-button w-full`} disabled={loading} onClick={() => {
                                            dispatch(deleteUser(setLoading, setOpenModal))
                                        }}>
                                            <div className={`primary-custom-button-inner ${styles.success_btn}`}>{loading ? 'Deleting...' : 'Delete'}</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default React.memo(DeleteProfileModal)

const Close = () =>{
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}

const Crown = () => {
    return (<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0244 13.2806L5.25012 5.90643L2.35522 13.2806H11.0244Z" fill="#F4900C"/>
    <path d="M13.9517 4.30426L8.84717 13.9402H19.0555L13.9517 4.30426Z" fill="#F4900C"/>
    <path d="M16.9758 13.2806L22.7501 5.90643L25.645 13.2806H16.9758Z" fill="#F4900C"/>
    <path d="M22.2982 11.8145L18.5789 2.7401L14.0055 11.8152L14.0001 11.8176L13.9946 11.8145L9.4213 2.73932L5.70195 11.8145C3.26439 8.42567 0.995605 6.25489 0.995605 6.25489C0.995605 6.25489 2.33339 16.1389 2.33339 25.6667H25.6668C25.6668 16.1389 27.0046 6.25489 27.0046 6.25489C27.0046 6.25489 24.7358 8.42567 22.2982 11.8145Z" fill="#FFCC4D"/>
    <path d="M13.9666 19.9796C15.5508 19.9796 16.8351 18.6954 16.8351 17.1112C16.8351 15.527 15.5508 14.2427 13.9666 14.2427C12.3824 14.2427 11.0981 15.527 11.0981 17.1112C11.0981 18.6954 12.3824 19.9796 13.9666 19.9796Z" fill="#5C913B"/>
    <path d="M20.5823 18.9871C21.6184 18.9871 22.4583 18.1472 22.4583 17.1111C22.4583 16.075 21.6184 15.2351 20.5823 15.2351C19.5462 15.2351 18.7063 16.075 18.7063 17.1111C18.7063 18.1472 19.5462 18.9871 20.5823 18.9871Z" fill="#981CEB"/>
    <path d="M25.5518 18.6559C26.4049 18.6559 27.0964 17.9643 27.0964 17.1112C27.0964 16.2581 26.4049 15.5665 25.5518 15.5665C24.6987 15.5665 24.0071 16.2581 24.0071 17.1112C24.0071 17.9643 24.6987 18.6559 25.5518 18.6559Z" fill="#DD2E44"/>
    <path d="M7.34988 18.9871C8.38597 18.9871 9.22589 18.1472 9.22589 17.1111C9.22589 16.075 8.38597 15.2351 7.34988 15.2351C6.31379 15.2351 5.47388 16.075 5.47388 17.1111C5.47388 18.1472 6.31379 18.9871 7.34988 18.9871Z" fill="#981CEB"/>
    <path d="M2.38085 18.6559C3.23395 18.6559 3.92553 17.9643 3.92553 17.1112C3.92553 16.2581 3.23395 15.5665 2.38085 15.5665C1.52775 15.5665 0.836182 16.2581 0.836182 17.1112C0.836182 17.9643 1.52775 18.6559 2.38085 18.6559Z" fill="#DD2E44"/>
    <path d="M25.6669 26.4445H2.33344C2.12716 26.4445 1.92933 26.3626 1.78347 26.2167C1.63761 26.0708 1.55566 25.873 1.55566 25.6667C1.55566 25.4605 1.63761 25.2626 1.78347 25.1168C1.92933 24.9709 2.12716 24.889 2.33344 24.889H25.6669C25.8731 24.889 26.071 24.9709 26.2168 25.1168C26.3627 25.2626 26.4446 25.4605 26.4446 25.6667C26.4446 25.873 26.3627 26.0708 26.2168 26.2167C26.071 26.3626 25.8731 26.4445 25.6669 26.4445ZM25.6669 23.7332H2.33344C2.12716 23.7332 1.92933 23.6512 1.78347 23.5054C1.63761 23.3595 1.55566 23.1617 1.55566 22.9554C1.55566 22.7491 1.63761 22.5513 1.78347 22.4054C1.92933 22.2596 2.12716 22.1776 2.33344 22.1776H25.6669C25.8731 22.1776 26.071 22.2596 26.2168 22.4054C26.3627 22.5513 26.4446 22.7491 26.4446 22.9554C26.4446 23.1617 26.3627 23.3595 26.2168 23.5054C26.071 23.6512 25.8731 23.7332 25.6669 23.7332Z" fill="#FFAC33"/>
    <path d="M1.12534 7.3492C1.72973 7.3492 2.21968 6.85924 2.21968 6.25486C2.21968 5.65047 1.72973 5.16052 1.12534 5.16052C0.520957 5.16052 0.0310059 5.65047 0.0310059 6.25486C0.0310059 6.85924 0.520957 7.3492 1.12534 7.3492Z" fill="#FFCC4D"/>
    <path d="M5.25011 6.83354C5.76214 6.83354 6.17723 6.41845 6.17723 5.90642C6.17723 5.39439 5.76214 4.97931 5.25011 4.97931C4.73808 4.97931 4.323 5.39439 4.323 5.90642C4.323 6.41845 4.73808 6.83354 5.25011 6.83354Z" fill="#F4900C"/>
    <path d="M9.42125 4.12766C10.1876 4.12766 10.8088 3.50643 10.8088 2.7401C10.8088 1.97377 10.1876 1.35254 9.42125 1.35254C8.65492 1.35254 8.03369 1.97377 8.03369 2.7401C8.03369 3.50643 8.65492 4.12766 9.42125 4.12766Z" fill="#FFCC4D"/>
    <path d="M26.8746 7.3492C27.479 7.3492 27.9689 6.85924 27.9689 6.25486C27.9689 5.65047 27.479 5.16052 26.8746 5.16052C26.2702 5.16052 25.7803 5.65047 25.7803 6.25486C25.7803 6.85924 26.2702 7.3492 26.8746 7.3492Z" fill="#FFCC4D"/>
    <path d="M22.7501 6.83354C23.2621 6.83354 23.6772 6.41845 23.6772 5.90642C23.6772 5.39439 23.2621 4.97931 22.7501 4.97931C22.2381 4.97931 21.823 5.39439 21.823 5.90642C21.823 6.41845 22.2381 6.83354 22.7501 6.83354Z" fill="#F4900C"/>
    <path d="M18.5787 4.12766C19.345 4.12766 19.9663 3.50643 19.9663 2.7401C19.9663 1.97377 19.345 1.35254 18.5787 1.35254C17.8124 1.35254 17.1912 1.97377 17.1912 2.7401C17.1912 3.50643 17.8124 4.12766 18.5787 4.12766Z" fill="#FFCC4D"/>
    <path d="M13.9518 5.69181C14.7181 5.69181 15.3393 5.07058 15.3393 4.30425C15.3393 3.53792 14.7181 2.91669 13.9518 2.91669C13.1854 2.91669 12.5642 3.53792 12.5642 4.30425C12.5642 5.07058 13.1854 5.69181 13.9518 5.69181Z" fill="#F4900C"/>
    </svg>
    )
}