import React from 'react'
import Loader from '../../Loader/Loader'
import LoaderBig from '../../LoaderBig/LoaderBig'

const LoaderPage = () => {
    return (
        // <pre>Loading...</pre>
        <LoaderBig />
    )
}

export default LoaderPage