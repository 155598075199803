import { styled } from '@mui/material/styles'
import { Switch } from '@mui/material'

const CustomSwitch = styled((props) => (
    <Switch
        focusVisibleClassName='.Mui-focusVisible'
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 30,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        color: '#484E56',

        background: '#232323',
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(11px)',
            color: '#FFFFFF',
            '& + .MuiSwitch-track': {
                background: '#232323',
                boxShadow: 'inset 0px 3px 20px rgba(0, 0, 0, 0.15)',
                opacity: 1,
                border: '3px solid #898989',
            },
            '& + .MuiSwitch-thumb': {
                // background: '#ED6843',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 1,
            },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            // color: '#ED6843',
            // background: '#ED6843',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 1 : 1,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 14,
        height: 14,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        background: 'linear-gradient(180deg, #282B30 0%, #1E2124 100%)',
        boxShadow: 'inset 0px 3px 20px rgba(0, 0, 0, 0.15)',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        border: '3px solid #373C42',
    },
}))

const FoxSwitch = ({ selected, value, onChange }) => {
    return (
        <CustomSwitch
            defaultChecked={selected}
            checked={value}
            onChange={(e) => {
                onChange(e.target.checked)
            }}
        />
    )
}

export default FoxSwitch