import React from 'react'
import awards from '../../../Assets/svgs/prize_awards.svg'
// import prize_back_lines from '../../../Assets/svgs/prize_back_lines.svg'
import styles from './PrizeSection.module.css'
import TournamentCard from '../TournamentCard/TournamentCard'
import PrizeCard from '../PrizeCard/PrizeCard'

const PrizeSection = ({tournament}) => {
    if(!tournament?.differentPrizes && tournament?.prizes?.length > 0)
        return (
            <div className={styles.prize_wrapper}>
                {/* Left */}
                <div className={styles.left_section}>
                    <div className={styles.top_prize_mix}>
                        <img src={awards} alt="" />
                    </div>

                    <div className={styles.bottom_para_wrapper}>
                        <div className={styles.bottom_para}>
                            <h1>All the top {tournament?.prizes?.length} winners will win the {tournament?.prizes[0]?.prizeName} as the Prize</h1>
                        </div>
                    </div>
                </div>

                {/* Right */}
                <div className={styles.right_section}>
                    <figure className={styles.fig_wrapper}>
                        <img src={tournament?.prizes[0]?.prizeImg || 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1695829008/Frame_1814105410_pffmvu.webp'} alt="" className='w-full h-full object-cover'/>

                        <div className={styles.total_cnt}>{`1 ${tournament?.prizes?.length > 1 ? '- ' + tournament?.prizes?.length : ''}`}</div>
                    </figure>

                    <h1>{tournament?.prizes[0]?.prizeName}</h1>

                    <div className={styles.worth_field}>
                        <h2>Worth:</h2>
                        <h2>{tournament?.prizes[0]?.prizeAmount ? '$' + tournament?.prizes[0]?.prizeAmount : '--'}</h2>
                    </div>
                </div>
            </div>
        )
    if(tournament?.prizes?.length > 0)
        return (
            <>
                <div className={styles.winner_cards}>
                    {tournament?.prizes?.slice(0, 3)?.map((prize, key) => (
                        <TournamentCard id={key} prize={prize} key={key} />
                    )) }
                </div>

                {/* Lower Section-Cards */}
                <div className={styles.prize_cards}>
                    {tournament?.prizes?.slice(3, tournament?.prizes?.length)?.map((prize, key) => (
                        <PrizeCard key={key} id={key} prize={prize} />
                    ))}
                </div>
            </>
        )
        // tournament?.communityTheme?.backgroundColor + '19'
    if(tournament?.prizes?.length === 0)
        return (
            <div className={styles.no_prize_container}>
                <img src={'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1696609589/NO_PRIZE.bdd5311bed0a23d6fa60783618e57526_qnaliq.webp'} alt="" />
                <p>Tournament do not comprise of any prize</p>
            </div>
        )
}

export default PrizeSection