import React, { useEffect, useState } from 'react'
import styles from './Community.module.css'
import DashboardNav from '../DashboardNavCommunity/DashboardNav'
import eve1 from "../../Assets/Images/eve1.svg";
import EventCard from '../DashboardCards/EventCard/EventCard';
import TableCard from '../DashboardCards/TableCard/TableCard';
import tableUser from "../../Assets/Images/tableUser.svg";
import MaxWinning from './MaxWinning/MaxWinning';
import Activity from './Activity/Activity';
import CommunityPlayers from './CommunirtyPlayers/CommunityPlayers';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateEventCard from '../DashboardCards/CreateEventCard/CreateEventCard';
import CreateTableCard from '../DashboardCards/CreateTableCard/CreateTableCard';
import CreateTableModal from '../shared/modals/CreateTableModal/CreateTableModal';
import { useDispatch, useSelector } from 'react-redux';
import { getTables, saveTableDataState, saveTableState } from '../../redux/actions/TableAction';
import { getCommunity } from '../../redux/actions/CommunityAction';
import { useParams } from 'react-router-dom';
import TableCardSkelton from '../skeltons/TableCardSkelton/TableCardSkelton';
import { editTableData } from '../../redux/actions/otherAction';
import { ChooseNavLinks } from '../../redux/actions/GlobalAction';
import axios from 'axios';
import { BACKEND_URL } from '../../constants';
import CreateTournamentModal from '../shared/modals/CreateTournamentModal/CreateTournamentModal';
import EventCardSkelton from './../DashboardCards/EventCardSkelton/EventCardSkelton';

const Community = ({data, setData, openModal, setOpenModal, tableData, setTableData, socketObj}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams()
    const id = params?.communitySlug.toLowerCase()
    const isTableSaved = location.state

    const {isLogin, userInfo} = useSelector((state) => state.userLoginReducer)
    const {tournaments, data: tables, loading: tablesLoading} = useSelector((state) => state.getTablesReducer)
    const {loading, data:communityInfo, error} = useSelector((state) => state.getCommunityByIDReducer)

    const [activity, setActivity] = useState([])
    const [topPlayers, setTopPlayers] = useState([])
    const [hover, setHover] = useState(false)
    const [openEventModal, setOpenEventModal] = useState(false)

    useEffect(() => {
        dispatch(getCommunity(id))
        dispatch(getTables(id))
    },[dispatch, id])

    useEffect(() => {
        if(communityInfo?.defaultTable && !isTableSaved)
            setTableData(communityInfo?.defaultTable)
    },[communityInfo, isTableSaved])

    useEffect(() => {
        window.scroll(0, 0)
    },[])

    useEffect(() => {
        const fetchCommunityActivity = async () => {
            await axios.post(`${BACKEND_URL}/community/getCommunityActivity`,{
                slug: id
            })
            .then((res) => {
                setActivity(res.data.activity)
                setTopPlayers(res.data.topPlayers)
            })
            .catch(err => console.log(err))
        }

        fetchCommunityActivity()
    },[id])

    useEffect(() => {
        dispatch(ChooseNavLinks(communityInfo?.slug, '/community', false))

        return() => dispatch(ChooseNavLinks('', '', false))
    }, [communityInfo, dispatch])

    // if(loading)
    //     return <LoaderBig />
    if(error)
        return <pre>{error}</pre>

    return (
        <div className={styles.community} id='scroll'
            style={{
                background: loading ? 'linear-gradient(270deg, rgba(61, 61, 61, 0.05) 0%, #3D3D3D 50%)' : `linear-gradient(0deg, ${communityInfo?.theme?.backgroundColor + '33'} 0%, ${communityInfo?.theme?.backgroundColor+ '33'} 100%), url(${communityInfo?.backgroundImages[communityInfo?.backgroundImageId  - 1]})`,
                backgroundRepeat: loading ? '' : 'no-repeat',
                backgroundSize: loading ? '' : 'cover',
                backgroundPosition: loading ? '' : 'center',
                backgroundAttachment: loading ? '' : 'fixed'
            }}
        >
            <div className={styles.top_header}
                style={{
                    backgroundImage: loading ? 'linear-gradient(270deg, rgba(61, 61, 61, 0.05) 0%, #2a2929 50%)' : `url(${communityInfo?.pageBanners[communityInfo?.pageBannerId  - 1]})`,
                }}
            >
                <DashboardNav backUrl={'/community'} title={communityInfo?.slug} />
                <div className={styles.top_text_wrappper}>
                    {loading ? <h1 className={`animate-pulse ${styles.top_text_loading}`}>{''}</h1>
                    : <div className={styles.top_text_back}>
                        <h1>{communityInfo?.communityName}</h1>
                    </div>}
                    {!loading && <div className={styles.mobile_top_nav}>
                        <button onClick={() => {navigate('/community')}}>
                            <BackArrow />
                        </button>
                        <h1>{communityInfo?.communityName}</h1>
                    </div>}
                </div>

                {window?.innerWidth > 650 && isLogin && userInfo?.isAdmin && userInfo?.communityId?.length > 0 && userInfo?.communityId?.find((x) => x === communityInfo?._id) && <button onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={`${styles.edit_comm_btn} secondary-custom-button`} onClick={() => navigate(`/${id}/editCommunity`)}>
                    <div className={`${styles.edit_comm_btn_inner} secondary-custom-button-inner`}>
                        <Edit fill={hover ? "#D93D48" : "white"} />
                        Edit Community Page
                    </div>
                </button>}

            </div>

            <div className={styles.main_wrapper}>
                {/* {isLogin && userInfo?.isAdmin && <Button onClick={() => navigate('/editCommunity')}>Edit Community</Button>} */}
                <div className={styles.top_wrapper_holder}>
                    {/* Left */}
                    <div className={styles.left_wrapping_details}>
                        {/* Max Winnings */}
                        <MaxWinning communityInfo={communityInfo} />

                        {/* Activity */}
                        <Activity activity={activity} />

                        {/* Live Events */}
                        {/* <LiveEvents /> */}

                        {/* Online */}
                        {/* <Online /> */}
                    </div>

                    {/* Right */}
                    <div className={styles.community_wrapper}>
                        {/* Top Community Wrapper */}
                        <CommunityPlayers topPlayers={topPlayers} />
                    </div>
                </div>

                {((tournaments.length > 0) || (isLogin && userInfo?.isAdmin && userInfo?.communityId?.length > 0 && userInfo?.communityId?.find((x) => x === communityInfo?._id))) && <div className={styles.wrapper_holder}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '50px' }} className="dashboard-item-title">UPCOMING
                    {window.innerWidth <= 650 && <span>Events</span>}
                    <svg xmlns="http://www.w3.org/2000/svg" style={{
                        marginBottom: '20px',
                        marginLeft: '20px',
                                }}><defs><linearGradient id="gradient" y1="0" y2="1"><stop stopColor="#FF0000" offset="0"></stop><stop stopColor="#ff000033" offset="1"></stop></linearGradient></defs><g><text id="text" y="100" fontFamily="intro" fontSize="120" fontWeight="400" strokeWidth="1" stroke="url(#gradient)" fill="rgba(127, 0, 0, 0.70)" style={{
                        color: '#FFF',
                        fontFamily: 'Clash Display',
                        fontSize: '48px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        letterSpacing: '4.4px',
                            }}>EVENTS</text></g></svg>
                    </div>
                    <div className={styles.card_wrapper}>
                        {window?.innerWidth > 650 && isLogin && userInfo?.isAdmin && userInfo?.communityId?.length > 0 && userInfo?.communityId?.find((x) => x === communityInfo?._id) && <CreateEventCard
                            img={eve1}
                            title="POKER World Cup"
                            subtitle="by sneaker heads"
                            id={1}
                            color="#002059"
                            onClick={() => {
                                dispatch(saveTableState(null))
                                dispatch(saveTableDataState(null))
                                setOpenEventModal(true)
                                dispatch(editTableData(null))
                            }}
                            communityId={communityInfo?._id}
                        />}
                        {tablesLoading
                            ? Array(2).fill(0,0).map((table, key) => <EventCardSkelton key={key} /> )
                            : tournaments?.map((tournament, key) => (
                            <EventCard
                                tournament={tournament}
                                key={key}
                                live={tournament?.time <= Date.now()}
                                isAdmin={isLogin && userInfo?.isAdmin && userInfo?.communityId?.length > 0 && userInfo?.communityId?.find((x) => x === communityInfo?._id)}
                                community
                            />
                        ))}
                    </div>
                </div>}

                {((tables.length > 0) || (isLogin && userInfo?.isAdmin && userInfo?.communityId?.length > 0 && userInfo?.communityId?.find((x) => x === communityInfo?._id))) && <div className={styles.wrapper_holder}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '50px', marginBottom: '20px' }} className="dashboard-item-title">LIVE
                    {window.innerWidth <= 650 && <span>Tables</span>}
                    <svg xmlns="http://www.w3.org/2000/svg" style={{
                        marginBottom: '20px',
                        marginLeft: '20px',
                                }}><defs><linearGradient id="gradient" y1="0" y2="1"><stop stopColor="#FF0000" offset="0"></stop><stop stopColor="#ff000033" offset="1"></stop></linearGradient></defs><g><text id="text" y="100" fontFamily="intro" fontSize="120" fontWeight="400" strokeWidth="1" stroke="url(#gradient)" fill="rgba(127, 0, 0, 0.70)" style={{
                        color: '#FFF',
                        fontFamily: 'Clash Display',
                        fontSize: '48px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        letterSpacing: '4.4px',
                            }}>TABLES</text></g></svg>
                    </div>
                    <div className={styles.table_wrapper}>
                        {window?.innerWidth > 650 && isLogin && userInfo?.isAdmin && userInfo?.communityId?.length > 0 && userInfo?.communityId?.find((x) => x === communityInfo?._id) && <CreateTableCard
                            color="#3C4B5A"
                            id={0}
                            img={tableUser}
                            title="Black BEAST TABLE"
                            playersOnline={5}
                            onClick={() => {dispatch(saveTableState(null)); dispatch(saveTableDataState(null)); setOpenModal(true); dispatch(editTableData(null))}}
                            smallBlind={500}
                            communityId={communityInfo?._id}
                        />}
                        {tablesLoading
                            ? Array(2).fill(0,0).map((table, key) => <TableCardSkelton key={key} /> )
                            : tables?.map((table, key) => (
                                <TableCard
                                    color="#3C4B5A"
                                    key={key}
                                    id={key+1}
                                    img={table?.cardPreview}
                                    socketObj={socketObj}
                                    // title="Black BEAST TABLE"
                                    // playersOnline={5}
                                    // smallBlind={500}
                                    table={table}
                                />
                            ))
                        }
                        {/* <TableCard
                            color="#3C4B5A"
                            id={2}
                            img={tableUser}
                            title="Black BEAST TABLE"
                            playersOnline={5}
                            smallBlind={500}
                        />
                        <TableCard
                            color="#29830B"
                            id={3}
                            img={tableUser}
                            title="Black BEAST TABLE"
                            playersOnline={5}
                            smallBlind={500}
                        />
                        <TableCard
                            color="#56939B"
                            id={4}
                            img={tableUser}
                            title="Black BEAST TABLE"
                            playersOnline={5}
                            smallBlind={500}
                        /> */}
                    </div>
                </div>}
            </div>

            {openEventModal && <CreateTournamentModal communityName={communityInfo?.communityName} chipImg={communityInfo?.chipImg} setTableData={setTableData} communitySlug={id} edit={null} tableData={tableData} openModal={openEventModal} setOpenModal={setOpenEventModal} data={data} setData={setData} communityId={communityInfo?._id} />}
            {openModal && <CreateTableModal setTableData={setTableData} communitySlug={id} edit={null} tableData={tableData} openModal={openModal} setOpenModal={setOpenModal} data={data} setData={setData} communityId={communityInfo?._id} />}
        </div>
    )
}

export default Community

const Edit = ({fill}) => {
    return(<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1371_17690)">
    <path d="M11.332 1.99955C11.5071 1.82445 11.715 1.68556 11.9438 1.5908C12.1725 1.49604 12.4177 1.44727 12.6654 1.44727C12.913 1.44727 13.1582 1.49604 13.387 1.5908C13.6157 1.68556 13.8236 1.82445 13.9987 1.99955C14.1738 2.17465 14.3127 2.38251 14.4074 2.61129C14.5022 2.84006 14.551 3.08526 14.551 3.33288C14.551 3.58051 14.5022 3.8257 14.4074 4.05448C14.3127 4.28325 14.1738 4.49112 13.9987 4.66622L4.9987 13.6662L1.33203 14.6662L2.33203 10.9995L11.332 1.99955Z" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1371_17690">
    <rect width="16" height="16" fill={fill}/>
    </clipPath>
    </defs>
    </svg>
    )
}

const BackArrow = () => {
    return(<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.1663 14H5.83301" stroke="#D93D48" strokeWidth="2.33333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.9997 22.1693L5.83301 14.0026L13.9997 5.83594" stroke="#D93D48" strokeWidth="2.33333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}