import React from 'react'
import styles from './CommunityCardSkelton.module.css'

const CommunityCardSkelton = () => {
    return (
    <div
        className={`community___wrapper ${styles.wrapper}`}
        style={{
            background: '#242424'
        }}
    >
        <div className={styles.inner_wrapper}>
            <figure className={`animate-pulse ${styles.image_wrapper}`}>
                {/* <img src={data?.backgroundImages[data?.backgroundImageId - 1] || data?.backgroundImage} alt="" className='w-full h-full object-cover' /> */}
            </figure>

            <div className={`animate-pulse ${styles.content_wrapper_outer}`}
                style={{}}
            >
                <div className={styles.content_wrapper} >
                    <div className={styles.top_dsg}>
                        <figure className={`community___figure_holder ${styles.figure_holder}`}
                            style={{
                            }}
                        >
                            {/* <img src={data?.tableInfo?.dealersImage} alt="" className='w-full h-full object-cover' /> */}
                        </figure>

                        <div className={styles.online_holder}>
                            <div className={styles.online_prsn}>
                                <div className={styles.online_}></div>
                                <div className={styles.online_}></div>
                                <div className={styles.online_}></div>
                            </div>
                            <span className={styles.online_cnt}>{}</span>
                        </div>
                    </div>

                    <div className={styles.med_dsg}>
                        <h1 className={`animate-pulse ${styles.heading}`}>{}</h1>
                        <p className={`animate-pulse ${styles.active_table}`}>{}</p>
                    </div>

                    <div className={styles.btm_dsg}>
                        <div className={styles.play_coins_outer}>
                            <div className={styles.play_coins_inner}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default CommunityCardSkelton