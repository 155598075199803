import { useEffect, useState } from 'react'
import styles from './LeaderboardSection.module.css'
import axios from 'axios'
import { BACKEND_URL } from '../../../constants'
import { Delete } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import DeleteLeaderboard from '../../shared/modals/DeleteLeaderboard/DeleteLeaderboard'

const LeaderboardSection = ({tournament, tournamentId, fetchItem}) => {
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [data, setData] = useState('')
    const [participants, setParticipants] = useState([])

    const {userInfo, verificationHash, accessToken} = useSelector((state) => state.userLoginReducer)

    const deleteAsset = async () =>{
        if(!data) return

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${accessToken}`,
            },
        }

        await axios.post(`${BACKEND_URL}/community/deleteLeaderboardEvent`,{
            eventId: data,
            tournamentId,
            verificationHash,
        }, config)
        .then((res) => {
            getParticipants()
            setOpenModal(false)
            fetchItem()
        })
        .catch(err => {
            console.log(err)
            setOpenModal(false)
        })
    }

    const getParticipants = async () => {
        setLoading(true)
        await axios.post(`${BACKEND_URL}/public/getTournamentLeaderboard`,{
            tournamentId
        })
        .then((res) => {
            setParticipants(res.data.participants)
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        getParticipants()
    },[tournamentId])

    return (
        <div className={styles.table_wrapper}>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>User</th>
                        <th>Table No.</th>
                        <th>Current Holdings</th>
                        {(userInfo?.isAdmin && userInfo?.communitySlug?.length > 0 && userInfo?.communitySlug?.find((x) => x === tournament?.slug)) && <th>Status</th>}
                    </tr>
                </thead>
                <tbody>
                    {participants?.map((user, key) => {
                                return(
                                    <tr key={key}>
                                        <td>{key+1}.</td>
                                        <td>
                                            <div className={styles.holder_item_td}>
                                                <figure className={styles.image_holder}>
                                                    <img src={user?.image} alt="" />
                                                </figure>
                                                <div className={styles.align_items_td}>
                                                    <h1>{user?.username}</h1>
                                                    <p>{user?.walletAddress?.substring(0, 7) + '...' + user?.walletAddress?.substring(user?.walletAddress?.length - 3, user?.walletAddress?.length)}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{(user?.chips <= 0) ? '--' : (user?.tableNumber || (user?.isJoined && '--') || 'Lobby/Disconnect')}</td>
                                        <td>{(user?.chips <= 0) ? '--' : ((user?.isJoined && user?.tableNumber && user?.chips) || (!user?.isJoined && user?.chips) || '--')}</td>
                                        {(userInfo?.isAdmin && userInfo?.communitySlug?.length > 0 && userInfo?.communitySlug?.find((x) => x === tournament?.slug)) &&
                                            <td className='cursor-pointer' onClick={() => {
                                                setOpenModal(true)
                                                setData(user?._id)
                                            }}><Delete /></td>}
                                    </tr>
                                )
                    })}
                    {loading && <tr className={`animate-pulse`} style={{
                        height: '50px',
                        background: 'linear-gradient(270deg, rgba(149, 149, 149, 0.03) 0%, rgba(61, 61, 61, 0.64) 100%), #010101',
                        boxShadow: '0px 4px 0px 0px rgba(0, 0, 0, 0.65)',
                        border: 'none',
                        outline: 'none',
                        width: '100%',
                    }}>
                        <td>
                            <div className='animate-pulse' style={{
                                width: '23px',
                                height: '13px',
                                borderRadius: '8px',
                                background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                            }} />
                        </td>
                        <td>
                            <div className={styles.holder_item_td}>
                                <figure className={`animate-pulse ${styles.image_holder}`} style={{
                                    background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                                }} />
                                <div className={`gap-1 ${styles.align_items_td}`}>
                                    <div className='animate-pulse' style={{
                                        width: '71px',
                                        height: '14px',
                                        borderRadius: '8px',
                                        background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                                    }} />
                                    <div className='animate-pulse' style={{
                                        width: '154px',
                                        height: '10.5px',
                                        borderRadius: '8px',
                                        background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                                    }} />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className='animate-pulse' style={{
                                width: '23px',
                                height: '13px',
                                marginLeft: 'auto',
                                borderRadius: '8px',
                                background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                            }} />
                        </td>
                        <td>
                            <h1 className='animate-pulse' style={{
                                width: '35px',
                                height: '13px',
                                borderRadius: '8px',
                                marginLeft: 'auto',
                                background: 'linear-gradient(270deg, rgba(219, 219, 219, 0.02) 0%, rgba(219, 219, 219, 0.40) 50%)'
                            }}>{''}</h1>
                        </td>
                    </tr>}
                </tbody>
            </table>

            <DeleteLeaderboard openModal={openModal} setOpenModal={setOpenModal} deleteAsset={deleteAsset} />
        </div>
    )
}

export default LeaderboardSection