import React from "react";
import styles from "./TableCardSkelton.module.css";
// import tableCoin from "../../../Assets/Images/tableCoin.svg";
import TableTopCardSvg from "../../../Assets/Images/TableTopCardSvg";
import { PlayButtonSvg } from "../../../Assets/Images/PlayButtonSvg";

const TableCardSkelton = ({id}) => {
  return (
    <div className={`animate-pulse ${styles[`tablecard-btm-layer`]}`}>
      <div className={styles[`tablecard-middle-layer`]}>
        <TableTopCardSvg skelton={true} fill={'#000'} id={id} />

        <div className={styles[`tablecard-top-layer`]}>
          <div className={`animate-pulse ${styles[`tablecard-top-layer-img`]}`} />
          <div className={styles[`tablecard-top-layer-title`]} />

          <div className={`animate-pulse ${styles[`tablecard-top-layer-sub-flex`]}`}>
            <div className={styles[`tablecard-top-layer-sub-num`]} />
            <div className={styles[`tablecard-top-layer-sub-num`]} />

            <div className={`animate-pulse ${styles[`tablecard-play-btn`]}`}>
              <PlayButtonSvg skelton={true}/>
              {/* <div className={styles[`shine`]} />
              <div className={styles[`tablecard-play-btn-top`]}></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableCardSkelton;
