import React, { useEffect, useState } from "react";
import "./userSummary.css";

const UserSummary = ({ hidden, id, round, name, winners, userPlayerHands, cardBg, allPlayerHands, walletAddress, handRank, chipImg, userWalletAddress }) => {
  const [won, setwon] = useState(false);
  const [winnerData, setwinnerData] = useState();
  const [playerHands, setplayerHands] = useState(userPlayerHands);
  const [playerHandRank, setplayerHandRank] = useState();
  console.log('winners', winners)

  useEffect(() => {
    var isWon = false
    for (let i = 0; i < winners.length; i++) {
      if (winners[i]?.playerData?.walletAddress === round?.walletAddress) {
        isWon = true
        setwon(isWon)
        setwinnerData(winners[i])
        return
      }
    }

    if (!isWon) {
      setwon(false)
      setwinnerData()
    }
  }, [winners])

  useEffect(() => {
    if (allPlayerHands.length > 0) {
      const hands = allPlayerHands.find((allPlayerHand) => allPlayerHand.walletAddress == walletAddress)
      setplayerHands(hands.playerHands)
      setplayerHandRank(hands.handRank)
      // console.log(hands)
    } else {
      setplayerHandRank()
      setplayerHands(userPlayerHands)
    }
  }, [allPlayerHands, userPlayerHands])

  console.log(allPlayerHands, 'walletAddress')

  return (
    <div className={won ? "userSummary-won" : "userSummary"}>
      {won && <div className="won-user-summary-winner">Winner</div>}

      <div className="userSumarry-cards">
        {<img
          src={(hidden || playerHands.length <= 0 || ((round?.status == "Fold") && (round?.walletAddress !== userWalletAddress))) ? cardBg : `https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/${playerHands[0][1]}-${playerHands[0][0]}.svg`}
          alt="Card"
          className="userSummary-card"
        />}
        {<img
          src={(hidden || playerHands.length <= 0 || ((round?.status == "Fold") && (round?.walletAddress !== userWalletAddress))) ? cardBg : `https://res.cloudinary.com/kuramaverse/image/upload/v1693862198/ArcadeVerse%20Table%20Cards/${playerHands[1][1]}-${playerHands[1][0]}.svg`}
          alt="Card"
          className="userSummary-card"
        />}
      </div>
      <div className="userSumary-text">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }} className="userSumary-text-name">
          {name?.length > 20 ? name?.substring(0, 5) + '...' + name?.substring(name?.length -3) : name}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {winners.length > 0 && <div style={won ? { borderRadius: '4px', padding: '6px 10px 6px', background: '#000000', color: '#C2FF85', display: 'flex', alignItems: 'center', gap: '6px', fontSize: '16px' } : { borderRadius: '4px', padding: '7px 10px 6px', background: '#390606', border: '2px solid #ffffff0d', color: '#FF8585', display: 'flex', alignItems: 'center', gap: '6px', fontSize: '16px' }} className="userSumary-text-info">{won ? "+" : "-"}{won ? (winnerData?.pot - winnerData?.playerData?.totalTurnAmount) : round?.totalTurnAmount} <img style={{ width: '15px' }} src={chipImg} /></div>}
          <div style={won ? { borderRadius: '4px', padding: '6px 10px 6px', background: '#000000', fontSize: '16px' } : { borderRadius: '4px', padding: '7px 10px 6px', background: '#390606', border: '2px solid #ffffff0d', fontSize: '16px' }} className="userSumary-text-info">{(handRank && handRank?.handRank) || (playerHandRank || round?.status)}</div>
        </div>
      </div>
    </div>
  );
};

export default UserSummary;
