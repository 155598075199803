import React from 'react'
import styles from './CommunityInfo.module.css'
import Input from '../Input/Input'

const CommunityInfo = ({setTabNumber, info, setInfo}) => {
    return <div className={styles.wrapper}>
        <h1>Community info</h1>

        <div className={styles.form}>
            {/* <Input label='Project Name' value={info.projectName} onChange={(e) => {setInfo((state) => ({...state, projectName: e}))}} /> */}
            <Input label='Name for Community' value={info.communityName} onChange={(e) => {setInfo((state) => ({...state, communityName: e}))}} />
            <Input label='Slug for Community(No spaces)' value={info.communitySlug} onChange={(e) => {setInfo((state) => ({...state, communitySlug: e?.replaceAll(/\s/g,'')}))}} />
            <Input label='Background Image' upload value={info.backgroundImage} onChange={(e) => {setInfo((state) => ({...state, backgroundImage: e}))}} />
        </div>

        <button className={styles.btn} disabled={!info.communityName || !info.communitySlug || !info.backgroundImage} onClick={() => {setTabNumber(2)}}>Continue</button>
    </div>
}

export default CommunityInfo
