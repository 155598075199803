import React, { useEffect, useState } from 'react'
import styles from './Leaderboard.module.css'
import Table from '../../Components/Leaderboard/Table/Table'
import winner_1 from '../../Assets/svgs/Winner1.svg'
import winner_2 from '../../Assets/svgs/Winner2.svg'
import winner_3 from '../../Assets/svgs/Winner3.svg'
// import LeaderboardRankingCard from '../../Assets/svgs/LeaderboardRankingCard.svg'
import player from '../../Assets/Images/playerModel2.png'
import dropdown from '../../Assets/svgs/dropdown.svg'
import SelectLeaderboardOption from '../../Components/shared/Popovers/SelectLeaderboardOption/SelectLeaderboardOption'
import { getLeaderboardAction } from '../../redux/actions/GlobalAction'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { getCommunities } from '../../redux/actions/CommunityAction'
import { REQUEST_GET_LEADERBOARD } from '../../redux/constants/GlobalConstant'

const Leaderboard = () => {
    const dispatch = useDispatch()
    const [active, setActive] = useState(0)
    const [communityName, setCommunityName] = useState('')
    const [page, setPage] = useState(0)
    const [fetched, setFetched] = useState(false)
    const {size, leaderboardData} = useSelector((state) => state.getLeaderboardReducer)

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchPosts = async (communityName) => {
        if ((((page*30) < size) || (page === 0) || communityName) && (localStorage.getItem('fetchingPosts') !== 'true') && (page == Number(localStorage.getItem('setPage')))) {
            localStorage.setItem('fetchingPosts', true)
            dispatch(getLeaderboardAction(communityName, page, setPage))
        }
    }

    useEffect(() => {
        if (!fetched) {
            localStorage.setItem('setPage', 0)
        }
        fetchPosts(communityName)
    }, [communityName])

    useEffect(() => {
        if(!fetched){
            dispatch(getCommunities())
            setFetched(true)
        }
    },[fetched, dispatch])

    return  <div className="dashboard relative" id="scroll" style={{paddingTop: 0}}>
        <div className={styles.main_wrapper}>
            <InfiniteScroll
                dataLength={leaderboardData.length} //This is important field to render the next data
                next={fetchPosts}
                hasMore={((page*30) < size) || page === 0}
                // height={(window.innerWidth < 1000) ? (window.innerHeight - 160) : (window.innerHeight - 400)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                <>
                    <div className={styles.leaderboard_top}>
                        {/* <div className={styles.lower_side}>
                            <img src={lower_side} alt="" />
                        </div> */}
                        {/* <div className={styles.back_side}>
                            <img src={back_side} alt="" />
                        </div> */}

                        <div className={styles.top_button_options_wrpr}>
                            <div className={styles.top_button_options}>
                                <button className={active === 0 ? styles.checked_btn : styles.unchecked_btn} onClick={() => {
                                    if (active !== 0) {
                                        setActive(0)
                                        setPage(0)
                                        dispatch({
                                            type: REQUEST_GET_LEADERBOARD,
                                            leaderboardData: [],
                                            userScore: '',
                                            size: ''
                                        })
                                        localStorage.setItem('setPage', 0)
                                        setCommunityName('')
                                    }
                                }}>Overall</button>
                                <button className={active === 1 ? styles.checked_btn : styles.unchecked_btn} style={{justifyContent: 'space-between', padding: '0 12px'}} onClick={(e) => {
                                    handleClick(e)
                                }}>{communityName || 'Select'} <img src={dropdown} alt="" /></button>
                            </div>
                        </div>

                        {/* <div className={styles.align_winners}>
                            <div className={styles.winner_1}>
                                <div className='flex items-center flex-col gap-5 absolute top-4'>
                                    <figure className='rounded-full w-[72px] height-[72px] overflow-hidden'>
                                        <img src={player} alt="" className='w-full h-full object-cover' />
                                    </figure>
                                    <div className='flex flex-col items-center gap-1'>
                                        <h1 className={styles.heading_winner}>linkin park</h1>
                                        <p className={styles.games_won}>204 XP</p>
                                    </div>
                                </div>

                                <img src={winner_1} alt="" />
                            </div>

                            <div className={styles.winner_2}>
                                <div className='flex items-center flex-col gap-5 absolute top-4'>
                                    <figure className='rounded-full w-[72px] height-[72px] overflow-hidden'>
                                        <img src={player} alt="" className='w-full h-full object-cover' />
                                    </figure>
                                    <div className='flex flex-col items-center gap-1'>
                                        <h1 className={styles.heading_winner}>linkin park</h1>
                                        <p className={styles.games_won}>204 XP</p>
                                    </div>
                                </div>

                                <img src={winner_2} alt="" />
                            </div>

                            <div className={styles.winner_3}>
                                <div className='flex items-center flex-col gap-5 absolute top-4'>
                                    <figure className='rounded-full w-[72px] height-[72px] overflow-hidden'>
                                        <img src={player} alt="" className='w-full h-full object-cover' />
                                    </figure>
                                    <div className='flex flex-col items-center gap-1'>
                                        <h1 className={styles.heading_winner}>linkin park</h1>
                                        <p className={styles.games_won}>204 XP</p>
                                    </div>
                                </div>

                                <img src={winner_3} alt="" />
                            </div>
                        </div> */}
                    </div>

                    <Table />

                    {/* {isLogin && <div className={styles.my_field}>
                        <div className={styles.field}>{100}.</div>
                        <div className={styles.field}>
                            <div className={styles.holder_item_td}>
                                <figure className={styles.image_holder}>
                                    <img src={userInfo?.image} alt="" />
                                </figure>
                                <div className={styles.align_items_td}>
                                    <h1>{userInfo?.username}</h1>
                                    <p>{userInfo?.walletAddress}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.field}>{1221}</div>
                        <div className={styles.field}>{900}</div>
                    </div>} */}
                </>
            </InfiniteScroll>
        </div>
    <SelectLeaderboardOption anchorEl={anchorEl} setData={setCommunityName} setPage={setPage} setActive={setActive} data={communityName} handleClose={handleClose} />
    </div>
}

export default Leaderboard
