import styles from './CommunityPreview.module.css'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef } from 'react'
import DashboardNav from '../../../DashboardNav/DashboardNav'

const CommunityPreview = ({openModal, setOpenModal, communityInfo}) => {
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0'
                initialFocus={cancelButtonRef}
                onClose={setOpenModal}
            >
                <div className='h-screen px-4 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[#0c0c0d9a] bg-opacity-75 backdrop-blur-[3px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block bg-transparent rounded-lg text-left transform transition-all my-8 align-middle max-w-[1200px] w-full'>
                            <div
                                className={`${styles.modal_background} flex justify-center w-full items-center rounded-lg text-white h-full p-10 flex-col gap-y-8 border border-[#3D3D3D]`}
                            >
                                <div className={styles.top_header}>
                                    <h1>Community Page Preview</h1>

                                    <button onClick={() => setOpenModal(false)}>
                                        <Close />
                                    </button>
                                </div>

                                <div className={styles.divider}></div>
                                <div className={styles.community} id='scroll'
                                // background: `linear-gradient(0deg, ${communityInfo?.theme?.backgroundColor + '33'} 0%, ${communityInfo?.theme?.backgroundColor+ '33'} 100%), url(${communityInfo?.backgroundImages[communityInfo?.backgroundImageId  - 1]})`,
                                    style={{
                                        backgroundImage: typeof communityInfo?.backgroundImages[communityInfo?.backgroundImageId  - 1] !== 'object' ? `linear-gradient(0deg, ${communityInfo?.themeColour?.backgroundColor + '33'} 0%, ${communityInfo?.themeColour?.backgroundColor+ '33'} 100%), url(${communityInfo?.backgroundImages[communityInfo?.backgroundImageId  - 1] || ''})` : `linear-gradient(0deg, ${communityInfo?.themeColour?.backgroundColor + '33'} 0%, ${communityInfo?.themeColour?.backgroundColor+ '33'} 100%), url(${URL.createObjectURL(communityInfo?.backgroundImages[communityInfo?.backgroundImageId  - 1])})`
                                    }}
                                >
                                    <div className={styles.top_header_community}
                                        style={{
                                            backgroundImage: typeof communityInfo?.pageBanners[communityInfo?.pageBannerId  - 1] !== 'object' ? `url(${communityInfo?.pageBanners[communityInfo?.pageBannerId  - 1] || ''})` : `url(${URL.createObjectURL(communityInfo?.pageBanners[communityInfo?.pageBannerId  - 1])})`
                                        }}
                                    >
                                        <DashboardNav disabled backUrl={'/community'} title={communityInfo?.url} />
                                        <div className={styles.top_text_wrappper}>
                                            <div className={styles.top_text_back}>
                                                <h1>{communityInfo?.communityName}</h1>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.main_wrapper}>
                                        <img src="https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1690561992/asd_m4jwh9.webp" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default React.memo(CommunityPreview)

const Close = () =>{
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}