export const PlayButtonSvg = ({skelton}) => {
    if(skelton){
        return(<svg width="220" height="45" viewBox="0 0 220 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.1522 6.2035C14.2573 2.96396 17.3009 0.786377 20.7238 0.786377H198.932C202.355 0.786377 205.398 2.96396 206.503 6.20351L219.598 44.5898H0.0576172L13.1522 6.2035Z" fill="url(#paint0_linear_2767_51360)"/>
        <defs>
        <linearGradient id="paint0_linear_2767_51360" x1="219.598" y1="23.0723" x2="0.0576189" y2="23.0723" gradientUnits="userSpaceOnUse">
        <stop stopColor="#373737"/>
        <stop offset="1"/>
        </linearGradient>
        </defs>
        </svg>
        )
    }
}