import styles from './AllSummary.module.css'
import React, { useEffect, useState } from 'react'

const AllSummary = ({round, dealerImage}) => {
    const [winnerText, setwinnerText] = useState('');

    useEffect(() => {
        if (round?.status == 'Winner') {
            var text = ''
            for (let i = 0; i < round?.finalWinners?.length; i++) {
                text = text + round?.finalWinners[i]?.playerData?.username

                if (i !== (round?.finalWinners.length - 1)) {
                    text = text + ', '
                }
            }
            setwinnerText(text)
        }
    }, [])

    return (
        <div className={styles.wrappper}>
            <img src={round?.player?.image ? round?.player?.image : dealerImage} alt='' style={{ width: '30px', height: '30px', objectFit: 'contain', background: 'black'}} className='rounded-full'/>
            <h1 className={styles.user_name}>{round?.player?.username ? round?.player?.username : 'Dealer'}</h1>
            <h1 className={styles.text}>{round?.status == 'Winner' ? `${winnerText} Won`: round?.status} {round?.amount}</h1>
        </div>
    )
}

export default AllSummary