import React, { useState } from 'react'
import styles from './LoginModal.module.css'

const ConnectBox = ({name, icon, onClick, loading}) => {
    const [onHover, setonHover] = useState(false)

    return(
        <div className={styles.outer_box} style={{cursor: loading ? 'default' : ''}} onMouseEnter={() => setonHover(true)} onMouseLeave={() => setonHover(false)} onClick={() => {if(!loading) onClick()}} >
            <div className={styles.align_items}>
                <img src={icon} alt="" />
                <h1 className={styles.connect_heading}>{name}</h1>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
                <path style={{ transition: 'all 0.3s linear' }} d="M1 13L7 7L1 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke={onHover ? "#ff0000bf" : "white"}/>
            </svg>
        </div>
    )
}

export default ConnectBox