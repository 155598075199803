import React from 'react'
import styles from './TableTemplate.module.css'
import Input from '../Input/Input'
import { floors, textures } from '../../../data'

const TableTemplate = ({setTabNumber, info, setInfo}) => {
    return <div className={styles.wrapper}>
        <h1>Create Table</h1>

        <div className={styles.form}>
            <Input selectData={floors} label={'CHOOSE BACKGROUND'} select value={info.background} onChange={(e) => {setInfo((state) => ({...state, background: e}))}} />
            <Input label={`DEALER'S IMAGE`} upload value={info.dealersImage} onChange={(e) => {setInfo((state) => ({...state, dealersImage: e}))}} />
            <Input label={`BACKGROUND SYMBOL`} upload value={info.backgroundSymbol} onChange={(e) => {setInfo((state) => ({...state, backgroundSymbol: e}))}} />
            <Input selectData={textures} label={'TABLE FRAME MATERIAL'} select value={info.tableFrameMaterial} onChange={(e) => {setInfo((state) => ({...state, tableFrameMaterial: e}))}} />
            <Input selectData={textures} label={'TABLE SURFACE'} select value={info.tableFrameSurface} onChange={(e) => {setInfo((state) => ({...state, tableFrameSurface: e}))}} />
        </div>

        <button className={styles.btn} onClick={() => setTabNumber(3)} disabled={!info.background || !info.dealersImage || !info.backgroundSymbol || !info.tableFrameMaterial || !info.tableFrameSurface} >Save and Create</button>
    </div>
}

export default TableTemplate
