import { useSelector } from 'react-redux'
import { getCurrentLevel, getNextXP } from '../../../constants'
import styles from './LevelProgress.module.css'
import React from 'react'

const LevelProgress = () => {
    const {userInfo} = useSelector((state) => state.userLoginReducer)

    return <div className={styles.container}>
        <div className={styles.level_slider}>
            <div className={styles.slider_content_holder} style={{transform: 'scale(0.6)', opacity: getCurrentLevel(userInfo?.overallXP || 0) - 1 < 0 ? '0' : '0.2'}}>
                <Crown />
                <span className={styles.level_number}>Level {getCurrentLevel(userInfo?.overallXP || 0) - 1}</span>
            </div>
            <div className={styles.slider_content_holder}>
                <Crown />
                <span className={styles.level_number}>Level {getCurrentLevel(userInfo?.overallXP || 0)}</span>
            </div>
            <div className={styles.slider_content_holder} style={{transform: 'scale(0.6)', opacity: '0.2'}}>
                <Crown />
                <span className={styles.level_number}>Level {getCurrentLevel(userInfo?.overallXP || 0) + 1}</span>
            </div>
        </div>

        <div className={styles.slider_wrapper}>
            <h1 className={styles.completion_status}>{Math.min(100, Math.round(((userInfo?.overallXP || 0) / getNextXP(userInfo?.overallXP || 0)) * 100))}% Complete</h1>

            <div className='flex items-center w-full gap-2'>
                <span className={styles.currentXP}>{userInfo?.overallXP || 0} XP</span>
                <div className={styles.progress_slider}>
                    <div className={styles.progress} style={{
                        width: `${Math.min(100, ((userInfo?.overallXP || 0) / getNextXP(userInfo?.overallXP || 0)) * 100)}%`
                    }}></div>
                </div>
                <span  className={styles.totalXP}>{getNextXP(userInfo?.overallXP || 0)} XP</span>
            </div>
        </div>

        <div className={styles.bottom_text}>
            <p style={{ lineHeight: '24px' }}>Your level is based on XP. You get 2 XP for each round you play and 4 XP for each win. You can earn additional XP by completing daily tasks.</p>
        </div>

        {/* <div className={styles.level_progress_container}>
            <h1 className={styles.heading}>Level Progress</h1>

            <div className={styles.progress_wrapper}>
                <Time />

                <h1 className={styles.title}>Time Spent</h1>

                <div className={styles.vertical_align}>
                    <div className={styles.progress_slider_small}>
                        <div className={styles.progress}></div>
                    </div>
                    <div className={styles.horizontal_align}>
                        <h2 className={styles.time}>1H 12M</h2>

                        <h2 className={styles.precise_time}>2H</h2>
                    </div>
                </div>
            </div>
            <div className={styles.progress_wrapper}>
                <Played />

                <h1 className={styles.title}>Time Spent</h1>

                <div className={styles.vertical_align}>
                    <div className={styles.progress_slider_small}>
                        <div className={styles.progress}></div>
                    </div>
                    <div className={styles.horizontal_align}>
                        <h2 className={styles.time}>1H 12M</h2>

                        <h2 className={styles.precise_time}>2H</h2>
                    </div>
                </div>
            </div>
            <div className={styles.progress_wrapper}>
                <Won />

                <h1 className={styles.title}>Time Spent</h1>

                <div className={styles.vertical_align}>
                    <div className={styles.progress_slider_small}>
                        <div className={styles.progress}></div>
                    </div>
                    <div className={styles.horizontal_align}>
                        <h2 className={styles.time}>1H 12M</h2>

                        <h2 className={styles.precise_time}>2H</h2>
                    </div>
                </div>
            </div>
        </div> */}
    </div>
}

export default LevelProgress

const Crown = () => {
    return(<svg width="138" height="137" viewBox="0 0 138 137" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4184_2461)">
    <path d="M54.5295 64.819L26.347 28.8278L12.2178 64.819H54.5295Z" fill="#F4900C"/>
    <path d="M68.8182 21.0078L43.9041 68.0381H93.7284L68.8182 21.0078Z" fill="#F4900C"/>
    <path d="M83.5775 64.819L111.76 28.8278L125.889 64.819H83.5775Z" fill="#F4900C"/>
    <path d="M109.554 57.6632L91.4013 13.3738L69.0801 57.667L69.0535 57.6784L69.0269 57.6632L46.7057 13.37L28.5526 57.6632C16.6555 41.1235 5.58218 30.5285 5.58218 30.5285C5.58218 30.5285 12.1115 78.7697 12.1115 125.272H125.995C125.995 78.7697 132.525 30.5285 132.525 30.5285C132.525 30.5285 121.452 41.1235 109.554 57.6632Z" fill="#FFCC4D"/>
    <path d="M68.8903 97.515C76.6224 97.515 82.8904 91.247 82.8904 83.5149C82.8904 75.7828 76.6224 69.5148 68.8903 69.5148C61.1582 69.5148 54.8902 75.7828 54.8902 83.5149C54.8902 91.247 61.1582 97.515 68.8903 97.515Z" fill="#5C913B"/>
    <path d="M101.18 92.6712C106.237 92.6712 110.336 88.5718 110.336 83.5149C110.336 78.458 106.237 74.3586 101.18 74.3586C96.1233 74.3586 92.0239 78.458 92.0239 83.5149C92.0239 88.5718 96.1233 92.6712 101.18 92.6712Z" fill="#981CEB"/>
    <path d="M125.434 91.054C129.597 91.054 132.973 87.6786 132.973 83.5149C132.973 79.3512 129.597 75.9758 125.434 75.9758C121.27 75.9758 117.895 79.3512 117.895 83.5149C117.895 87.6786 121.27 91.054 125.434 91.054Z" fill="#DD2E44"/>
    <path d="M36.5966 92.6712C41.6534 92.6712 45.7529 88.5718 45.7529 83.5149C45.7529 78.458 41.6534 74.3586 36.5966 74.3586C31.5397 74.3586 27.4403 78.458 27.4403 83.5149C27.4403 88.5718 31.5397 92.6712 36.5966 92.6712Z" fill="#981CEB"/>
    <path d="M12.3431 91.054C16.5068 91.054 19.8822 87.6786 19.8822 83.5149C19.8822 79.3512 16.5068 75.9758 12.3431 75.9758C8.17936 75.9758 4.80399 79.3512 4.80399 83.5149C4.80399 87.6786 8.17936 91.054 12.3431 91.054Z" fill="#DD2E44"/>
    <path d="M125.995 129.068H12.1115C11.1047 129.068 10.1392 128.669 9.42726 127.957C8.71535 127.245 8.3154 126.279 8.3154 125.272C8.3154 124.266 8.71535 123.3 9.42726 122.588C10.1392 121.876 11.1047 121.476 12.1115 121.476H125.995C127.002 121.476 127.968 121.876 128.68 122.588C129.392 123.3 129.792 124.266 129.792 125.272C129.792 126.279 129.392 127.245 128.68 127.957C127.968 128.669 127.002 129.068 125.995 129.068ZM125.995 115.835H12.1115C11.1047 115.835 10.1392 115.435 9.42726 114.723C8.71535 114.011 8.3154 113.046 8.3154 112.039C8.3154 111.032 8.71535 110.067 9.42726 109.355C10.1392 108.643 11.1047 108.243 12.1115 108.243H125.995C127.002 108.243 127.968 108.643 128.68 109.355C129.392 110.067 129.792 111.032 129.792 112.039C129.792 113.046 129.392 114.011 128.68 114.723C127.968 115.435 127.002 115.835 125.995 115.835Z" fill="#FFAC33"/>
    <path d="M6.21616 35.8696C9.166 35.8696 11.5573 33.4783 11.5573 30.5285C11.5573 27.5787 9.166 25.1873 6.21616 25.1873C3.26632 25.1873 0.875 27.5787 0.875 30.5285C0.875 33.4783 3.26632 35.8696 6.21616 35.8696Z" fill="#FFCC4D"/>
    <path d="M26.347 33.3528C28.8461 33.3528 30.872 31.3269 30.872 28.8278C30.872 26.3287 28.8461 24.3028 26.347 24.3028C23.848 24.3028 21.8221 26.3287 21.8221 28.8278C21.8221 31.3269 23.848 33.3528 26.347 33.3528Z" fill="#F4900C"/>
    <path d="M46.7057 20.1461C50.4459 20.1461 53.478 17.114 53.478 13.3738C53.478 9.63353 50.4459 6.60147 46.7057 6.60147C42.9655 6.60147 39.9334 9.63353 39.9334 13.3738C39.9334 17.114 42.9655 20.1461 46.7057 20.1461Z" fill="#FFCC4D"/>
    <path d="M131.891 35.8696C134.841 35.8696 137.232 33.4783 137.232 30.5285C137.232 27.5787 134.841 25.1873 131.891 25.1873C128.941 25.1873 126.55 27.5787 126.55 30.5285C126.55 33.4783 128.941 35.8696 131.891 35.8696Z" fill="#FFCC4D"/>
    <path d="M111.76 33.3528C114.259 33.3528 116.285 31.3269 116.285 28.8278C116.285 26.3287 114.259 24.3028 111.76 24.3028C109.261 24.3028 107.235 26.3287 107.235 28.8278C107.235 31.3269 109.261 33.3528 111.76 33.3528Z" fill="#F4900C"/>
    <path d="M91.4013 20.1461C95.1416 20.1461 98.1736 17.114 98.1736 13.3738C98.1736 9.63353 95.1416 6.60147 91.4013 6.60147C87.6611 6.60147 84.629 9.63353 84.629 13.3738C84.629 17.114 87.6611 20.1461 91.4013 20.1461Z" fill="#FFCC4D"/>
    <path d="M68.8182 27.7801C72.5584 27.7801 75.5905 24.748 75.5905 21.0078C75.5905 17.2676 72.5584 14.2355 68.8182 14.2355C65.0779 14.2355 62.0459 17.2676 62.0459 21.0078C62.0459 24.748 65.0779 27.7801 68.8182 27.7801Z" fill="#F4900C"/>
    </g>
    <defs>
    <clipPath id="clip0_4184_2461">
    <rect width="136.661" height="136.661" fill="white" transform="translate(0.723145)"/>
    </clipPath>
    </defs>
    </svg>
    )
}

// const Time = () => {
//     return(<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <g clipPath="url(#clip0_3092_49809)">
//     <path d="M6.44322 28.1842L5.17328 31.6102H6.66408L9.42483 29.7294L6.44322 28.1842Z" fill="#FF2727"/>
//     <path opacity="0.1" d="M6.44322 28.1842L5.17328 31.6102H6.66408L9.42483 29.7294L6.44322 28.1842Z" fill="black"/>
//     <path d="M25.5601 28.1842L26.83 31.6102H25.3392L22.5785 29.7294L25.5601 28.1842Z" fill="#FF2727"/>
//     <path opacity="0.1" d="M25.5601 28.1842L26.83 31.6102H25.3392L22.5785 29.7294L25.5601 28.1842Z" fill="black"/>
//     <path d="M26.7735 27.4474C32.7224 21.4985 32.7224 11.8535 26.7735 5.90458C20.8246 -0.044307 11.1795 -0.0443097 5.23064 5.90458C-0.718244 11.8535 -0.718244 21.4985 5.23064 27.4474C11.1795 33.3963 20.8246 33.3963 26.7735 27.4474Z" fill="#FF2727"/>
//     <path opacity="0.1" d="M15.8164 30.961C23.8443 30.961 30.3522 24.4531 30.3522 16.4252C30.3522 8.39733 23.8443 1.88943 15.8164 1.88943C7.78853 1.88943 1.28064 8.39733 1.28064 16.4252C1.28064 24.4531 7.78853 30.961 15.8164 30.961Z" fill="black"/>
//     <path d="M25.6306 26.2404C30.9486 20.9224 30.9486 12.3002 25.6306 6.9822C20.3126 1.66419 11.6904 1.6642 6.37238 6.9822C1.05438 12.3002 1.05438 20.9224 6.37238 26.2404C11.6904 31.5584 20.3126 31.5584 25.6306 26.2404Z" fill="black"/>
//     <path d="M29.3085 13.7183C23.1075 17.4756 12.9159 23.6392 7.22252 27.018C5.61179 25.6601 4.33788 23.9472 3.50085 22.0139L22.3389 4.5535C24.099 5.4801 25.6349 6.78066 26.8389 8.36389C28.0429 9.94713 28.8858 11.7747 29.3085 13.7183Z" fill="#2D2D2D"/>
//     <path d="M29.6077 17.0838C29.5582 18.599 29.2538 20.095 28.7073 21.509L11.727 29.541C10.9865 29.2964 10.2687 28.988 9.5816 28.6193C14.7086 25.6546 24.0844 20.2605 29.6077 17.0838Z" fill="#2D2D2D"/>
//     <path d="M16.1326 3.98163H15.8699V6.04596H16.1326V3.98163Z" fill="#C7C7C7"/>
//     <path d="M9.80165 5.60605L9.57336 5.73785L10.6055 7.52561L10.8338 7.39381L9.80165 5.60605Z" fill="#C7C7C7"/>
//     <path d="M5.12971 10.1807L4.99835 10.4082L6.78611 11.4403L6.91747 11.2128L5.12971 10.1807Z" fill="#C7C7C7"/>
//     <path d="M5.43688 16.4792H3.37256V16.7419H5.43688V16.4792Z" fill="#C7C7C7"/>
//     <path d="M6.78465 21.7802L4.99689 22.8123L5.12825 23.0398L6.91601 22.0077L6.78465 21.7802Z" fill="#C7C7C7"/>
//     <path d="M10.605 25.6964L9.57373 27.4847L9.80209 27.6164L10.8333 25.8281L10.605 25.6964Z" fill="#C7C7C7"/>
//     <path d="M16.1326 27.1754H15.8699V29.2397H16.1326V27.1754Z" fill="#C7C7C7"/>
//     <path d="M21.3989 25.6936L21.1707 25.8254L22.2028 27.6131L22.4311 27.4813L21.3989 25.6936Z" fill="#C7C7C7"/>
//     <path d="M25.2163 21.7775L25.0849 22.0051L26.8727 23.0372L27.004 22.8097L25.2163 21.7775Z" fill="#C7C7C7"/>
//     <path d="M28.6307 16.4792H26.5664V16.7419H28.6307V16.4792Z" fill="#C7C7C7"/>
//     <path d="M26.8731 10.1845L25.0854 11.2166L25.2167 11.4442L27.0045 10.412L26.8731 10.1845Z" fill="#C7C7C7"/>
//     <path d="M22.2029 5.60895L21.1716 7.39725L21.4 7.52893L22.4312 5.74063L22.2029 5.60895Z" fill="#C7C7C7"/>
//     <path d="M28.5283 16.7861L15.3204 17.1548L15.3364 15.8635L28.5319 16.4352L28.5283 16.7861Z" fill="#FF2727"/>
//     <path d="M16.0925 7.32306L16.6642 17.374L15.372 17.357L15.7425 7.3186L16.0925 7.32306Z" fill="white"/>
//     <path d="M16.0017 17.0514C16.2451 17.0514 16.4425 16.8541 16.4425 16.6106C16.4425 16.3671 16.2451 16.1698 16.0017 16.1698C15.7582 16.1698 15.5609 16.3671 15.5609 16.6106C15.5609 16.8541 15.7582 17.0514 16.0017 17.0514Z" fill="#FF2727"/>
//     </g>
//     <defs>
//     <clipPath id="clip0_3092_49809">
//     <rect width="32" height="32" fill="white" transform="translate(0 0.666656)"/>
//     </clipPath>
//     </defs>
//     </svg>
//     )
// }

// const Played = () => {
//     return(<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M2.28467 17.4533V27.6182C2.28466 28.4124 2.50192 29.1926 2.91457 29.8804C3.32723 30.5681 3.92074 31.1391 4.63541 31.536C5.35007 31.9328 6.16068 32.1415 6.98572 32.141C7.81075 32.1405 8.62111 31.9309 9.33528 31.5332L18.4791 26.454L27.623 21.3363C28.3362 20.9382 28.9282 20.3667 29.3398 19.6791C29.7513 18.9914 29.968 18.2117 29.968 17.4181C29.968 16.6245 29.7513 15.8448 29.3398 15.1571C28.9282 14.4694 28.3362 13.898 27.623 13.4999L18.4791 8.42064L9.33528 3.34137C8.62111 2.9437 7.81075 2.73407 6.98572 2.73358C6.16068 2.7331 5.35007 2.94178 4.63541 3.33862C3.92074 3.73545 3.32723 4.30645 2.91457 4.99418C2.50192 5.68192 2.28466 6.46215 2.28467 7.25637V17.4533Z" fill="#CD1900"/>
// <g opacity="0.3">
// <path d="M2.28467 17.4533V27.6182C2.28466 28.4124 2.50192 29.1926 2.91457 29.8804C3.32723 30.5681 3.92074 31.1391 4.63541 31.536C5.35007 31.9328 6.16068 32.1415 6.98572 32.141C7.81075 32.1405 8.62111 31.9309 9.33528 31.5332L18.4791 26.454L27.623 21.3363C28.3362 20.9382 28.9282 20.3667 29.3398 19.6791C29.7513 18.9914 29.968 18.2117 29.968 17.4181C29.968 16.6245 29.7513 15.8448 29.3398 15.1571C28.9282 14.4694 28.3362 13.898 27.623 13.4999L18.4791 8.42064L9.33528 3.34137C8.62111 2.9437 7.81075 2.73407 6.98572 2.73358C6.16068 2.7331 5.35007 2.94178 4.63541 3.33862C3.92074 3.73545 3.32723 4.30645 2.91457 4.99418C2.50192 5.68192 2.28466 6.46215 2.28467 7.25637V17.4533Z" fill="black"/>
// </g>
// <path d="M2.28467 14.8238V24.9888C2.28551 25.7828 2.50319 26.5626 2.91585 27.2501C3.32851 27.9376 3.92167 28.5086 4.63585 28.9058C5.35003 29.303 6.16013 29.5125 6.98495 29.5133C7.80976 29.5141 8.6203 29.3061 9.33528 28.9102L18.4791 23.8245L27.623 18.7452C28.3362 18.3472 28.9282 17.7757 29.3398 17.088C29.7513 16.4004 29.968 15.6207 29.968 14.827C29.968 14.0334 29.7513 13.2537 29.3398 12.5661C28.9282 11.8784 28.3362 11.3069 27.623 10.9088L18.4791 5.82957L9.33528 0.743912C8.6203 0.348029 7.80976 0.140042 6.98495 0.14081C6.16013 0.141578 5.35003 0.351071 4.63585 0.748285C3.92167 1.1455 3.32851 1.71648 2.91585 2.40397C2.50319 3.09146 2.28551 3.87131 2.28467 4.66532V14.8238Z" fill="#CD1900"/>
// <path opacity="0.2" d="M26 13.0002L7 3.00018V3.95256L26 13.0002Z" fill="white"/>
// </svg>
//     )
// }

// const Won = () => {
//     return(<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M9.80211 16.5151C10.5347 16.5151 11.0598 16.4805 11.107 16.4774L11.0064 15.031C10.9749 15.031 7.6546 15.248 5.94728 14.5594C4.58897 14.006 3.08917 10.1858 2.42574 6.81205C2.00441 4.67399 2.23394 4.00112 2.41002 3.78731C2.44775 3.74329 2.54836 3.62067 2.93196 3.62067H4.25568C4.63928 4.7746 5.25241 5.93481 6.17681 5.93481H8.84626V4.49162H6.28371C6.06362 4.27152 5.70203 3.46032 5.49137 2.7057L5.34359 2.17748H2.93196C2.62305 2.15912 2.3141 2.2126 2.02933 2.33371C1.74456 2.45481 1.49173 2.64026 1.29067 2.87549C0.661821 3.63325 0.57693 4.93495 1.00455 7.09817C1.34878 8.8396 1.86161 10.5434 2.53579 12.1855C3.40674 14.2355 4.37202 15.4901 5.40333 15.9083C6.6736 16.4176 8.55071 16.5151 9.80211 16.5151Z" fill="#DB0000"/>
//     <path opacity="0.2" d="M6.72705 4.48541H8.84941V5.93489H7.05405L6.72705 4.48541Z" fill="black"/>
//     <path opacity="0.2" d="M11.107 16.4775C11.0504 16.4775 10.5347 16.5153 9.80212 16.5153C9.67949 16.5153 9.55372 16.5153 9.42166 16.5153L9.10724 15.0626C10.1606 15.0941 10.9938 15.0406 11.0189 15.0375L11.107 16.4775Z" fill="black"/>
//     <path d="M23.6934 16.515C22.9607 16.515 22.4357 16.4804 22.3885 16.4773L22.486 15.0309C22.5206 15.0309 25.8409 15.2479 27.545 14.5593C28.9065 14.0059 30.4032 10.1857 31.0697 6.81196C31.4879 4.6739 31.2584 4.00103 31.0697 3.78722C31.032 3.74321 30.9314 3.62058 30.5478 3.62058H29.2241C28.8405 4.77451 28.2242 5.93472 27.3029 5.93472H24.6303V4.49153H27.1929C27.5628 3.94865 27.8323 3.34374 27.9884 2.70562L28.1362 2.17739H30.5478C30.8567 2.15928 31.1656 2.21287 31.4503 2.33396C31.735 2.45505 31.9879 2.64036 32.1891 2.8754C32.8179 3.63316 32.8997 4.93486 32.4752 7.09808C32.1299 8.83961 31.6161 10.5434 30.9408 12.1854C30.073 14.2354 29.1077 15.49 28.0764 15.9082C26.8187 16.4175 24.9416 16.515 23.6934 16.515Z" fill="#DB0000"/>
//     <path opacity="0.2" d="M26.7684 4.48541H24.6461V5.93489H26.4414L26.7684 4.48541Z" fill="black"/>
//     <path opacity="0.2" d="M22.3885 16.4775C22.4451 16.4775 22.9607 16.5153 23.6934 16.5153C23.816 16.5153 23.9417 16.5153 24.0738 16.5153L24.3882 15.0626C23.3349 15.0941 22.5017 15.0406 22.4765 15.0375L22.3885 16.4775Z" fill="black"/>
//     <path d="M9.18896 30.9695V31.7807H24.3033V30.9695H23.0079L22.0457 29.586L17.647 27.8347V19.251H15.8485V27.8347L11.4497 29.586L10.4844 30.9695H9.18896Z" fill="#DB0000"/>
//     <path d="M15.3517 20.3953H18.1438V19.4929H15.3517V20.3953Z" fill="#DB0000"/>
//     <path opacity="0.2" d="M15.3517 20.0687L18.1438 19.9461V19.4933H15.3517V20.0687Z" fill="black"/>
//     <path d="M7.49744 1.20938C7.49744 1.20938 8.20489 12.9027 10.0474 16.135C11.8899 19.3672 15.8234 19.6408 16.7415 19.6408C17.6596 19.6408 21.5899 19.3641 23.4324 16.135C25.2749 12.9059 25.9824 1.20938 25.9824 1.20938H7.49744Z" fill="#DB0000"/>
//     <path opacity="0.3" d="M15.358 19.5087C13.7859 19.2415 11.3868 18.4428 10.0694 16.135C8.22686 12.9027 7.51941 1.20938 7.51941 1.20938H9.25817C9.49713 3.66501 9.96562 13.0442 11.2988 15.7734C12.4496 18.1598 15.1568 19.4395 15.358 19.5087Z" fill="white"/>
//     <path opacity="0.2" d="M23.0079 30.9693H10.4844L10.8303 30.4725L22.3571 30.0354L23.0079 30.9693Z" fill="black"/>
//     <path opacity="0.5" d="M16.7477 4.90039L17.7413 6.91583L19.9612 7.23654L18.3544 8.80236L18.7349 11.0159L16.7477 9.972L14.7606 11.0159L15.141 8.80236L13.5312 7.23654L15.7542 6.91583L16.7477 4.90039Z" fill="white"/>
//     <path d="M31.6262 27.3315C30.6375 27.3777 29.6467 27.3546 28.6612 27.2623C27.589 27.0976 26.5306 26.8527 25.495 26.5297C25.489 26.5295 25.4832 26.5311 25.4782 26.5344C25.4733 26.5377 25.4695 26.5425 25.4674 26.5481C25.4653 26.5536 25.465 26.5597 25.4666 26.5655C25.4681 26.5712 25.4715 26.5763 25.4761 26.58C27.4119 27.4172 29.5401 27.7076 31.6294 27.4195C31.6797 27.4101 31.6765 27.3315 31.6262 27.3315Z" fill="#263238"/>
//     <path d="M31.991 29.3624C31.0226 29.3216 29.3184 29.2398 26.4917 28.1676C28.1712 29.0943 30.075 29.5363 31.991 29.4442C32.0444 29.4568 32.057 29.3656 31.991 29.3624Z" fill="#263238"/>
//     </svg>
//     )
// }