import React, { useEffect, useRef, useState } from 'react'
import communityImg from '../../../Assets/Images/community.png'
import One from '../../../Assets/svgs/One.svg'
import Two from '../../../Assets/svgs/Two.svg'
import Three from '../../../Assets/svgs/Three.svg'
import Four from '../../../Assets/svgs/Four.svg'
import Five from '../../../Assets/svgs/Five.svg'
import Six from '../../../Assets/svgs/Six.svg'
import Seven from '../../../Assets/svgs/Seven.svg'
import Eight from '../../../Assets/svgs/Eight.svg'
import Nine from '../../../Assets/svgs/Nine.svg'
import Ten from '../../../Assets/svgs/Ten.svg'
import styles from './mainCard.module.css'
import CommunityPlayers from '../../Community/CommunirtyPlayers/CommunityPlayers'
import DailyTasks from '../DailyTasks/DailyTasks'
import { useNavigate } from 'react-router-dom'
import { DualArrow } from '../../../Assets/svgs/DualArrow'
import LoginModal from '../../shared/modals/LoginModal/LoginModal'
import PlayGameModal from '../../shared/modals/PlayGameModal/PlayGameModal'
import CreateProfileModal from '../../shared/modals/CreateProfile/CreateProfile'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMostPlayedCommunities, fetchMostRewardingTable } from '../../../redux/actions/otherAction'
import CreateGuestModal from '../../shared/modals/CreateGuestModal/CreateGuestModal'
import { ArrowRightRounded } from '@mui/icons-material'


const MainCard = ({socketObj}) => {
    const socket = socketObj
    const slider = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [hovered, setHovered] = useState(false)

    const {isLogin, accessToken, verificationHash} = useSelector((state) => state.userLoginReducer)
    const {fetched: rewardingFetched, loading, table: rewardingTable, topPlayers} = useSelector((state) => state.mostRewardedCommunitiesReducer)
    const {fetched: playedFetched, loading: loadMostPlayed, communities: mostPlayed} = useSelector((state) => state.mostPlayedCommunitiesReducer)

    const [openModal, setOpenModal] = useState(false);
    const [openGuestModal, setOpenGuestModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openTableModal, setOpenTableModal] = useState(false);
    const [checkOnlyWallet, setCheckOnlyWallet] = useState(false);
    const [checkOnlyDiscordConnect, setCheckOnlyDiscordConnect] = useState(false);

    const connectBtn = () =>{
        setOpenTableModal(false)
        setOpenModal(true)
    }

    const handlePlayGame = () => {
        if(isLogin)
        setOpenTableModal(true)
        else
        setOpenModal(true)
    }

    const joinTable = (chipValue, tableId) => {
        socket.emit("joinTable", {
            tableId: tableId || rewardingTable._id,
            verificationHash,
            accessToken,
            socketId: socket.id,
            chipValue
        });
    }

    const joinSpectatorTable = () => {
        socket.emit("joinTable", {
            tableId: rewardingTable._id,
            verificationHash,
            accessToken,
            socketId: socket.id,
            isSpectator: true
        });
    }

    // const slideBack = () => {
    //     // const slider = document.getElementById('live_table_cards_holder')
    //     const clientWidth = slider.current.clientWidth
    //     const scrollByValue = parseInt(clientWidth/2)

    //     if(slider.current.scrollLeft === 0){
    //     return
    //     }else if(slider.current.scrollLeft - scrollByValue < 0){
    //     slider.current.scroll(-1 * slider.current.scrollLeft, 0)
    //     return
    //     }else{
    //     slider.current.scroll(slider.current.scrollLeft - scrollByValue, 0);
    //     }
    // }

    const slideForward = () => {
        const scrollWidth = slider.current.scrollWidth
        const clientWidth = slider.current.clientWidth
        const scrollByValue = parseInt(clientWidth)
        if(slider.current.scrollLeft === clientWidth){
        return
        }else if(slider.current.scrollLeft + scrollByValue > scrollWidth) {
        slider.current.scroll(-1 * (scrollWidth - slider.current.scrollLeft), 0)
        return
        }else{
        slider.current.scroll(slider.current.scrollLeft + scrollByValue, 0);
        }
    }

    useEffect(() => {
        if(!playedFetched) dispatch(fetchMostPlayedCommunities())
        if(!rewardingFetched) dispatch(fetchMostRewardingTable())
    },[dispatch, playedFetched, rewardingFetched])

    return (
        <div className={styles.wrapper}>
            <div className={styles.top__more_info}>
                {/* Daily Tasks */}
                <div className={styles.daily_tasks_wrapper}>
                    <DailyTasks />
                </div>

                {/* Others Wrapper */}
                <div className={styles.other_stuff_wrapper}>
                    <CommunityPlayers loading={loading} maxFit topPlayers={topPlayers} />

                    <div className={styles.most_rewarding}
                        onMouseEnter={() => {if(!loading)setHovered(true)}}
                        onMouseLeave={() => setHovered(false)}
                    >
                        <div className={styles.top_txt}>Most Rewarding Table</div>

                        {/* <div style={{background: 'rgba(255, 0, 0, 0.35)', width: '100%', minHeight: '1px'}} /> */}

                        <div className={styles.rewarding_btm} onClick={() => {
                            handlePlayGame()
                        }}>
                            <figure
                                className={styles.rewarding_fig_wrapper}
                            >
                                {loading ? <div className={`animate-pulse ${styles.loader_image}`} />
                                : <img src={rewardingTable?.cardPreview} alt="" className='w-full h-full object-cover' />}
                            </figure>
                            <div className={styles.wrapper_rewarding}>
                                {loading ? <div className={`animate-pulse ${styles.rewarding_head_loader}`} />
                                : <h2 className={styles.rewarding_head}>
                                    {rewardingTable?.tableName}
                                </h2 >}

                                <div className={styles.max_winning_wrapper}>
                                    <div className={styles.max_winning_cnt}>
                                        <h2>Max Winnings</h2>
                                        <div className='flex items-center gap-3'>
                                            {loading ? <div className={`animate-pulse ${styles.loader_chip_image}`} />
                                            : <img src={rewardingTable?.chipImg} alt="" className='w-10 h-10'/>}

                                            {loading ? <div className={`animate-pulse ${styles.rewarding_head_loader}`} />
                                            : <h3>20K</h3>}
                                    </div>
                                    </div>

                                    {/* Arrow */}
                                    {!loading && <button>
                                        <DualArrow fill={hovered ? '#F00' : '#FFF'} />
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.bottom_most_played_wrapper}>
                <div className={styles.top_txt}>
                    Most Played Communities
                </div>
                {/* Most Played */}
                <div className={styles.most_played_main_wrpr} ref={slider}>
                    {loadMostPlayed
                    ? Array(3).fill(0, 0)?.map((_c, key) => {
                        const Icon = data[key].Icon
                        return <div className={styles.wrappr_card_holder} key={key}>
                            <div className={styles.num_big}>
                                <img src={Icon} alt="" />
                            </div>

                            <div className={`animate-pulse ${styles.inside_wrpr_holder_otr_loader}`}>
                            </div>
                        </div>
                    })
                    : mostPlayed?.map((community, key) => {
                        const Icon = data[key].Icon
                        return <div className={styles.wrappr_card_holder} key={key}>
                            <div className={styles.num_big}>
                                <img src={Icon} alt="" />
                            </div>

                            <div className={styles.inside_wrpr_holder_otr} onClick={() => {
                                navigate(`/${community?.slug}`)
                            }}>
                                <div className={styles.inside_wrpr_holder} style={{cursor: 'pointer'}}>
                                    <figure
                                        className={styles.holder_most_played_card}
                                    >
                                        <img style={{ objectFit: 'cover', width: '100%', height: '63.5px' }} src={community?.backgroundImage || communityImg} alt="" />
                                    </figure>

                                    <div className={styles.text_wrpper_inside_card}>
                                        <h1>{community?.communityName}</h1>
                                        {/* Arrow */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>

                {mostPlayed?.length > 5 && <button onClick={slideForward} className={styles.rightArrowBtn}><ArrowRightRounded /> </button>}
            </div>

            <LoginModal setOpenGuestModal={setOpenGuestModal} checkOnlyDiscordConnect={checkOnlyDiscordConnect} checkOnlyWallet={checkOnlyWallet} openModal={openModal} setOpenModal={() => {setOpenModal(false)}} setOpenProfileModal={setOpenCreateModal} backUrl={`/pokertable/${rewardingTable?._id}`}/>
            {openGuestModal && <CreateGuestModal setOpenConnectModal={setOpenModal} backUrl={`/pokertable/${rewardingTable?._id}`} openModal={openGuestModal} setOpenModal={setOpenGuestModal} setOpenCreateModal={setOpenCreateModal} />}
            {openTableModal && <PlayGameModal connectBtn={connectBtn} setCheckOnlyDiscordConnect={setCheckOnlyDiscordConnect} setCheckOnlyWallet={setCheckOnlyWallet} openModal={openTableModal} setOpenModal={() => setOpenTableModal(false)} table={rewardingTable} joinTable={joinTable} joinSpectatorTable={joinSpectatorTable} />}
            {openCreateModal && <CreateProfileModal openModal={openCreateModal} setOpenModal={setOpenCreateModal} />}
        </div>
    )
}

export default React.memo(MainCard)


const data = [
    {
        Icon: One,
    },
    {
        Icon: Two,
    },
    {
        Icon: Three,
    },
    {
        Icon: Four,
    },
    {
        Icon: Five,
    },
    {
        Icon: Six,
    },
    {
        Icon: Seven,
    },
    {
        Icon: Eight,
    },
    {
        Icon: Nine,
    },
    {
        Icon: Ten,
    },
]