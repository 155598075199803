import { FAILED_DAILY_TASKS, FAILED_GET_FREE_TABLES, FAILED_GET_LEADERBOARD, FAILED_GET_TABLES, REQUEST_DAILY_TASKS, REQUEST_GET_FREE_TABLES, REQUEST_GET_LEADERBOARD, REQUEST_GET_TABLES, SELECT_NAVBAR, SET_CURRENT_POSITION, SUCCESS_DAILY_TASKS, SUCCESS_GET_FREE_TABLES, SUCCESS_GET_LEADERBOARD, SUCCESS_GET_TABLES } from "../constants/GlobalConstant";

const navbarState = {
    title: '',
    backUrl: '',
    disabled: false,
    currId: 'dashboard'
}

export const NavbarReduer = (state = navbarState, action) => {
    switch(action.type){
        case SELECT_NAVBAR:
            return{
                title: action.title,
                backUrl: action.backUrl,
                disabled:action.disabled
            }
        case SET_CURRENT_POSITION:
            return{
                currId: action.payload
            }
        default:
            return state
    }
}

const dailyTasksState = {
    loading: true,
    fetched: false,
    tasks: null,
    error: null
}

export const dailyTasksReducer = (state = dailyTasksState, action) => {
    switch(action.type){
        case REQUEST_DAILY_TASKS:
            return{
                loading: true,
                fetched: false,
                tasks: null,
                error: null
            }
        case SUCCESS_DAILY_TASKS:
            return{
                loading: false,
                fetched: true,
                tasks: action.payload,
                error: null
            }
        case FAILED_DAILY_TASKS:
            return{
                loading: false,
                fetched: false,
                tasks: null,
                error: action.payload
            }
        default:
            return state
    }
}

const getFreeTablesState = {
    loading: false,
    fetched: false,
    tables: null,
    error: null
}

export const getFreeTableReducer = (state = getFreeTablesState, action) => {
    switch(action.type){
        case REQUEST_GET_FREE_TABLES:
            return{
                loading: true,
                fetched: false,
                tables: null,
                error: null
            }
        case SUCCESS_GET_FREE_TABLES:
            return{
                loading: false,
                fetched: true,
                tables: action.payload,
                error: null
            }
        case FAILED_GET_FREE_TABLES:
            return{
                loading: false,
                fetched: false,
                tables: null,
                error: action.payload
            }
        default:
            return state
    }
}

const getTablesState = {
    loading: false,
    fetched: false,
    tables: null,
    error: null
}

export const getPublicTablesReducer = (state = getTablesState, action) => {
    switch(action.type){
        case REQUEST_GET_TABLES:
            return{
                loading: true,
                fetched: false,
                tables: null,
                error: null
            }
        case SUCCESS_GET_TABLES:
            return{
                loading: false,
                fetched: true,
                tables: action.payload,
                error: null
            }
        case FAILED_GET_TABLES:
            return{
                loading: false,
                fetched: false,
                tables: null,
                error: action.payload
            }
        default:
            return state
    }
}

const getLoeaderboardState = {
    loading: false,
    leaderboardData: [],
    userScore: '',
    size: ''
}

export const getLeaderboardReducer = (state = getLoeaderboardState, action) => {
    switch(action.type){
        case REQUEST_GET_LEADERBOARD:
            return{
                loading: true,
                leaderboardData: action.leaderboardData,
                userScore: action.userScore,
                size: action.size,
                error: null
            }
        case SUCCESS_GET_LEADERBOARD:
            return{
                loading: false,
                leaderboardData: action.leaderboardData,
                userScore: action.userScore,
                size: action.size,
                error: null
            }
        case FAILED_GET_LEADERBOARD:
            return{
                loading: false,
                leaderboardData: [],
                userScore: '',
                size: '',
                error: action.payload
            }
        default:
            return state
    }
}