import React, { useEffect, useState } from 'react'
import styles from './Tournament.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import RegisterModal from '../../Components/shared/modals/RegisterModal/RegisterModal';
import PrizeSection from '../../Components/Tournament/PrizeSection/PrizeSection';
import LeaderboardSection from '../../Components/Tournament/LeaderboardSection/LeaderboardSection';
import { getTournamentById } from '../../redux/actions/TournamentAction';
import RulesSection from '../../Components/Tournament/RulesSection/RulesSection';
import DashboardNav from './../../Components/DashboardNavCommunity/DashboardNav';
import { activateTableId, useCountdown } from '../../redux/actions/otherAction';
import PlayGameModal from '../../Components/shared/modals/PlayGameModal/PlayGameModal';
import LoginModal from '../../Components/shared/modals/LoginModal/LoginModal';
import CreateGuestModal from '../../Components/shared/modals/CreateGuestModal/CreateGuestModal';
import CreateProfileModal from '../../Components/shared/modals/CreateProfile/CreateProfile';
import { BACKEND_URL } from '../../constants';
import { updateLocalUserInfo } from '../../redux/actions/userAction';
import axios from 'axios';
import { ChooseNavLinks } from '../../redux/actions/GlobalAction';

const Tournament = ({socketObj}) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const id = params?.tournamentId
    const socket = socketObj
    const tournamentState = location.state

    const {loading, tournament: thisTournament, error, registerations} = useSelector((state) => state.getTournamentReducer)
    const {isLogin, userInfo, accessToken, verificationHash} = useSelector((state) => state.userLoginReducer)

    const [tournament, setTournament] = useState(false ? tournamentState : thisTournament)
    const [tab, setTab] = useState(1)
    const [active, setActive] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [playingTime, setPlayingTime] = useState(false)
    const [openTableModal, setOpenTableModal] = useState(false);
    const [checkOnlyWallet, setCheckOnlyWallet] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openGuestModal, setOpenGuestModal] = useState(false);
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [checkOnlyDiscordConnect, setCheckOnlyDiscordConnect] = useState(false);

    const isRegistered = registerations?.find((e) => e.walletAddress === userInfo?.walletAddress)
    // const isNotAllowed = registerations?.find((e) => e.walletAddress === userInfo?.walletAddress)?.notAllowed

    const fetchItem = () => {
        dispatch(getTournamentById(id))
    }

    useEffect(() => {
        // if(!tournamentState)
            dispatch(getTournamentById(id))
    },[dispatch, id, tournamentState])

    useEffect(() => {
        // if(thisTournament && !tournamentState)
            setTournament(thisTournament)
    }, [thisTournament])

    useEffect(() => {
        dispatch(ChooseNavLinks(tournament?.slug, -1, false))

        return() => dispatch(ChooseNavLinks('', '', false))
    }, [tournament, dispatch])

    const handlePlayGame = async () => {
        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + accessToken,
            },
        }

        // if(table?.nftsRequired){
        await axios.post(`${BACKEND_URL}/community/playGame`,
        {
            verificationHash,
            tournamentId: tournament?._id
        },
        config).then((res) => {
            if(res.data?.status === 200){
                const value = (isRegistered && isRegistered?.chips) ? isRegistered?.chips : (tournament?.tournamentSpeed === 'Turbo' ? 750 : tournament?.tournamentSpeed === 'Regular' ? 1000 : 500)
                dispatch(activateTableId(res.data?.tableId))
                navigate(`/pokertable/${res.data?.tableId}`, { state: { tournament: true } })
                joinTable(value, res.data?.tableId)
            }else{
                if (res.data?.msg.includes('Verification Hash')) {
                    dispatch(updateLocalUserInfo())
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const connectBtn = () =>{
        setOpenTableModal(false)
        setOpenLoginModal(true)
    }

    const joinTable = (chipValue, tableId) => {
        socket.emit("joinTable", {
            tableId: tableId || tournament._id,
            verificationHash,
            accessToken,
            socketId: socket.id,
            chipValue,
            tournamentId: tournament._id,
            tournamentSpeed: tournament.tournamentSpeed,
            tournamentTime: tournament.time
        });
    }

    const joinSpectatorTable = () => {
        socket.emit("joinTable", {
        tableId: tournament._id,
        verificationHash,
        accessToken,
        socketId: socket.id,
        isSpectator: true
        });
    }

    useEffect(() => {
        window.scroll(0, 0)
    },[])

    useEffect(() => {
        if(Date.now() < tournament?.time){
            setActive(true)
        }else if(Date.now() < tournament?.time + 600000){
            setPlayingTime(true)
        }
    },[tournament])

    useEffect(() => {
        let interval, interval2
        if(Date.now() < tournament?.time){
            interval = setTimeout(() => {
                setActive(false)

                if(Date.now() < tournament?.time + 600000){
                    setPlayingTime(true)
                }
            }, tournament?.time - Date.now())
        }
        if(Date.now() < tournament?.time + 600000){
            interval2 = setTimeout(() => {
                setPlayingTime(false)
            }, tournament?.time + 600000 - Date.now())
        }
        return () => {
            if(interval)
                clearTimeout(interval)
            if(interval2)
                clearTimeout(interval2)
        }
    },[tournament])

    if(error)
        return <pre>{error}</pre>

    return (
        <div className={styles.tournament}
            id='scroll'
            style={{
                background: loading ? 'linear-gradient(270deg, rgba(61, 61, 61, 0.05) 0%, #3D3D3D 50%)' : `linear-gradient(0deg, ${tournament?.communityTheme?.backgroundColor + '33'} 0%, ${tournament?.communityTheme?.backgroundColor+ '33'} 100%), url(${tournament?.tournamentBackgroundImage}) center center no-repeat fixed`,
                // backgroundRepeat: loading ? '' : 'no-repeat',
                // backgroundSize: loading ? '' : 'cover',
                // backgroundPosition: loading ? '' : 'center',
                // backgroundAttachment: loading ? '' : 'fixed'
            }}
        >
            <div className={styles.banner}
                style={{
                    backgroundImage: loading ? 'linear-gradient(270deg, rgba(61, 61, 61, 0.05) 0%, #3D3D3D 50%)' : `url(${tournament?.cardPreview})`,
                    position: 'relative',
                    backgroundPosition: 'top'
                }}
            >
                <DashboardNav backUrl={`/${tournament?.slug}`} title={tournament?.slug} />

                {!loading && <div className={styles.total_registered_mobile}>
                    {loading ? '--' : registerations?.length || 0} Registered
                </div>}
            </div>

            <div className={styles.main_body}>
                {!loading && <div className={styles.abst_sub_banner}>
                    <button className={styles.mobile_back} onClick={() => navigate(`/${tournament?.slug}`)}>
                        <BackBtn />
                    </button>

                    <div className={styles.left_wrpr}>
                        <img src={tournament?.tournamentLogoImage} alt="" className={styles.logo_img} style={{borderColor: tournament?.communityTheme?.backgroundColor}} />
                        <h1>{tournament?.tournamentName}</h1>
                        <p className={styles.mobile_community_owner}>By {tournament?.slug}</p>

                        {/* {Date.now() >= tournament?.time && <div className={`animate-pulse ${styles.live_box}`}>LIVE</div>} */}
                    </div>

                    {window?.innerWidth > 650 && !isLogin && <button className={`${isRegistered ? 'cursor-default' : ''} primary-custom-button`} onClick={connectBtn}>
                        <div className={`primary-custom-button-inner ${styles.register_btn}`}>Connect Wallet</div>
                    </button>}

                    {window?.innerWidth > 650 && !loading && isLogin && active && <button className={`${isRegistered ? `cursor-default ${styles.disable_register}` : ''} primary-custom-button`} disabled={isRegistered} onClick={() => {if(!isRegistered) setOpenModal(true)}} >
                        <div className={`primary-custom-button-inner ${styles.register_btn}`}>{isRegistered ? 'Already Registered' : 'Register'}</div>
                    </button>}

                    {window?.innerWidth > 650 && !loading && isLogin && !active && isRegistered && ((playingTime && (Date.now() < (tournament?.time + 600000))) || (isRegistered?.chips >= 0)) && <button disabled={isRegistered.isJoined || tournament.winner || (isRegistered?.chips === 0)} className={`${false ? 'cursor-default' : ''} primary-custom-button`}
                        onClick={handlePlayGame} >
                        <div className={`primary-custom-button-inner ${styles.register_btn}`}>{tournament?.winner ? 'Winner Announced' : (isRegistered.isJoined ? "Already Joined" : (isRegistered?.chips === 0 ? 'Already Joined' : `Join Now`))}</div>
                    </button>}
                </div>}

                {/* Left */}
                <div className={styles.left_section}>
                    <div className={styles.tab_groups}>
                        <button className={tab === 1 ? styles.selected_tab : styles.tab} onClick={() => setTab(1)}>Prize</button>
                        <button className={tab === 2 ? styles.selected_tab : styles.tab} onClick={() => setTab(2)}>Rules</button>
                        {(((tournament?.time + 120000) < new Date().getTime()) || (userInfo?.isAdmin && userInfo?.communitySlug?.length > 0 && userInfo?.communitySlug?.find((x) => x === tournament?.slug))) && <button className={tab === 3 ? styles.selected_tab : styles.tab} onClick={() => setTab(3)}>Leaderboard</button>}
                    </div>

                    {/* Upper Section-Cards */}
                    {tab === 1 && <PrizeSection tournament={tournament} />}
                    {tab === 2 && <RulesSection tournament={tournament} />}
                    {tab === 3 && <LeaderboardSection fetchItem={fetchItem} tournamentId={id} tournament={tournament} />}
                </div>

                {/* Right */}
                <div className={styles.right_section}>
                    <RightBox
                        label={!isRegistered && !loading && active ? 'Entry Starts In:' : Date.now() < tournament?.time ? 'Entry Starts In:' : playingTime && (Date.now() < (tournament?.time + 600000)) && isRegistered ? 'Entry Ends In:' : 'Entry:'}
                        timer={loading ? null : (active ? tournament?.time : playingTime && (Date.now() < (tournament?.time + 600000)) && isRegistered ? (tournament?.time + 600000) : null)}
                        tournament={tournament}
                        text = {!isRegistered && !loading && active ? 'Expired' : active || loading ? '' : 'Expired'}
                    />
                    <RightBox label={'Type:'} text={tournament?.tournamentType} />
                    <RightBox label={'Speed:'} text={tournament?.tournamentSpeed} />
                    <RightBox label={'Entry Fees:'} text={tournament?.entryFees} coin={tournament?.chipName} />
                    <RightBox label={'Registeration'} text={loading ? '--' : registerations?.length || 0} />
                </div>
            </div>

            <LoginModal setOpenGuestModal={setOpenGuestModal} checkOnlyDiscordConnect={checkOnlyDiscordConnect} checkOnlyWallet={checkOnlyWallet} openModal={openLoginModal} setOpenModal={() => {setOpenLoginModal(false)}} setOpenProfileModal={setOpenCreateModal} backUrl={location.pathname}/>
            <RegisterModal tournament={tournament} openModal={openModal} setOpenModal={setOpenModal} />
            {openTableModal && <PlayGameModal connectBtn={connectBtn} setCheckOnlyDiscordConnect={setCheckOnlyDiscordConnect} setCheckOnlyWallet={setCheckOnlyWallet} key={id} openModal={openTableModal} setOpenModal={() => setOpenTableModal(false)} table={tournament} joinTable={joinTable} joinSpectatorTable={joinSpectatorTable} />}
            {openGuestModal && <CreateGuestModal setOpenConnectModal={setOpenModal} backUrl={location.pathname} openModal={openGuestModal} setOpenModal={setOpenGuestModal} setOpenCreateModal={setOpenCreateModal} />}
            {openCreateModal && <CreateProfileModal openModal={openCreateModal} setOpenModal={setOpenCreateModal} />}

            {!loading && isLogin && window?.innerWidth <= 650 && (
                <div className={styles.abst_mobile_btns}>
                    {active && <button className={`${isRegistered ? `cursor-default ${styles.disable_register}` : ''} ${styles.register_btn_mob} primary-custom-button`} disabled={isRegistered} onClick={() => {if(!isRegistered) setOpenModal(true)}} >
                        <div className={`primary-custom-button-inner ${styles.register_btn}`}>{isRegistered ? 'Already Registered' : 'Register'}</div>
                    </button>}

                    {!active && isRegistered && ((playingTime && (Date.now() < (tournament?.time + 600000))) || (isRegistered?.chips >= 0)) && <button disabled={isRegistered.isJoined || tournament.winner || (isRegistered?.chips === 0)} className={`${false ? 'cursor-default' : ''} ${styles.register_btn_mob} primary-custom-button`}
                        onClick={handlePlayGame} >
                        <div className={`primary-custom-button-inner ${styles.register_btn}`}>{tournament?.winner ? 'Winner Announced' : (isRegistered.isJoined ? "Already Joined" : (isRegistered?.chips === 0 ? 'Already Joined' : `Join Now`))}</div>
                    </button>}
                </div>
            )}
        </div>
    )
}

export default Tournament

const RightBox = ({label, text, timer, coin, tournament}) => {
    const {days, hours, minutes, seconds} = useCountdown(timer)

    return(
        <div className={styles.right_box_container}>
            <label htmlFor="">{label}</label>

            {timer && <div className={`flex items-baseline gap-2 ${styles.timer_tournament}`}>
                {days > 0 && <div className='flex items-baseline'>
                    <h1>{days < 0 ? '--' : days}</h1>
                    <h2>D</h2>
                </div>}

                {days > 0 && <h1>:</h1>}

                {hours > 0 && <div className='flex items-baseline'>
                    <h1>{hours < 0 ? '--' : hours}</h1>
                    <h2>H</h2>
                </div>}

                {hours > 0 && <h1>:</h1>}

                <div className='flex items-baseline'>
                    <h1>{minutes < 0 ? '--' : minutes}</h1>
                    <h2>M</h2>
                </div>

                {hours === 0 && <h1>:</h1>}

                {(hours === 0 || seconds < 0) && <div className='flex items-baseline'>
                    <h1>{seconds < 0 ? '--' : seconds}</h1>
                    <h2>S</h2>
                </div>}
            </div>}

            {!timer && <div className='flex items-center gap-2'>
                <h1>{(label === 'Entry Fees:') ? (text === 0 ? 'FREE' : text) : text}</h1>
                {coin && ((label === 'Entry Fees:') && (text !== 0)) && <div className={styles.coin_hldr}>
                    <div className={styles.coin_hldr_inner}>
                        {coin}
                    </div>
                </div>}
            </div>}
        </div>
    )
}

const BackBtn = () => {
    return(<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.1663 14H5.83301" stroke="#D93D48" strokeWidth="2.33333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.9997 22.1693L5.83301 14.0026L13.9997 5.83594" stroke="#D93D48" strokeWidth="2.33333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}