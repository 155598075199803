import { useDispatch, useSelector } from 'react-redux'
import CreateTableModal from '../../shared/modals/CreateTableModal/CreateTableModal'
import TableCard from './TableCard'
import styles from './Tables.module.css'
import React, { useEffect, useState } from 'react'
import { getTables, saveTableDataState, saveTableState } from '../../../redux/actions/TableAction'
import { editTableData } from '../../../redux/actions/otherAction'
import TableCardSkelton from './TableCardSkelton'

const Tables = ({data, setData, openModal, setOpenModal, tableData, communintyId, id, setTableData, slug}) => {
  const dispatch = useDispatch()
  const {data: tables, loading} = useSelector((state) => state.getTablesReducer)
  const [hover, setHover] = useState(false)

  useEffect(() => {
      if(tables?.length === 0) dispatch(getTables(slug))
  },[dispatch, slug])

  return (
    <div className={styles.container}>
      <div className={styles.page_header}>
        <h1 className={styles.heading}>{`Active Tables(${tables?.length})`}</h1>

        <button
          className={`${styles.createTableBtn} primary-custom-button`}
          onClick={() => {dispatch(saveTableState(null)); dispatch(saveTableDataState(null)); setOpenModal(true); dispatch(editTableData(null))}}
          onMouseEnter={() => {setHover(true)}}
          onMouseLeave={() => {setHover(false)}}
        >
          <div className='primary-custom-button-inner flex items-center gap-1'>
            <Add fill={hover ? "#D93D48" : "white"} />{'   '}Create Table
          </div>
        </button>
      </div>

      <div className={styles.wrap_tables}>
        {loading
          ? Array(3).fill(0, 0)?.map((e, k) => (
              <TableCardSkelton key={k} />
            ))
          : tables?.map((table, key) => (
            <TableCard key={key} id={key} data={table} setOpenModal={setOpenModal} />
          ))
        }
      </div>

      {openModal && <CreateTableModal setTableData={setTableData} communitySlug={id} tableData={tableData} openModal={openModal} setOpenModal={setOpenModal} data={data} setData={setData} communityId={communintyId} />}
    </div>
  )
}

export default React.memo(Tables)

const Add = ({fill}) => {
  return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 5V19M5 12H19" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  )
}