import styles from './PageProperties.module.css'
import React, { useState } from 'react'
import PreviewSection from './PreviewSection'
import Input from '../Input/Input'
import UploadImage from '../../shared/Upload/UploadImage'
import { useDispatch, useSelector } from 'react-redux'
import { updateCommunityAction } from '../../../redux/actions/CommunityAction'
import { CommunityCardColorShades } from '../../../colors'
import { useNavigate } from 'react-router-dom'
import CommunityPreview from '../../shared/modals/CommunityPreview/CommunityPreview'

const PageProperties = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {data:communityInfo} = useSelector((state) => state.getCommunityByIDReducer)
    const [openPreviewModal, setOpenPreviewModal] = useState(false)

    const [data, setData] = useState({
        communityName: communityInfo?.communityName || '',
        url: communityInfo?.slug || '',
        themeColour: communityInfo?.theme || {
            id: 0,
            backgroundColor: '#890084'
        },
        bannerImage1: communityInfo?.pageBanners[0] || null,
        bannerImage2: communityInfo?.pageBanners[1] || null,
        bannerImage3: communityInfo?.pageBanners[2] || null,
        backgroundImage1: communityInfo?.backgroundImages[0] || null,
        backgroundImage2: communityInfo?.backgroundImages[1] || null,
        backgroundImage3: communityInfo?.backgroundImages[2] || null,
        bannerSelected: communityInfo?.pageBannerId || -1,
        backgroundSelected: communityInfo?.backgroundImageId || -1
    })

    const validateCreation = () => {
        if(data?.communityName === '' || data?.url === '' || !data?.themeColour || (!data?.bannerImage1 && !data?.bannerImage2 && !data?.bannerImage3) || (!data?.backgroundImage1 && !data?.backgroundImage2 && !data?.backgroundImage3) || data?.backgroundSelected === -1 || data?.bannerSelected === -1)
            return false
        return true
    }

    const updateCommunity = () =>{
        dispatch(updateCommunityAction(data, communityInfo?._id, navigate))
    }

    return <div className={styles.container}>
        {/* Form Section */}
        <div className={styles.form_section}>
            <Input label={'COMMUNITY NAME'} value={data?.communityName} onChange={(e) => setData((prev) => ({...prev, communityName: e}))} />
            <Input disabled={true} label={'URL'} value={data?.url} onChange={(e) => setData((prev) => ({...prev, url: e}))} />

            <div className={styles.form_field_wrapper}>
                <label className={styles.label_heading}>THEME COLOR</label>

                <div className={styles.theme_color_holder}>
                    {/* <div className={`${styles.theme_color} ${styles.theme_null}`} onClick={() => setData((prev) => ({...prev, themeColour: -1}))}>
                        <button className={styles.theme_btn}>
                            <NullSelection />
                        </button>
                    </div> */}

                    {CommunityCardColorShades.map((color, key) => (
                        <div key={key} className={`${styles.theme_color} ${key === data?.themeColour?.id ? styles.theme_selected : styles.theme_default}`} onClick={() => setData((prev) => ({...prev, themeColour: color}))}>
                            <button className={styles.theme_btn} style={{background: `${color?.backgroundColor}`}}>
                                {/* TODO: selected -> svg */}
                                {key === data?.themeColour?.id && <SelectedSvg />}
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.form_field_wrapper}>
                <label className={styles.label_heading}>PAGE BANNER</label>

                <div className={styles.upload_images_wrapper}>
                    <UploadImage id={1} selected={data?.bannerSelected} slug={`page_properties_bannerImage1`} value={data?.bannerImage1} onChange={(e) => {setData((prev) => ({...prev, bannerImage1: e}))}} setSelected={(e) => {setData((prev) => ({...prev, bannerSelected: e}))}} />
                    <UploadImage id={2} selected={data?.bannerSelected} slug={`page_properties_bannerImage2`} value={data?.bannerImage2} onChange={(e) => {setData((prev) => ({...prev, bannerImage2: e}))}} setSelected={(e) => {setData((prev) => ({...prev, bannerSelected: e}))}} />
                    <UploadImage id={3} selected={data?.bannerSelected} slug={`page_properties_bannerImage3`} value={data?.bannerImage3} onChange={(e) => {setData((prev) => ({...prev, bannerImage3: e}))}} setSelected={(e) => {setData((prev) => ({...prev, bannerSelected: e}))}} />
                </div>
            </div>

            <div className={styles.form_field_wrapper}>
                <label className={styles.label_heading}>BACKGROUND IMAGE</label>
                <div className={styles.upload_images_wrapper}>
                    <UploadImage id={1} selected={data?.backgroundSelected} slug={`page_properties_backgroundImage1`} value={data?.backgroundImage1} onChange={(e) => {setData((prev) => ({...prev, backgroundImage1: e}))}} setSelected={(e) => {setData((prev) => ({...prev, backgroundSelected: e}))}} />
                    <UploadImage id={2} selected={data?.backgroundSelected} slug={`page_properties_backgroundImage2`} value={data?.backgroundImage2} onChange={(e) => {setData((prev) => ({...prev, backgroundImage2: e}))}} setSelected={(e) => {setData((prev) => ({...prev, backgroundSelected: e}))}} />
                    <UploadImage id={3} selected={data?.backgroundSelected} slug={`page_properties_backgroundImage3`} value={data?.backgroundImage3} onChange={(e) => {setData((prev) => ({...prev, backgroundImage3: e}))}} setSelected={(e) => {setData((prev) => ({...prev, backgroundSelected: e}))}} />
                </div>
            </div>
        </div>

        {/* Preview Section */}
        <PreviewSection validateCreation={validateCreation} updateCommunity={updateCommunity} setOpenPreviewModal={setOpenPreviewModal} communityInfo={{...data, backgroundImages:[data?.backgroundImage1, data?.backgroundImage2, data?.backgroundImage3], backgroundImageId: data?.backgroundSelected, pageBanners: [data?.bannerImage1, data?.bannerImage2, data?.bannerImage3], pageBannerId: data?.bannerSelected}} />

        {openPreviewModal && <CommunityPreview openModal={openPreviewModal} setOpenModal={setOpenPreviewModal} communityInfo={{...data, backgroundImages:[data?.backgroundImage1, data?.backgroundImage2, data?.backgroundImage3], backgroundImageId: data?.backgroundSelected, pageBanners: [data?.bannerImage1, data?.bannerImage2, data?.bannerImage3], pageBannerId: data?.bannerSelected}}/>}
    </div>
}

export default PageProperties

const SelectedSvg = () => {
    return(<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2083_37280" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
    <rect x="0.96843" y="0.806641" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_2083_37280)">
    <path d="M12.9684 22.8066C11.5851 22.8066 10.2851 22.5441 9.06843 22.0191C7.85176 21.4941 6.79343 20.7816 5.89343 19.8816C4.99343 18.9816 4.28093 17.9233 3.75593 16.7066C3.23093 15.49 2.96843 14.19 2.96843 12.8066C2.96843 11.4233 3.23093 10.1233 3.75593 8.90664C4.28093 7.68997 4.99343 6.63164 5.89343 5.73164C6.79343 4.83164 7.85176 4.11914 9.06843 3.59414C10.2851 3.06914 11.5851 2.80664 12.9684 2.80664C14.0518 2.80664 15.0768 2.96497 16.0434 3.28164C17.0101 3.59831 17.9018 4.03997 18.7184 4.60664L17.2684 6.08164C16.6351 5.68164 15.9601 5.36914 15.2434 5.14414C14.5268 4.91914 13.7684 4.80664 12.9684 4.80664C10.7518 4.80664 8.86426 5.58581 7.30593 7.14414C5.7476 8.70247 4.96843 10.59 4.96843 12.8066C4.96843 15.0233 5.7476 16.9108 7.30593 18.4691C8.86426 20.0275 10.7518 20.8066 12.9684 20.8066C15.1851 20.8066 17.0726 20.0275 18.6309 18.4691C20.1893 16.9108 20.9684 15.0233 20.9684 12.8066C20.9684 12.5066 20.9518 12.2066 20.9184 11.9066C20.8851 11.6066 20.8351 11.315 20.7684 11.0316L22.3934 9.40664C22.5768 9.93997 22.7184 10.49 22.8184 11.0566C22.9184 11.6233 22.9684 12.2066 22.9684 12.8066C22.9684 14.19 22.7059 15.49 22.1809 16.7066C21.6559 17.9233 20.9434 18.9816 20.0434 19.8816C19.1434 20.7816 18.0851 21.4941 16.8684 22.0191C15.6518 22.5441 14.3518 22.8066 12.9684 22.8066ZM11.5684 17.4066L7.31843 13.1566L8.71843 11.7566L11.5684 14.6066L21.5684 4.58164L22.9684 5.98164L11.5684 17.4066Z" fill="#1B0707"/>
    </g>
    </svg>
    )
}

// const NullSelection = () => {
//     return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <mask id="mask0_2083_37274" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
//     <rect width="24" height="24" fill="#D9D9D9"/>
//     </mask>
//     <g mask="url(#mask0_2083_37274)">
//     <path d="M12 21C10.755 21 9.585 20.7638 8.49 20.2912C7.395 19.8187 6.4425 19.1775 5.6325 18.3675C4.8225 17.5575 4.18125 16.605 3.70875 15.51C3.23625 14.415 3 13.245 3 12C3 10.755 3.23625 9.585 3.70875 8.49C4.18125 7.395 4.8225 6.4425 5.6325 5.6325C6.4425 4.8225 7.395 4.18125 8.49 3.70875C9.585 3.23625 10.755 3 12 3C13.245 3 14.415 3.23625 15.51 3.70875C16.605 4.18125 17.5575 4.8225 18.3675 5.6325C19.1775 6.4425 19.8187 7.395 20.2912 8.49C20.7638 9.585 21 10.755 21 12C21 13.245 20.7638 14.415 20.2912 15.51C19.8187 16.605 19.1775 17.5575 18.3675 18.3675C17.5575 19.1775 16.605 19.8187 15.51 20.2912C14.415 20.7638 13.245 21 12 21ZM12 19.2C14.01 19.2 15.7125 18.5025 17.1075 17.1075C18.5025 15.7125 19.2 14.01 19.2 12C19.2 11.19 19.0688 10.41 18.8062 9.66C18.5437 8.91 18.165 8.22 17.67 7.59L7.59 17.67C8.22 18.165 8.91 18.5437 9.66 18.8062C10.41 19.0688 11.19 19.2 12 19.2ZM6.33 16.41L16.41 6.33C15.78 5.835 15.09 5.45625 14.34 5.19375C13.59 4.93125 12.81 4.8 12 4.8C9.99 4.8 8.2875 5.4975 6.8925 6.8925C5.4975 8.2875 4.8 9.99 4.8 12C4.8 12.81 4.93125 13.59 5.19375 14.34C5.45625 15.09 5.835 15.78 6.33 16.41Z" fill="#C9C9C9"/>
//     </g>
//     </svg>
//     )
// }