import { ALL_CHAT_FAILED, ALL_CHAT_REQUEST, ALL_CHAT_SUCCESS, CONNECT_DISCORD_COMPLETED, CONNECT_DISCORD_FAILED, CONNECT_DISCORD_PARTIAL, CONNECT_DISCORD_REQUEST, UPDATE_PROFILE_FAIL, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT, USER_RESETTING_LOGIN, WALLET_NOT_FOUND } from "../constants/userConstant"

const localUserInfo = JSON.parse(localStorage?.getItem('user'))

const userState = {
    loading: false,
    isLogin: localUserInfo ? true : false,
    userInfo: localUserInfo ? localUserInfo.user : null,
    accessToken: localUserInfo?.accessToken || null,
    verificationHash: JSON.parse(localStorage?.getItem('verificationHash')) || null,
    verificationHashKURAMA: JSON.parse(localStorage?.getItem('verificationHashKURAMA')) || null,
    walletNotFound: false,
    expiresAt: localUserInfo?.expireAt || null,
    leaderBoardRank: localUserInfo?.leaderBoardRank || '',
    error: null,
}

export const userLoginReducer = (state = userState, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {
                loading: true,
                isLogin: false,
                userInfo: null,
                isAdmin: false,
                accessToken: null,
                walletNotFound: false,
                expiresAt: null,
                error: null,
                verificationHash: null,
                leaderBoardRank: '',
                verificationHashKURAMA: null,
            }
        case USER_RESETTING_LOGIN:
            return {
                loading: false,
                userInfo: null,
                isAdmin: false,
                accessToken: null,
                walletNotFound: false,
                leaderBoardRank: '',
                error: null,
                expiresAt: null,
                verificationHash: null,
                verificationHashKURAMA: null,
            }
        case USER_LOGIN_SUCCESS:
            return {
                loading: false,
                isLogin: action.payload ? true : false,
                userInfo: action.payload ? action.payload.user : action.payload,
                accessToken: action.payload.accessToken,
                verificationHash: action.verificationHash,
                verificationHashKURAMA: action.verificationHashKURAMA,
                walletNotFound: false,
                expiresAt: action.payload.expireAt,
                leaderBoardRank: action.payload.leaderBoardRank,
                error: null,
            }
        case USER_LOGIN_FAIL:
            return {
                loading: false,
                isLogin: false,
                userInfo: null,
                isAdmin: false,
                accessToken: null,
                walletNotFound: true,
                expiresAt: null,
                error: action.payload,
                verificationHash: null,
                leaderBoardRank: '',
                verificationHashKURAMA: null,
            }
        case WALLET_NOT_FOUND:
            return {
                loading: false,
                isLogin: false,
                userInfo: null,
                isAdmin: false,
                accessToken: null,
                walletNotFound: true,
                error: action.payload,
                expiresAt: null,
                verificationHash: null,
                leaderBoardRank: '',
                verificationHashKURAMA: null,
            }
        case USER_LOGOUT:
            return {
                loading: false,
                isLogin: false,
                userInfo: null,
                isAdmin: false,
                accessToken: null,
                walletNotFound: false,
                error: null,
                expiresAt: null,
                verificationHash: null,
                verificationHashKURAMA: null,
                leaderBoardRank: '',
            }

        default:
            return state
    }
}

const updateProfile = {
    loading: false,
    data: {},
    error: null,
}

export const updateProfileReducer = (state = updateProfile, action) => {
    switch (action.type) {
        case UPDATE_PROFILE_REQUEST:
            return {
                loading: true,
                data: {},
                error: null,
            }
        case UPDATE_PROFILE_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: null,
            }
        case UPDATE_PROFILE_FAIL:
            return {
                loading: false,
                data: {},
                error: action.payload,
            }

        default:
            return state
    }
}

const connectDiscordState = {
    connectingDiscord: false,
    data: null,
    error: null,
}

export const connectDiscordReducer = (state = connectDiscordState, action) => {
    switch (action.type) {
        case CONNECT_DISCORD_REQUEST:
            return {
                connectingDiscord: true,
                data: null,
                error: null,
            }
        case CONNECT_DISCORD_PARTIAL:
            return {
                connectingDiscord: false,
                data: action.payload,
                error: action.error,
            }
        case CONNECT_DISCORD_COMPLETED:
            return {
                connectingDiscord: false,
                data: action.payload,
                error: null,
            }
        case CONNECT_DISCORD_FAILED:
            return {
                connectingDiscord: false,
                data: null,
                error: action.payload,
            }

        default:
            return state
    }
}

const getAllChatsState = {
    loading: false,
    chats: [],
    size: 0,
    error: null
}

export const getAllChatsReducer = (state = getAllChatsState, action) => {
    switch (action.type) {
        case ALL_CHAT_REQUEST:
            return { ...state, loading: true, error: null, chats: [] }

        case ALL_CHAT_SUCCESS:
            return { loading: false, error: null, chats: action.payload, size: action.size }

        case ALL_CHAT_FAILED:
            return { ...state, error: action.payload, loading: false, chats: [] }

        default:
            return state
    }
}
