import axios from "axios"
import { store } from "../../store"
import { EDIT_COMMUNITY_TAB, EDIT_TABLE_INFO, FETCH_MOST_PLAYED_COMMUNITIES_FAILED, FETCH_MOST_PLAYED_COMMUNITIES_REQUEST, FETCH_MOST_PLAYED_COMMUNITIES_SUCCESS, FETCH_MOST_REWARDING_COMMUNITIES_FAILED, FETCH_MOST_REWARDING_COMMUNITIES_REQUEST, FETCH_MOST_REWARDING_COMMUNITIES_SUCCESS, FETCH_TODAY_MOST_PLAYED_COMMUNITIES_FAILED, FETCH_TODAY_MOST_PLAYED_COMMUNITIES_REQUEST, FETCH_TODAY_MOST_PLAYED_COMMUNITIES_SUCCESS, OPEN_TOAST, OPEN_TOAST_RESET, RESET_TABLE_ACTIVATE, SET_CLOSE_MODAL, SET_OPEN_MODAL, SET_TABLE_ACTIVATE } from "../constants/otherConstant"
import { BACKEND_URL, ErrorMessage } from "../../constants"
import { useEffect, useState } from "react"

export const openToast = (open, msg, heading, error = false) => async (dispatch) => {
    dispatch({
        type: OPEN_TOAST,
        open: open,
        msg: msg,
        heading: heading,
        error: error,
    })
}

export const openToastReset = () => async(dispatch) => {
    dispatch({
        type: OPEN_TOAST_RESET,
    })
}

export const activateTableId = (id) => async(dispatch) => {
    dispatch({
        type: SET_TABLE_ACTIVATE,
        payload: id
    })
}

export const deactivateTableId = () => async(dispatch) => {
    dispatch({
        type: RESET_TABLE_ACTIVATE
    })
}

export const getRoles = async (setRoles, serverId) => {
    const userLoginReducer = store.getState()?.userLoginReducer
    const token = userLoginReducer?.accessToken

    const config = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }

    if (token) {
        await axios
            .post(`${BACKEND_URL}/auth/getRolesDiscord`, {guildId: serverId}, config)
            .then(async (res) => {
                setRoles(res.data?.roles)
            })
            .catch((error) => {
                console.log(error)
            })
        }
}

export const editCommunityTabAction = (id) => async(dispatch) => {
    dispatch({
        type: EDIT_COMMUNITY_TAB,
        tabID: id
    })
}

export const editTableData = (data) => async(dispatch) => {
    dispatch({
        type: EDIT_TABLE_INFO,
        editTableInfo: data
    })
}

const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime()

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    )

    useEffect(() => {
        const interval = setInterval(() => {
            if(!targetDate) return
            setCountDown(countDownDate - new Date().getTime())
        }, 1000)

        return () => clearInterval(interval)
    }, [countDownDate, targetDate])

    return getReturnValues(countDown)
}

const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

    return {days, hours, minutes, seconds}
}

export { useCountdown }

export const newUserModal = () => async(dispatch) => {
    dispatch({
        type: SET_OPEN_MODAL
    })
}

export const closeNewUserModal = () => async(dispatch) => {
    dispatch({
        type: SET_CLOSE_MODAL
    })
}

export const fetchMostPlayedCommunities = () => async (dispatch) => {
    dispatch({
        type: FETCH_MOST_PLAYED_COMMUNITIES_REQUEST
    })
    await axios.get(`${BACKEND_URL}/public/mostPlayedCommunities`)
    .then((res) => {
        dispatch({
            type: FETCH_MOST_PLAYED_COMMUNITIES_SUCCESS,
            communities: res.data.communities,
        })
    })
    .catch(err => {
        dispatch({
            type: FETCH_MOST_PLAYED_COMMUNITIES_FAILED,
            payload: ErrorMessage(err)
        })
    })
}

export const fetchTodayMostPlayedCommunities = () => async (dispatch) => {
    dispatch({
        type: FETCH_TODAY_MOST_PLAYED_COMMUNITIES_REQUEST
    })
    await axios.get(`${BACKEND_URL}/public/todayMostPlayedCommunities`)
    .then((res) => {
        dispatch({
            type: FETCH_TODAY_MOST_PLAYED_COMMUNITIES_SUCCESS,
            communities: res.data.communities,
        })
    })
    .catch(err => {
        dispatch({
            type: FETCH_TODAY_MOST_PLAYED_COMMUNITIES_FAILED,
            payload: ErrorMessage(err)
        })
    })
}

export const fetchMostRewardingTable = () => async (dispatch) => {
    dispatch({
        type: FETCH_MOST_REWARDING_COMMUNITIES_REQUEST
    })
    await axios.get(`${BACKEND_URL}/public/mostRewardingTable`)
    .then((res) => {
        dispatch({
            type: FETCH_MOST_REWARDING_COMMUNITIES_SUCCESS,
            table: res.data.table,
            topPlayers: res.data.topPlayers
        })
    })
    .catch(err => {
        dispatch({
            type: FETCH_MOST_REWARDING_COMMUNITIES_FAILED,
            payload: ErrorMessage(err)
        })
    })
}