import React, { useState } from "react";
import "./tableCard.css";
import TableTopCardSvg from "../../../Assets/Images/TableTopCardSvg";
import holders from "../../../Assets/svgs/Holders.svg";
import discord from "../../../Assets/Images/discord.svg";
import { useSelector } from "react-redux";
import LoginModal from './../../shared/modals/LoginModal/LoginModal';
import { PlayButtonSvg } from "../../../Assets/Images/PlayButton";
import PlayGameModal from "../../shared/modals/PlayGameModal/PlayGameModal";
import CreateProfileModal from "../../shared/modals/CreateProfile/CreateProfile";
import CreateGuestModal from "../../shared/modals/CreateGuestModal/CreateGuestModal";

const TableCard = ({ id, img, table, socketObj }) => {
  const {isLogin, accessToken, verificationHash} = useSelector((state) => state.userLoginReducer)
  const socket = socketObj
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [openTableModal, setOpenTableModal] = useState(false);
  const [checkOnlyWallet, setCheckOnlyWallet] = useState(false);
  const [hover, setHover] = useState(false);
  const [checkOnlyDiscordConnect, setCheckOnlyDiscordConnect] = useState(false);

  const connectBtn = () =>{
    setOpenTableModal(false)
    setOpenModal(true)
}

  const handlePlayGame = () => {
    if(isLogin)
      setOpenTableModal(true)
    else
      setOpenModal(true)
  }

  const joinTable = (chipValue, tableId) => {
    socket.emit("joinTable", {
      tableId: tableId || table._id,
      verificationHash,
      accessToken,
      socketId: socket.id,
      chipValue
    });
  }

  const joinSpectatorTable = () => {
    socket.emit("joinTable", {
      tableId: table._id,
      verificationHash,
      accessToken,
      socketId: socket.id,
      isSpectator: true
    });
  }

  return (
    <div className="tablecard-btm-layer" id={`tablecard-btm-layer-${id}`}>
      <div
        className="tablecard-middle-layer"
        id={`tablecard-middle-layer-${id}`}
      >
        <TableTopCardSvg id={id} fill={table?.tableBackground?.backgroundColor} backOther={`linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${table?.tableBackground?.backgroundColor}`} />
        {(table?.nftsRequired || table?.discordRoleRequired) && <div className="tablecard-special-holder"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}

          style={{
            overflow: 'hidden',
            // width: hover ? 'fit-content' : '30px'
            width: hover ? '110px' : '30px'
          }}
        >
          <img src={table?.discordRoleRequired ? discord : holders} alt="" />
          {hover && <span className="textt whitespace-nowrap">{table?.discordRoleRequired ? 'Discord' : 'Holders'} Only</span>}
        </div>}
        <div className="tablecard-top-layer">
          <img src={img} className="tablecard-top-layer-img" alt='' />
          <div className="tablecard-top-layer-title">{table?.tableName}</div>
          <div className="tablecard-top-layer-sub-flex">
            <div className="tablecard-top-layer-sub">
              <div className="tablecard-top-layer-sub-circle animate-pulse" />
              <div className="tablecard-top-layer-sub-text">
                {(!table?.activePlayers || (table?.activePlayers < 0)) ? "Waiting for players" : `${table?.activePlayers} Online`}
              </div>
            </div>
            <div className="tablecard-top-layer-sub">
              <img
                src={table?.chipImg}
                alt=""
                className="tablecard-top-layer-sub-img"
              />
              <div className="tablecard-top-layer-sub-num">{table?.chipName}</div>
            </div>
            <div className="tablecard-play-btn" onClick={handlePlayGame}>
              <div className="shine"/>
              <div className="table-play-btn-svg">
                <PlayButtonSvg />
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoginModal setOpenGuestModal={setOpenGuestModal} checkOnlyDiscordConnect={checkOnlyDiscordConnect} checkOnlyWallet={checkOnlyWallet} openModal={openModal} setOpenModal={() => {setOpenModal(false)}} setOpenProfileModal={setOpenCreateModal} backUrl={`/pokertable/${table?._id}`}/>
      {openGuestModal && <CreateGuestModal setOpenConnectModal={setOpenModal} backUrl={`/pokertable/${table?._id}`} openModal={openGuestModal} setOpenModal={setOpenGuestModal} setOpenCreateModal={setOpenCreateModal} />}
      {openTableModal && <PlayGameModal connectBtn={connectBtn} setCheckOnlyDiscordConnect={setCheckOnlyDiscordConnect} setCheckOnlyWallet={setCheckOnlyWallet} key={id} openModal={openTableModal} setOpenModal={() => setOpenTableModal(false)} table={table} joinTable={joinTable} joinSpectatorTable={joinSpectatorTable} />}
      {openCreateModal && <CreateProfileModal openModal={openCreateModal} setOpenModal={setOpenCreateModal} />}
    </div>
  );
};

export default TableCard;
