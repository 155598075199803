import React from 'react'
import styles from './SetupCoins.module.css'
import Input from '../Input/Input'
import { textures } from '../../../data'

const SetupCoins = ({setTabNumber, toggleDrawer, info, setInfo}) => {
    return (
        <div className={styles.wrapper}>
            <h1>Setup Chips</h1>

            <div className={styles.form}>
                <Input selectData={textures} label={'CHOOSE BACKGROUND'} select value={info} onChange={(e) => setInfo(e)}/>
                <div className={styles.para_holder}>
                    Each user will get 1 Million Kurama coins when they connect their wallet and twitter, which can be used to play active games at your community. You will get 2% of kurama coins from the winner as your community Royalty, which can be converted into Kurama Tickets
                </div>
            </div>

            <div className={styles.btn_holder}>
                <button className={styles.btn_outline} onClick={() => toggleDrawer(true)}>Setup Personal Coin</button>
                <button className={styles.btn} onClick={() => setTabNumber(4)} disabled={!info} >Save Kurama coins as default</button>
            </div>
        </div>
    )
}

export default SetupCoins
