import { Dialog, Transition } from '@headlessui/react'
import styles from './TournamentWinnerModal.module.css'
import React, { Fragment, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const TournamentWinnerModal = ({openModal, setOpenModal, table, user, leaderboardData, tournamentRank}) => {
    const cancelButtonRef = useRef(null)
    const navigate = useNavigate()

    console.log(user, leaderboardData, table)
    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0 overflow-hidden'
                initialFocus={cancelButtonRef}
                onClose={() => 0}
            >
                <div className='h-screen px-4 pb-20 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[rgba(0,0,0,0.3)] bg-opacity-75 backdrop-blur-[10px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className={`inline-block bg-transparent rounded-lg text-left transform transition-all align-middle max-w-[600px] w-full`}>
                            <div
                                className={`${styles.modal_background} flex w-full items-center rounded-lg text-white h-full flex-col gap-y-8 border border-[#3D3D3D]`}
                            >
                                {/* Top-Banner */}
                                <div style={{ position: 'relative', overflow: 'hidden' }} className={styles.top_banner}>
                                    <img style={{ zIndex: -1, position: 'absolute', objectFit: 'cover' }} src={table?.tournamentBanner} />
                                    <img style={{ width: '60px', height: '60px', marginRight: '5px', border: `2px solid ${table?.tournamentTheme?.backgroundColor}`, borderRadius: '100%' }} src={table?.tournamentLogo} />
                                    <h1 className={styles.top_banner_heading}>{table?.tournamentName}</h1>
                                </div>

                                {/* <SideCard /> */}

                                {/* Awawrd-Plate */}
                                {(table?.tournamentPrizes.length >= tournamentRank) && (((table?.tournamentTime + 600000) < new Date().getTime()) || (tournamentRank == 1)) ? <div className={styles.prize_holder}>
                                    <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <img style={{ width: '100%', height: '240px', objectFit: 'cover' }} src={table?.tournamentPrizes[tournamentRank - 1]?.prizeImg || "https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1695829008/Frame_1814105410_pffmvu.webp"} />
                                    <div style={{ position: 'absolute', width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#00000080', width: '100%', height: '100%' }}>
                                            <img style={{ position: 'absolute', minWidth: '100px', width: '100px', height: '100px' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1696369790/Group_1000002720_yfephf.svg" />
                                            <span style={{ fontSize: '24px', color: '##564306', zIndex: 1, fontFamily: 'Oxanium', marginBottom: '25px', color: '#564306', fontWeight: 600 }}>{tournamentRank}</span>
                                        </div>
                                    </div>

                                    <div className={styles.prize_txt_wrpr}>
                                        <h1>{table?.tournamentPrizes[tournamentRank - 1]?.prizeName}</h1>
                                        <h2>Worth: {table?.tournamentPrizes[tournamentRank - 1]?.prizeAmount || '--'}</h2>
                                    </div>
                                </div> : <div className={styles.award_anounce_box}>
                                    {/* Left */}
                                    {/* <div>
                                        <img src={person} alt="" />
                                    </div> */}

                                    {/* Right */}
                                    <div style={{ position: 'relative', height: '155px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={styles.award_anounce_text}>
                                        <img style={{ position: 'absolute', height: '100%' }} src="https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1696359484/Frame_1814105401_rcvzzm.webp" />
                                        <div style={{ position: 'relative', width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img style={{ position: 'absolute', minWidth: '100px' }} src="https://res.cloudinary.com/kuramaverse/image/upload/v1696369790/Group_1000002720_yfephf.svg" />
                                            <span style={{ fontSize: '24px', color: '##564306', zIndex: 1, fontFamily: 'Oxanium', marginBottom: '25px', color: '#564306', fontWeight: 600 }}>{tournamentRank}</span>
                                        </div>
                                        {/* <h1 className={styles.heading}>{leaderboardData?.length} Place</h1> */}

                                        {/* <div className={styles.divider}></div>

                                        <div className={styles.row}>
                                            <h1 className={styles.left}>Holdings</h1>
                                            <h2 className={styles.right}>2000</h2>
                                        </div>
                                        <div className={styles.row}>
                                            <h1 className={styles.left}>Hands Won</h1>
                                            <h2  className={styles.right}>12/20</h2>
                                        </div> */}
                                    </div>
                                </div>}

                                {/* Error Message */}
                                {(table?.tournamentPrizes.length >= tournamentRank) && (((table?.tournamentTime + 600000) < new Date().getTime()) || (tournamentRank == 1)) ? <div className={styles.errorWrapper}>
                                    <Alert />
                                    <span style={{ marginTop: '2px' }}>{"Please open ticket in community's discord to claim your prize"}</span>
                                </div> : <div className={styles.errorWrapper}>
                                    <Alert />
                                    <span style={{ marginTop: '2px' }}>{"Ranking is preliminary. Kindly review the final rankings post-tournament."}</span>
                                </div>}

                                <div className={styles.button_holder}>
                                    <button style={{ width: '42%' }} className={`secondary-custom-button`} onClick={() => {
                                        navigate(`/tournament/${table?.tournamentId}`)
                                        setOpenModal(false)
                                    }}>
                                        <div className={`secondary-custom-button-inner ${styles.leave_btn}`}>See Results</div>
                                    </button>
                                    <button style={{ width: '55%' }} className="primary-custom-button">
                                        <button onClick={() => {
                                            navigate('/dashboard')
                                            setOpenModal(false)
                                        }} className={`primary-custom-button-inner ${styles.playing_btn}`}>
                                            Go back to Dashboard
                                            <div id="keepPlayingTimer" className={styles.back_flow_color} />
                                            {/* <div className={styles.back_flow_color} style={{width: `${((Date.now() - userInfo?.lastClaimed) / 14400000) * 100}%`}} /> */}
                                        </button>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default (TournamentWinnerModal)

const Alert = () => {
    return(<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5060_6419)">
    <path d="M17.5 13.549V6.8823C17.4997 6.59003 17.4225 6.30298 17.2763 6.04994C17.13 5.7969 16.9198 5.58677 16.6667 5.44063L10.8333 2.1073C10.58 1.96102 10.2926 1.88401 10 1.88401C9.70744 1.88401 9.42003 1.96102 9.16667 2.1073L3.33333 5.44063C3.08022 5.58677 2.86998 5.7969 2.72372 6.04994C2.57745 6.30298 2.5003 6.59003 2.5 6.8823V13.549C2.5003 13.8412 2.57745 14.1283 2.72372 14.3813C2.86998 14.6344 3.08022 14.8445 3.33333 14.9906L9.16667 18.324C9.42003 18.4702 9.70744 18.5473 10 18.5473C10.2926 18.5473 10.58 18.4702 10.8333 18.324L16.6667 14.9906C16.9198 14.8445 17.13 14.6344 17.2763 14.3813C17.4225 14.1283 17.4997 13.8412 17.5 13.549Z" stroke="#F70000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.25 3.72397L10 5.89064L13.75 3.72397" stroke="#F70000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.25 16.7073V12.3823L2.5 10.2156" stroke="#F70000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.5 10.2156L13.75 12.3823V16.7073" stroke="#F70000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.72461 6.01564L9.99961 10.224L17.2746 6.01564" stroke="#F70000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 18.6156V10.2156" stroke="#F70000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_5060_6419">
    <rect width="20" height="20" fill="white" transform="translate(0 0.215637)"/>
    </clipPath>
    </defs>
    </svg>
    )
}

const OptionWrapper = ({heading, valueSmall, chipName, chipImg, valueLarge, coin, line}) => {
    return(
        <div className={styles.align_cntnt}>
            <div className='flex items-center gap-1'>
                <h1 className={styles.heading}>{heading}</h1>
                {line && <div className='h-[1px] w-full bg-[#FFF] opacity-[0.2]'></div>}
            </div>
            <div className='flex items-center gap-[15px]'>
                {valueLarge && <h2 className={styles.cntnt_large}>{valueLarge}</h2>}
                {valueSmall && <h2 className={styles.cntnt_small}>{valueSmall}</h2>}

                {coin && <div className={styles.coin_wrapper_outer}>
                    <div className={styles.coin_wrapper}>
                        <img src={chipImg} alt="" style={{width: '20px'}} />
                        {chipName}
                    </div>
                </div>}
            </div>
        </div>
    )
}