import axios from "axios"
import { store } from "../../store"
import { CREATE_TOURNAMENT_FAILED, CREATE_TOURNAMENT_REQUEST, CREATE_TOURNAMENT_SUCCESS, DELETE_TOURNAMENT_FAILED, DELETE_TOURNAMENT_REQUEST, DELETE_TOURNAMENT_SUCCESS, GET_TOURNAMENTS_FAILED, GET_TOURNAMENTS_REQUEST, GET_TOURNAMENTS_SUCCESS, GET_TOURNAMENT_FAILED, GET_TOURNAMENT_REQUEST, GET_TOURNAMENT_SUCCESS, REGISTER_TOURNAMENT_FAILED, REGISTER_TOURNAMENT_REQUEST, REGISTER_TOURNAMENT_SUCCESS } from "../constants/TournamentConstant"
import { BACKEND_URL, ErrorMessage } from "../../constants"
import { uploadToCloudinary500, uploadToCloudinary1000 } from "./CommunityAction"
import { getTables } from "./TableAction"
import { updateLocalUserInfo } from "./userAction"

export const getTournamentById = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_TOURNAMENT_REQUEST })

        const { data } = await axios.post(
            `${BACKEND_URL}/public/getTournamentById`,
            {
                tournamentId: id
            },
        )

        if (data?.status === 200) {
            dispatch({
                type: GET_TOURNAMENT_SUCCESS,
                tournament: data?.tournament,
                registerations: data?.registerations
            })
        } else
            dispatch({
                type: GET_TOURNAMENT_FAILED,
                payload: data.msg,
            })
    } catch (error) {
        dispatch({
            type: GET_TOURNAMENT_FAILED,
            payload: ErrorMessage(error),
        })
    }
}

export const getTournaments = () => async (dispatch) => {
    try {
        dispatch({ type: GET_TOURNAMENTS_REQUEST })

        const { data } = await axios.get(
            `${BACKEND_URL}/public/getTournaments`,
        )

        if (data?.status === 200) {
            dispatch({
                type: GET_TOURNAMENTS_SUCCESS,
                tournaments: data?.tournaments
            })
        } else
            dispatch({
                type: GET_TOURNAMENTS_FAILED,
                payload: data.msg,
            })
    } catch (error) {
        dispatch({
            type: GET_TOURNAMENTS_FAILED,
            payload: ErrorMessage(error),
        })
    }
}

export const createTournament = (tournamentData, tableDesign, differentPrizes, communityId, roles, setOpenModal, slug, setError, communityInfo) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_TOURNAMENT_REQUEST })

        const userLoginReducer = store.getState().userLoginReducer
        const token = userLoginReducer?.accessToken

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
            },
        }

        let prizes = []

        if(differentPrizes){
            for(let i=0; i<tournamentData?.prizes?.length; i++) {
                let curr = {...tournamentData?.prizes[i]}
                const prizeImg = !curr?.prizeImg ? '' : typeof curr?.prizeImg !== 'string' ? await uploadToCloudinary500(curr?.prizeImg) : curr?.prizeImg
                curr = {...curr, prizeImg: prizeImg}
                prizes.push(curr)
            }
        }else{
            const prizeImg = !tournamentData?.prizes[0]?.prizeImg ? '' : typeof tournamentData?.prizes[0]?.prizeImg !== 'string' ? await uploadToCloudinary500(tournamentData?.prizes[0]?.prizeImg) : tournamentData?.prizes[0]?.prizeImg
            for(let i=0; i<tournamentData?.prizes?.length; i++) {
                let curr = {...tournamentData?.prizes[i]}
                curr = {...curr, prizeImg: prizeImg}
                prizes.push(curr)
            }
        }
        const cardPreviewUrl = typeof tournamentData?.bannerImg !== 'string' ? await uploadToCloudinary1000(tournamentData?.bannerImg) : tournamentData?.bannerImg
        // const prizeImg = typeof tournamentData?.prizeImg !== 'string' ? await uploadToCloudinary500(tournamentData?.prizeImg) : tournamentData?.prizeImg
        const backgroundImg = typeof tableDesign?.background !== 'string' ? await uploadToCloudinary1000(tableDesign?.background) : tableDesign?.background
        const tournamentLogoImage = communityInfo?.shortImage
        const carpetImg = typeof tableDesign?.carpet !== 'string' ? await uploadToCloudinary1000(tableDesign?.carpet) : tableDesign?.carpet
        const materialImg = typeof tableDesign?.material !== 'string' ? await uploadToCloudinary1000(tableDesign?.material) : tableDesign?.material
        const backdropImg = typeof tableDesign?.backdrop !== 'string' ? await uploadToCloudinary1000(tableDesign?.backdrop) : tableDesign?.backdrop
        const logoImg = typeof tableDesign?.logo !== 'string' ? await uploadToCloudinary500(tableDesign?.logo) : tableDesign?.logo
        const dealerImg = typeof tableDesign?.dealer !== 'string' ? await uploadToCloudinary500(tableDesign?.dealer) : tableDesign?.dealer
        const cardImg = typeof tableDesign?.card !== 'string' ? await uploadToCloudinary500(tableDesign?.card) : tableDesign?.card

        const { data } = await axios.post(
            `${BACKEND_URL}/community/createTournament`,
            {
                tournamentName: tournamentData?.name,
                tournamentType: tournamentData?.tournamentType,
                tournamentSpeed: tournamentData?.tournamentSpeed,
                tournamentLogoImage,
                time: tournamentData?.tournamentStartTime,
                communityId,
                nftsRequired: tournamentData?.nftRequired,
                holderOnly: tournamentData?.holderOnly,
                roleRequired: roles && roles.find((ele) => ele.id === tournamentData?.roleRequired),
                theme: tournamentData?.theme,
                backgroundImage: backgroundImg,
                maxBid: tournamentData?.maxBid,
                entryFees: tournamentData?.entryFees,
                cardPreview: cardPreviewUrl,
                tableBackground: tournamentData?.tableBackground,
                nftNumber: tournamentData?.nftNumber,
                discordRoleRequired: tournamentData?.rolesRequired,
                chipName: tournamentData?.chipName,
                prizes: prizes,
                cardImage: cardImg,
                carpetImage: carpetImg,
                backdropImage: backdropImg,
                materialImage: materialImg,
                logoImage: logoImg,
                differentPrizes: differentPrizes,
                dealerImage: dealerImg,
                verificationHash: userLoginReducer?.verificationHash,
            },
            config
        )

        if (data?.status === 200) {
            dispatch({
                type: CREATE_TOURNAMENT_SUCCESS,
                payload: data
            })
            setOpenModal(false)
            dispatch(getTables(slug))
            setError('')
        } else
            dispatch({
                type: CREATE_TOURNAMENT_FAILED,
                payload: data.msg,
            })
            setError(data.msg)
    } catch (error) {
        dispatch({
            type: CREATE_TOURNAMENT_FAILED,
            payload: ErrorMessage(error),
        })
        setError(ErrorMessage(error))
    }
}

export const deleteTournament = (slug, id, setOpenModal) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_TOURNAMENT_REQUEST })

        const userLoginReducer = store.getState().userLoginReducer
        const token = userLoginReducer?.accessToken

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
            },
        }

        const { data } = await axios.post(
            `${BACKEND_URL}/community/deleteTournament`,
            {
                tournamentId: id,
                communitySlug: slug,
                verificationHash: userLoginReducer?.verificationHash,
            },
            config
        )

        if (data?.status === 200) {
            dispatch({
                type: DELETE_TOURNAMENT_SUCCESS,
                payload: data
            })
            setOpenModal(false)
            dispatch(getTables(slug))
        } else
            dispatch({
                type: DELETE_TOURNAMENT_FAILED,
                payload: data.msg,
            })
    } catch (error) {
        dispatch({
            type: DELETE_TOURNAMENT_FAILED,
            payload: ErrorMessage(error),
        })
    }
}

export const registerTournament = (id, setOpenModal, setError) => async (dispatch) => {
    try {
        dispatch({ type: REGISTER_TOURNAMENT_REQUEST })

        const userLoginReducer = store.getState().userLoginReducer
        const token = userLoginReducer?.accessToken
        const userInfo = userLoginReducer?.userInfo
        const {tournament, registerations} = store.getState().getTournamentReducer

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
            },
        }

        const { data } = await axios.post(
            `${BACKEND_URL}/user/registerTournament`,
            {
                tournamentId: id,
                verificationHash: userLoginReducer?.verificationHash,
            },
            config
        )

        if (data?.status === 200) {
            dispatch({
                type: REGISTER_TOURNAMENT_SUCCESS,
                payload: data
            })
            dispatch({
                type: GET_TOURNAMENT_SUCCESS,
                tournament: tournament,
                registerations: [...registerations, {walletAddress: userInfo?.walletAddress }]
            })
            dispatch(updateLocalUserInfo())
            setOpenModal(false)
            setError('')
        } else{
            dispatch({
                type: REGISTER_TOURNAMENT_FAILED,
                payload: data.msg,
            })
            setError(data.msg)
        }
        // dispatch(getTournamentById(id))
    } catch (error) {
        dispatch({
            type: REGISTER_TOURNAMENT_FAILED,
            payload: ErrorMessage(error),
        })

        setError(ErrorMessage(error))
    }
}
