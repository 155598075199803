import React, { useEffect, useRef, useState } from 'react'
import './DashboardMenu.css'
import DashboardSvg from './../../Assets/svgs/DashboardSvg'
import CommunitySvg from './../../Assets/svgs/CommunitySvg'
import LeaderboardSvg from '../../Assets/svgs/LeaderboardSvg'
import ProfileSvg from './../../Assets/svgs/ProfileSvg'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ChooseNavLinks } from '../../redux/actions/GlobalAction'

const DashboardMenu = () => {
    const location = useLocation()
    const menu = useRef(null)

    const {isLogin} = useSelector((state) => state.userLoginReducer)
    const [active, setActive] = useState(-1)

    function clickItem(element) {
        // menu.current.style.removeProperty('--timeOut')
        // offsetMenuBorder(element)
    }

    useEffect(() => {
        const switchNavigationPage = () => {
            // console.log(menu.current.getElementsByTagName('a'))
            const current = location.pathname.split('/')[1]
            if(menu.current) menu.current.style.removeProperty('--timeOut')
            switch (current) {
                case '':
                    // setActive(0)
                    // element = menu.current.querySelector('.active')
                    // offsetMenuBorder(menu.current.getElementsByTagName('a')[0])
                    return
                case 'dashboard':
                    setActive(0)
                    offsetMenuBorder(menu.current.getElementsByTagName('a')[0])
                    return
                case 'community':
                    setActive(1)
                    offsetMenuBorder(menu.current.getElementsByTagName('a')[1])
                    return
                case 'leaderboard':
                    setActive(2)
                    offsetMenuBorder(menu.current.getElementsByTagName('a')[2])
                    return
                case 'profile':
                    setActive(3)
                    offsetMenuBorder(menu.current.getElementsByTagName('a')[3])
                    return
                default:
                    // offsetMenuBorder(menu.current.getElementsByTagName('a')[0])
                    return
            }
        }

        try {
            switchNavigationPage()
        } catch (e) {
            console.log(e)
        }
    },[location])

    function offsetMenuBorder(e) {
        const menuBorder = menu.current.querySelector('.menu__border')
        const element = e || menu.current.querySelector('.active')

        const offsetActiveItem = element.getBoundingClientRect()
        const left = Math.floor(offsetActiveItem.left - menu.current.offsetLeft - (menuBorder.offsetWidth - offsetActiveItem.width) / 2) + 'px'
        menuBorder.style.transform = `translate3d(${left}, 0 , 0)`
    }

    // useEffect(() => {
    //     try {
    //         const element = menu.current.querySelector('.active')
    //         offsetMenuBorder(element)
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }, [])

    if(location.pathname.split('/')[1] === 'pokertable') return ''

    return (
        <div id='body_main'>
            <menu className='menu' ref={menu}>
                {/* <MenuButton style={{ '--bgColorItem': '#ff8c00' }} Icon={() => <svg className='icon' viewBox='0 0 24 24'>
                        <path d='M3.8,6.6h16.4' />
                        <path d='M20.2,12.1H3.8' />
                        <path d='M3.8,17.5h16.4' />
                    </svg>} />
                <MenuButton style={{ '--bgColorItem': '#f54888' }} Icon={() => <svg className='icon' viewBox='0 0 24 24'>
                        <path
                            d='M6.7,4.8h10.7c0.3,0,0.6,0.2,0.7,0.5l2.8,7.3c0,0.1,0,0.2,0,0.3v5.6c0,0.4-0.4,0.8-0.8,0.8H3.8
                C3.4,19.3,3,19,3,18.5v-5.6c0-0.1,0-0.2,0.1-0.3L6,5.3C6.1,5,6.4,4.8,6.7,4.8z'
                        />
                        <path d='M3.4,12.9H8l1.6,2.8h4.9l1.5-2.8h4.6' />
                    </svg>} />
                <MenuButton style={{ '--bgColorItem': '#4343f5' }} Icon={() => <svg className='icon' viewBox='0 0 24 24' >
                    <path d='M3.4,11.9l8.8,4.4l8.4-4.4' />
                    <path d='M3.4,16.2l8.8,4.5l8.4-4.5' />
                    <path d='M3.7,7.8l8.6-4.5l8,4.5l-8,4.3L3.7,7.8z' />
                    </svg>} />
                <MenuButton style={{ '--bgColorItem': '#e0b115' }} Icon={() => <svg className='icon' viewBox='0 0 24 24' >
                    <path
                        d='M5.1,3.9h13.9c0.6,0,1.2,0.5,1.2,1.2v13.9c0,0.6-0.5,1.2-1.2,1.2H5.1c-0.6,0-1.2-0.5-1.2-1.2V5.1
                C3.9,4.4,4.4,3.9,5.1,3.9z'
                    />
                    <path d='M4.2,9.3h15.6' />
                    <path d='M9.1,9.5v10.3' />
                    </svg>} />
                <MenuButton style={{ '--bgColorItem': '#65ddb7' }} Icon={() => <svg className='icon' viewBox='0 0 24 24'>
                        <path
                            d='M5.1,3.9h13.9c0.6,0,1.2,0.5,1.2,1.2v13.9c0,0.6-0.5,1.2-1.2,1.2H5.1c-0.6,0-1.2-0.5-1.2-1.2V5.1
                C3.9,4.4,4.4,3.9,5.1,3.9z'
                        />
                        <path d='M5.5,20l9.9-9.9l4.7,4.7' />
                        <path d='M10.4,8.8c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C7.3,8,8,7.3,8.9,7.3C9.7,7.3,10.4,8,10.4,8.8z' />
                    </svg>} /> */}
                <MenuButton link={'/'} title={'Dashboard'} Icon={DashboardSvg} active={active} setActive={setActive} id={0} clickItem={clickItem} />
                <MenuButton link={'/community'} title={'Community'} Icon={CommunitySvg} active={active} setActive={setActive} id={1} clickItem={clickItem} />
                <MenuButton link={'/leaderboard'} title={'Leaderboard'} Icon={LeaderboardSvg} active={active} setActive={setActive} id={2} clickItem={clickItem} />
                {isLogin && <MenuButton link={'/profile'} title={'Profile'} Icon={ProfileSvg} active={active} setActive={setActive} id={3} clickItem={clickItem} />}

                <div className='menu__border'></div>
            </menu>

            <div className='svg-container'>
                <svg viewBox='0 0 202.9 45.5'>
                    <clipPath id="menu" clipPathUnits="objectBoundingBox" >
                        <path d="M0.837,0.5 C0.782,0.174,0.718,0,0.652,0 H0.36 C0.294,0,0.229,0.174,0.174,0.5 L0.165,0.556 C0.116,0.846,0.059,1,0,1 V1 H1 V1 C0.949,1,0.899,0.865,0.856,0.612 L0.837,0.5"></path>
                    </clipPath>
                </svg>

            </div>
        </div>
    )
}

// const DashboardMenu = () => {
//     return (
//         <div className={styles.menu_wrapper}>
//             <MenuButton Icon={DashboardSvg} />
//             <MenuButton Icon={CommunitySvg} />
//             <MenuButton Icon={LeaderboardSvg} />
//             <MenuButton Icon={ProfileSvg} />
//         </div>
//     )
// }

const MenuButton = ({ Icon, title, active, setActive, id, clickItem, link }) => {
    const dispatch = useDispatch()

    return (
        <Link
            to={link}
            className={`menu__item ${active === id ? 'active' : ''}`}
            onClick={(e) => {
                clickItem(e.target)
                setActive(id)
                dispatch(ChooseNavLinks('', '', ''))
            }}
        >
            <Icon active={active===id ? true : false} />

            {active === id && <h1 className={`menu__item__text_h1`}>{title}</h1>}
        </Link>
    )
}

export default DashboardMenu
