import { Dialog, Transition } from '@headlessui/react'
import styles from './WinnerRoundModal.module.css'
import React, { Fragment, useRef, useState } from 'react'
import Opponent from '../../../Players/Opponent/Opponent'
// import { Cards } from '../../../../tablesData'
import { useEffect } from 'react'
import { gsap } from 'gsap'
import { useNavigate } from 'react-router-dom'

const handRanks = [
    'High Card',
    'One Pair',
    'Two Pairs',
    'Three of a Kind',
    'Straight',
    'Flush',
    'Full House',
    'Four of a Kind',
    'Straight Flush',
    'Royal Flush'
];

const WinnerRoundModal = ({openModal, setOpenModal, setremoveAnimationCircle, winners, currentRound, cardImg, nextRoundTimer, table}) => {
    const cancelButtonRef = useRef(null)
    const navigate = useNavigate()
    const [wid, setWid] = useState(100)

    useEffect(() => {
        if (nextRoundTimer > 0) {
            const timer = (nextRoundTimer - new Date().getTime())/1000

            gsap.context(() => {
                gsap.set("#keepPlayingTimer", { width: '100%' })

                gsap
                .timeline()
                .to("#keepPlayingTimer", { width: '0%', duration: timer - 2, ease: 'linear' }, 0)
            });

            setTimeout(() => {
                setOpenModal(false)
            }, timer*1000)
        }
    }, [ nextRoundTimer ])

    useEffect(() => {
        gsap.ticker.lagSmoothing(false);
    }, [])

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0 overflow-hidden'
                initialFocus={cancelButtonRef}
                onClose={setOpenModal}
            >
                <div className={`h-screen px-4 pb-20 text-center block p-0 ${styles.mobile_cnvrt}`}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[rgba(0,0,0,0.3)] bg-opacity-75 backdrop-blur-[10px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className={`inline-block ${styles.modal__parent_mobile} bg-transparent rounded-lg text-left transform transition-all align-middle ${winners.length > 1 && window?.innerWidth > 1100 ? 'max-w-[1070px]' : window?.innerWidth > 1100 && winners.length <=1 ? 'max-w-[600px]' : window?.innerWidth <= 1100 && winners.length > 1 ? 'max-w-[1070] min-w-[1070px]' : 'max-w-[600px] min-w-[600px]'} w-full`}>
                            <div
                                className={`${styles.modal_background} flex justify-center w-full items-center rounded-lg text-white h-full flex-col gap-y-8 border border-[#3D3D3D]`}
                            >
                                <div className={styles.top_header}>
                                    <h1>Winner Round {currentRound}</h1>

                                    <button
                                        onClick={() => setOpenModal(false)}
                                    >
                                        <Close />
                                    </button>
                                </div>

                                {/* <div className={styles.divider}></div> */}

                                <div className={styles.modal_main_cntnt}>
                                    <div style={{ height: '280px' }} className='w-full flex items-start'>
                                        {winners?.length > 0 && <SideCard table={table} cardImg={cardImg} winner={winners[0]} setremoveAnimationCircle={setremoveAnimationCircle} style={winners?.length === 1 ? {justifyContent : "space-around"} : {}} />}

                                        {winners?.length > 1 && <div className={`h-[280px] w-[1px] min-w-[1px] bg-[#ff00003f]`}></div>}

                                        {winners?.length > 1 && <SideCard table={table} cardImg={cardImg} winner={winners[1]} setremoveAnimationCircle={setremoveAnimationCircle} />}
                                    </div>

                                    {winners?.length > 2 && <div className={`w-full h-[1px] min-h-[1px] bg-[#ff00003f]`}></div>}

                                    {winners?.length > 2 && <div style={{ height: '280px' }} className='w-full flex items-start justify-between'>
                                        <SideCard table={table} cardImg={cardImg} winner={winners[2]} setremoveAnimationCircle={setremoveAnimationCircle} style={winners?.length === 3 ? {justifyContent : "center"} : {}} />

                                        {winners?.length > 3 && <div className={`h-[280px] w-[1px] min-w-[1px] bg-[#ff00003f]`}></div>}

                                        {winners?.length > 3 && <SideCard table={table} cardImg={cardImg} winner={winners[3]} setremoveAnimationCircle={setremoveAnimationCircle} />}
                                    </div>}
                                </div>

                                <div className={styles.button_holder}>
                                    <button className={`secondary-custom-button`} onClick={() => {
                                        const slug = table?.slug
                                        navigate(slug && (slug === 'ArcadeVerse' || slug === 'arcadeverse') ? `/` : slug ? `/${slug}` :`/community`)
                                        setOpenModal(false)
                                    }}>
                                        <div className={`secondary-custom-button-inner ${styles.leave_btn}`}>Leave Table</div>
                                    </button>
                                    <button
                                        onClick={() => setOpenModal(false)}
                                        className={styles.playing_btn}
                                    >
                                        Keep Playing
                                        <div id="keepPlayingTimer" className={styles.back_flow_color} style={{width: `${wid}%`}} />
                                        {/* <div className={styles.back_flow_color} style={{width: `${((Date.now() - userInfo?.lastClaimed) / 14400000) * 100}%`}} /> */}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default (WinnerRoundModal)

const Close = () =>{
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}

const SideCard = ({setremoveAnimationCircle, style, winner, cardImg, table}) => {
    return(<div className={styles.side_card} style={style}>
        <div className='flex flex-col justify-between pt-[60px] gap-[20px] h-full'>
            <Opponent
                cardImg = {cardImg}
                Data={winner?.playerData}
                // playerTurn={playerTurn}
                winnerAnnounced={true}
                hasWinnerWon={true}
                hands={winner?.playingPlayerHands?.playerHands || []}
                setremoveAnimationCircle={setremoveAnimationCircle}
                // socketGameTimer={socketGameTimer}
            />
        </div>

        <div className='flex flex-col justify-between h-full gap-[16px]'>
            <h1 className={styles.main_heading}>{winner?.status}</h1>

            <div className='flex flex-col gap-[16px]'>
                <OptionWrapper heading={`winner:`} valueSmall={winner?.playerData?.username} />
                <OptionWrapper heading={`Win amount:`} valueSmall={winner?.pot} coin chipName={table?.chipName} chipImg={table?.chipImg} />
                <OptionWrapper heading={`Hand:`} valueSmall={handRanks[winner?.maxHandRank.handRank]} line />
            </div>

            {/* <p className={styles.round_details}>Next round starts in 10</p> */}
        </div>
    </div>)
}

const OptionWrapper = ({heading, valueSmall, chipName, chipImg, valueLarge, coin, line}) => {
    return(
        <div className={styles.align_cntnt}>
            <div className='flex items-center gap-1'>
                <h1 className={styles.heading}>{heading}</h1>
                {line && <div className='h-[1px] w-full bg-[#FFF] opacity-[0.2]'></div>}
            </div>
            <div className='flex items-center gap-[15px]'>
                {valueLarge && <h2 className={styles.cntnt_large}>{valueLarge}</h2>}
                {valueSmall && <h2 className={styles.cntnt_small}>{valueSmall}</h2>}

                {coin && <div className={styles.coin_wrapper_outer}>
                    <div className={styles.coin_wrapper}>
                        <img src={chipImg} alt="" style={{width: '20px'}} />
                        {chipName}
                    </div>
                </div>}
            </div>
        </div>
    )
}
