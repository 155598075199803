import React from "react";
import styles from "./UserSummarySkelton.module.css";

const UserSummarySkelton = () => {
  return (
    <div className={styles[`userSummary`]}>
      <div className={styles[`userSumarry-cards`]}>
        <div className={styles[`userSummary-card`]}>
          <div className={styles[`userSummary-card-mark`]} />
        </div>
        <div className={styles[`userSummary-card`]}>
          <div className={styles[`userSummary-card-mark`]} />
        </div>
      </div>
      <div className={styles[`userSumary-text`]}>
        <div className={styles[`userSumary-text-name`]} />
        <div className={styles[`userSumary-text-info`]} />
      </div>
    </div>
  );
};

export default UserSummarySkelton;
