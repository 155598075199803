import styles from './DeleteTable.module.css'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef } from 'react'
import { useDispatch } from 'react-redux'
import TableTopCard from '../../../EditCommunity/Tables/TableTopCard/TableTopCard'
import { deleteTable } from '../../../../redux/actions/TableAction'

const DeleteTable = ({openModal, setOpenModal, communityId, tableId, id, data}) => {
    const dispatch = useDispatch()
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0 overflow-y-auto'
                initialFocus={cancelButtonRef}
                onClose={setOpenModal}
            >
                <div className='h-screen px-4 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[#0c0c0d9a] bg-opacity-75 backdrop-blur-[3px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block bg-transparent rounded-lg text-left transform transition-all my-8 align-middle max-w-[540px] w-full'>
                            <div
                                className={`${styles.modal_background} flex justify-center w-full items-center rounded-lg text-white h-full p-10 flex-col gap-y-8 border border-[#3D3D3D]`}
                            >
                                <div className={styles.top_header}>
                                    <h1>Delete Table</h1>

                                    <button onClick={() => setOpenModal(false)}>
                                        <Close />
                                    </button>
                                </div>

                                <div className={styles.divider}></div>

                                <div className={styles.modal_main_cntnt}>
                                    {/* < */}
                                    <div className={styles.table_top_card}>
                                        <TableTopCard id={id} table={data} img={data?.cardPreview} />
                                    </div>

                                    {/* heading */}
                                    <h1 className={styles.heading}>Are you sure you want to delete this table?</h1>
                                    {/* main-part */}
                                    <div className={styles.btn_holder}>
                                        <button className={`secondary-custom-button w-full`} onClick={() => setOpenModal(false)}>
                                            <div className={` ${styles.cancel_btn} secondary-custom-button-inner`}>Keep</div>
                                        </button>
                                        <button className={`primary-custom-button w-full`} disabled={false} onClick={() => {
                                            deleteTable(communityId, dispatch, tableId)
                                            setOpenModal(false)
                                        }}>
                                            <div className={`primary-custom-button-inner ${styles.success_btn}`}>Delete</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default React.memo(DeleteTable)

const Close = () =>{
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}