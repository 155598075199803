import React from 'react'

const TableTopCardSvg = ({ fill = '#26303A', id, skelton }) => {
    if (skelton) {
        return (
            <svg
                width='400'
                height='210'
                viewBox='0 0 400 210'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M0 180.403V189.692C0 200.738 8.95431 209.692 20 209.692H380C391.046 209.692 400 200.738 400 189.692V180.403C400 191.449 391.046 200.403 380 200.403H20C8.95431 200.403 0 191.449 0 180.403Z'
                    fill='#222222'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M0 180.403V189.692C0 200.738 8.95431 209.692 20 209.692H380C391.046 209.692 400 200.738 400 189.692V180.403C400 191.449 391.046 200.403 380 200.403H20C8.95431 200.403 0 191.449 0 180.403Z'
                    fill='black'
                    fillOpacity='0.2'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M0 180.403V189.692C0 200.738 8.95431 209.692 20 209.692H380C391.046 209.692 400 200.738 400 189.692V180.403C400 191.449 391.046 200.403 380 200.403H20C8.95431 200.403 0 191.449 0 180.403Z'
                    fill='black'
                    fillOpacity='0.2'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M138.495 18.9046C134.896 9.02546 126.481 0.692307 115.967 0.692307H20C8.95431 0.692307 0 9.64663 0 20.6923V182.726C0 193.771 8.95431 202.726 20 202.726H380C391.046 202.726 400 193.771 400 182.726V20.6923C400 9.64661 391.046 0.692307 380 0.692307L284.033 0.692307C273.519 0.692307 265.104 9.02547 261.505 18.9046C252.293 44.1936 228.232 62.2312 200 62.2312C171.769 62.2312 147.708 44.1936 138.495 18.9046Z'
                    fill='#414141'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M138.495 18.9046C134.896 9.02546 126.481 0.692307 115.967 0.692307H20C8.95431 0.692307 0 9.64663 0 20.6923V182.726C0 193.771 8.95431 202.726 20 202.726H380C391.046 202.726 400 193.771 400 182.726V20.6923C400 9.64661 391.046 0.692307 380 0.692307L284.033 0.692307C273.519 0.692307 265.104 9.02547 261.505 18.9046C252.293 44.1936 228.232 62.2312 200 62.2312C171.769 62.2312 147.708 44.1936 138.495 18.9046Z'
                    fill='url(#paint0_linear_2901_3262)'
                />
                <defs>
                    <linearGradient
                        id='paint0_linear_2901_3262'
                        x1='3.56712e-06'
                        y1='25.3768'
                        x2='295.088'
                        y2='-151.525'
                        gradientUnits='userSpaceOnUse'
                    >
                        <stop stopColor='#222222' />
                        <stop offset='1' stopOpacity='0' />
                    </linearGradient>
                </defs>
            </svg>
        )
    }
    // return (
    //     <svg
    //         width='400'
    //         height='209'
    //         viewBox='0 0 400 209'
    //         fill='none'
    //         xmlns='http://www.w3.org/2000/svg'
    //     >
    //         <path
    //             fillRule='evenodd'
    //             clipRule='evenodd'
    //             d='M0 179.077V188.272C0 199.318 8.95431 208.272 20 208.272H380C391.046 208.272 400 199.318 400 188.272V179.077C400 190.123 391.046 199.077 380 199.077H20C8.95431 199.077 0 190.123 0 179.077Z'
    //             // fill='#6F0DD2'
    //         />
    //         <path
    //             fillRule='evenodd'
    //             clipRule='evenodd'
    //             d='M0 179.077V188.272C0 199.318 8.95431 208.272 20 208.272H380C391.046 208.272 400 199.318 400 188.272V179.077C400 190.123 391.046 199.077 380 199.077H20C8.95431 199.077 0 190.123 0 179.077Z'
    //             fill='black'
    //             fillOpacity='1'
    //         />
    //         <linearGradient id={`Gradient${id}`}>
    //             <stop stopColor='rgba(0, 0, 0, 0.40)' stopOpacity={'0%'} />
    //             <stop stopColor='rgba(0, 0, 0, 0.40)' stopOpacity={'100%'} />
    //             <stop stopColor={fill} stopOpacity={'80%'} />
    //         </linearGradient>
    //         <path
    //             fillRule='evenodd'
    //             clipRule='evenodd'
    //             d='M0 179.077V188.272C0 199.318 8.95431 208.272 20 208.272H380C391.046 208.272 400 199.318 400 188.272V179.077C400 190.123 391.046 199.077 380 199.077H20C8.95431 199.077 0 190.123 0 179.077Z'
    //             fill={fill ? `url(#Gradient${id})` : '#26303A'}
    //         />
    //         <path
    //             fillRule='evenodd'
    //             clipRule='evenodd'
    //             d='M138.001 18.9371C134.509 8.74094 125.978 0 115.2 0H20C8.95431 0 0 8.95431 0 20V180C0 191.046 8.95431 200 20 200H380C391.046 200 400 191.046 400 180V20C400 8.95431 391.046 0 380 0H284.8C274.022 0 265.491 8.74094 261.999 18.9371C253.177 44.6984 228.751 63.2184 200 63.2184C171.249 63.2184 146.823 44.6984 138.001 18.9371Z'
    //             fill={fill ? fill : '#3C4B5A'}
    //         />
    //     </svg>
    // )

    // return (
    //     <svg
    //         width='400'
    //         height='207'
    //         viewBox='0 0 400 207'
    //         fill='none'
    //         xmlns='http://www.w3.org/2000/svg'
    //     >
    //         <path
    //             fillRule='evenodd'
    //             clipRule='evenodd'
    //             d='M0 186.897V180C0 191.046 8.95431 200 20 200H380.027C390.718 200 399.452 191.612 400 181.054V186.897C400 197.942 391.046 206.897 380 206.897H295.402L304.598 200H86.2069L94.2529 206.897H20C8.95431 206.897 0 197.942 0 186.897Z'
    //             fill='#6F0DD2'
    //         />
    //         <path
    //             fillRule='evenodd'
    //             clipRule='evenodd'
    //             d='M0 186.897V180C0 191.046 8.95431 200 20 200H380.027C390.718 200 399.452 191.612 400 181.054V186.897C400 197.942 391.046 206.897 380 206.897H295.402L304.598 200H86.2069L94.2529 206.897H20C8.95431 206.897 0 197.942 0 186.897Z'
    //             fill='black'
    //             fillOpacity='1'
    //         />
    //         <linearGradient id={`Gradient${id}`}>
    //             <stop stopColor='rgba(0, 0, 0, 0.40)' stopOpacity={'0%'} />
    //             <stop stopColor='rgba(0, 0, 0, 0.40)' stopOpacity={'100%'} />
    //             <stop stopColor={fill} stopOpacity={'80%'} />
    //         </linearGradient>
    //         <linearGradient id={`Gradient2${id}`}>
    //             <stop stopColor='rgba(0, 0, 0, 0.80)' stopOpacity={'0%'} />
    //             <stop stopColor='rgba(0, 0, 0, 0.80)' stopOpacity={'100%'} />
    //             <stop stopColor={fill} stopOpacity={'80%'} />
    //         </linearGradient>
    //         <path
    //             fillRule='evenodd'
    //             clipRule='evenodd'
    //             d='M0 186.897V180C0 191.046 8.95431 200 20 200H380.027C390.718 200 399.452 191.612 400 181.054V186.897C400 197.942 391.046 206.897 380 206.897H295.402L304.598 200H86.2069L94.2529 206.897H20C8.95431 206.897 0 197.942 0 186.897Z'
    //             fill={fill ? `url(#Gradient${id})` : '#26303A'}
    //         />
    //         <path
    //             fillRule='evenodd'
    //             clipRule='evenodd'
    //             d='M110.36 0C120.822 0 129.205 8.28504 133.088 18.0001C143.1 43.0497 169.28 60.9195 200 60.9195C230.72 60.9195 256.9 43.0497 266.912 18.0001C270.795 8.28504 279.178 0 289.64 0H380C391.046 0 400 8.95431 400 20V180C400 191.046 391.046 200 380 200H304.598L291.425 158.456C290.371 155.132 287.286 152.874 283.799 152.874H105.856C102.369 152.874 99.2839 155.132 98.2301 158.456L85.0575 200H20C8.95431 200 0 191.046 0 180V20C0 8.95431 8.95431 0 20 0H110.36Z'
    //             fill={fill ? fill : '#3C4B5A'}
    //         />
    //         <path
    //             fillRule='evenodd'
    //             clipRule='evenodd'
    //             d='M105.856 152.871C102.369 152.871 99.2836 155.129 98.2297 158.453L85.0571 199.998L94.2525 206.894L96.5514 199.998H96.552L96.5515 199.999H293.103L293.103 199.998H293.103L295.402 206.894L304.597 199.998L291.425 158.453C290.371 155.129 287.286 152.871 283.799 152.871H105.856ZM293.103 199.998L283.279 167.289C282.349 164.19 279.496 162.068 276.259 162.068H113.395C110.159 162.068 107.306 164.19 106.375 167.289L96.552 199.998H293.103Z'
    //             fill={fill ? `url(#Gradient2${id})` : '#202830'}
    //         />
    //     </svg>
    // )

    return (
        <svg
            width='400'
            height='207'
            viewBox='0 0 400 207'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 183.908V177.012C0 189.708 10.2923 200 22.9885 200H86.2078L94.2529 206.896H295.402L304.597 200H377.043C389.331 200 399.37 190.358 400 178.224V183.908C400 196.604 389.708 206.897 377.012 206.897H22.9885C10.2923 206.897 0 196.604 0 183.908Z'
                fill={fill}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 183.908V177.012C0 189.708 10.2923 200 22.9885 200H86.2078L94.2529 206.896H295.402L304.597 200H377.043C389.331 200 399.37 190.358 400 178.224V183.908C400 196.604 389.708 206.897 377.012 206.897H22.9885C10.2923 206.897 0 196.604 0 183.908Z'
                fill='black'
                fillOpacity='0.25'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M107.588 0C119.614 0 129.216 9.59404 134.174 20.5502C144.9 44.2537 170.333 60.9195 200 60.9195C229.667 60.9195 255.099 44.2537 265.826 20.5502C270.784 9.59403 280.386 0 292.412 0H377.011C389.708 0 400 10.2923 400 22.9885V177.011C400 189.708 389.708 200 377.012 200H22.9885C10.2923 200 0 189.708 0 177.011V22.9885C0 10.2923 10.2923 0 22.9885 0H107.588ZM106.731 152.873C102.723 152.873 99.1769 155.468 97.9656 159.289L85.0575 199.999H304.598L291.69 159.289C290.478 155.468 286.932 152.873 282.924 152.873H106.731Z'
                fill={fill}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M106.731 152.871C102.723 152.871 99.1766 155.467 97.9653 159.287L85.0571 199.998L94.2525 206.894L96.5514 199.998H96.5517L96.5515 199.998H293.103L295.402 206.894L304.597 199.998L291.689 159.287C290.478 155.467 286.932 152.871 282.924 152.871H106.731ZM293.103 199.998H96.5517L106.141 168.069C107.211 164.506 110.49 162.067 114.21 162.067H275.445C279.164 162.067 282.444 164.506 283.514 168.069L293.103 199.998Z'
                fill={fill}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M106.731 152.871C102.723 152.871 99.1766 155.467 97.9653 159.287L85.0571 199.998L94.2525 206.894L96.5514 199.998H96.5517L96.5515 199.998H293.103L295.402 206.894L304.597 199.998L291.689 159.287C290.478 155.467 286.932 152.871 282.924 152.871H106.731ZM293.103 199.998H96.5517L106.141 168.069C107.211 164.506 110.49 162.067 114.21 162.067H275.445C279.164 162.067 282.444 164.506 283.514 168.069L293.103 199.998Z'
                fill='black'
                fillOpacity='0.4'
            />
        </svg>
    )
}

export default TableTopCardSvg
