import styles from './ExchangeModal.module.css'
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import poker from '../../../../Assets/Coins/poker.svg'
import blvck from '../../../../Assets/Coins/blvck.svg'
import tsc from '../../../../Assets/Coins/tsc.svg'
import noBalance from '../../../../Assets/svgs/no_balance.svg'
import SelectCoinPopover from '../../Popovers/SelectCoinPopover/SelectCoinPopover'
import { useDispatch, useSelector } from 'react-redux'
import { exchangeChipsForCoinsAction, exchangeCoinForChipsAction } from '../../../../redux/actions/CommunityAction'
import { SwipeableDrawer } from '@mui/material'

const data = [
    {
        id: 0,
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), rgba(242, 65, 1, 0.50)',
        boxShadow: '0px -2px 7px 0px rgba(202, 65, 17, 0.75), 0px -4px 13px 0px #CA4111',
        backInner: '#CA4111',
        name: 'Kurama Coin',
        value: 50000,
        coin: poker,
        borderColor: '#F2410180'
    },
    {
        id: 1,
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), rgba(115, 115, 115, 0.50)',
        boxShadow: '0px -2px 7px 0px rgba(115, 115, 115, 0.50), 0px -4px 13px 0px #737373',
        backInner: '#737373',
        name: 'BLVCK Coin',
        value: 10000,
        coin: blvck,
        borderColor: '#73737380'
    },
    {
        id: 2,
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), #FACA32',
        boxShadow: '0px -2px 7px 0px rgba(239, 173, 44, 0.75), 0px -4px 13px 0px #EFAD2C',
        backInner: '#EFAD2C',
        name: 'TSC Coin',
        value: 10000,
        coin: tsc,
        borderColor: '#FACA32'
    },
]

const ExchangeModal = ({ openModal, setOpenModal }) => {
    const cancelButtonRef = useRef(null)
    const dispatch = useDispatch()
    const {allChips, loading: chipsLoading} = useSelector((state) => state.getUserCoinsForArcadeVerseReducer)
    const {loading: exchanging} = useSelector((state) => state.exchangeCoinForChipsReducer)

    const [toggle, setToggle] = useState(true)
    const [index, setIndex] = useState(0)
    const [outputIndex, setOutputIndex] = useState(1)

    const[coinValue, setCoinValue] = useState('')
    const[chipValue, setChipValue] = useState('')

    const handleCoinValue = (e) => {
        const factor = allChips[index]?.slug === "kuramaverse" ? 1000 : 10

        setCoinValue(parseInt(e.target.value) || 0)
        setChipValue(factor * (parseInt(e.target.value) || 0))
    }
    const handleChipValue = (e) => {
        const factor = allChips[index]?.slug === "kuramaverse" ? 1000 : 10

        setChipValue(parseInt(e.target.value) || 0)
        setCoinValue(parseInt((parseInt(e.target.value) || 0) / factor))
    }
    const handleExchange = () => {
        if(toggle) dispatch(exchangeCoinForChipsAction(coinValue, allChips[index], setOpenModal))
        else dispatch(exchangeChipsForCoinsAction(coinValue, allChips[index], setOpenModal))
    }

    const MainModal = () => {
        return(
            <>
                <div className={styles.top_header}>
                <h1>Balance</h1>
                <button onClick={setOpenModal} className='outline-none'><Close /></button>
            </div>

            <div className={styles.divider}></div>

            {allChips?.length === 0 && !chipsLoading
                ? <div className={styles.no_data_cntnt}>
                    <img style={{ width: '300px' }} src={noBalance} alt="" />
                    <div className='flex flex-col items-center gao-[8px]'>
                        <h1>You don't have any Chips</h1>
                        {/* <h2>You can exchange other coins for chips.</h2> */}
                    </div>
                </div>
                : <div className={styles.modal_main_cntnt}>
                    {/* Left */}
                    <div className={styles.left_section}>
                        {allChips?.map((coin, key) => {
                            return <CoinButton setToggle={setToggle} setChipValue={setChipValue} setCoinValue={setCoinValue} key={key} coin={coin} setIndex={setIndex} index={index} thisIndex={key} />
                        })}
                    </div>

                    <div className={styles.vertical_divider}></div>

                    {/* Right */}
                    <div className={styles.right_section}>
                        <div className='flex items-center justify-between w-full'>
                            <h1 className={styles.con_exchg_head}>Coin Exchange</h1>
                            <Info />
                        </div>

                        <div className={styles.main_exchg_pattern}>
                            {toggle
                            ? <ExchangeSide title={'For'} type={2} inputValue={coinValue} setInputValue={setCoinValue} handleInput={handleCoinValue} coinType={allChips[index]} setOutputIndex={setOutputIndex} outputIndex={outputIndex} />
                            : <ExchangeSide title={'For'} type={1} inputValue={chipValue} setInputValue={setChipValue} handleInput={handleChipValue} coinType={allChips[index]} />
                            }
                            <button className={styles.exchg_btn} onClick={() => {setToggle((prev) => !prev)}}>
                                <ConvertSvg />
                            </button>
                            {toggle
                            ? <ExchangeSide title={'Get'} type={1} inputValue={chipValue} setInputValue={setChipValue} handleInput={handleChipValue} coinType={allChips[index]} />
                            : <ExchangeSide title={'Get'} type={2} inputValue={coinValue} setInputValue={setCoinValue} handleInput={handleCoinValue} coinType={allChips[index]} setOutputIndex={setOutputIndex} outputIndex={outputIndex} />
                            }
                        </div>

                        <button
                            className='primary-custom-button w-full'
                            disabled={exchanging || !coinValue || coinValue === 0 || !allChips[index]?.coinData?.value || allChips[index]?.coinData?.value === 0 || (allChips[index]?.coinData?.value < coinValue && toggle) || (allChips[index]?.value < chipValue && !toggle)}
                            onClick={handleExchange}
                        >
                            <div className={`primary-custom-button-inner ${styles.exchange_btn}`} >
                                    {exchanging ? 'Exchanging...' :
                                        (allChips[index]?.coinData?.value < coinValue && toggle) ? 'Unsufficient Coins'
                                        : (allChips[index]?.value < chipValue && !toggle) ? 'Unsufficient Chips'
                                        : !allChips[index]?.coinData?.value || allChips[index]?.coinData?.value === 0 ? 'No Sufficient Balance'
                                        : coinValue > 0 && toggle ? <div className='flex item-center gap-2'>Exchange {coinValue} <span><img src={allChips[index]?.coinData?.coinImg} alt="" className='w-4 h-4' /></span> for {chipValue} <span><img src={allChips[index]?.chipImg} alt="" className='w-4 h-4' /></span></div>
                                        : coinValue > 0 && !toggle ? <div className='flex item-center gap-2'>Exchange {chipValue} <span><img src={allChips[index]?.chipImg} alt="" className='w-4 h-4' /></span> for {coinValue} <span><img src={allChips[index]?.coinData?.coinImg} alt="" className='w-4 h-4' /></span></div>
                                        : 'Exchange'}
                            </div>
                        </button>
                    </div>
                </div>
            }
            </>
        )
    }

    if(window.innerWidth > 650)
    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0 overflow-y-auto'
                initialFocus={cancelButtonRef}
                onClose={setOpenModal}
            >
                <div className='h-screen px-4 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[#0c0c0d9a] bg-opacity-75 backdrop-blur-[3px] transition-opacity' 
                            style={{
                                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%)`
                            }}
                        />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block min-h-[500px] bg-transparent rounded-lg text-left transform transition-all my-8 align-middle max-w-[1030px] w-full'>
                            <div className={`${styles.modal_background} flex justify-center w-full items-center rounded-lg text-white h-full p-10 flex-col gap-y-8 border border-[#3D3D3D]`}>
                                <MainModal />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
    else return <SwipeableDrawer
        anchor={'bottom'}
        open={openModal}
        onClose={(e) => {setOpenModal(false)}}
        transitionDuration={600}
        onOpen={(e) => setOpenModal(true)}
        className='transition-all ease-in-out'
        PaperProps={{
            sx: {
                backdropFilter: 'blur(10px)',
                borderRadius: '0 !important',
                color: 'white',
                height: '85vh',
                width: '100%',
                background: 'transparent',
                // background: 'linear-gradient(167.1deg, #262626 36.57%, #1B1818 94.9%)',
                boxShadow: 'none',
                padding: '0',
                paddingBottom: '0',
            },
        }}
        ModalProps={{
            sx: {
                zIndex: '1280',
            },
        }}
    >
        <div className={styles.modal_background_swipe} style={{height: allChips?.length === 0 ? '100%' : ''}}>
            <MainModal/>
        </div>
    </SwipeableDrawer>
}

export default ExchangeModal

const CoinButton = ({coin, index, setIndex, thisIndex, setCoinValue, setChipValue, setToggle}) => {
    return(
        <div className={styles.button_holder}
            style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), ${coin?.theme?.backgroundColor}`,
                border: '1px solid',
                borderColor:  coin?.theme?.backgroundColor
            }}
            onClick={() => {
                setCoinValue(0)
                setChipValue(0)
                setToggle(true)
                setIndex(thisIndex)
            }}
        >
            {index === thisIndex && <div className={styles.side_selected_bar}
                style={{
                    background: coin?.theme?.backgroundColor,
                    boxShadow: `0px -2px 7px 0px ${coin?.theme?.backgroundColor + 'bf'}, 0px -4px 13px 0px ${coin?.theme?.backgroundColor}`,
                }}
            ></div>}

            <figure className={styles.fig_wrapper}>
                <img src={coin?.chipImg} alt="" />
            </figure>
            <div className='flex flex-col gap-1'>
                <h2 className={styles.coinName}>{coin?.chipsName}</h2>
                <h3 className={styles.coinValue}>{coin?.value}</h3>
            </div>
        </div>
    )
}

const ExchangeSide = ({title, coinType, select, setOutputIndex, outputIndex, inputValue, setInputValue, handleInput, type}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const amount = type === 2 ? coinType?.coinData?.value : coinType?.value

    return(
        <div className={styles.side_exchg_wrapper}>
            <h2 className={styles.exchg_type}>{title}:</h2>

            <div className='w-full pt-[35px]'>
                <div className={styles.coin_type_outer}
                    style={{
                        background: `linear-gradient(360deg, ${coinType?.theme?.backgroundColor} 0%, rgba(250, 202, 50, 0) 100%)`
                    }}
                >
                    <div className={styles.coin_type}
                        style={{
                            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%), ${coinType?.theme?.backgroundColor}`,
                        }}
                    >
                        <div className='flex w-full items-center justify-end px-[9px]'>
                            <figure className={styles.selected_coin}>
                                <img src={type === 2 ? coinType?.coinData?.coinImg :coinType?.chipImg} alt="" />
                            </figure>

                            <span className={styles.selected_value}>{type === 2 ? coinType?.coinData?.value : coinType?.value}</span>
                        </div>

                        <div className='flex w-full items-center justify-between'>
                            <h1 className={styles.seclected_coin_name}>{type === 2 ? coinType?.coinData?.coinName : coinType?.chipsName}</h1>

                            {select && <button className='w-[15px] h-[15px] flex items-center justify-center' onClick={handleClick}>
                                <ArrowDropDown />
                            </button>}
                        </div>

                        <div className={styles.bottom_selected_bar}
                            style={{
                                background: coinType?.theme?.backgroundColor,
                                boxShadow: `0px -2px 7px 0px ${coinType?.theme?.backgroundColor + 'bf'}, 0px -4px 13px 0px ${coinType?.theme?.backgroundColor}`,
                            }}
                        ></div>
                    </div>
                </div>
            </div>

            <div className={styles.bottom_inp_txt_wrapper}>
                <input type="text" className={styles.exchg_input} value={inputValue} onChange={handleInput} />
                <div className={styles.predefined_price_wrapper}>
                    <button className={styles.predefined_size} onClick={() => {handleInput({target :{value: Math.ceil((amount*25)/100)}})}}>25%</button>
                    <button className={styles.predefined_size} onClick={() => {handleInput({target :{value: Math.ceil((amount*50)/100)}})}}>50%</button>
                    <button className={styles.predefined_size} onClick={() => {handleInput({target :{value: Math.ceil((amount*100)/100)}})}}>100%</button>
                </div>
            </div>

            <SelectCoinPopover coins={data} anchorEl={anchorEl} id={id} open={open} handleClose={handleClose} outputIndex={outputIndex} setOutputIndex={setOutputIndex} />
        </div>
    )
}

const ConvertSvg = () => {
    return(<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 16L1 16M1 16L5 12M1 16L5 20M1 6L17 6M17 6L13 2M17 6L13 10" stroke="#FF0000" strokeOpacity="0.6" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="bevel"/>
    </svg>
    )
}

const ArrowDropDown = ()  => {
    return(
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 1.5L7 6.5L12 1.5" stroke="white" strokeWidth="1.66667" strokeLinecap="square"/>
    </svg>
    )
}

const Info = () =>{
    return(<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3" clipPath="url(#clip0_2016_37425)">
    <path d="M10.0013 13.3333V9.99996M10.0013 6.66663H10.0096M18.3346 9.99996C18.3346 14.6023 14.6037 18.3333 10.0013 18.3333C5.39893 18.3333 1.66797 14.6023 1.66797 9.99996C1.66797 5.39759 5.39893 1.66663 10.0013 1.66663C14.6037 1.66663 18.3346 5.39759 18.3346 9.99996Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_2016_37425">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    )
}

const Close = () =>{
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
}