import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LoaderPage from '../OtherPages/LoaderPage/LoaderPage'
import { connectToDiscord, loginByDiscord } from '../../redux/actions/userAction'

const DiscordConnectPage = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const code = location.search.split('=')[1]

    useEffect(() => {
        const validate = JSON.parse(localStorage.getItem('LoginDiscord'))
        const getDiscordUser = async () => {
            if (code) {
                if(validate)
                    dispatch(loginByDiscord(code, navigate))
                else
                    dispatch(connectToDiscord(code, navigate))
            }
        }

        getDiscordUser()
    }, [code, dispatch, navigate])

    return <LoaderPage />
}

export default DiscordConnectPage