import React from 'react'
import styles from './Loader.module.css'
import outer_circle from '../../Assets/Loader/Back.svg'
import spade from '../../Assets/Loader/Spade.svg'
import diamond from '../../Assets/Loader/Diamond.svg'
import club from '../../Assets/Loader/Club.svg'
import heart from '../../Assets/Loader/Heart.svg'
import { useEffect } from 'react'
import { gsap } from 'gsap'

const Loader = () => {
    useEffect(() => {
        const ctx = gsap.context(() => {
            gsap.set("#image2", { display: 'none', opacity: 0 })
            gsap.set("#image3", { display: 'none', opacity: 0 })
            gsap.set("#image4", { display: 'none', opacity: 0 })

            gsap
            .timeline({
                onUpdate: function() {
                    if (this.progress() > 0.99) {
                        this.play(0)
                    }
                }
            })
            .to("#image1", { display: 'block', opacity: 1 }, 0)
            .to("#image1", { display: 'none', opacity: 0 }, 2.5)
            .to("#image2", { display: 'block', opacity: 1 }, 3)
            .to("#image2", { display: 'none', opacity: 0 }, 5.5)
            .to("#image3", { display: 'block', opacity: 1 }, 6)
            .to("#image3", { display: 'none', opacity: 0 }, 8.5)
            .to("#image4", { display: 'block', opacity: 1 }, 9)
            .to("#image4", { display: 'none', opacity: 0 }, 11.5)
            .to("#image1", { display: 'block', opacity: 1 }, 12)
        });

        return () => ctx.revert();
}, [])

    return <div className={styles.loader_bg}>
        <div className={styles.main_wrapper}>
            <img src={outer_circle} alt="" className={styles.outer_circle}/>
            <div className={styles.centre_src}>
                <img style={{ display: 'none' }} id='image1' src={heart} alt="" className={styles.centre_src_img} />
                <img style={{ display: 'none' }} id='image2' src={spade} alt="" className={styles.centre_src_img} />
                <img style={{ display: 'none' }} id='image3' src={diamond} alt="" className={styles.centre_src_img} />
                <img style={{ display: 'none' }} id='image4' src={club} alt="" className={styles.centre_src_img} />
            </div>
        </div>
    </div>
}

export default Loader
