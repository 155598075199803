import CommunityGraph from '../../Graph/ComunityGraph/CommunityGraph'
import styles from './Activity.module.css'
// import React, { useState } from 'react'

const Activity = ({disabled, activity}) => {
  // const [active, setActive] = useState(1)

  return (
    <div className={styles.container} style={{borderRadius: disabled ? '12px' : '', padding : disabled ? '4px 10px' : ''}}>
      <div className={styles.row_align}>
        <h2 className={styles.max_winning_head} style={{fontSize: disabled ? '16px' : ''}}>Activity</h2>
          {/* <div className={styles.week_tab}>
              <button onClick={() => setActive(1)} className={active === 1 ? styles.active_tab : styles.normal_tab} style={{padding: disabled ? '0px' : '', width: disabled ? '50px' : '', fontSize: disabled ? '10px' : '', lineHeight: disabled ? 'unset' : ''}}>Weekly</button>
              <button onClick={() => setActive(2)} className={active === 2 ? styles.active_tab : styles.normal_tab} style={{padding: disabled ? '0px' : '', width: disabled ? '50px' : '', fontSize: disabled ? '10px' : '', lineHeight: disabled ? 'unset' : ''}}>Daily</button>
          </div> */}
        </div>
      <span className={styles.border_line}></span>
      <CommunityGraph activity={activity} />
    </div>
  )
}

export default Activity
