import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import LoaderPage from '../OtherPages/LoaderPage/LoaderPage'
import { useDispatch } from 'react-redux'
import { connectToTwitter, loginByTwitter } from '../../redux/actions/userAction'

const TwitterConnectPage = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const oauth_token_line = location.search.split('&')[0]
    const oauth_token = oauth_token_line.split('=')[1]
    const oauth_verifier_line = location.search.split('&')[1]
    const oauth_verifier = oauth_verifier_line.split('=')[1]

    useEffect(() => {
        const getTwitter = async () => {
            const validate = JSON.parse(localStorage.getItem('LoginTwitter'))
            if (oauth_token && oauth_verifier) {
                if(validate)
                    dispatch(loginByTwitter(oauth_token, oauth_verifier, navigate))
                else
                    dispatch(connectToTwitter(oauth_token, oauth_verifier, navigate))
            }
        }

        getTwitter()
    }, [oauth_token, oauth_verifier, dispatch, navigate])

    return <LoaderPage />

}

export default TwitterConnectPage