import { getWalletAddress } from '../../../constants'
import styles from './CommunityPlayers.module.css'
// import React, { useState } from 'react'

const CommunityPlayers = ({maxFit, disabled, topPlayers, loading}) => {
    // const [active, setActive] = useState(1)

    return <div className={styles.container} style={{width: maxFit ? '100%' : '', height: maxFit ? '382px' : '', borderRadius: disabled ? '12px' : '', padding : disabled ? '4px 10px' : ''}}>
        <div className={styles.row_align}>
            <h2 className={maxFit ? styles.max_winning_dashboard : styles.max_winning_head} style={{fontSize: disabled ? '16px' : ''}}>Top Players</h2>

            {/* <div className={styles.week_tab}>
                <button onClick={() => setActive(1)} className={active === 1 ? styles.active_tab : styles.normal_tab} style={{padding: disabled ? '0px' : '', width: disabled ? '50px' : '', fontSize: disabled ? '10px' : '', lineHeight: disabled ? 'unset' : ''}}>Weekly</button>
                <button onClick={() => setActive(2)} className={active === 2 ? styles.active_tab : styles.normal_tab} style={{padding: disabled ? '0px' : '', width: disabled ? '50px' : '', fontSize: disabled ? '10px' : '', lineHeight: disabled ? 'unset' : ''}}>Daily</button>
            </div> */}
        </div>

        <span className={styles.border_line}></span>

        <div className={styles.bottom_container} style={{overflow: maxFit ? 'unset' : ''}}>
            <div className={styles.user_title_field}>
                <TitleField />
            </div>
            <div className={styles.user_value_field}>
                {loading
                    ? Array(3).fill(0, 0)?.map((i, k) => (
                        <LoadField key={k} />
                    ))
                    : topPlayers?.map((value, key) => {
                        return <ValueField data={value} rank={key+1} key={key} maxFit={maxFit} />
                    })
                }
                {/* <ValueField /> */}
            </div>
        </div>
    </div>
}

export default CommunityPlayers


const TitleField = () => {
    return(
        <div className={styles.title_wrapper_field}>
            <h2 className={styles.top_f_t}>User</h2>
            <h2 className={styles.top_f_t}>wins</h2>
        </div>
    )
}

const LoadField = () => {
    return(
        <div className={`animate-pulse ${styles.wrapper_field_loading}`}>
    </div>
    )
}

const ValueField = ({rank, maxFit, data}) => {
    return(
        <div className={styles.wrapper_field} style={{border: maxFit ? '' : '1px solid rgba(255, 255, 255, 0.10)'}}>
            <div className={styles.brief_info}>
                <div className={styles.sr_no} style={{width: '16px'}}>
                    {rank === 1 ? <GoldBadge /> : rank === 2 ? <SilverBadge /> : rank === 3 ? <BronzeBadge /> : rank + '.'}
                </div>

                <div className={styles.per_user_brief}>
                    <div className={styles.image_wrapper}>
                        <img alt='' style={{ borderRadius: '6px' }} src={data?.image} />
                    </div>
                    <div className={styles.title_wrapper}>
                        <h2 className={styles.heading}>{data?.username}</h2>
                        <h3 className={styles.wallet_address}>{getWalletAddress(data?.walletAddress)}</h3>
                    </div>
                </div>
            </div>

            <div className={styles.won_wrapp}>
                {/* {rank === 1 ? <GoldBadge /> : rank === 2 ? <SilverBadge /> : rank === 3 ? <BronzeBadge /> : ''} */}
                {/* <h2 className={rank === 1 ? styles.games_won_gold : rank === 2 ? styles.games_won_silver : rank === 3 ? styles.games_won_bronze : styles.games_won}>124</h2> */}
                <h2 className={styles.games_won}>{data?.weeklyXP || 0}</h2>
            </div>
        </div>
    )
}


const SilverBadge = () => {
    return(<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1521 14H3.86646L3.85494 13.9685C3.91057 13.9335 3.96619 13.8979 4.02232 13.8654C5.02464 13.2463 6.02345 12.6272 7.02928 12.0133C7.08689 11.985 7.13445 11.9388 7.16536 11.8813C7.19626 11.8238 7.20896 11.7578 7.20167 11.6925C7.19115 11.3066 7.19215 10.9186 7.20167 10.5343C7.20652 10.4749 7.19459 10.4152 7.16729 10.3626C7.13999 10.31 7.09851 10.2666 7.04782 10.2377C6.07608 9.56032 5.16846 8.80301 4.42775 7.85997C4.14259 7.49886 3.91307 7.08616 3.65247 6.70337C3.61188 6.64353 3.54372 6.5744 3.48007 6.56408C2.23018 6.36701 1.39524 5.60351 0.827427 4.49127C0.239567 3.34034 0.0025058 2.09912 0 0.807873C0 0.362667 0.343295 0.0227001 0.777801 0.00773949C0.827916 0.00773949 0.878032 0.00773949 0.928148 0.00773949C5.64139 0.00773949 10.3546 0.00515882 15.0679 0C15.5836 0 16.0291 0.334808 15.9985 0.986882C15.9349 2.34984 15.6788 3.65296 14.9741 4.82865C14.4083 5.77323 13.6155 6.39178 12.525 6.55995C12.4933 6.5641 12.4626 6.57471 12.4349 6.59119C12.4072 6.60767 12.383 6.62967 12.3636 6.65591C11.7948 7.75732 10.9639 8.62348 10.0377 9.4004C9.6794 9.70064 9.30102 9.9756 8.92465 10.2521C8.88023 10.2795 8.84416 10.3191 8.82051 10.3666C8.79685 10.414 8.78658 10.4673 8.79085 10.5204C8.79786 10.9197 8.79836 11.3184 8.79085 11.7172C8.78586 11.77 8.79679 11.8231 8.82217 11.8693C8.84755 11.9154 8.88614 11.9524 8.93268 11.9752C9.95805 12.6028 10.9813 13.2337 12.0023 13.8679C12.0579 13.902 12.1125 13.9371 12.1677 13.9711L12.1521 14ZM3.18388 1.67043H1.60924C1.75508 2.22139 1.87384 2.75378 2.03772 3.26966C2.18108 3.74589 2.44979 4.17193 2.81403 4.50055C2.92228 4.59651 3.04807 4.67131 3.18388 4.76881V1.67043ZM12.8031 4.78377C13.9157 4.02336 14.1342 2.86057 14.3407 1.67146H12.8031V4.78377Z" fill="url(#paint0_linear_513_582)"/>
    <defs>
    <linearGradient id="paint0_linear_513_582" x1="0" y1="0" x2="12.4393" y2="13.2512" gradientUnits="userSpaceOnUse">
    <stop offset="0.534375" stopColor="white" stopOpacity="0.83"/>
    <stop offset="0.680208" stopColor="#BCBCBC"/>
    <stop offset="1" stopColor="#A2A2A2"/>
    </linearGradient>
    </defs>
    </svg>
    )
}

// const SilverBadge = () => {
//     return(<svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M0.00585938 0.907227H8.01865V7.12837C8.01865 7.43712 7.94387 7.71784 7.7943 7.97052C7.64473 8.22319 7.4396 8.42858 7.1789 8.58671L4.30071 10.3046L4.90007 12.2534H7.68209L5.40328 13.8623L6.29109 16.6604L4.01226 14.9264L1.73342 16.6604L2.62123 13.8623L0.342422 12.2534H3.12444L3.7238 10.3046L0.829568 8.60273C0.568887 8.44995 0.366436 8.24455 0.222214 7.98654C0.0779777 7.72853 0.00585938 7.44247 0.00585938 7.12837V0.907227ZM1.25582 2.15721V7.12837C1.25582 7.2085 1.27585 7.28195 1.31592 7.34873C1.35599 7.41551 1.41075 7.47026 1.48019 7.513L3.38728 8.64123V2.15721H1.25582ZM6.76869 2.15721H4.63724V8.64123L6.54432 7.513C6.61376 7.47026 6.66852 7.41551 6.70859 7.34873C6.74866 7.28195 6.76869 7.2085 6.76869 7.12837V2.15721Z" fill="url(#paint0_linear_1329_18709)"/>
//     <defs>
//     <linearGradient id="paint0_linear_1329_18709" x1="0.00585942" y1="1.54243" x2="0.106744" y2="16.6864" gradientUnits="userSpaceOnUse">
//     <stop offset="0.2375" stopColor="white" stopOpacity="0.38"/>
//     <stop offset="0.39375" stopColor="white"/>
//     </linearGradient>
//     </defs>
//     </svg>
//     )
// }

const GoldBadge = () => {
    return(<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1521 14H3.86646L3.85494 13.9685C3.91057 13.9335 3.96619 13.8979 4.02232 13.8654C5.02464 13.2463 6.02345 12.6272 7.02928 12.0133C7.08689 11.985 7.13445 11.9388 7.16536 11.8813C7.19626 11.8238 7.20896 11.7578 7.20167 11.6925C7.19115 11.3066 7.19215 10.9186 7.20167 10.5343C7.20652 10.4749 7.19459 10.4152 7.16729 10.3626C7.13999 10.31 7.09851 10.2666 7.04782 10.2377C6.07608 9.56032 5.16846 8.80301 4.42775 7.85997C4.14259 7.49886 3.91307 7.08616 3.65247 6.70337C3.61188 6.64353 3.54372 6.5744 3.48007 6.56408C2.23018 6.36701 1.39524 5.60351 0.827427 4.49127C0.239567 3.34034 0.0025058 2.09912 0 0.807873C0 0.362667 0.343295 0.0227001 0.777801 0.00773949C0.827916 0.00773949 0.878032 0.00773949 0.928148 0.00773949C5.64139 0.00773949 10.3546 0.00515882 15.0679 0C15.5836 0 16.0291 0.334808 15.9985 0.986882C15.9349 2.34984 15.6788 3.65296 14.9741 4.82865C14.4083 5.77323 13.6155 6.39178 12.525 6.55995C12.4933 6.5641 12.4626 6.57471 12.4349 6.59119C12.4072 6.60767 12.383 6.62967 12.3636 6.65591C11.7948 7.75732 10.9639 8.62348 10.0377 9.4004C9.6794 9.70064 9.30102 9.9756 8.92465 10.2521C8.88023 10.2795 8.84416 10.3191 8.82051 10.3666C8.79685 10.414 8.78658 10.4673 8.79085 10.5204C8.79786 10.9197 8.79836 11.3184 8.79085 11.7172C8.78586 11.77 8.79679 11.8231 8.82217 11.8693C8.84755 11.9154 8.88614 11.9524 8.93268 11.9752C9.95805 12.6028 10.9813 13.2337 12.0023 13.8679C12.0579 13.902 12.1125 13.9371 12.1677 13.9711L12.1521 14ZM3.18388 1.67043H1.60924C1.75508 2.22139 1.87384 2.75378 2.03772 3.26966C2.18108 3.74589 2.44979 4.17193 2.81403 4.50055C2.92228 4.59651 3.04807 4.67131 3.18388 4.76881V1.67043ZM12.8031 4.78377C13.9157 4.02336 14.1342 2.86057 14.3407 1.67146H12.8031V4.78377Z" fill="url(#paint0_linear_513_210)"/>
    <defs>
    <linearGradient id="paint0_linear_513_210" x1="0" y1="0" x2="12.4393" y2="13.2512" gradientUnits="userSpaceOnUse">
    <stop offset="0.581526" stopColor="#FF9900"/>
    <stop offset="0.680185" stopColor="#C58018"/>
    <stop offset="1" stopColor="#C58018"/>
    </linearGradient>
    </defs>
    </svg>
    )
}

// const GoldBadge = () => {
//     return(<svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M0.00585938 0.907104H8.01865V7.12825C8.01865 7.437 7.94387 7.71772 7.7943 7.9704C7.64473 8.22306 7.4396 8.42846 7.1789 8.58659L4.30071 10.3045L4.90007 12.2533H7.68209L5.40328 13.8622L6.29109 16.6603L4.01226 14.9263L1.73342 16.6603L2.62123 13.8622L0.342422 12.2533H3.12444L3.7238 10.3045L0.829568 8.60261C0.568887 8.44983 0.366436 8.24443 0.222214 7.98642C0.0779777 7.7284 0.00585938 7.44235 0.00585938 7.12825V0.907104ZM1.25582 2.15708V7.12825C1.25582 7.20838 1.27585 7.28183 1.31592 7.34861C1.35599 7.41538 1.41075 7.47014 1.48019 7.51288L3.38728 8.64111V2.15708H1.25582ZM6.76869 2.15708H4.63724V8.64111L6.54432 7.51288C6.61376 7.47014 6.66852 7.41538 6.70859 7.34861C6.74866 7.28183 6.76869 7.20838 6.76869 7.12825V2.15708Z" fill="url(#paint0_linear_1329_18696)"/>
//     <defs>
//     <linearGradient id="paint0_linear_1329_18696" x1="4.01226" y1="0.0608609" x2="3.45553" y2="16.6416" gradientUnits="userSpaceOnUse">
//     <stop stopColor="#FFD600" stopOpacity="0.58"/>
//     <stop offset="0.378125" stopColor="#FFD600"/>
//     </linearGradient>
//     </defs>
//     </svg>
//     )
// }

const BronzeBadge = () => {
    return(<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1521 14H3.86646L3.85494 13.9685C3.91057 13.9335 3.96619 13.8979 4.02232 13.8654C5.02464 13.2463 6.02345 12.6272 7.02928 12.0133C7.08689 11.985 7.13445 11.9388 7.16536 11.8813C7.19626 11.8238 7.20896 11.7578 7.20167 11.6925C7.19115 11.3066 7.19215 10.9186 7.20167 10.5343C7.20652 10.4749 7.19459 10.4152 7.16729 10.3626C7.13999 10.31 7.09851 10.2666 7.04782 10.2377C6.07608 9.56032 5.16846 8.80301 4.42775 7.85997C4.14259 7.49886 3.91307 7.08616 3.65247 6.70337C3.61188 6.64353 3.54372 6.5744 3.48007 6.56408C2.23018 6.36701 1.39524 5.60351 0.827427 4.49127C0.239567 3.34034 0.0025058 2.09912 0 0.807873C0 0.362667 0.343295 0.0227001 0.777801 0.00773949C0.827916 0.00773949 0.878032 0.00773949 0.928148 0.00773949C5.64139 0.00773949 10.3546 0.00515882 15.0679 0C15.5836 0 16.0291 0.334808 15.9985 0.986882C15.9349 2.34984 15.6788 3.65296 14.9741 4.82865C14.4083 5.77323 13.6155 6.39178 12.525 6.55995C12.4933 6.5641 12.4626 6.57471 12.4349 6.59119C12.4072 6.60767 12.383 6.62967 12.3636 6.65591C11.7948 7.75732 10.9639 8.62348 10.0377 9.4004C9.6794 9.70064 9.30102 9.9756 8.92465 10.2521C8.88023 10.2795 8.84416 10.3191 8.82051 10.3666C8.79685 10.414 8.78658 10.4673 8.79085 10.5204C8.79786 10.9197 8.79836 11.3184 8.79085 11.7172C8.78586 11.77 8.79679 11.8231 8.82217 11.8693C8.84755 11.9154 8.88614 11.9524 8.93268 11.9752C9.95805 12.6028 10.9813 13.2337 12.0023 13.8679C12.0579 13.902 12.1125 13.9371 12.1677 13.9711L12.1521 14ZM3.18388 1.67043H1.60924C1.75508 2.22139 1.87384 2.75378 2.03772 3.26966C2.18108 3.74589 2.44979 4.17193 2.81403 4.50055C2.92228 4.59651 3.04807 4.67131 3.18388 4.76881V1.67043ZM12.8031 4.78377C13.9157 4.02336 14.1342 2.86057 14.3407 1.67146H12.8031V4.78377Z" fill="url(#paint0_linear_513_311)"/>
    <defs>
    <linearGradient id="paint0_linear_513_311" x1="0" y1="0" x2="12.4393" y2="13.2512" gradientUnits="userSpaceOnUse">
    <stop offset="0.607292" stopColor="#E15A21"/>
    <stop offset="0.815625" stopColor="#8B3B19"/>
    <stop offset="1" stopColor="#742909"/>
    </linearGradient>
    </defs>
    </svg>
    )
}

// const BronzeBadge = () => {
//     return(<svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M0.00585938 0.907227H8.01865V7.12837C8.01865 7.43712 7.94387 7.71784 7.7943 7.97052C7.64473 8.22319 7.4396 8.42858 7.1789 8.58671L4.30071 10.3046L4.90007 12.2534H7.68209L5.40328 13.8623L6.29109 16.6604L4.01226 14.9264L1.73342 16.6604L2.62123 13.8623L0.342422 12.2534H3.12444L3.7238 10.3046L0.829568 8.60273C0.568887 8.44995 0.366436 8.24455 0.222214 7.98654C0.0779777 7.72853 0.00585938 7.44247 0.00585938 7.12837V0.907227ZM1.25582 2.15721V7.12837C1.25582 7.2085 1.27585 7.28195 1.31592 7.34873C1.35599 7.41551 1.41075 7.47026 1.48019 7.513L3.38728 8.64123V2.15721H1.25582ZM6.76869 2.15721H4.63724V8.64123L6.54432 7.513C6.61376 7.47026 6.66852 7.41551 6.70859 7.34873C6.74866 7.28195 6.76869 7.2085 6.76869 7.12837V2.15721Z" fill="url(#paint0_linear_1329_18722)"/>
//     <defs>
//     <linearGradient id="paint0_linear_1329_18722" x1="0.00585942" y1="1.54243" x2="0.106744" y2="16.6864" gradientUnits="userSpaceOnUse">
//     <stop stopColor="#B55D0C" stopOpacity="0.86"/>
//     <stop offset="0.216667" stopColor="#F27400"/>
//     </linearGradient>
//     </defs>
//     </svg>
//     )
// }

// const NormalBadge = () => {
//     return(<svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M0.00585938 0.907227H8.01865V7.12837C8.01865 7.43712 7.94387 7.71784 7.7943 7.97052C7.64473 8.22319 7.4396 8.42858 7.1789 8.58671L4.30071 10.3046L4.90007 12.2534H7.68209L5.40328 13.8623L6.29109 16.6604L4.01226 14.9264L1.73342 16.6604L2.62123 13.8623L0.342422 12.2534H3.12444L3.7238 10.3046L0.829568 8.60273C0.568887 8.44995 0.366436 8.24455 0.222214 7.98654C0.0779777 7.72853 0.00585938 7.44247 0.00585938 7.12837V0.907227ZM1.25582 2.15721V7.12837C1.25582 7.2085 1.27585 7.28195 1.31592 7.34873C1.35599 7.41551 1.41075 7.47026 1.48019 7.513L3.38728 8.64123V2.15721H1.25582ZM6.7687 2.15721H4.63724V8.64123L6.54432 7.513C6.61376 7.47026 6.66852 7.41551 6.70859 7.34873C6.74866 7.28195 6.7687 7.2085 6.7687 7.12837V2.15721Z" fill="white"/>
//     </svg>
//     )
// }