import Web3 from "web3"
import bcrypt from 'bcryptjs'
import { BACKEND_URL, ErrorMessage } from "../../constants"
import axios from "axios"
import { store } from "../../store"
import WalletConnectProvider from "@walletconnect/web3-provider"
import { openToast } from "./otherAction"
import { USER_LOGIN_FAIL, USER_LOGIN_SUCCESS } from "../constants/userConstant"

const web3 = new Web3(Web3.givenProvider)

export const addMetamaskToProfile = (setLoading) => async(dispatch) => {
    try {
        setLoading(true)

        const userLoginReducer = store.getState().userLoginReducer
        const token = userLoginReducer?.accessToken
        const userInfo = userLoginReducer?.userInfo

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${token}`,
            },
        }

        if (window.ethereum) {
            var address
            if (window.ethereum.providers) {
                address = await window.ethereum.providers
                    .find((provider) => provider.isMetaMask)
                    .request({ method: 'eth_requestAccounts' })
            } else {
                address = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                })
            }
            if (address.length > 0) {
                await web3.eth
                    .getAccounts()
                    .then(async (res) => {
                        if (res.length > 0 && res[0].length > 1) {
                            console.log(res[0])
                            const { data } = await axios.post(
                                    `${BACKEND_URL}/auth/connectWalletAddress`,
                                    {
                                        walletAddress: res[0],
                                        connectType: userInfo.connectType,
                                        walletType: 'Metamask'
                                    },
                                    config
                                )

                            if (data.status === 200) {
                                const salt = await bcrypt.genSalt(10)
                                const verificationHash = await bcrypt.hashSync(
                                    `${
                                        process.env.REACT_APP_KURAMA_VERIFICATION_HASH
                                    } ${token.substring(0, 15)}`,
                                    salt
                                )
                                const verificationHashKURAMA = await bcrypt.hashSync(
                                        `Kurama Verification Hash for ArcadeVerse`,
                                        salt
                                    )
                                dispatch({
                                    type: USER_LOGIN_SUCCESS,
                                    payload: data,
                                    verificationHash,
                                    verificationHashKURAMA
                                })
                                localStorage.setItem('user', JSON.stringify(data))
                                localStorage.setItem(
                                    'verificationHash',
                                    JSON.stringify(verificationHash)
                                )
                                localStorage.setItem(
                                    'verificationHashKURAMA',
                                    JSON.stringify(verificationHashKURAMA)
                                )
                            } else {
                                dispatch({
                                    type: USER_LOGIN_FAIL,
                                    payload: res?.data?.msg || 'Something Failed...',
                                })
                                dispatch(openToast(true, data?.msg, `Metamask`, true))
                            }
                            setLoading(false)
                        } else {
                            console.log('Wallet Address missing')
                            setLoading(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err, 'Wallet not found!')
                        window.open(
                            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
                            '_blank'
                        )
                        setLoading(false)
                    })
            } else {
                window.open(
                    'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
                    '_blank'
                )
                setLoading(false)
            }
        } else {
            window.open(
                'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
                '_blank'
            )
            setLoading(false)
        }
    } catch (error) {
        setLoading(false)
        dispatch(openToast(true, ErrorMessage(error), `Metamask`, true))
    }
}

export const addWalletConnectToProfile = (setLoading) => async(dispatch) => {
    try {
        setLoading(true)

        const userLoginReducer = store.getState().userLoginReducer
        const token = userLoginReducer?.accessToken
        const userInfo = userLoginReducer?.userInfo

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${token}`,
            },
        }

        if (window.ethereum) {
            const provider = new WalletConnectProvider({
                rpc: {
                    1: 'https://cloudflare-eth.com',
                    137: 'https://polygon-rpc.com',
                    // 100: 'https://dai.poa.network',
                },
                bridge: 'https://bridge.walletconnect.org',
            })

            await provider.enable()
            const web3 = new Web3(provider)

            await web3.eth
                .getAccounts()
                .then(async (res) => {
                    if (res.length > 0 && res[0].length > 1) {
                        const { data } = await axios.post(
                            `${BACKEND_URL}/auth/connectWalletAddress`,
                            {
                                walletAddress: res[0],
                                connectType: userInfo.connectType,
                                walletType: 'WalletConnect'
                            },
                            config
                        )

                        if (data.status === 200) {
                            const salt = await bcrypt.genSalt(10)
                            const verificationHash = await bcrypt.hashSync(
                                `${
                                    process.env.REACT_APP_KURAMA_VERIFICATION_HASH
                                } ${token.substring(0, 15)}`,
                                salt
                            )
                            const verificationHashKURAMA = await bcrypt.hashSync(
                                    `Kurama Verification Hash for ArcadeVerse`,
                                    salt
                                )
                            dispatch({
                                type: USER_LOGIN_SUCCESS,
                                payload: data,
                                verificationHash,
                                verificationHashKURAMA
                            })
                            localStorage.setItem('user', JSON.stringify(data))
                            localStorage.setItem(
                                'verificationHash',
                                JSON.stringify(verificationHash)
                            )
                            localStorage.setItem(
                                'verificationHashKURAMA',
                                JSON.stringify(verificationHashKURAMA)
                            )
                        } else {
                            dispatch({
                                type: USER_LOGIN_FAIL,
                                payload: res?.data?.msg || 'Something Failed...',
                            })
                            dispatch(openToast(true, data?.msg, `Metamask`, true))
                        }
                        setLoading(false)
                    } else {
                        console.log('Wallet Address missing')
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    console.log(err, 'Wallet not found!')
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }
    } catch (error) {
        setLoading(false)
        dispatch(openToast(true, ErrorMessage(error), `Wallet Connect`, true))
    }
}

export const addCoinbaseToProfile = (setLoading) => async(dispatch) => {
    try {
        setLoading(true)

        const userLoginReducer = store.getState().userLoginReducer
        const token = userLoginReducer?.accessToken
        const userInfo = userLoginReducer?.userInfo

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${token}`,
            },
        }

        if (window.ethereum) {
            var address = ""
            if (window.ethereum.providers) {
                address = await window.ethereum.providers
                    .find((provider) => provider.isCoinbaseWallet)
                    .request({ method: 'eth_requestAccounts' })
            } else {
                if (window.ethereum.isCoinbaseWallet) {
                    address = await window.ethereum.request({
                        method: 'eth_requestAccounts',
                    })
                }
            }
            if (address && address?.length > 0) {
                await web3.eth
                    .getAccounts()
                    .then(async (res) => {
                        if (res.length > 0 && res[0].length > 1) {
                            const { data } = await axios.post(
                                `${BACKEND_URL}/auth/connectWalletAddress`,
                                {
                                    walletAddress: res[0],
                                    connectType: userInfo.connectType,
                                    walletType: 'CoinBase'
                                },
                                config
                            )

                            if (data.status === 200) {
                                const salt = await bcrypt.genSalt(10)
                                const verificationHash = await bcrypt.hashSync(
                                    `${
                                        process.env.REACT_APP_KURAMA_VERIFICATION_HASH
                                    } ${token.substring(0, 15)}`,
                                    salt
                                )
                                const verificationHashKURAMA = await bcrypt.hashSync(
                                        `Kurama Verification Hash for ArcadeVerse`,
                                        salt
                                    )
                                dispatch({
                                    type: USER_LOGIN_SUCCESS,
                                    payload: data,
                                    verificationHash,
                                    verificationHashKURAMA
                                })
                                localStorage.setItem('user', JSON.stringify(data))
                                localStorage.setItem(
                                    'verificationHash',
                                    JSON.stringify(verificationHash)
                                )
                                localStorage.setItem(
                                    'verificationHashKURAMA',
                                    JSON.stringify(verificationHashKURAMA)
                                )
                            } else {
                                dispatch({
                                    type: USER_LOGIN_FAIL,
                                    payload: res?.data?.msg || 'Something Failed...',
                                })
                                dispatch(openToast(true, data?.msg, `Metamask`, true))
                            }
                            setLoading(false)
                        } else {
                            console.log('Wallet Address missing')
                            setLoading(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err, 'Wallet not found!')
                        window.open(
                            'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad',
                            '_blank'
                        )
                        setLoading(false)
                    })
            } else {
                window.open(
                    'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad',
                    '_blank'
                )
                setLoading(false)
            }
        } else {
            window.open(
                'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad',
                '_blank'
            )
            setLoading(false)
        }
    } catch (error) {
        dispatch(openToast(true, ErrorMessage(error), `Coinbase`, true))
        setLoading(false)
    }
}
