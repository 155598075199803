export const CREATE_TOURNAMENT_REQUEST = 'CREATE_TOURNAMENT_REQUEST'
export const CREATE_TOURNAMENT_SUCCESS = 'CREATE_TOURNAMENT_SUCCESS'
export const CREATE_TOURNAMENT_FAILED = 'CREATE_TOURNAMENT_FAILED'

export const DELETE_TOURNAMENT_REQUEST = 'DELETE_TOURNAMENT_REQUEST'
export const DELETE_TOURNAMENT_SUCCESS = 'DELETE_TOURNAMENT_SUCCESS'
export const DELETE_TOURNAMENT_FAILED = 'DELETE_TOURNAMENT_FAILED'

export const GET_TOURNAMENT_REQUEST = 'GET_TOURNAMENT_REQUEST'
export const GET_TOURNAMENT_SUCCESS = 'GET_TOURNAMENT_SUCCESS'
export const GET_TOURNAMENT_FAILED = 'GET_TOURNAMENT_FAILED'

export const GET_TOURNAMENTS_REQUEST = 'GET_TOURNAMENTS_REQUEST'
export const GET_TOURNAMENTS_SUCCESS = 'GET_TOURNAMENTS_SUCCESS'
export const GET_TOURNAMENTS_FAILED = 'GET_TOURNAMENTS_FAILED'

export const REGISTER_TOURNAMENT_REQUEST = 'REGISTER_TOURNAMENT_REQUEST'
export const REGISTER_TOURNAMENT_SUCCESS = 'REGISTER_TOURNAMENT_SUCCESS'
export const REGISTER_TOURNAMENT_FAILED = 'REGISTER_TOURNAMENT_FAILED'
