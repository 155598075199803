import React from 'react'
import styles from './Profile.module.css'
import TopInfo from '../../Components/Profile/TopInfo/TopInfo'
import History from '../../Components/Profile/History/History'
import LevelProgress from '../../Components/Profile/LevelProgress/LevelProgress'

const Profile = () => {
    return (
        <div className={styles.profile_page} id='scroll'>
            <div className={styles.main_wrapper}>
                <div className={styles.wrapp_top}>
                    <TopInfo />

                    <div className={styles.profile_stats}>
                        <div className={styles.box_outer}>
                            <h1>Lost</h1>
                            <h2>72</h2>
                        </div>
                        <div className={styles.box_outer}>
                            <h1>Played</h1>
                            <h2>253</h2>
                        </div>
                        <div className={styles.box_outer}>
                            <h1>Won</h1>
                            <h2>153</h2>
                        </div>
                    </div>
                </div>

                <div className={styles.body_holder}>
                    <LevelProgress />

                    <History />
                </div>
            </div>
        </div>
    )
}

export default Profile
