import styles from './RulesSection.module.css'
import React from 'react'

const RulesSection = ({ tournament }) => {
    return (
        <ol className={`list-disc list-inside pt-2 ${styles.list}`}>
            <p className={`${styles.headingBig}`}>Tournament Type: No Limit Texas Hold'em</p>
            <p className={`${styles.headingSmall}`}>Registration</p>
            <p className={`${styles.para}`}>
                Players must register within the specified timeframe and pay the entry fee. Late registrations will not be accepted.
            </p>
            <p className={`${styles.headingSmall}`}>How to Enter Tounament</p>
            <p className={`${styles.para}`}> 
                Once the registration period concludes, the tournament will commence, and only registered players will have access. To enter the tournament, simply click on the "Join" button located in the top right corner of the tournament page. All players will be randomly assigned to tables, with each table accommodating a maximum of seven players. Players must enter the tournament within 10 minutes of its commencement; late entries will not be accepted.
            </p>
            <p className={`${styles.headingSmall}`}>Tournament Starting Time</p>
            <p className={`${styles.para}`}>
                The tournament will commence in 2 minutes, providing all players with an opportunity to join.
            </p>
            <p className={`${styles.headingSmall}`}>Starting Chips and Blind Levels</p>
            <p className={`${styles.para}`}>
                Each player begins with an initial stack of {tournament?.tournamentSpeed === 'Regular' ? 1000 : (tournament?.tournamentSpeed === 'Hyper' ? 750 : 500)} chips, and as the game unfolds, the blinds and minimum bidding amounts will steadily rise at set intervals.
            </p>
            <p className={`${styles.headingSmall}`}>Elimination</p>
            <p className={`${styles.para}`}>
                Players are eliminated from the tournament when they lose all their chips. The tournament carries on until only one player remains, who is then declared the winner.
            </p>
            <p className={`${styles.headingSmall}`}>Rebuys and Add-Ons</p>
            <p className={`${styles.para}`}>
                Players are not permitted to make any rebuys or addons during the tournament.
            </p>
            <p className={`${styles.headingSmall}`}>Rejoin and Disconnect Rules</p>
            <p className={`${styles.para}`}>
                If you get disconnected from the tournament due to a poor network connection or any other reason, you won't have the ability to rejoin the tournament.
            </p>
        </ol>
    )
}

export default RulesSection