import React from "react";
import "./button.css";

const Button = ({ type, text, icon, onClick }) => {
  return (
    <div
      className={type === "small" ? "button-small" : "button-medium"}
      onClick={() => {
        if(onClick) onClick();
        document.getElementById("button-audio").play();
      }}
    >
      <div
        className={
          type === "small"
            ? "button-bottom-layer-small"
            : "button-bottom-layer-medium"
        }
      >
        <div className="button-border-layer">
          {type === "small" ? (
            <div style={(text == 'Summary' || text == 'Leaderboard') ? { padding: '10px 16px 6px' } : {}} className="button-top-layer-small">
              {icon && <img src={icon} alt="" className="button-icon" />}
              {text && <div className="button-top-layer-small-text">{text}</div>}
            </div>
          ) : (
            <div className="button-top-layer-medium">{text}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Button;
