import styles from './CreateTableModal.module.css'
import React, { Fragment, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Input from '../../Input/Input'
// import FoxSwitch from '../../Switch/Switch'
import { useDispatch, useSelector } from 'react-redux'
import { createTableAction, saveTableState, updateTableAction } from '../../../../redux/actions/TableAction'
import { useNavigate } from 'react-router-dom'
import TablePreview from '../../../CommunitiesPage/TablePreview/TablePreview'
import TableTopCard from '../../../EditCommunity/Tables/TableTopCard/TableTopCard'
import { TableCardColorShades } from '../../../../colors'
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState } from 'react'
import { getRoles } from '../../../../redux/actions/otherAction'

const CreateTableModal = ({ openModal, setOpenModal, data, setData, tableData, communityId,  communitySlug, setTableData }) => {
    const navigate= useNavigate()
    const dispatch = useDispatch()
    const {loading} = useSelector((state) => state.createTableReducer)

    const [roles, setRoles] = useState([])
    const [hover, setHover] = useState(false)
    const cancelButtonRef = useRef(null)
    const {data:communityInfo} = useSelector((state) => state.getCommunityByIDReducer)
    const {table:storedTableData} = useSelector((state) => state.saveTableReducer)
    const {editTableInfo:edit} = useSelector((state) => state.editTableInfoReducer)

    const isDisabled = () =>{
        if(data.name === '' || data.coin === '' || data.maxPlayers === '' || !data?.cardPreview || data.maxChips === '' || data.minBid === '' || data.maxBid === ''){
            return true
        }
        return false
    }

    const successfullyCreated = () => {
        setOpenModal(false)
        setData({
            name: '',
            coin: '',
            cardPreview: communityInfo?.shortImage,
            minBid: 50,
            minBuyIn: 5000,
            holderOnly: false,
            nftRequired: '',
            rolesRequired: false,
            roleRequired: '',
            tableBackground: TableCardColorShades[0]
        })
    }

    useEffect(() => {
        if(edit){
            setData({
                name: edit?.tableName,
                coin: edit.chipName ? edit.chipName : "Play Chips",
                cardPreview: edit?.cardPreview,
                minBid: edit?.minBid,
                minBuyIn: edit?.minBuyIn,
                holderOnly: edit?.nftsRequired > 0 ? true : false,
                nftRequired: edit?.nftsRequired,
                rolesRequired: edit?.discordRoleRequired,
                roleRequired: edit?.roleRequired?.id,
                tableBackground: edit?.tableBackground
            })
        }else{
            setData({
                name: '',
                coin: '',
                cardPreview: communityInfo?.shortImage,
                minBid: 50,
                minBuyIn: 5000,
                holderOnly: false,
                nftRequired: '',
                rolesRequired: false,
                roleRequired: '',
                tableBackground: TableCardColorShades[0]
            })
        }
    },[edit])

    useEffect(() => {
        // if(edit){
        //     setData({
        //         name: edit?.tableName,
        //         coin: edit?.chipName,
        //         maxPlayers : edit?.maxPlayers,
        //         maxChips: edit?.maxChips,
        //         cardPreview: edit?.cardPreview,
        //         minBid: edit?.minBid,
        //         maxBid: edit?.maxBid,
        //         holderOnly: edit?.holderOnly,
        //         nftRequired: edit?.nftsRequired,
        //         rolesRequired: edit?.rolesRequired,
        //         roleRequired: edit?.roleRequired,
        //         tableBackground: edit?.tableBackground
        //     })
        // }else{
        //     setData({
        //         name: '',
        //         coin: '',
        //         cardPreview: null,
        //         maxPlayers :'',
        //         maxChips: '',
        //         minBid: '',
        //         maxBid: '',
        //         holderOnly: false,
        //         nftRequired: '',
        //         rolesRequired: false,
        //         roleRequired: '',
        //         tableBackground: TableCardColorShades[0]
        //     })
        // }

        if(storedTableData)
            setData({
                name: storedTableData?.name,
                coin: storedTableData?.coin,
                cardPreview: storedTableData?.cardPreview,
                minBid: storedTableData?.minBid,
                minBuyIn: storedTableData?.minBuyIn,
                holderOnly: storedTableData?.holderOnly,
                nftRequired: storedTableData?.nftRequired,
                rolesRequired: storedTableData?.rolesRequired,
                roleRequired: storedTableData?.roleRequired,
                tableBackground: storedTableData?.tableBackground
            })
    },[storedTableData])

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getRoles(setRoles, communityInfo?.serverId)
    },[communityInfo])

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0'
                initialFocus={cancelButtonRef}
                onClose={setOpenModal}
            >
                <div className='h-screen px-4 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[#0c0c0d9a] bg-opacity-75 backdrop-blur-[3px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block bg-transparent rounded-lg text-left transform transition-all my-8 align-middle max-w-[1030px] w-full'>
                            <div className={`${styles.modal_background} flex justify-start w-full items-center rounded-lg text-white h-full p-10 flex-col gap-y-8 border border-[#3D3D3D]`}>
                                <div className={styles.top_header}>
                                    <h1>{edit ? 'Update' : 'Create'} Table</h1>
                                </div>

                                <div className={styles.divider}></div>

                                <div className={styles.modal_main_cntnt}>
                                    <div className={styles.form_cntnt}>
                                        <Input label={'TABLE NAME'} value={data?.name} onChange={((e) => {setData((prev) => ({...prev, name: e?.substr(0, 25)}))})} />
                                        <Input select selectData={communityInfo?.chipName ? ['Play Chips', `${communityInfo?.chipName}`] : ['Play Chips']} label={'CHIP'} value={data?.coin} onChange={((e) => {setData((prev) => ({...prev, coin: e}))})} />
                                        {/* <Input label={'MAX PLAYERS'} value={data?.maxPlayers} onChange={((e) => {setData((prev) => ({...prev, maxPlayers: e}))})} /> */}
                                        {/* <Input label={'MAX CHIPS'} value={data?.maxChips} onChange={((e) => {setData((prev) => ({...prev, maxChips: e}))})} /> */}
                                        <Input label={'MINIMUM Buy in'} value={data?.minBuyIn} readonly={true} />

                                        <Input select
                                            discord
                                            label={'Minimum Bid'}
                                            value={data?.minBid}
                                            selectData={[
                                                {
                                                    id: 50,
                                                    name: '50/100'
                                                },
                                                {
                                                    id: 250,
                                                    name: '250/500'
                                                },
                                                {
                                                    id: 1000,
                                                    name: '1000/2000'
                                                },
                                            ]}
                                            onChange={((e) => {
                                                if(e === 50){
                                                    setData((prev) => ({...prev, minBuyIn: 5000}))
                                                }else if(e === 250){
                                                    setData((prev) => ({...prev, minBuyIn: 15000}))
                                                }else if(e === 1000){
                                                    setData((prev) => ({...prev, minBuyIn: 30000}))
                                                }
                                                setData((prev) => ({...prev, minBid: e}))
                                            })}
                                        />
                                        {/* <div className={styles.field_col}>
                                            <label>Minimum Bid</label>
                                            <select
                                                className={styles.select_input}
                                                value={data.minBid}
                                                onChange={((e) => {
                                                    console.log(e.target.value)
                                                    if(e.target.value === '100'){
                                                        setData((prev) => ({...prev, minBuyIn: 10000}))
                                                    }else if(e.target.value === '500'){
                                                        setData((prev) => ({...prev, minBuyIn: 25000}))
                                                    }else if(e.target.value === '2000'){
                                                        setData((prev) => ({...prev, minBuyIn: 50000}))
                                                    }
                                                    setData((prev) => ({...prev, minBid: e.target.value}))
                                                })}
                                            >
                                                <option value={100} className='bg-[#111]'>
                                                    50/100
                                                </option>
                                                <option value={500} className='bg-[#111]'>
                                                    250/500
                                                </option>
                                                <option value={2000} className='bg-[#111]'>
                                                    1000/2000
                                                </option>
                                            </select>
                                        </div> */}
                                        {/* <div className={styles.alin_items}>
                                            <Input label={'MAXIMUM BID'} value={data?.maxBid} onChange={((e) => {setData((prev) => ({...prev, maxBid: e}))})} />
                                        </div> */}
                                        <Input label={'no. of nfts required'} value={data?.nftRequired} onChange={((e) => {
                                            if(e !== ''){
                                                setData((prev) => ({...prev, holderOnly: true}))
                                            }else{
                                                setData((prev) => ({...prev, holderOnly: false}))
                                            }

                                            setData((prev) => ({...prev, nftRequired: e}))
                                        })} />

                                        <Input select
                                            discord
                                            label={'Discord Role'}
                                            value={data?.roleRequired}
                                            selectData={roles}
                                            onChange={((e) => {
                                                if(e !== ''){
                                                    setData((prev) => ({...prev, rolesRequired: true}))
                                                }else{
                                                    setData((prev) => ({...prev, rolesRequired: false}))
                                                }

                                                setData((prev) => ({...prev, roleRequired: e}))
                                            })}
                                        />

                                        {/* <div className={styles.field_col}>
                                            <label>Discord Role</label>
                                            <select
                                                className={styles.select_input}
                                                value={data.roleRequired}
                                                onChange={((e) => {
                                                    if(e.target.value !== ''){
                                                        setData((prev) => ({...prev, rolesRequired: true}))
                                                    }else{
                                                        setData((prev) => ({...prev, rolesRequired: false}))
                                                    }

                                                    setData((prev) => ({...prev, roleRequired: e.target.value}))
                                                })}
                                            >
                                                <option value={''} className='bg-[#111]'>
                                                    SELECT
                                                </option>

                                                {roles?.map((opt, key) => {
                                                    if(opt?.name !== '@everyone')
                                                        return <option key={key} value={opt?.id} className='bg-[#111]'>
                                                            {opt?.name}
                                                        </option>
                                                    else return ''
                                                })}
                                            </select>
                                        </div> */}

                                        {/* <div className={styles.divider}></div> */}
                                        {/* <div className={styles.alin_items}>
                                            <div className={styles.toggle_box_col}>
                                                <div className={styles.toggle_box}>
                                                    <h1>holders only</h1>
                                                    <FoxSwitch value={data?.holderOnly} onChange={((e) => {setData((prev) => ({...prev, holderOnly: e}))})} />
                                                </div>
                                                {data?.holderOnly && <Input label={'no. of nfts required'} value={data?.nftRequired} onChange={((e) => {setData((prev) => ({...prev, nftRequired: e}))})} />}
                                            </div>
                                            <div className={styles.toggle_box_col}>
                                                <div className={styles.toggle_box}>
                                                    <h1>discord role required</h1>
                                                    <FoxSwitch value={data?.rolesRequired} onChange={((e) => {setData((prev) => ({...prev, rolesRequired: e}))})}/>
                                                </div>
                                                // {data?.rolesRequired && <Input label={'roles required'} value={data.roleRequired} onChange={((e) => {setData((prev) => ({...prev, roleRequired: e}))})} />}
                                                {data?.rolesRequired && <select
                                                    className={styles.select_input}
                                                    value={data.roleRequired}
                                                    onChange={((e) => {setData((prev) => ({...prev, roleRequired: e.target.value}))})}
                                                >
                                                    <option value={''} className='bg-[#111]'>
                                                        SELECT
                                                    </option>

                                                    {roles?.map((opt, key) => {
                                                        if(opt?.name !== '@everyone')
                                                            return <option key={key} value={opt?.id} className='bg-[#111]'>
                                                                {opt?.name}
                                                            </option>
                                                        else return ''
                                                    })}
                                                </select>}
                                            </div>
                                        </div> */}
                                        {/* <div className={styles.divider}></div> */}

                                        {!edit && <button className="primary-custom-button" disabled={isDisabled() || loading} onClick={() => {
                                                dispatch(createTableAction(data, successfullyCreated, tableData, communityId, setTableData, roles, communityInfo?.slug))
                                                dispatch(saveTableState(null))
                                            }}
                                        >
                                            <div className={`${styles.create_game} primary-custom-button-inner`} >{loading ? 'Creating...' : 'Create Game'}</div>
                                        </button>}

                                        {edit && <button className="primary-custom-button" disabled={isDisabled() || loading} onClick={() => {
                                                dispatch(updateTableAction(data, successfullyCreated, tableData, communityId, setTableData, edit?._id, roles, communityInfo?.slug))
                                                dispatch(saveTableState(null))
                                            }}
                                        >
                                            <div className={`${styles.create_game} primary-custom-button-inner`} >{loading ? 'Updating...' : 'Update Table'}</div>
                                        </button>}
                                    </div>

                                    <div className='flex flex-col gap-10'>
                                        <div className={styles.side_preview}>
                                            <div className={styles.preview_wrapper}>
                                                <label htmlFor="" className={styles.tablprvw}>Card Preview</label>
                                                <div className={styles.card_preview_box}>
                                                    <TableTopCard openModal={openModal} table={data} img={data?.cardPreview} edit setImage={(e) => setData((prev) => ({...prev, cardPreview: e}))} communityInfo={communityInfo} />
                                                </div>
                                                <div className={styles.wrapp_colors}>
                                                    {TableCardColorShades?.map((item, key) => (
                                                        <div className={`${styles.wrap_color} ${key=== data?.tableBackground?.id ? styles.selected : ''}`} key={key} onClick={() => setData((prev) => ({...prev, tableBackground: item}))}>
                                                            {/* <Tooltip title={
                                                                <div className='flex flex-col gap-1'>
                                                                    <div className='flex items-center gap-1'>
                                                                        <div className={styles.wrap_color_inner} style={{background: item?.backgroundColor}}></div>
                                                                        <div className={styles.wrap_color_inner} style={{background: item?.backgroundColor}}></div>
                                                                    </div>
                                                                    <div className='flex items-center gap-1'>
                                                                        <div className={styles.wrap_color_inner} style={{background: item?.backgroundColor}}></div>
                                                                        <div className={styles.wrap_color_inner} style={{background: item?.backgroundColor}}></div>
                                                                    </div>
                                                                </div>
                                                            } arrow> */}
                                                                <div className={styles.wrap_color_inner} style={{background: item?.backgroundColor}}></div>
                                                            {/* </Tooltip> */}
                                                            {/* <HtmlTooltip title={''}/> */}
                                                            <Menu
                                                                id="demo-positioned-menu"
                                                                aria-labelledby="demo-positioned-button"
                                                                anchorEl={anchorEl}
                                                                open={open}
                                                                onClose={handleClose}
                                                                anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                                }}
                                                                transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                                }}
                                                            >
                                                                {/* <div>XYZ</div> */}
                                                                <MenuItem onClick={handleClose}>Profile</MenuItem>
                                                            </Menu>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.side_preview}>
                                            <div className={styles.preview_wrapper}>
                                                <label htmlFor="" className={styles.tablprvw}>Table Preview</label>
                                                <div className={styles.preview_box}>
                                                    <TablePreview tableData={tableData} edit={edit} setTableData={setTableData} />
                                                </div>
                                            </div>
                                            <button onClick={() => {
                                                    dispatch(saveTableState(data))
                                                    navigate(edit ? `/${communitySlug}/updateTable` : `/${communitySlug}/editTable`);
                                                    setOpenModal(false)}
                                                }
                                                onMouseEnter={() => setHover(true)}
                                                onMouseLeave={() => setHover(false)}
                                                className='secondary-custom-button'
                                            >
                                                <div
                                                    className={`secondary-custom-button-inner ${styles.edit_table}`}
                                                >
                                                    Edit table <ArrowRight fill={hover ? '#D93D48' : 'white'} />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default React.memo(CreateTableModal)

const ArrowRight = ({fill}) =>{
    return(
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
        <path d="M8.36719 15.5L13.3672 10.5L8.36719 5.5" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
    )
}