import React from "react";
import "./TableLeaderboard.css";
import emptySummary from '../../Assets/svgs/empty_summary.svg'

const TableLeaderboard = ({ SetopenLeaderboard, leaderboardData, chipImg }) => {
  return (
    <div className="leaderboard-modal">
    <div className="leaderboard-header">
      <div className="leaderboard-header-text">Leaderboard</div>
      <button
        onClick={() => SetopenLeaderboard(false)}
        >
        <CloseIcon />
      </button>
    </div>

    <div style={{ width: '100%', height: '1px', background: 'rgb(255 255 255 / 10%)', margin: '17px 0 15px 0' }}></div>

    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', color: 'white', width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid rgb(255 255 255 / 20%)', borderRadius: '6px', padding: '4px 10px', color: 'rgb(255 255 255 / 50%)', fontFamily: 'Poppins', fontSize: '14px', fontStyle: 'normal', fontWeight: 400 }}>
            <h2 style={{ width: '10%', textAlign: 'left' }}></h2>
            <h2 style={{ width: '45%', textAlign: 'left' }}>User</h2>
            <h2 style={{ width: '45%', textAlign: 'right' }}>Holdings</h2>
        </div>
        {leaderboardData?.length > 0 && leaderboardData.map((player) => {
          return (<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid rgb(255 255 255 / 20%)', borderRadius: '6px', padding: '4px 10px' }}>
              <h2 style={{ width: '10%', textAlign: 'left' }}><img style={{ width: '20px' }} src={"https://res.cloudinary.com/kuramaverse/image/upload/v1695654184/goldenCup_dfacpu.svg"} /></h2>
              <div style={{ width: '45%', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '40px', height: '40px', marginRight: '7px' }}>
                    <img src={player?.image} style={{ borderRadius: '6px' }} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <h2 style={{ fontFamily: 'Orbitron', fontSize: '16px' }}>{player?.username}</h2>
                      <h2 style={{ fontFamily: 'Orbitron', fontSize: '12px', color: 'rgba(255, 255, 255, 0.7)' }}>Table {player?.tableNumber}</h2>
                  </div>
              </div>
              <h2 style={{ width: '45%', textAlign: 'right', fontFamily: 'Orbitron', fontSize: '18px', display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>{player?.chips} <img style={{ width: '20px' }} src={chipImg} /></h2>
          </div>
      </div>)
        })}
    </div>

    {(leaderboardData?.length == 0) && <div style={{ marginTop: '20px' }} className="load-empty-leaderboard-part">
      <img src={emptySummary} alt="" />
      <h1>Game Summary appears here</h1>
    </div>}
  </div>
  );
};

export default TableLeaderboard;

const CloseIcon = () => {
  return(<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_2431_44127" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
  <rect x="0.000488281" y="0.5" width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_2431_44127)">
  <path d="M6.40049 19.5L5.00049 18.1L10.6005 12.5L5.00049 6.9L6.40049 5.5L12.0005 11.1L17.6005 5.5L19.0005 6.9L13.4005 12.5L19.0005 18.1L17.6005 19.5L12.0005 13.9L6.40049 19.5Z" fill="#EEEEEE"/>
  </g>
  </svg>
  )
}
