import axios from "axios"
import { BACKEND_URL, ErrorMessage } from "../../constants"
import { store } from "../../store"
import { CREATE_TABLE_FAILED, CREATE_TABLE_REQUEST, CREATE_TABLE_SUCCESS, GET_TABLES_FAILED, GET_TABLES_REQUEST, GET_TABLES_SUCCESS, GET_TABLE_FAILED, GET_TABLE_REQUEST, GET_TABLE_SUCCESS, SAVE_STATE_TABLE, SAVE_STATE_TABLE_ASSETS, SAVE_STATE_TOURNAMENT_TABLE } from "../constants/TableConstant"
import { uploadToCloudinary1000, uploadToCloudinary500 } from "./CommunityAction"

export const updateTableAction = (tableData, successfullyCreated, tableDesign, communityId, setTableData, tableId, roles, slug) =>
    async (dispatch) => {
        try {
            dispatch({ type: CREATE_TABLE_REQUEST })
            const userLoginReducer = store.getState().userLoginReducer
            const token = userLoginReducer?.accessToken

            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + token,
                },
            }

            const cardPreviewUrl = typeof tableData?.cardPreview !== 'string' ? await uploadToCloudinary500(tableData?.cardPreview) : tableData?.cardPreview
            const backgroundImg = typeof tableDesign?.background !== 'string' ? await uploadToCloudinary1000(tableDesign?.background) : tableDesign?.background
            const carpetImg = typeof tableDesign?.carpet !== 'string' ? await uploadToCloudinary1000(tableDesign?.carpet) : tableDesign?.carpet
            const materialImg = typeof tableDesign?.material !== 'string' ? await uploadToCloudinary1000(tableDesign?.material) : tableDesign?.material
            const backdropImg = typeof tableDesign?.backdrop !== 'string' ? await uploadToCloudinary1000(tableDesign?.backdrop) : tableDesign?.backdrop
            const logoImg = typeof tableDesign?.logo !== 'string' ? await uploadToCloudinary500(tableDesign?.logo) : tableDesign?.logo
            const dealerImg = typeof tableDesign?.dealer !== 'string' ? await uploadToCloudinary500(tableDesign?.dealer) : tableDesign?.dealer
            const cardImg = typeof tableDesign?.card !== 'string' ? await uploadToCloudinary500(tableDesign?.card) : tableDesign?.card

            const { data } = await axios.post(
                `${BACKEND_URL}/community/updateTable`,
                {
                    tableName: tableData?.name,
                    tableId,
                    communityId,
                    serverId: tableData?.serverId,
                    holdersOnly: tableData?.holdersOnly,
                    activePlayers: tableData?.activePlayers,
                    nftsRequired: tableData?.nftRequired,
                    roleRequired: roles && roles.find((ele) => ele.id === tableData?.roleRequired),
                    theme: tableData?.theme,
                    backgroundImage: backgroundImg,
                    minBid: tableData?.minBid,
                    minBuyIn: tableData?.minBuyIn,
                    nftNumber: tableData?.nftNumber,
                    discordRoleRequired: tableData?.rolesRequired,
                    chipName: tableData?.coin,
                    cardPreview: cardPreviewUrl,
                    tableBackground: tableData?.tableBackground,
                    cardImage: cardImg,
                    carpetImage: carpetImg,
                    backdropImage: backdropImg,
                    materialImage: materialImg,
                    logoImage: logoImg,
                    dealerImage: dealerImg,
                    verificationHash: userLoginReducer?.verificationHash,
                },
                config
            )
            if (data) {
                dispatch({
                    type: CREATE_TABLE_SUCCESS,
                    payload: data.msg,
                })

                if(successfullyCreated) successfullyCreated()
                dispatch(getTables(slug))
                setTableData({
                    background: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358666/floor_fbext1.webp',
                    card: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358012/image_291_xrljhr.webp',
                    logo: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358013/image_282_mgqzo2.webp',
                    backdrop: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358013/backdrop_iz8hif.webp',
                    dealer: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358013/image-removebg-preview_1_zcbmln.webp',
                    carpet: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358016/inside_fyosub.webp',
                    material: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358017/table_final_iuove4.webp',
                })
                // navigate('/')
            } else
                dispatch({
                    type: CREATE_TABLE_FAILED,
                    payload: data.msg,
                })
        } catch (error) {
            dispatch({
                type: CREATE_TABLE_FAILED,
                payload: ErrorMessage(error),
            })
        }
    }

export const createTableAction = (tableData, successfullyCreated, tableDesign, communityId, setTableData, roles, slug) =>
    async (dispatch) => {
        try {
            dispatch({ type: CREATE_TABLE_REQUEST })
            const userLoginReducer = store.getState().userLoginReducer
            const token = userLoginReducer?.accessToken

            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + token,
                },
            }

            const cardPreviewUrl = typeof tableData?.cardPreview !== 'string' ? await uploadToCloudinary500(tableData?.cardPreview) : tableData?.cardPreview
            const backgroundImg = typeof tableDesign?.background !== 'string' ? await uploadToCloudinary1000(tableDesign?.background) : tableDesign?.background
            const carpetImg = typeof tableDesign?.carpet !== 'string' ? await uploadToCloudinary1000(tableDesign?.carpet) : tableDesign?.carpet
            const materialImg = typeof tableDesign?.material !== 'string' ? await uploadToCloudinary1000(tableDesign?.material) : tableDesign?.material
            const backdropImg = typeof tableDesign?.backdrop !== 'string' ? await uploadToCloudinary1000(tableDesign?.backdrop) : tableDesign?.backdrop
            const logoImg = typeof tableDesign?.logo !== 'string' ? await uploadToCloudinary500(tableDesign?.logo) : tableDesign?.logo
            const dealerImg = typeof tableDesign?.dealer !== 'string' ? await uploadToCloudinary500(tableDesign?.dealer) : tableDesign?.dealer
            const cardImg = typeof tableDesign?.card !== 'string' ? await uploadToCloudinary500(tableDesign?.card) : tableDesign?.card

            const { data } = await axios.post(
                `${BACKEND_URL}/community/createTable`,
                {
                    tableName: tableData?.name,
                    communityId,
                    serverId: tableData?.serverId,
                    holdersOnly: tableData?.holdersOnly,
                    activePlayers: tableData?.activePlayers,
                    nftsRequired: tableData?.nftRequired,
                    roleRequired: roles && roles.find((ele) => ele.id === tableData?.roleRequired),
                    theme: tableData?.theme,
                    backgroundImage: backgroundImg,
                    minBid: tableData?.minBid,
                    minBuyIn: tableData?.minBuyIn,
                    cardPreview: cardPreviewUrl,
                    tableBackground: tableData?.tableBackground,
                    nftNumber: tableData?.nftNumber,
                    discordRoleRequired: tableData?.rolesRequired,
                    chipName: tableData?.coin,
                    cardImage: cardImg,
                    carpetImage: carpetImg,
                    backdropImage: backdropImg,
                    materialImage: materialImg,
                    logoImage: logoImg,
                    dealerImage: dealerImg,
                    verificationHash: userLoginReducer?.verificationHash,
                },
                config
            )
            if (data) {
                dispatch({
                    type: CREATE_TABLE_SUCCESS,
                    payload: data.msg,
                })

                if(successfullyCreated) successfullyCreated()
                dispatch(getTables(slug))
                setTableData({
                    background: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358666/floor_fbext1.webp',
                    card: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358012/image_291_xrljhr.webp',
                    logo: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358013/image_282_mgqzo2.webp',
                    backdrop: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358013/backdrop_iz8hif.webp',
                    dealer: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358013/image-removebg-preview_1_zcbmln.webp',
                    carpet: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358016/inside_fyosub.webp',
                    material: 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1689358017/table_final_iuove4.webp',
                })
                // navigate('/')
            } else
                dispatch({
                    type: CREATE_TABLE_FAILED,
                    payload: data.msg,
                })
        } catch (error) {
            dispatch({
                type: CREATE_TABLE_FAILED,
                payload: ErrorMessage(error),
            })
        }
    }

export const deleteTable = async(slug, dispatch, tableId) =>{
    try {
        const userLoginReducer = store.getState().userLoginReducer
        const token = userLoginReducer?.accessToken

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
            },
        }

        const { data } = await axios.post(
            `${BACKEND_URL}/community/deleteTable`,
            {
                tableId,
                verificationHash: userLoginReducer?.verificationHash,
            },
            config
        )
        if (data) {
            dispatch(getTables(slug))
        }
    } catch (error) {
    }
}

export const getTables = (communityId) => async (dispatch) => {
    try {
        dispatch({ type: GET_TABLES_REQUEST })
        const userLoginReducer = store.getState().userLoginReducer
        const token = userLoginReducer?.accessToken

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
            },
        }

        const { data } = await axios.get(
            `${BACKEND_URL}/community/getTables/${communityId}`,
            config
        )
        if (data) {
            let arr = data?.tournaments
            let arr2 = data?.tables
            arr.reverse()
            arr2.reverse()
            dispatch({
                type: GET_TABLES_SUCCESS,
                payload: arr2,
                tournaments: arr
            })
        } else
            dispatch({
                type: GET_TABLES_FAILED,
                payload: data.msg,
            })
    } catch (error) {
        dispatch({
            type: GET_TABLES_FAILED,
            payload: ErrorMessage(error),
        })
    }
}

export const getTable = (tableId) => async (dispatch) => {
    try {
        dispatch({ type: GET_TABLE_REQUEST })
        const userLoginReducer = store.getState().userLoginReducer
        const token = userLoginReducer?.accessToken

        const config = {
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
            },
        }

        const { data } = await axios.post(
            `${BACKEND_URL}/community/getTable`,
            {
                verificationHash: userLoginReducer?.verificationHash,
                tableId
            },
            config
        )
        if (data && data?.table) {
            dispatch({
                type: GET_TABLE_SUCCESS,
                payload: data?.table,
            })
        } else
            dispatch({
                type: GET_TABLE_FAILED,
                payload: data.msg,
            })
    } catch (error) {
        dispatch({
            type: GET_TABLE_FAILED,
            payload: ErrorMessage(error),
        })
    }
}

export const saveTableState = (data) => async (dispatch) => {
    const {tableData} = store.getState().saveTableReducer

    dispatch({
        type: SAVE_STATE_TABLE,
        table: data,
        tableData
    })
}

export const saveTournamentTableState = (data) => async (dispatch) => {
    const {tableData} = store.getState().saveTableReducer

    dispatch({
        type: SAVE_STATE_TOURNAMENT_TABLE,
        tournament: data,
        tableData
    })
}

export const saveTableDataState = (data) => async (dispatch) => {
    const {table} = store.getState().saveTableReducer

    dispatch({
        type: SAVE_STATE_TABLE_ASSETS,
        tableData: data,
        table
    })
}