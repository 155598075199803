import React, { useState } from 'react'
import styles from './CreateComunity.module.css'
import CommunityBar from '../../Components/CreateComunity/CommunityBar/CommunityBar'
import CommunityPreview from '../../Components/CreateComunity/CommunityPreview/CommunityPreview'
import CommunityInfo from '../../Components/CreateComunity/CommunityInfo/CommunityInfo'
import TableTemplate from '../../Components/CreateComunity/TableTemplate/TableTemplate'
import SetupCoins from '../../Components/CreateComunity/SetupCoins/SetupCoins'
import CreateGame from '../../Components/CreateComunity/CreateGame/CreateGame'
import { SwipeableDrawer } from '@mui/material'
import SetupCoinDrawer from '../../Components/CreateComunity/SetupCoinDrawer/SetupCoinDrawer'
import { useDispatch } from 'react-redux'
import { createCommunityAction } from '../../redux/actions/CommunityAction'
import { useNavigate } from 'react-router-dom'

const CreateComunity = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [tabNumber, setTabNumber] = useState(1)
    const [open, toggleDrawer] = useState(false)
    const [communityInfo, setCommunityInfo] = useState({
        // projectName: "",
        communitySlug: "",
        communityName: "",
        backgroundImage: ""
    })
    const [tableTemplate, setTableTemplate] = useState({
        background: 1,
        dealersImage: "",
        backgroundSymbol: "",
        tableFrameMaterial: 1,
        tableFrameSurface: 1,
    })
    const [createGame, setCreateGame] = useState({
        tableName: "",
        tableMascott: "",
        minimumBid: "",
        maximumBid: "",
        entryFees: "",
        maxPlayers: "",
    })
    const [coinSetup, setCoinSetup] = useState('')
    const [setupData, setSetupData] = useState({
        collectionName: '',
        description: '',
        websiteLink: '',
        twitterLink: '',
        discordLink: '',
        email: '',
    })

    const createCommunity = () =>{
        dispatch(createCommunityAction(communityInfo, tableTemplate, createGame, coinSetup, setupData, navigate))
    }

    return (
        <div className={styles.create_community}>
            <div className={styles.main_wrapper}>
                <div style={{height: '100%', paddingBottom: '50px'}}>
                    <CommunityBar setTabNumber={setTabNumber} tabNumber={tabNumber}/>
                </div>

                {tabNumber === 1 && <CommunityInfo setTabNumber={setTabNumber} info={communityInfo} setInfo={setCommunityInfo} />}
                {tabNumber === 2 && <TableTemplate setTabNumber={setTabNumber} info={tableTemplate} setInfo={setTableTemplate} />}
                {tabNumber === 3 && <SetupCoins setTabNumber={setTabNumber} toggleDrawer={toggleDrawer} setInfo={setCoinSetup} info={coinSetup} />}
                {tabNumber >= 4 && <CreateGame setTabNumber={setTabNumber} info={createGame} setInfo={setCreateGame} createCommunity={createCommunity} />}

                <div style={{height: '100%', paddingBottom: '50px'}}>
                    <CommunityPreview tabNumber={tabNumber} communityInfo={communityInfo} tableTemplate={tableTemplate} createGame={createGame} coinSetup={coinSetup} setupData={setupData} />
                </div>
            </div>

            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={(e) => toggleDrawer(false)}
                transitionDuration={300}
                onOpen={(e) => toggleDrawer(true)}
                className='bg-[#0C0C0CB2] transition-all ease-in-out backdrop-blur-md'
                PaperProps={{
                    sx: {
                        backdropFilter: 'blur(50px)',
                        borderRadius: '0 !important',
                        color: 'white',
                        height: '100%',
                        width: '800px',
                        background: '#0F0F0F',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        padding: '20px',
                        '@media(max-width: 800px)': {
                            width: '100%',
                        },
                    },
                }}
                ModalProps={{
                    sx: {
                        zIndex: '1280',
                    },
                }}
            >
                {toggleDrawer && <SetupCoinDrawer data={setupData} setData={setSetupData} toggleDrawer={toggleDrawer} />}
            </SwipeableDrawer>
        </div>
    )
}

export default CreateComunity
