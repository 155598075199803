import React from 'react'
import styles from './TournamentCard.module.css'
import award_1 from '../../../Assets/svgs/award_1.svg'
import award_2 from '../../../Assets/svgs/award_2.svg'
import award_3 from '../../../Assets/svgs/award_3.svg'

const TournamentCard = ({prize, id}) => {
    return (
        <div className={styles[`outer_card_${id+1}`]}>
            <div className={styles[`inner_card`]}>
                <figure className={styles.fig_wrap}>
                    <img src={prize?.prizeImg || 'https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1695829008/Frame_1814105410_pffmvu.webp'} alt="" className='w-full h-full object-cover'/>

                    <img className={styles.abst_award} src={id === 0 ? award_1 : id === 1 ? award_2 : award_3} alt="" />
                </figure>

                <h1 className={styles.title}>{prize?.prizeName}</h1>

                <div className={styles.more_info}>
                    <h2>Worth:</h2>
                    <h2>{prize?.prizeAmount ? '$' + prize?.prizeAmount : '--'}</h2>
                </div>
            </div>
        </div>
    )
}

export default TournamentCard