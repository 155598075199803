import React from 'react'

const CreateAboveCircleSvg = () => {
    return (
        <svg
            width='110'
            height='110'
            viewBox='0 0 110 110'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter='url(#filter0_i_1044_14514)'>
                <circle cx='55' cy='55' r='55' fill='black' />
            </g>
            <circle
                cx='55'
                cy='55'
                r='54.5'
                stroke='url(#paint0_linear_1044_14514)'
            />
            <path
                d='M53.1429 74.9961C52.5906 74.9961 52.1429 74.5484 52.1429 73.9961V58.8532C52.1429 58.301 51.6951 57.8532 51.1429 57.8532H36C35.4477 57.8532 35 57.4055 35 56.8532V53.139C35 52.5867 35.4477 52.139 36 52.139H51.1429C51.6951 52.139 52.1429 51.6912 52.1429 51.139V35.9961C52.1429 35.4438 52.5906 34.9961 53.1429 34.9961H56.8571C57.4094 34.9961 57.8571 35.4438 57.8571 35.9961V51.139C57.8571 51.6912 58.3049 52.139 58.8571 52.139H74C74.5523 52.139 75 52.5867 75 53.139V56.8532C75 57.4055 74.5523 57.8532 74 57.8532H58.8571C58.3049 57.8532 57.8571 58.301 57.8571 58.8532V73.9961C57.8571 74.5484 57.4094 74.9961 56.8571 74.9961H53.1429Z'
                fill='#FF0000'
                fillOpacity='0.7'
            />
            <defs>
                <filter
                    id='filter0_i_1044_14514'
                    x='0'
                    y='0'
                    width='110'
                    height='110'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation='10' />
                    <feComposite
                        in2='hardAlpha'
                        operator='arithmetic'
                        k2='-1'
                        k3='1'
                    />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0.952941 0 0 0 0 0.188235 0 0 0 0 0.188235 0 0 0 0.4 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='shape'
                        result='effect1_innerShadow_1044_14514'
                    />
                </filter>
                <linearGradient
                    id='paint0_linear_1044_14514'
                    x1='-5.74138e-07'
                    y1='-5.38815'
                    x2='135.544'
                    y2='72.1353'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#F13636' />
                    <stop offset='1' stopColor='#FF0000' />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default CreateAboveCircleSvg
