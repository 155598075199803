import React, { useEffect, useState } from "react";
import "./dashboardNav.css";
import coins from "../../Assets/Images/coins.svg";
import gameCoin from "../../Assets/Images/gameCoin.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginModal from "../shared/modals/LoginModal/LoginModal";
import { useSelector } from "react-redux";
import ExchangeModal from "../shared/modals/ExchangeModal/ExchangeModal";
import CreateProfileModal from "../shared/modals/CreateProfile/CreateProfile";
import { ArrowBack } from "@mui/icons-material";
import PlayCoins from "../shared/modals/PlayCoins/PlayCoins";
import CreateGuestModal from "../shared/modals/CreateGuestModal/CreateGuestModal";

const DashboardNav = ({backUrl: propsBackUrl, title: propsTitle, disabled}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const {allChips} = useSelector((state) => state.getUserCoinsForArcadeVerseReducer)
  const { isLogin, userInfo } = useSelector((state) => state.userLoginReducer)
  const { title, backUrl } = useSelector((state) => state.NavbarReduer)
  const [active, setActive] = useState("dashboard");

  const fix = true
  // const [fix, setFix] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [dashBlink, setDashBlink] = useState(true);
  const [exchangeModal, setExchangeModal] = useState(false);
  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [checkOnlyWallet, setCheckOnlyWallet] = useState(false);
  const [playCoinsModal, setPlayCoinsModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  // function checkScrollDirectionIsUp(event) {
  //   if (event.wheelDelta) {
  //     return event.wheelDelta > 0;
  //   }
  //   return event.deltaY < 0;
  // }

  const getCurrentChip = () => {
    const arr = allChips.filter((chip) => chip?.slug === title)
    return arr?.length > 0 ? arr[0] : {}
  }

  useEffect(() => {
    setTimeout(() => {
      setDashBlink(false)
    }, 6000)
  },[])

  // useEffect(() => {
  //   try{
  //     function checkScrollDirection(event) {
  //       if (checkScrollDirectionIsUp(event)) {
  //         setFix(true);
  //       } else {
  //         setFix(false);
  //       }
  //     }

  //     document?.getElementById("scroll")?.addEventListener("wheel", checkScrollDirection);
  //   }catch(err){
  //     console.log(err)
  //   }
  // },[]);

  useEffect(() => {
    if (active === "dashboard") {
      if(document.getElementById("glow")) document.getElementById("glow").style.transform = isLogin ? "translateX(0)" : "translateX(34px)";
      // setTranslate((prev) => (prev - ))
    } else if (active === "community") {
      if(document.getElementById("glow")) document.getElementById("glow").style.transform = isLogin ? "translateX(160px)" : "translateX(194px)";
    } else if (active === "leaderboard") {
      if(document.getElementById("glow")) document.getElementById("glow").style.transform = isLogin ? "translateX(320px)" : "translateX(354px)";
    } else if (active === "profile") {
      if(document.getElementById("glow")) document.getElementById("glow").style.transform = isLogin ? "translateX(480px)": "translateX(504px)";
    }
  }, [active, isLogin]);

  useEffect(() => {
    const switchNavigationPage = () => {
        const current = location.pathname.split('/')[1]

        switch (current) {
            case '':
              setActive("")
                return
            case 'dashboard':
              setActive("dashboard")
                return
            case 'community':
              setActive("community")
                return
            case 'tournament':
              setActive("community")
                return
            case 'leaderboard':
              setActive("leaderboard")
                return
            case 'profile':
              setActive("profile")
                return
            default:
              setActive("")
                return
        }
    }

    return switchNavigationPage()
  }, [location])

  if(location.pathname.split('/')[2] && active !== 'community'){
    return
  }

  return (
    <div className={!fix ? "dash-nav-fixed" : "dash-nav"} style={{position: disabled ? 'unset' : '',}}>
      <Link to={disabled ? '#' : '/'} className="dash-logo-equalizer" style={{width: disabled ? 'auto' : '', cursor: disabled ? 'default' : ''}}>
        <img src={"https://res.cloudinary.com/kuramaverse/image/upload/v1695756520/ArcadeVerse_Logo_vujobz.svg"} alt="poker" className="dash-logo" />
      </Link>

      <div className="" style={{padding: '2px 100px 0px 100px', position: 'absolute', width: '705px', top: '-4px', height: '61px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: `url(${'https://res.cloudinary.com/kuramaverse/image/upload/v1694892443/Group_1000002711_mkwqte.svg'}) no-repeat`, backgroundSize: 'contain', paddingBottom: '4px', left: '50%', transform: 'translate(-50%)' }}>
        <button onClick={()=>{if(!disabled) navigate(backUrl || propsBackUrl || -1)}} style={{color: "#D93D48", zIndex: 2, cursor: disabled ? 'default' : ''}}>
          <ArrowBack />
        </button>
        <h2 className="title_community_name">{title || propsTitle}</h2>
      </div>

      {isLogin && <div className="dash-utils">
        {getCurrentChip()?.slug && <div className="dash-utils-flex-2 cursor-pointer" onClick={() => {if(!disabled) setExchangeModal(true)}} style={{cursor: disabled ? 'default' : ''}}>
          <img src={getCurrentChip()?.chipImg || gameCoin} alt="coins" className="dash-utils-img" />
          <div className="dash-utils-text">{(getCurrentChip()?.value > 1000000 ? (Math.floor(getCurrentChip()?.value / 1000)) + 'K' : getCurrentChip()?.value) || 0}</div>
        </div>}
        <div className={Date.now() - userInfo?.lastClaimed > 14400000 && dashBlink ? `dash-utils-flex-1-lighter` : ''}>
          <div className="dash-utils-flex-1 cursor-pointer" onClick={() => {if(!disabled) setPlayCoinsModal(true)}} style={{cursor: disabled ? 'default' : '', border: Date.now() - userInfo?.lastClaimed > 14400000 && dashBlink ? '1.5px solid #F2BD01' : '', overflow: Date.now() - userInfo?.lastClaimed > 14400000 ? '' : 'hidden'}}>
            <img src={coins} alt="coins" className="dash-utils-img" />
            <div className="dash-utils-text">{userInfo?.playChips > 1000000 ? (Math.floor(userInfo?.playChips / 1000)) + 'K' : userInfo?.playChips}</div>
            {Date.now() - userInfo?.lastClaimed < 14400000 && <div className="dash-utils-flex-1-bottom-line" style={{width: `${((Date.now() - userInfo?.lastClaimed) / 14400000) * 100}%`}}></div>}
          </div>
        </div>
      </div>}

      {!isLogin && <button className='login-button' onClick={() => {if(!disabled) setOpenModal(true)}}>
        <div className="login-button-inner">
          Connect
        </div>
      </button>}

      {playCoinsModal && !disabled && <PlayCoins setCheckOnlyWallet={setCheckOnlyWallet} setOpenLoginModal={setOpenModal} openModal={playCoinsModal} setOpenModal={() => {setPlayCoinsModal(false)}} />}
      {exchangeModal && !disabled && <ExchangeModal openModal={exchangeModal} setOpenModal={() => {setExchangeModal(false)}} />}
      {openModal && !disabled && <LoginModal backUrl={location.pathname} checkOnlyWallet={checkOnlyWallet} setOpenGuestModal={setOpenGuestModal} setOpenProfileModal={setOpenCreateModal} openModal={openModal} setOpenModal={() => {setOpenModal(false)}} />}
      {openGuestModal && <CreateGuestModal setOpenConnectModal={setOpenModal} openModal={openGuestModal} setOpenModal={setOpenGuestModal} setOpenCreateModal={setOpenCreateModal} />}
      {openCreateModal && <CreateProfileModal openModal={openCreateModal} setOpenModal={setOpenCreateModal} />}
    </div>
  );
};

export default DashboardNav;
