import { useSelector } from 'react-redux'
// import prev from '../../../Assets/svgs/commu_preview.svg'
import styles from './PreviewSection.module.css'
import React from 'react'
// import CommunityPlayers from './../../Community/CommunirtyPlayers/CommunityPlayers';
// import Online from './../../Community/Online/Online';
// import LiveEvents from './../../Community/LiveEvents/LiveEvents';
// import Activity from './../../Community/Activity/Activity';
// import MaxWinning from './../../Community/MaxWinning/MaxWinning';

const PreviewSection = ({updateCommunity, validateCreation, setOpenPreviewModal, communityInfo}) => {
    const {loading} = useSelector((state) => state.createCommunityReducer)

    return <div className={styles.preview_container}>
        <span className={styles.label_heading}>Page PREVIEW</span>

        <div className={styles.community} id='scroll'
            style={{
                backgroundImage: typeof communityInfo?.backgroundImages[communityInfo?.backgroundImageId  - 1] !== 'object' ? `linear-gradient(0deg, ${communityInfo?.themeColour?.backgroundColor + '33'} 0%, ${communityInfo?.themeColour?.backgroundColor+ '33'} 100%), url(${communityInfo?.backgroundImages[communityInfo?.backgroundImageId  - 1] || ''})` : `linear-gradient(0deg, ${communityInfo?.themeColour?.backgroundColor + '33'} 0%, ${communityInfo?.themeColour?.backgroundColor+ '33'} 100%), url(${URL.createObjectURL(communityInfo?.backgroundImages[communityInfo?.backgroundImageId  - 1])})`
            }}
        >
            <div className={styles.top_header_community}
                style={{
                    backgroundImage: typeof communityInfo?.pageBanners[communityInfo?.pageBannerId  - 1] !== 'object' ? `url(${communityInfo?.pageBanners[communityInfo?.pageBannerId  - 1]})` : `url(${URL.createObjectURL(communityInfo?.pageBanners[communityInfo?.pageBannerId  - 1])})`
                }}
            >
                <div className={styles.top_text_wrappper}>
                    <div className={styles.top_text_back}>
                        <h1>{communityInfo?.communityName}</h1>
                    </div>
                </div>
            </div>

            <div className={styles.main_wrapper}>
                <img src={`https://res.cloudinary.com/kuramaverse/image/upload/g_xy_center,q_auto:best/v1690561992/asd_m4jwh9.webp`} alt='' className='w-[80%]'/>
            </div>
        </div>

        <button className="secondary-custom-button w-full" onClick={() => setOpenPreviewModal(true)}>
            <div className={`secondary-custom-button-inner ${styles.preview_btn}`}>
                View full preview
            </div>
        </button>

        <button className="secondary-custom-button w-full" disabled={!validateCreation() || loading} onClick={updateCommunity}>
            <div className={`secondary-custom-button-inner ${styles.preview_btn}`}>
                {loading ? 'Updating...' : 'Update Community'}
            </div>
        </button>
    </div>
}

export default React.memo(PreviewSection)
