import styles from './CreateTournamentModal.module.css'
import React, { Fragment, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import uploadSvg from '../../../../Assets/svgs/upload.svg'
import Input from '../../Input/Input'
import { useDispatch, useSelector } from 'react-redux'
import { saveTableState } from '../../../../redux/actions/TableAction'
import { useNavigate } from 'react-router-dom'
import TablePreview from '../../../CommunitiesPage/TablePreview/TablePreview'
import { TableCardColorShades } from '../../../../colors'
import { useState } from 'react'
import { getRoles } from '../../../../redux/actions/otherAction'
import { createTournament } from '../../../../redux/actions/TournamentAction'
import EventCard from './../../../DashboardCards/EventCard/EventCard';
import FoxSwitch from './../../Switch/Switch';
import { Close } from '@mui/icons-material'

const CreateTournamentModal = ({ openModal, setOpenModal, chipImg, tableData, communityId,  communitySlug, setTableData, communityName }) => {
    const cancelButtonRef = useRef(null)
    const navigate= useNavigate()
    const dispatch = useDispatch()
    const {loading} = useSelector((state) => state.createTournamentReducer)
    const {data:communityInfo} = useSelector((state) => state.getCommunityByIDReducer)
    const {table:storedTableData} = useSelector((state) => state.saveTableReducer)
    const {editTableInfo:edit} = useSelector((state) => state.editTableInfoReducer)

    const [date, setDate] = useState('')
    const [time, setTime] = useState('')
    const [roles, setRoles] = useState([])
    const [error, setError] = useState('')
    const [hover, setHover] = useState(false)
    const [prizeCount, setPrizeCount] = useState(1)
    const [differentPrizes, setDifferentPrizes] = useState(false)

    useEffect(() => {
        let arr = []
        for(let i=0; i<prizeCount; i++){
            arr.push({
                prizeImg: null,
                prizeName: '',
                prizeAmount: ''
            })
        }
        setData((prev) => ({...prev, prizes: arr}))
    },[prizeCount])

    const [data, setData] = useState({
        name: '',
        tournamentType: '',
        tournamentStartTime: '',
        tournamentSpeed: '',
        entryFees: '',
        maxBid: 100,
        holderOnly: false,
        nftRequired: '',
        rolesRequired: false,
        roleRequired: '',
        tableBackground: TableCardColorShades[0],
        prizeName: '',
        prizeAmount: '',
        prizeImg: null,
        bannerImg: null,
        prizes: [],
        chipName: '',
    })

    const isDisabled = () =>{
        if(data.name === '' ||
            data.tournamentType === '' ||
            data.tournamentStartTime === '' ||
            data?.tournamentSpeed === '' ||
            data?.entryFees === '' ||
            data.maxBid === ''||
            data.maxBid === '' ||
            data.chipName === '' ||
            !data.bannerImg
        ){
            return true
        }
        return false
    }

    // const successfullyCreated = () => {
    //     setOpenModal(false)
    //     setData({
    //         name: '',
    //         tournamentType: '',
    //         tournamentStartTime: '',
    //         tournamentSpeed: '',
    //         entryFees: 0,
    //         maxBid: 100,
    //         holderOnly: false,
    //         nftRequired: '',
    //         rolesRequired: false,
    //         roleRequired: '',
    //         tableBackground: TableCardColorShades[0],
    //         prizeName: '',
    //         prizeAmount: 0,
    //         prizeImg: null,
    //         bannerImg: null,
    //     })
    // }

    const createModal = () => {
        setError('')
        for(let i=0; i<prizeCount; i++){
            let obj  = {...data?.prizes[i]}

            if(!obj.prizeName){
                setError(`Enter Prize ${i+1} Name Properly!`)
                return
            }

            // if(!obj.prizeAmount){
            //     setError(`Enter Prize ${i+1} Amount Properly!`)
            //     return
            // }
        }

        dispatch(createTournament(data, tableData, differentPrizes, communityId, roles, setOpenModal, communitySlug, setError, communityInfo))
        // dispatch(saveTableState(null))
    }

    useEffect(() => {
        if(edit){
            // setData({
            // })
        }else{
            setData({
                name: '',
                tournamentType: '',
                tournamentStartTime: '',
                tournamentSpeed: '',
                entryFees: '',
                maxBid: 100,
                holderOnly: false,
                nftRequired: '',
                rolesRequired: false,
                roleRequired: '',
                tableBackground: TableCardColorShades[0],
                prizeName: '',
                prizeAmount: '',
                prizeImg: null,
                bannerImg: null,
                chipName: '',
                prizes: [],
            })
            setPrizeCount(1)
            setDifferentPrizes(false)
            setDate('')
            setTime('')
        }
    },[edit])

    useEffect(() => {
        if(storedTableData)
            setData({
                name: storedTableData?.name,
                tournamentType: storedTableData?.tournamentType,
                tournamentStartTime: storedTableData.tournamentStartTime,
                tournamentSpeed: storedTableData?.tournamentSpeed,
                entryFees: storedTableData?.entryFees,
                maxBid: storedTableData?.maxBid,
                holderOnly: storedTableData?.holderOnly,
                nftRequired: storedTableData?.nftRequired,
                rolesRequired: storedTableData?.rolesRequired,
                roleRequired: storedTableData?.roleRequired,
                prizeName: storedTableData?.prizeName,
                prizeAmount: storedTableData?.prizeAmount,
                prizeImg: storedTableData?.prizeImg,
                bannerImg: storedTableData?.bannerImg,
                chipName: storedTableData?.chipName,
                prizes: storedTableData?.prizes,
            })
    },[storedTableData])

    useEffect(() => {
        let date1 = new Date(
            date?.split('-')[0],
            date?.split('-')[1] - 1,
            date?.split('-')[2],
            time?.split(':')[0],
            time?.split(':')[1]
        )

        date1 = new Date(date1)?.getTime()
        date1 = new Date(date1)?.toJSON()

        setData((state) => ({ ...state, tournamentStartTime: date1 }))
    }, [date, time])

    // const [anchorEl, setAnchorEl] = useState(null);
    // const open = Boolean(anchorEl);
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    useEffect(() => {
        getRoles(setRoles, communityInfo?.serverId)
    },[communityInfo])

    return (
        <Transition.Root show={openModal} as={Fragment}>
            <Dialog
                as='div'
                className='fixed z-10 inset-0'
                initialFocus={cancelButtonRef}
                onClose={setOpenModal}
            >
                <div className='h-screen px-4 text-center block p-0'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <Dialog.Overlay className='fixed inset-0 bg-[#0c0c0d9a] bg-opacity-75 backdrop-blur-[3px] transition-opacity' />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className='inline-block align-middle h-screen'
                        aria-hidden='true'
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                        enterTo='opacity-100 translate-y-0 sm:scale-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                        leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    >
                        <div className='inline-block bg-transparent rounded-lg text-left transform transition-all my-8 align-middle max-w-[1030px] w-full'>
                            <div className={`${styles.modal_background} flex justify-start w-full items-center rounded-lg text-white h-full p-10 flex-col gap-y-8 border border-[#3D3D3D]`}>
                                <div className={styles.top_header}>
                                    <h1>{edit ? 'Update' : 'Create'} Tournament</h1>
                                </div>

                                <div className={styles.divider}></div>

                                <div className={styles.modal_main_cntnt}>
                                    <div className={styles.form_cntnt}>
                                        <Input label={'TOURNAMENT NAME'} value={data?.name} onChange={((e) => {setData((prev) => ({...prev, name: e?.substr(0, 25)}))})} />

                                        <div className={styles.field_col}>
                                            <label>Tournament banner</label>

                                            {!data?.bannerImg ?
                                                <>
                                                    <input type="file" hidden id="browse_bannerImg" onChange={(e) => {setData((prev) => ({...prev, bannerImg: e.target.files[0]}))}} />
                                                    <label htmlFor="browse_bannerImg" className={styles.browse_image_box}>
                                                        <div className='flex flex-col items-center gap-1'>
                                                            <img src={uploadSvg} alt="" />
                                                            Browse Image
                                                        </div>
                                                    </label>
                                                </>
                                            : data?.bannerImg && <div className={styles.preview_image_box}>
                                                <img className={styles.preview_image} src={typeof data?.bannerImg === 'object' ? URL.createObjectURL(data?.bannerImg) : data?.bannerImg} alt="" />

                                                <button className={styles.del_preview_img}  onClick={(e) => {
                                                    setData((prev) => ({...prev, bannerImg: null}))
                                                }}>
                                                    <Close />
                                                </button>
                                            </div>}
                                        </div>

                                        <Input select selectData={communityInfo?.chipName ? ['Play Chips', `${communityInfo?.chipName}`] : ['Play Chips']} label={'CHIP'} value={data?.chipName} onChange={((e) => {setData((prev) => ({...prev, chipName: e}))})} />

                                        <Input select
                                            label={'TOURNAMENT TYPE'}
                                            value={data?.tournamentType}
                                            selectData={[
                                                'No Limit',
                                            ]}
                                            onChange={((e) => {setData((prev) => ({...prev, tournamentType: e}))})}
                                        />

                                        <Input select
                                            label={'TOURNAMENT SPEED'}
                                            value={data?.tournamentSpeed}
                                            selectData={[
                                                'Regular',
                                                'Turbo',
                                                'Hyper',
                                            ]}
                                            onChange={((e) => {setData((prev) => ({...prev, tournamentSpeed: e}))})}
                                        />

                                        {/* <Input label={'Tournament Starting Time'} value={data?.tournamentStartTime} onChange={((e) => {setData((prev) => ({...prev, tournamentStartTime: e}))})} /> */}
                                        <div className={styles.alin_items}>
                                            <Input type='date' label={'Tournament Starting Date'} value={date} onChange={((e) => {setDate(e)})} />
                                            <Input type='time' label={'Tournament Starting Time'} value={time} onChange={((e) => {setTime(e)})} />
                                        </div>

                                        <Input label={'ENTRY FEES'} value={data?.entryFees} onChange={((e) => {setData((prev) => ({...prev, entryFees: e}))})} />

                                        <Input label={'no. of nfts required'} value={data?.nftRequired} onChange={((e) => {
                                            if(e !== ''){
                                                setData((prev) => ({...prev, holderOnly: true}))
                                            }else{
                                                setData((prev) => ({...prev, holderOnly: false}))
                                            }

                                            setData((prev) => ({...prev, nftRequired: e}))
                                        })} />

                                        <Input select
                                            discord
                                            label={'Discord Role'}
                                            value={data?.roleRequired}
                                            selectData={roles}
                                            onChange={((e) => {
                                                if(e !== ''){
                                                    setData((prev) => ({...prev, rolesRequired: true}))
                                                }else{
                                                    setData((prev) => ({...prev, rolesRequired: false}))
                                                }

                                                setData((prev) => ({...prev, roleRequired: e}))
                                            })}
                                        />

                                        {/* <div className={styles.field_col}>
                                            <label>Discord Role</label>
                                            <select
                                                className={styles.select_input}
                                                value={data.roleRequired}
                                                onChange={((e) => {
                                                    if(e.target.value !== ''){
                                                        setData((prev) => ({...prev, rolesRequired: true}))
                                                    }else{
                                                        setData((prev) => ({...prev, rolesRequired: false}))
                                                    }

                                                    setData((prev) => ({...prev, roleRequired: e.target.value}))
                                                })}
                                            >
                                                <option value={''} className='bg-[#111]'>
                                                    SELECT
                                                </option>

                                                {roles?.map((opt, key) => {
                                                    if(opt?.name !== '@everyone')
                                                        return <option key={key} value={opt?.id} className='bg-[#111]'>
                                                            {opt?.name}
                                                        </option>
                                                    else return ''
                                                })}
                                            </select>
                                        </div> */}

                                        <Input label={'No. Of Winners'} value={prizeCount} onChange={((e) => {setPrizeCount(e)})} />
                                        {parseInt(prizeCount || 0) > 0 && <div className='flex items-center justify-between w-full'>
                                            <label className={styles.label}>Different Prizes for all winners</label>
                                            <FoxSwitch value={differentPrizes} onChange={(e) => setDifferentPrizes(e)} />
                                        </div>}

                                        {differentPrizes && parseInt(prizeCount || 0) > 0 && <Prizes data={data} setData={setData} prizeCount={prizeCount} differentPrizes={differentPrizes} />}
                                        {!differentPrizes && parseInt(prizeCount || 0) > 0 && <Prize data={data} setData={setData} prizeCount={prizeCount} differentPrizes={differentPrizes} />}

                                        {error && <div className={styles.errorWrapper}>
                                            <Alert />
                                            <span>{error}</span>
                                        </div>}

                                        {!edit && <button className="primary-custom-button" disabled={isDisabled() || loading} onClick={() => {
                                                createModal()
                                            }}
                                        >
                                            <div className={`${styles.create_game} primary-custom-button-inner`} >{loading ? 'Creating...' : 'Create Tournament'}</div>
                                        </button>}

                                        {edit && <button className="primary-custom-button" disabled={isDisabled() || loading} onClick={() => {
                                                // dispatch(updateTableAction(data, successfullyCreated, tableData, communityId, setTableData, edit?._id, roles, communityInfo?.slug))
                                                dispatch(saveTableState(null))
                                            }}
                                        >
                                            <div className={`${styles.create_game} primary-custom-button-inner`} >{loading ? 'Updating...' : 'Update Tournament'}</div>
                                        </button>}
                                    </div>

                                    <div className='flex flex-col gap-10'>
                                        <div className={styles.side_preview}>
                                            <div className={styles.preview_wrapper}>
                                                <label htmlFor="" className={styles.tablprvw}>Tournament Preview</label>
                                                <div className={styles.card_preview_box}>
                                                    {/* <TableTopCard openModal={openModal} table={data} img={data?.cardPreview} edit setImage={(e) => setData((prev) => ({...prev, cardPreview: e}))} /> */}
                                                    <EventCard chipImg={chipImg} communityName={communityName} disabled tournament={{...data, time: data?.tournamentStartTime}} community={true} />
                                                </div>
                                                <div className={styles.wrapp_colors}>
                                                    {TableCardColorShades?.map((item, key) => (
                                                        <div className={`${styles.wrap_color} ${key=== data?.tableBackground?.id ? styles.selected : ''}`} key={key} onClick={() => setData((prev) => ({...prev, tableBackground: item}))}>
                                                            <div className={styles.wrap_color_inner} style={{background: item?.backgroundColor}}></div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.side_preview}>
                                            <div className={styles.preview_wrapper}>
                                                <label htmlFor="" className={styles.tablprvw}>Table Preview</label>
                                                <div className={styles.preview_box}>
                                                    <TablePreview tableData={tableData} edit={edit} setTableData={setTableData} />
                                                </div>
                                            </div>
                                            <button onClick={() => {
                                                    dispatch(saveTableState(data))
                                                    navigate(`/${communitySlug}/editTable`);
                                                    setOpenModal(false)}
                                                }
                                                onMouseEnter={() => setHover(true)}
                                                onMouseLeave={() => setHover(false)}
                                                className='secondary-custom-button'
                                            >
                                                <div
                                                    className={`secondary-custom-button-inner ${styles.edit_table}`}
                                                >
                                                    Edit table <ArrowRight fill={hover ? '#D93D48' : 'white'} />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default React.memo(CreateTournamentModal)

const ArrowRight = ({fill}) =>{
    return(
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
        <path d="M8.36719 15.5L13.3672 10.5L8.36719 5.5" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
    )
}

const Prizes = ({prizeCount, setData, data, differentPrizes}) => {
    return <>
        {Array(parseInt(prizeCount) || 0)?.fill(0, 0)?.map((i, k) => {
            return <div className={styles.prize_fields_wrapper} key={k}>
            <div className={styles.field_col}>
                <label>Prize {k+1}</label>
                <label>Prize Picture (OPTIONAL)</label>

                {!data?.prizes[k]?.prizeImg ?
                    <>
                        <input type="file" name="pri" hidden id={`browse_prizeImg${k}`} onChange={(e) => {
                            let arr = [...data?.prizes]
                            arr[k]={...arr[k], prizeImg : e.target.files[0]}
                            setData((prev) => ({...prev, prizes: arr}))
                        }} />
                        <label htmlFor={`browse_prizeImg${k}`} className={styles.browse_image_box}>
                            <div className='flex flex-col items-center gap-1'>
                                <img src={uploadSvg} alt="" />
                                Browse Image
                            </div>
                            {/* <span className={styles.subtitle}>Drag or choose your file to upload</span> */}
                        </label>
                    </>
                : (data?.prizes[k]?.prizeImg) && <div className={styles.preview_image_box}>
                    {data?.prizes[k]?.prizeImg && <img className={styles.preview_image} src={typeof data?.prizes[k]?.prizeImg === 'object' ? URL.createObjectURL(data?.prizes[k]?.prizeImg) : data?.prizes[k]?.prizeImg} alt="" />}

                    <button className={styles.del_preview_img}  onClick={(e) => {
                        let arr = [...data?.prizes]
                        arr[k]={...arr[k], prizeImg : null}
                        setData((prev) => ({...prev, prizes: arr}))
                    }}>
                        <Close />
                    </button>
                </div>}
            </div>

            <div className={styles.alin_items}>
                <Input label={'PRIZE NAME'} dark={true} value={data?.prizes[k]?.prizeName} onChange={((e) => {
                    if(differentPrizes){
                        let arr = [...data?.prizes]
                        arr[k]={...arr[k], prizeName: e}
                        setData((prev) => ({...prev, prizes: arr}))
                    }else{
                        let arr = [...data?.prizes]
                        for(let i=0; i<prizeCount; i++){
                            arr[i]={...arr[i], prizeName: e}
                        }

                        setData((prev) => ({...prev, prizes: arr}))
                    }
                })} />
                <Input label={'PRIZE WORTH ($)'} dark={true} value={data?.prizes[k]?.prizeAmount} onChange={((e) => {
                    if(differentPrizes){
                        let arr = [...data?.prizes]
                        arr[k]={...arr[k], prizeAmount: e}
                        setData((prev) => ({...prev, prizes: arr}))
                    }else{
                        let arr = [...data?.prizes]
                        for(let i=0; i<prizeCount; i++){
                            arr[i]={...arr[i], prizeAmount: e}
                        }

                        setData((prev) => ({...prev, prizes: arr}))
                    }
                })} />
            </div>
        </div>
    })}
</>
}

const Prize = ({prizeCount, setData, data}) => {
    return <div className={styles.prize_fields_wrapper}>
            <div className={styles.field_col}>
                <label>Prize</label>
                <label>Prize Picture (OPTIONAL)</label>

                {!data?.prizes[0]?.prizeImg ?
                    <>
                        <input type="file" hidden id={`browse_prizeImg_`} onChange={(e) => {
                            let arr = [...data?.prizes]
                            for(let i=0; i<prizeCount; i++){
                                arr[i]={...arr[i], prizeImg: e.target.files[0]}
                            }

                            setData((prev) => ({...prev, prizes: arr}))
                        }} />

                        <label htmlFor={`browse_prizeImg_`} className={styles.browse_image_box}>
                            <div className='flex flex-col items-center gap-1'>
                                <img src={uploadSvg} alt="" />
                                Browse Image
                            </div>
                        </label>
                    </>
                : data?.prizes[0]?.prizeImg && <div className={styles.preview_image_box}>
                    {data?.prizes[0]?.prizeImg && <img className={styles.preview_image}  src={typeof data?.prizes[0]?.prizeImg === 'object' ? URL.createObjectURL(data?.prizes[0]?.prizeImg) : data?.prizes[0]?.prizeImg} alt="" />}

                    <button className={styles.del_preview_img}  onClick={(e) => {
                        let arr = [...data?.prizes]
                        for(let i=0; i<prizeCount; i++){
                            arr[i]={...arr[i], prizeImg: null}
                        }

                        setData((prev) => ({...prev, prizes: arr}))
                    }}>
                        <Close />
                    </button>
                </div>}
            </div>

            <div className={styles.alin_items}>
                <Input label={'PRIZE NAME'} dark={true} value={data?.prizes[0]?.prizeName} onChange={((e) => {
                    let arr = [...data?.prizes]
                    for(let i=0; i<prizeCount; i++){
                        arr[i]={...arr[i], prizeName: e}
                    }

                    setData((prev) => ({...prev, prizes: arr}))
                })} />
                <Input label={'PRIZE WORTH ($)'} dark={true} value={data?.prizes[0]?.prizeAmount} onChange={((e) => {
                    let arr = [...data?.prizes]
                    for(let i=0; i<prizeCount; i++){
                        arr[i]={...arr[i], prizeAmount: e}
                    }

                    setData((prev) => ({...prev, prizes: arr}))
                })} />
            </div>
        </div>
    }

const Alert = () => {
    return(<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99951 6.00003V8.6667M7.99951 11.3334H8.00618M7.07639 2.59451L1.59313 12.0656C1.289 12.5909 1.13693 12.8536 1.1594 13.0692C1.17901 13.2572 1.27752 13.4281 1.43043 13.5392C1.60573 13.6667 1.90924 13.6667 2.51625 13.6667H13.4828C14.0898 13.6667 14.3933 13.6667 14.5686 13.5392C14.7215 13.4281 14.82 13.2572 14.8396 13.0692C14.8621 12.8536 14.71 12.5909 14.4059 12.0656L8.92263 2.59451C8.61959 2.07107 8.46806 1.80935 8.27038 1.72145C8.09794 1.64477 7.90108 1.64477 7.72864 1.72145C7.53096 1.80935 7.37944 2.07107 7.07639 2.59451Z" stroke="#F70000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
    </svg>
    )
}