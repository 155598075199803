import { Popover } from '@mui/material'
import styles from './SelectRoundPopover.module.css'
import React from 'react'
import { useDispatch } from 'react-redux'
import { emptyRound, getRound } from '../../../../redux/actions/CommunityAction'

const SelectRoundPopover = ({ handleClose, anchorEl, rounds, currentRound, setCurrentRound, valid, table}) => {
    const dispatch = useDispatch()
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const changeSelection = (e, round) => {
        // console.log(rounds, round, currentRound)
        if(round === rounds){
            dispatch(emptyRound())
        }else{
            dispatch(getRound(table?._id, rounds - (round + 1)))
        }
        setCurrentRound(round)
        handleClose(e)
    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPopover-paper': {
                    background: 'transparent',
                    transform: window?.innerWidth > 1100 ? '' : 'rotate(90deg) !important',
                    top: window?.innerWidth > 1100 ? '' : '345px !important'
                },
            }}
        >
            <div className={`${styles.popover_wrapper}`}>
                {Array(rounds > 15 ? rounds - (rounds - 15) : rounds).fill(0, 0).map((round, key) => (
                    <PopRow round={(rounds - key || 1)} key={key} rounds={rounds} changeSelection={changeSelection} currentRound={currentRound} valid={valid} setCurrentRound={setCurrentRound} handleClose={handleClose} />
                ))}
            </div>
        </Popover>
    )
}

export default SelectRoundPopover

const PopRow = ({round, currentRound, setCurrentRound, handleClose, rounds, changeSelection}) =>{
    return(
        <div className={styles.button_holder}
            onClick={(e) => {changeSelection(e, round)}}
        >
            <div className='flex gap-[8px]'>
                <h2 className={styles.roundText}>{`Round ${round}`}</h2>
                {rounds === round && <h3 className={styles.roundSelected}>[Current]</h3>}
            </div>
        </div>
    )
}